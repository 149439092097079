import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Custom hook to ensure components are recognized as part of the What's On section
 * This sets appropriate document titles and ensures correct navigation highlighting
 */
export const useWhatsOnSection = () => {
  const location = useLocation();
  
  useEffect(() => {
    // Find the What's On link in the navigation
    const whatsOnLink = document.querySelector('a[href="/whats-on"]');
    
    // If the link exists and doesn't have the active class, simulate a click on it
    if (whatsOnLink && !whatsOnLink.classList.contains('active')) {
      // Create and dispatch a custom event that the TopNav component could listen for
      const event = new CustomEvent('forceWhatsOnSection', { detail: { section: 'castle' } });
      document.dispatchEvent(event);
    }
    
    // Set document title based on current path
    if (location.pathname.includes('events')) {
      document.title = "Events - Belvoir Castle";
    } else if (location.pathname.includes('exhibitions')) {
      document.title = "Exhibitions - Belvoir Castle";
    } else if (location.pathname.includes('seasonal-activities')) {
      document.title = "Seasonal Activities - Belvoir Castle";
    } else {
      document.title = "What's On - Belvoir Castle";
    }
  }, [location]);
}; 