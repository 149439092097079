import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
`;

const flash = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const BannerContainer = styled.div`
  position: fixed;
  top: 27px; // Start at top
  left: 0;
  right: 0;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  padding: 0.3rem 0;
  text-align: center;
  z-index: 0; // Below navbar z-index
  transform: translateY(-100%);
  animation: ${props => {
    if (props['data-show'] === 'true') return slideDown;
    if (props['data-hide'] === 'true') return slideUp;
    return 'none';
  }} 0.5s ease forwards;
  margin-top: 120px; // Add margin to push it below navbar
`;

const BannerContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.1rem;
  font-weight: 500;
  animation: ${props => props['data-flash'] === 'true' ? flash : 'none'} 0.5s ease 3;
`;

const BannerLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: 600;
  padding: 0.1rem 0.6rem;
  border: 1px solid white;
  border-radius: 15px;
  transition: all 0.3s ease;
  font-size: 0.9rem;

  &:hover {
    background-color: white;
    color: ${props => props.theme.colors.accent};
  }
`;

const AnnouncementBanner = () => {
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);
  const [flash, setFlash] = useState(false);

  useEffect(() => {
    // Initial delay before showing
    const showTimer = setTimeout(() => {
      setShow(true);
      setHide(false);
      setFlash(true);
      
      // Start hide timer only after banner is shown
      const hideTimer = setTimeout(() => {
        setHide(true);
        setShow(false);
      }, 15000);

      return () => clearTimeout(hideTimer);
    }, 3000);

    return () => clearTimeout(showTimer);
  }, []);

  return (
    <BannerContainer data-show={show.toString()} data-hide={hide.toString()}>
      <BannerContent data-flash={flash.toString()}>
      Roll Up, Roll Up! Book Your Tickets for the Circus!
        <BannerLink to="/whats-on/events">Book Now</BannerLink>
      </BannerContent>
    </BannerContainer>
  );
};

export default AnnouncementBanner; 