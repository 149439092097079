import React from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTree, 
  faChild, 
  faMountain, 
  faLeaf, 
  faUtensils, 
  faMapMarkedAlt,
  faShieldAlt,
  faCoffee,
  faRoute,
  faSmile
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';

// Import images from existing assets
import playgroundImage from '../../../assets/playground.jpg';

import playground1 from '../../../assets/playground1.jpg';
import playground2 from '../../../assets/playground2.jpg';
import playground3 from '../../../assets/playground3.jpg';
import playground4 from '../../../assets/playground4.jpg';
import playground5 from '../../../assets/playground5.jpg';
import playground6 from '../../../assets/playground6.png';
import playstructure from '../../../assets/playstructure.jpg';
import playground from '../../../assets/playground.jpg';
import gardensImage from '../../../assets/gardens.png';
import castleImage from '../../../assets/castle.png';
import playgroundBanImage from '../../../assets/banner/playBan4.webp';

const PlaygroundContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const HighlightSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 5rem 2rem;
  margin: 5rem 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    clip-path: polygon(0 0, 100% 100%, 0 100%);
  }
`;

const HighlightContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const HighlightTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: white;
  margin-bottom: 2rem;
`;

const HighlightText = styled.p`
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const FeaturesSection = styled.div`
  margin: 5rem 0;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const FeatureContent = styled.div`
  padding: 2rem;
  text-align: center;
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  color: white;
  font-size: 32px;
`;

const FeatureTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const GallerySection = styled.div`
  margin: 5rem 0;
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 250px);
  gap: 1.5rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 200px);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 200px);
  }
`;

const GalleryItem = styled.div`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  
  &:nth-child(1) {
    grid-column: span 2;
    grid-row: span 1;
    
    @media (max-width: 768px) {
      grid-column: span 1;
    }
  }
  
  &:nth-child(4) {
    grid-column: span 2;
    grid-row: span 1;
    
    @media (max-width: 768px) {
      grid-column: span 1;
    }
  }
  
  &:hover img {
    transform: scale(1.1);
  }
  
  &:hover .overlay {
    opacity: 1;
  }
`;

const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
`;

const GalleryOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

const GalleryTitle = styled.h5`
  color: white;
  font-size: 1.5rem;
  text-align: center;
  padding: 1rem;
`;

const MapSection = styled.div`
  margin: 5rem 0;
`;

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 12px;
  overflow: hidden;
  margin-top: 3rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    height: 400px;
  }
`;

const MapPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${playgroundImage});
  background-size: cover;
  background-position: center;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const MapOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const MapIcon = styled.div`
  font-size: 4rem;
  color: ${props => props.theme.colors.accent};
  margin-bottom: 1rem;
`;

const MapText = styled.p`
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary};
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1rem 2rem;
  border-radius: 8px;
  font-weight: 600;
`;

const InfoSection = styled.div`
  margin: 5rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const InfoColumn = styled.div`
  flex: 1;
  min-width: 300px;
`;

const InfoTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  position: relative;
  padding-left: 2rem;
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 30px;
    background-color: ${props => props.theme.colors.accent};
    border-radius: 5px;
  }
`;

const InfoList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const InfoItem = styled.li`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  padding-left: 2rem;
  position: relative;
  
  &::before {
    content: '•';
    position: absolute;
    left: 0;
    color: ${props => props.theme.colors.accent};
    font-size: 1.5rem;
  }
`;

const InfoText = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
`;

const CTAButton = styled.a`
  display: inline-block;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1rem 2rem;
  border: 2px solid transparent;
  border-radius: 25px;
  text-decoration: none;
  transition: all 0.3s ease;
  margin-top: 1rem;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const VisitorHubSection = styled.div`
  margin: 5rem 0;
  background-color: ${props => props.theme.colors.greyBackground};
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const VisitorHubContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  align-items: center;
  
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const VisitorHubInfo = styled.div`
  flex: 1;
  min-width: 300px;
`;

const VisitorHubImageContainer = styled.div`
  flex: 1;
  min-width: 300px;
  height: 350px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    height: 250px;
  }
`;

const VisitorHubImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const KidsGallerySection = styled.div`
  margin: 5rem 0;
`;

const KidsGalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const KidsGalleryItem = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  height: 200px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  
  &:hover img {
    transform: scale(1.1);
  }
`;

const KidsGalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
`;

const AdventurePlayground = () => {
  const { ref: featuresRef, inView: featuresInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  const { ref: highlightRef, inView: highlightInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  const { ref: galleryRef, inView: galleryInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  const { ref: mapRef, inView: mapInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  const { ref: infoRef1, inView: infoInView1 } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  const { ref: infoRef2, inView: infoInView2 } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  const { ref: visitorHubRef, inView: visitorHubInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  const { ref: kidsGalleryRef, inView: kidsGalleryInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  return (
    <PlaygroundContainer>
      <ParallaxBanner 
        title="Adventure Playground"
        subtitle="Fun for All Ages at Belvoir Castle"
        image={playgroundImage}
      />
      
      <ContentContainer>
        <SectionTitle>Adventure Playground at Belvoir Castle</SectionTitle>
        <IntroText>
          The Adventure Playground at Belvoir Castle offers a fantastic outdoor experience for children of all ages. 
          Set within our beautiful grounds, the playground provides a safe and exciting environment where children 
          can play, explore, and burn off energy while parents relax and enjoy the surroundings.
        </IntroText>
        
        <FeaturesSection ref={featuresRef}>
          <SectionTitle>Playground Features</SectionTitle>
          <FeaturesGrid>
            <FeatureCard>
              <FeatureContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faTree} />
                </IconWrapper>
                <FeatureTitle>Wooden Play Structures</FeatureTitle>
                <FeatureDescription>
                  Our playground features beautifully crafted wooden play structures including towers, 
                  bridges, and climbing frames designed to blend with the natural environment.
                </FeatureDescription>
              </FeatureContent>
            </FeatureCard>
            
            <FeatureCard>
              <FeatureContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faRoute} />
                </IconWrapper>
                <FeatureTitle>Zip Line</FeatureTitle>
                <FeatureDescription>
                  Experience the thrill of our zip line as you soar through the playground. 
                  A favorite feature that provides excitement for older children.
                </FeatureDescription>
              </FeatureContent>
            </FeatureCard>
            
            <FeatureCard>
              <FeatureContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faMountain} />
                </IconWrapper>
                <FeatureTitle>Slides & Climbing</FeatureTitle>
                <FeatureDescription>
                  Multiple slides of different heights and challenging climbing structures 
                  provide hours of fun and physical activity for children of all ages.
                </FeatureDescription>
              </FeatureContent>
            </FeatureCard>
            
            <FeatureCard>
              <FeatureContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faChild} />
                </IconWrapper>
                <FeatureTitle>Toddler Area</FeatureTitle>
                <FeatureDescription>
                  A specially designed area for our youngest visitors with age-appropriate equipment 
                  and soft play surfaces for safe exploration.
                </FeatureDescription>
              </FeatureContent>
            </FeatureCard>
            
            <FeatureCard>
              <FeatureContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faLeaf} />
                </IconWrapper>
                <FeatureTitle>Picnic Area</FeatureTitle>
                <FeatureDescription>
                  Relax and enjoy refreshments at our dedicated picnic area with tables and benches, 
                  positioned to allow parents to supervise children while they play.
                </FeatureDescription>
              </FeatureContent>
            </FeatureCard>
            
            <FeatureCard>
              <FeatureContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faShieldAlt} />
                </IconWrapper>
                <FeatureTitle>Safe Play Environment</FeatureTitle>
                <FeatureDescription>
                  All equipment is regularly inspected and maintained to the highest safety standards, 
                  with impact-absorbing surfaces throughout the play area.
                </FeatureDescription>
              </FeatureContent>
            </FeatureCard>
          </FeaturesGrid>
        </FeaturesSection>
        
        <KidsGallerySection ref={kidsGalleryRef}>
          <SectionTitle>Children at Play</SectionTitle>
          <IntroText>
            Memories are made at Belvoir Castle's Adventure Playground. See the joy and excitement as children explore our play areas.
          </IntroText>
          
          <KidsGalleryGrid>
            <KidsGalleryItem>
              <KidsGalleryImage src={playground1} alt="Children playing in the adventure playground" />
            </KidsGalleryItem>
            <KidsGalleryItem>
              <KidsGalleryImage src={playground2} alt="Children enjoying the playground equipment" />
            </KidsGalleryItem>
            <KidsGalleryItem>
              <KidsGalleryImage src={playground3} alt="Family fun at the playground" />
            </KidsGalleryItem>
            <KidsGalleryItem>
              <KidsGalleryImage src={playground4} alt="Children climbing and playing" />
            </KidsGalleryItem>
            <KidsGalleryItem>
              <KidsGalleryImage src={playground5} alt="Playground activities" />
            </KidsGalleryItem>
            <KidsGalleryItem>
              <KidsGalleryImage src={playground6} alt="Children having fun at the playground" />
            </KidsGalleryItem>
          </KidsGalleryGrid>
        </KidsGallerySection>
        
        <HighlightSection ref={highlightRef}>
          <HighlightContent>
            <IconWrapper>
              <FontAwesomeIcon icon={faSmile} />
            </IconWrapper>
            <HighlightTitle>A Perfect Family Day Out</HighlightTitle>
            <HighlightText>
              The Adventure Playground is included with your garden admission ticket, making it 
              excellent value for a family day out. Combine your playground visit with exploring 
              the beautiful gardens and enjoying refreshments from our Visitor Hub café.
            </HighlightText>
            <CTAButton href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=40ca044f-534f-4b7f-83ea-b4156f0633d3&type=WebPage&locationId=978&mainWebPageId=b0f1a62a-a34a-4c4e-a88d-6fda03a19279&startId=e39e1d45-8941-4502-8c92-bb7082a9ddbf">
              Book Your Visit
            </CTAButton>
          </HighlightContent>
        </HighlightSection>
        
        <GallerySection ref={galleryRef}>
          <SectionTitle>Playground Gallery</SectionTitle>
          <IntroText>
            Take a peek at some of the exciting features in our Adventure Playground.
          </IntroText>
          
          <GalleryGrid>
            <GalleryItem>
              <GalleryImage src={playground} alt="Playground Overview" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Playground Overview</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={playstructure} alt="Play Structures" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Play Structures</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={playground3} alt="Zip Line" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Zip Line</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={playground4} alt="Climbing Area" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Climbing Area</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={playground5} alt="Toddler Zone" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Toddler Zone</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={playground6} alt="Zip Line" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Zip Line</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
          </GalleryGrid>
        </GallerySection>
        
        <MapSection ref={mapRef}>
          <SectionTitle>Playground Map</SectionTitle>
          <IntroText>
            Find your way around our Adventure Playground with this helpful map.
          </IntroText>
          
          <MapContainer>
            <MapPlaceholder />
            <MapOverlay>
              <MapIcon>
                <FontAwesomeIcon icon={faMapMarkedAlt} />
              </MapIcon>
              <MapText>Interactive Map Coming Soon!</MapText>
            </MapOverlay>
          </MapContainer>
        </MapSection>
        
        <InfoSection>
          <InfoColumn ref={infoRef1}>
            <InfoTitle>Visitor Information</InfoTitle>
            <InfoText>
              Our Adventure Playground is designed for children of all ages, with specific areas 
              dedicated to different age groups. Adult supervision is required at all times, and 
              we recommend comfortable clothing and footwear suitable for outdoor play.
            </InfoText>
            
            <InfoList>
              <InfoItem>Open daily from 9:30 AM to 5:00 PM</InfoItem>
              <InfoItem>Included with garden admission tickets</InfoItem>
              <InfoItem>Picnic area available with tables and benches</InfoItem>
              <InfoItem>Visitor Hub with café adjacent to the playground</InfoItem>
              <InfoItem>Toilet facilities located in the Visitor Hub</InfoItem>
            </InfoList>
            
            <CTAButton href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=40ca044f-534f-4b7f-83ea-b4156f0633d3&type=WebPage&locationId=978&mainWebPageId=b0f1a62a-a34a-4c4e-a88d-6fda03a19279&startId=e39e1d45-8941-4502-8c92-bb7082a9ddbf">
              Plan Your Visit
            </CTAButton>
          </InfoColumn>
          
          <InfoColumn ref={infoRef2}>
            <InfoTitle>Safety Information</InfoTitle>
            <InfoText>
              At Belvoir Castle, the safety of our visitors is our top priority. Our Adventure 
              Playground is regularly inspected and maintained to the highest standards, and our 
              staff are always on hand to ensure a safe and enjoyable experience.
            </InfoText>
            
            <IconWrapper style={{ margin: '2rem auto', backgroundColor: theme.colors.primary }}>
              <FontAwesomeIcon icon={faShieldAlt} />
            </IconWrapper>
            
            <InfoText>
              We ask all visitors to follow the safety guidelines posted throughout the playground 
              and to supervise children at all times. Appropriate footwear must be worn, and we 
              recommend bringing sun protection and water during warmer months.
            </InfoText>
            
            <InfoText>
              First aid facilities are available on site, and our staff are trained to assist in 
              case of any emergencies.
            </InfoText>
          </InfoColumn>
        </InfoSection>
        
        <VisitorHubSection ref={visitorHubRef}>
          <SectionTitle>Visitor Hub</SectionTitle>
          <VisitorHubContent>
            <VisitorHubInfo>
              <InfoTitle>Refreshments & Facilities</InfoTitle>
              <InfoText>
                Located adjacent to the Adventure Playground, our Visitor Hub provides a convenient 
                place to relax and refuel during your visit. The Hub features a café serving a variety 
                of hot and cold refreshments, light meals, and snacks.
              </InfoText>
              <InfoList>
                <InfoItem>Fresh coffee, tea, and cold drinks</InfoItem>
                <InfoItem>Selection of sandwiches, pastries, and cakes</InfoItem>
                <InfoItem>Ice cream and snacks</InfoItem>
                <InfoItem>Children's meal options</InfoItem>
                <InfoItem>Indoor and outdoor seating areas</InfoItem>
                <InfoItem>Clean, modern toilet facilities</InfoItem>
                <InfoItem>Baby changing facilities</InfoItem>
              </InfoList>
              <InfoText>
                The Visitor Hub is the perfect place to take a break while the children enjoy the 
                playground, with seating positioned to allow you to keep an eye on the play area.
              </InfoText>
              <IconWrapper style={{ display: 'inline-flex', margin: '1rem 1rem 1rem 0', width: '50px', height: '50px' }}>
                <FontAwesomeIcon icon={faCoffee} />
              </IconWrapper>
              <IconWrapper style={{ display: 'inline-flex', margin: '1rem 1rem 1rem 0', width: '50px', height: '50px' }}>
                <FontAwesomeIcon icon={faUtensils} />
              </IconWrapper>
            </VisitorHubInfo>
            <VisitorHubImageContainer>
              <VisitorHubImage src={gardensImage} alt="Visitor Hub" />
            </VisitorHubImageContainer>
          </VisitorHubContent>
        </VisitorHubSection>
      </ContentContainer>
      
      <Footer />
    </PlaygroundContainer>
  );
};

export default AdventurePlayground;
