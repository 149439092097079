import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Import partner logos
import tripadvisorLogo from '../../assets/partners/tripadvisor.png';
import hiddenEnglandLogo from '../../assets/partners/hidden-england.png';
import rhsLogo from '../../assets/partners/rhs.png';
import historicHousesLogo from '../../assets/partners/historic-houses.png';
import visitLincolnshireLogo from '../../assets/partners/visit-lincolnshire.png';
import visitNottinghamshireLogo from '../../assets/partners/visit-nottinghamshire.png';
import southKestevenLogo from '../../assets/partners/south-kesteven.png';
import explorers from '../../assets/partners/explorers.png';
import whiteLogo from '../../assets/whiteLogo.webp';

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 2rem 0 1rem 0;
`;

const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 5%;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
`;

const LogoSection = styled.div`
  flex: 1;
  max-width: 100%;

  img {
    width: 200px;
    height: auto;
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const FooterNav = styled.div`
  display: flex;
  gap: 4rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }
`;

const FooterSection = styled.div`
  h3 {
    color: ${props => props.theme.colors.accent};
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-family: ${props => props.theme.fonts.main};
  }

  p {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  a {
    color: white;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s ease;

    &:hover {
      color: ${props => props.theme.colors.accent};
    }
  }
`;

const DirectionsButton = styled.button`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: 1px solid white;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: white;
    color: ${props => props.theme.colors.accent};
  }
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.8rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
`;

const BottomLinks = styled.div`
  display: flex;
  gap: 2rem;

  a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: ${props => props.theme.colors.accent};
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const PartnersSection = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin-top: 2rem;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 0.5rem;
  width: 100%;

  img {
    width: auto;
    height: 40px;
    object-fit: contain;
    opacity: 0.9;
    transition: opacity 0.3s ease;
    flex-shrink: 0;

    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    gap: 1rem;
    margin: 1rem 0;
    
    img {
      height: 35px;
    }
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterTop>
        <LogoSection>
          <img src={whiteLogo} alt="Belvoir Castle Logo" />
          <PartnersSection>
            <img src={tripadvisorLogo} alt="TripAdvisor" />
            <img src={hiddenEnglandLogo} alt="Hidden England" />
            <img src={rhsLogo} alt="RHS" />
            <img src={historicHousesLogo} alt="Historic Houses" />
            <img src={visitLincolnshireLogo} alt="Visit Lincolnshire" />
            <img src={visitNottinghamshireLogo} alt="Visit Nottinghamshire" />
            <img src={southKestevenLogo} alt="Discover South Kesteven" />
            <img src={explorers} alt="Explorers" />
          </PartnersSection>
        </LogoSection>
        
        <FooterNav>
          <FooterSection>
            <h3>Visit Us</h3>
            <p>Belvoir Castle</p>
            <p>Grantham</p>
            <p>Leicestershire</p>
            <p>NG32 1PE</p>
            <DirectionsButton>Get Directions</DirectionsButton>
          </FooterSection>

          <FooterSection>
            <h3>Contact</h3>
            <p>Tel: +44 (0)1476 871001</p>
            <p>Email: info@belvoircastle.com</p>
          </FooterSection>

          <FooterSection>
            <h3>Quick Links</h3>
            <FooterLinks>
              <Link to="/your-visit/day-tickets">Buy Tickets</Link>
              <Link to="/whats-on/events">Events</Link>
              <Link to="/weddings">Weddings</Link>
              <Link to="/castle-dining">Dining</Link>
              <Link to="/accommodations">Stay With Us</Link>
            </FooterLinks>
          </FooterSection>
        </FooterNav>
      </FooterTop>

      <FooterBottom>
        <div>
          <p>&copy; {new Date().getFullYear()} Belvoir Castle. All rights reserved.</p>
        </div>
        <BottomLinks>
          <Link to="/your-visit/opening-times">Opening Times</Link>
          <Link to="/cookie-policy">Cookie Policy</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms">Terms & Conditions</Link>
        </BottomLinks>
      </FooterBottom>
    </FooterWrapper>
  );
};

export default Footer;
