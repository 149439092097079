import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTshirt, 
  faExternalLinkAlt, 
  faShoppingBag, 
  faClock,
  faStore
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';

// Import placeholder image - replace with actual shop image
import retailImage from '../../../assets/ToriMurphy.webp';

// Import custom hook to force retail section
import { useRetailSection } from '../../../hooks/useRetailSection';

const ToriMurphyContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const CurrentTimeDisplay = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 3rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
`;

const ShopCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto 4rem;
`;

const CardImage = styled.div`
  height: 350px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const CardContent = styled.div`
  padding: 2rem;
`;

const CardTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0;
`;

const StatusIndicator = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.isOpen ? '#4CAF50' : '#F44336'};
  margin-right: 8px;
`;

const StatusText = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: ${props => props.isOpen ? '#4CAF50' : '#F44336'};
  display: flex;
  align-items: center;
`;

const TimeInfo = styled.div`
  margin-bottom: 1.5rem;
`;

const DayLabel = styled.div`
  color: ${props => props.theme.colors.secondary};
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
`;

const Hours = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: 1.6rem;
  font-weight: 500;
`;

const Note = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: 0.9rem;
  font-style: italic;
  margin-top: 0.5rem;
`;

const ShopDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const ExternalLinkButton = styled.a`
  display: inline-flex;
  align-items: center;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  text-decoration: none;
  border: 2px solid transparent;
  border-radius: 25px;
  padding: 1rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  
  svg {
    margin-left: 0.5rem;
  }
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 4rem 0;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  text-align: center;
  padding: 2rem;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  
  svg {
    color: white;
    font-size: 32px;
  }
`;

const FeatureTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const StatusBadge = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  background-color: ${props => props.isOpen ? 'rgba(76, 175, 80, 0.85)' : 'rgba(244, 67, 54, 0.85)'};
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  backdrop-filter: blur(4px);
`;

const ToriMurphy = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  
  // Use custom hook to ensure we stay in retail section
  useRetailSection();
  
  // Update current time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    
    return () => clearInterval(timer);
  }, []);
  
  // Format current time
  const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const currentDay = currentTime.toLocaleDateString('en-US', { weekday: 'long' });
  
  // Shop opening hours
  const openingHours = {
    days: "Daily",
    times: "9:00 - 17:00",
    note: "Last entry at 16:30"
  };
  
  // Function to check if the shop is currently open
  const isShopOpen = () => {
    // Parse opening hours
    const timeRange = openingHours.times.split('-').map(t => t.trim());
    if (timeRange.length !== 2) return false;
    
    // Convert times to 24-hour format for comparison
    const [openHour, openMinute] = timeRange[0].split(':').map(Number);
    const [closeHour, closeMinute] = timeRange[1].split(':').map(Number);
    
    const openTime = new Date();
    openTime.setHours(openHour, openMinute || 0, 0);
    
    const closeTime = new Date();
    closeTime.setHours(closeHour, closeMinute || 0, 0);
    
    // Check if current time is between open and close times
    return currentTime >= openTime && currentTime <= closeTime;
  };
  
  return (
    <ToriMurphyContainer>
      <ParallaxBanner 
        title="Tori Murphy"
        subtitle="British-made luxury textiles and homeware"
      />
      
      <ContentContainer>
        <SectionTitle>Tori Murphy</SectionTitle>
        <IntroText>
          Discover the exquisite collection of British-made luxury textiles and homeware at Tori Murphy's boutique in the Belvoir Castle Retail Village. 
          Each piece is crafted with meticulous attention to detail, combining timeless design with exceptional quality.
        </IntroText>
        
        <CurrentTimeDisplay>
          Current Time: {formattedTime} on {currentDay}
        </CurrentTimeDisplay>
        
        <ShopCard>
          <CardImage image={retailImage} />
          <CardContent>
            <CardTitle>Tori Murphy at Belvoir Castle</CardTitle>
            <StatusContainer>
              <StatusText isOpen={isShopOpen()}>
                <StatusIndicator isOpen={isShopOpen()} />
                {isShopOpen() ? 'Open Now' : 'Closed'}
              </StatusText>
            </StatusContainer>
            <TimeInfo>
              <DayLabel>{openingHours.days}</DayLabel>
              <Hours>{openingHours.times}</Hours>
              <Note>{openingHours.note}</Note>
            </TimeInfo>
            <ShopDescription>
              Tori Murphy is a British textile design company founded in 2012, specializing in woven fabrics and homeware. 
              All products are designed in-house and manufactured in England, with a focus on quality, craftsmanship, and timeless design. 
              The collection includes throws, cushions, upholstery fabrics, and accessories that combine contemporary aesthetics with traditional techniques.
            </ShopDescription>
            <ExternalLinkButton href="https://www.torimurphy.com" target="_blank" rel="noopener noreferrer">
              Visit Tori Murphy Website <FontAwesomeIcon icon={faExternalLinkAlt} />
            </ExternalLinkButton>
          </CardContent>
        </ShopCard>
        
        <SectionTitle>Featured Products</SectionTitle>
        <IntroText>
          Tori Murphy offers a range of luxury textiles and homeware, all designed and made in Britain with a focus on quality and craftsmanship.
        </IntroText>
        
        <FeaturesGrid>
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faTshirt} />
            </IconWrapper>
            <FeatureTitle>Luxury Textiles</FeatureTitle>
            <FeatureDescription>
              Beautifully crafted throws, cushions, and fabrics made from the finest materials, designed to add elegance and comfort to your home.
            </FeatureDescription>
          </FeatureCard>
          
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faShoppingBag} />
            </IconWrapper>
            <FeatureTitle>Exclusive Collection</FeatureTitle>
            <FeatureDescription>
              Discover special pieces created exclusively for Belvoir Castle, inspired by the estate's rich heritage and stunning surroundings.
            </FeatureDescription>
          </FeatureCard>
          
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faStore} />
            </IconWrapper>
            <FeatureTitle>British Craftsmanship</FeatureTitle>
            <FeatureDescription>
              Every Tori Murphy product is designed and made in Britain, supporting local manufacturing and traditional textile skills.
            </FeatureDescription>
          </FeatureCard>
        </FeaturesGrid>
        
        <div style={{ textAlign: 'center', margin: '3rem 0' }}>
          <ExternalLinkButton href="https://www.torimurphy.com" target="_blank" rel="noopener noreferrer">
            Shop Online at Tori Murphy <FontAwesomeIcon icon={faExternalLinkAlt} />
          </ExternalLinkButton>
        </div>
      </ContentContainer>
      
      <Footer />
    </ToriMurphyContainer>
  );
};

export default ToriMurphy; 