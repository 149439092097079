import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUtensils, 
  faCoffee,
  faClock,
  faCrown
} from '@fortawesome/free-solid-svg-icons';
import ParallaxBanner from '../../common/Parralax-banner';
import Footer from '../../Footer/Footer';
import Button from '../../Button/Button';
import castleImage from '../../../assets/castle2.png';
import aviaryImage from '../../../assets/tearoom.webp';
import afternoonImage from '../../../assets/afternoonTea.webp';

const DiningContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 3rem;
`;

const TabButton = styled.button`
  background-color: ${props => props.$active ? props.theme.colors.accent : 'transparent'};
  color: ${props => props.$active ? 'white' : props.theme.colors.accent};
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  border: 2px solid ${props => props.theme.colors.accent};
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    background-color: ${props => props.$active ? props.theme.colors.accent : 'rgba(0, 0, 0, 0.05)'};
  }

  svg {
    font-size: 1.2rem;
  }
`;

const TabDropdown = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  min-width: 200px;
  z-index: 1000;
  margin-top: 0.5rem;

  ${TabButton}:hover & {
    display: block;
  }
`;

const DropdownItem = styled.div`
  padding: 0.8rem 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  svg {
    font-size: 1rem;
  }
`;

const IntroSection = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const IntroTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  color: ${props => props.theme.colors.primary};
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.body};
  color: ${props => props.theme.colors.secondary};
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
`;

const CurrentTimeDisplay = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 3rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const VenuesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 3rem;
  margin-top: 4rem;
`;

const VenueCard = styled.div`
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
`;

const VenueImage = styled.div`
  height: 250px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  position: relative;
`;

const VenueStatus = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: ${props => props.$isOpen ? 'rgba(39, 174, 96, 0.85)' : 'rgba(244, 67, 54, 0.85)'};
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  backdrop-filter: blur(4px);
`;

const VenueContent = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
`;

const VenueTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  svg {
    font-size: 1.5rem;
  }
`;

const VenueDescription = styled.p`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.secondary};
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const VenueHours = styled.div`
  font-size: 1rem;
  color: ${props => props.theme.colors.secondary};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

const VenueButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const StyledButton = styled(Button)`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: 2px solid ${props => props.theme.colors.accent};
  padding: 0.6rem 1.5rem;
  border-radius: 50px;
  font-family: ${props => props.theme.fonts.main};
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  width: auto;
  text-align: center;

  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
  }
`;

const TabContent = styled.div`
  background-color: ${props => props.$active ? props.theme.colors.accent : 'rgba(0, 0, 0, 0.05)'};
`;

const CastleDining = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentHour = new Date().getHours();
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  const venues = [
    {
      title: "The Aviary Tearoom",
      description: "Experience traditional afternoon tea in our elegant tearoom. Enjoy freshly baked scones, delicate sandwiches, and a selection of fine teas in a historic setting.",
      image: aviaryImage,
      icon: faCoffee,
      hours: "10:00 - 17:00",
      isOpen: currentHour >= 10 && currentHour < 17,
      path: "/castle-dining/aviary-tearoom"
    },
    {
      title: "Afternoon Tea",
      description: "Indulge in our signature afternoon tea experience. Served in the grand surroundings of the castle, featuring homemade pastries, finger sandwiches, and premium teas.",
      image: afternoonImage,
      icon: faUtensils,
      hours: "12:00 - 17:00",
      isOpen: currentHour >= 12 && currentHour < 17,
      path: "/castle-dining/afternoon-tea"
    }
  ];

  return (
    <DiningContainer>
      <ParallaxBanner 
        image={castleImage}
        title="Castle Dining"
        subtitle="Elegant dining experiences in historic surroundings"
      />
      <ContentContainer>
        <TabContainer>
          <TabButton 
            $active={location.pathname === '/retail-village/dining'}
            onClick={() => navigate('/retail-village/dining')}
          >
            Retail Village Dining
          </TabButton>
          <TabButton 
            $active={location.pathname === '/castle-dining'}
            onClick={() => navigate('/castle-dining')}
          >
            Castle Dining
          </TabButton>
        </TabContainer>
        <IntroSection>
          <IntroTitle>Castle Dining Experiences</IntroTitle>
          <IntroText>
            Discover the elegance of dining within the historic walls of Belvoir Castle. From traditional afternoon tea 
            to intimate dining experiences, our castle venues offer a unique blend of heritage and culinary excellence.
          </IntroText>
        </IntroSection>
        <CurrentTimeDisplay>
          Current Time: {currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
        </CurrentTimeDisplay>
        <VenuesGrid>
          {venues.map((venue, index) => (
            <VenueCard key={index}>
              <VenueImage image={venue.image}>
                <VenueStatus $isOpen={venue.isOpen}>
                  <FontAwesomeIcon icon={faClock} />
                  {venue.isOpen ? 'Open Now' : 'Closed'}
                </VenueStatus>
              </VenueImage>
              <VenueContent>
                <VenueTitle>
                  <FontAwesomeIcon icon={venue.icon} />
                  {venue.title}
                </VenueTitle>
                <VenueDescription>{venue.description}</VenueDescription>
                <VenueHours>
                  <FontAwesomeIcon icon={faClock} />
                  {venue.hours}
                </VenueHours>
                <VenueButtonContainer>
                  <StyledButton onClick={() => navigate(venue.path)}>
                    Learn More
                  </StyledButton>
                </VenueButtonContainer>
              </VenueContent>
            </VenueCard>
          ))}
        </VenuesGrid>
      </ContentContainer>
      <Footer />
    </DiningContainer>
  );
};

export default CastleDining; 