import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faClock, 
  faUsers, 
  faTicketAlt,
  faArrowRight,
  faFilm,
  faPalette,
  faCrown,
  faTree,
  faSeedling,
  faDoorOpen
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';

// Import images
import castleImage from '../../../assets/castle2.png';
import filmTourImage from '../../../assets/tours/film-lovers.webp';
import artTourImage from '../../../assets/tours/art-lovers.webp';
import exclusiveTourImage from '../../../assets/tours/exclusive-tour.webp';
import parklandTourImage from '../../../assets/tours/garden-tour.webp';
import gardenTourImage from '../../../assets/rose-garden.jpg';
import butlerTourImage from '../../../assets/tours/what-the-butler-saw.webp';

const ToursContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 3px;
    background-color: ${props => props.theme.colors.accent};
    margin: 1rem auto 0;
  }
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const ToursGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const TourCard = styled.div`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  
  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
`;

const TourImage = styled.div`
  height: 250px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: ${props => props.image.includes('butler-saw') ? 'center 25%' : 'center'};
`;

const TourContent = styled.div`
  padding: 2rem;
`;

const TourTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  svg {
    color: ${props => props.theme.colors.accent};
  }
`;

const TourDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 1.5rem;
`;

const TourDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1.5rem;
  color: ${props => props.theme.colors.secondary};
  font-size: 1rem;

  svg {
    color: ${props => props.theme.colors.accent};
  }
`;

const BookButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  text-decoration: none;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  font-weight: 600;
  transition: all 0.3s ease;
  border: 2px solid ${props => props.theme.colors.accent};
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
  }
  
  svg {
    margin-left: 0.5rem;
  }
`;

const FeaturedTours = () => {
  const tours = [
    {
      title: "Film Lover's Tour",
      description: "Join our knowledgeable guides and step into the State Rooms that were used as locations in the popular Netflix Series, The Crown and films such as The Young Victoria and Victoria & Abdul. Hear firsthand about the film crews using our beautiful Castle and Grounds in location filming.",
      duration: "1 ½ hours",
      icon: faFilm,
      image: filmTourImage
    },
    {
      title: "Belvoir's Art Collection Tour",
      description: "Join a guide for a tour around the State Rooms, home to the Duke and Duchess of Rutland. Hear about his Grace's incredible art collection, including works by Gainsborough, Stubbs, and Sir Joshua Reynolds.",
      duration: "1 ½ hours",
      icon: faPalette,
      image: artTourImage
    },
    {
      title: "Exclusive Belvoir Tour",
      description: "Gain exclusive access to areas of the Castle which are usually out of bounds. See historical documents which are not on everyday display in an exclusive group whilst hearing stories and antidotes about royalty and 'celebrities' of the day.",
      duration: "1 ½ hours",
      groupSize: "Minimum 4 visitors, maximum 12",
      icon: faCrown,
      image: exclusiveTourImage
    },
    {
      title: "Parkland Tours",
      description: "From Knipton Gate across to the Walled Garden, learn about the recently discovered Lancelot 'Capability' Brown's plans for the Parkland around Belvoir Castle and how these have been implemented in recent years, travelling right up to the Brewer's Grave and past our 'hound hotel'.",
      duration: "1 ½ hours",
      icon: faTree,
      image: parklandTourImage
    },
    {
      title: "Garden Tour",
      description: "Enjoy a seasonal tour with our head gardener and discover the beauty of our Castle Gardens. Enjoy the Snowdrops early February, Daffodils in Spring & Roses all summer long. Autumn leaves welcome you whilst you explore the secrets of our Garden.",
      icon: faSeedling,
      image: gardenTourImage
    },
    {
      title: "What the Butler Saw",
      description: "Join one of our knowledgeable guides on tour taking you to areas of the Castle used by household staff in times gone by. Hear fascinating stories of how the house was run downstairs and, on the estate, including innovations that were used at Belvoir Castle.",
      icon: faDoorOpen,
      image: butlerTourImage
    }
  ];

  return (
    <ToursContainer>
      <ParallaxBanner 
        title="Featured Tours"
        subtitle="Discover the Hidden Stories of Belvoir Castle"
        image={castleImage}
      />
      
      <ContentContainer>
        <SectionTitle>Guided Tours at Belvoir Castle</SectionTitle>
        <IntroText>
          Experience the rich history and fascinating stories of Belvoir Castle through our carefully curated guided tours. 
          Each tour offers a unique perspective on the castle's heritage, from its stunning art collection to its role in film and television.
        </IntroText>
        
        <ToursGrid>
          {tours.map((tour, index) => (
            <TourCard key={index}>
              <TourImage image={tour.image} />
              <TourContent>
                <TourTitle>
                  <FontAwesomeIcon icon={tour.icon} />
                  {tour.title}
                </TourTitle>
                <TourDescription>{tour.description}</TourDescription>
                <TourDetails>
                  {tour.duration && (
                    <div>
                      <FontAwesomeIcon icon={faClock} /> {tour.duration}
                    </div>
                  )}
                  {tour.groupSize && (
                    <div>
                      <FontAwesomeIcon icon={faUsers} /> {tour.groupSize}
                    </div>
                  )}
                </TourDetails>
                <BookButton to="/book-tickets">
                  Booking Coming Soon <FontAwesomeIcon icon={faArrowRight} />
                </BookButton>
              </TourContent>
            </TourCard>
          ))}
        </ToursGrid>

        {/* New Daily Tours Section */}
        <SectionTitle style={{ marginTop: '4rem' }}>Daily Tours Available</SectionTitle>
        <IntroText>
          Looking for a more traditional castle experience? Our daily guided tours of the state rooms and 
          Capability Brown bus tours are available throughout the year.
        </IntroText>
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
          <BookButton to="/your-visit/tours">
            View Daily Tours <FontAwesomeIcon icon={faArrowRight} />
          </BookButton>
        </div>
      </ContentContainer>
      
      <Footer />
    </ToursContainer>
  );
};

export default FeaturedTours; 