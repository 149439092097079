import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCalendarAlt, 
  faLeaf, 
  faSnowflake, 
  faSun, 
  faTree,
  faTicketAlt,
  faUsers,
  faChild,
  faCamera,
  faUtensils
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';
import { useWhatsOnSection } from '../../../hooks/useWhatsOnSection';

// Import images
import castleImage from '../../../assets/castle.jpg';
import gardensImage from '../../../assets/gardens.png';
import playgroundImage from '../../../assets/play.png';
import activitiesImage from '../../../assets/activities.jpg';
import roseGardenImage from '../../../assets/rose-garden.jpg';

const SeasonalContainer = styled.div`
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const SeasonTabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  gap: 1rem;
`;

const SeasonTab = styled.button`
  background-color: ${props => props.active ? props.theme.colors.accent : props.theme.colors.greyBackground};
  color: ${props => props.active ? 'white' : props.theme.colors.primary};
  border: none;
  border-radius: 30px;
  padding: 0.8rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 0.5rem;
    color: ${props => props.active ? 'white' : props.color || props.theme.colors.accent};
  }
  
  &:hover {
    background-color: ${props => props.active ? props.theme.colors.accent : '#e9e9e9'};
    transform: translateY(-3px);
  }
`;

const ActivitiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const ActivityCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
`;

const ActivityImage = styled.div`
  height: 250px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const ActivityContent = styled.div`
  padding: 1.5rem;
`;

const ActivityTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.6rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
`;

const ActivityDate = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 1rem;
  
  svg {
    margin-right: 0.5rem;
    color: ${props => props.theme.colors.accent};
  }
`;

const ActivityDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const ActivityFeatures = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const ActivityFeature = styled.span`
  display: inline-flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.9rem;
  
  svg {
    margin-right: 0.4rem;
    color: ${props => props.theme.colors.accent};
  }
`;

const ActivityButton = styled.button`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 0.5rem;
  }
  
  &:hover {
    background-color: ${props => props.theme.colors.primary};
    transform: translateY(-3px);
  }
`;

const HighlightSection = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  padding: 4rem 2rem;
  margin: 5rem 0;
  text-align: center;
`;

const HighlightContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const HighlightTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const HighlightDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const HighlightButton = styled.button`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: inline-flex;
  align-items: center;
  
  svg {
    margin-right: 0.5rem;
  }
  
  &:hover {
    background-color: ${props => props.theme.colors.primary};
    transform: translateY(-3px);
  }
`;

const YearRoundSection = styled.div`
  margin: 5rem 0;
`;

const YearRoundGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const YearRoundCard = styled.div`
  text-align: center;
  padding: 2rem;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  
  svg {
    color: white;
    font-size: 32px;
  }
`;

const YearRoundTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const YearRoundDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const SeasonalActivities = () => {
  const [activeSeason, setActiveSeason] = useState('spring');
  
  // Use the What's On section hook
  useWhatsOnSection();
  
  // Seasonal activities data
  const seasonalActivities = {
    spring: [
      {
        id: 1,
        title: "Spring Garden Tours",
        date: "April - May 2024",
        description: "Experience the awakening of Belvoir's magnificent gardens as spring blooms transform the landscape. Our expert gardeners lead guided tours showcasing the seasonal highlights, including rare spring bulbs, flowering trees, and the castle's famous daffodil displays.",
        image: gardensImage,
        features: [
          { icon: faUsers, text: "Guided Tours" },
          { icon: faCamera, text: "Photography" },
          { icon: faLeaf, text: "Garden Enthusiasts" }
        ]
      },
      {
        id: 2,
        title: "Easter Egg Hunt Extravaganza",
        date: "Easter Weekend 2024",
        description: "Join us for our annual Easter celebration with a grand egg hunt throughout the castle grounds. Children will delight in searching for hidden treasures while adults can enjoy spring-inspired refreshments and entertainment for the whole family.",
        image: activitiesImage,
        features: [
          { icon: faChild, text: "Family-Friendly" },
          { icon: faUtensils, text: "Refreshments" },
          { icon: faTicketAlt, text: "Special Event" }
        ]
      },
      {
        id: 3,
        title: "Spring Foraging Walks",
        date: "May 2024",
        description: "Discover the edible delights of spring with our expert-led foraging walks through Belvoir's woodlands and meadows. Learn to identify wild garlic, spring greens, and edible flowers, followed by a demonstration of how to prepare your foraged treasures.",
        image: roseGardenImage,
        features: [
          { icon: faUsers, text: "Expert-Led" },
          { icon: faLeaf, text: "Nature" },
          { icon: faUtensils, text: "Culinary" }
        ]
      }
    ],
    summer: [
      {
        id: 1,
        title: "Summer Rose Garden Tours",
        date: "June - August 2024",
        description: "Immerse yourself in the intoxicating scents and stunning colors of Belvoir's historic rose gardens at their peak. Our specialized tours highlight the castle's collection of heritage roses, modern varieties, and the fascinating history of rose cultivation at Belvoir.",
        image: roseGardenImage,
        features: [
          { icon: faUsers, text: "Guided Tours" },
          { icon: faCamera, text: "Photography" },
          { icon: faLeaf, text: "Garden Enthusiasts" }
        ]
      },
      {
        id: 2,
        title: "Outdoor Theatre Performances",
        date: "July - August 2024",
        description: "Experience the magic of live theatre against the backdrop of Belvoir Castle. Our summer season features performances of Shakespeare classics and family-friendly productions in the castle grounds. Bring a picnic and enjoy theatre under the stars.",
        image: castleImage,
        features: [
          { icon: faUsers, text: "Entertainment" },
          { icon: faChild, text: "Family-Friendly" },
          { icon: faTicketAlt, text: "Special Event" }
        ]
      },
      {
        id: 3,
        title: "Summer Adventure Days",
        date: "School Holidays 2024",
        description: "Keep the children entertained during the summer holidays with our action-packed adventure days. Activities include treasure hunts, outdoor games, nature crafts, and exclusive access to our adventure playground with special supervised activities.",
        image: playgroundImage,
        features: [
          { icon: faChild, text: "Children's Activities" },
          { icon: faUsers, text: "Supervised" },
          { icon: faTicketAlt, text: "Special Event" }
        ]
      }
    ],
    autumn: [
      {
        id: 1,
        title: "Autumn Colours Walks",
        date: "September - October 2024",
        description: "Witness the spectacular transformation of Belvoir's woodlands and gardens as autumn paints the landscape in gold, russet, and crimson. Our guided walks take you through the best spots to experience the seasonal display and learn about the estate's ancient trees.",
        image: gardensImage,
        features: [
          { icon: faUsers, text: "Guided Tours" },
          { icon: faCamera, text: "Photography" },
          { icon: faLeaf, text: "Nature" }
        ]
      },
      {
        id: 2,
        title: "Harvest Festival Celebrations",
        date: "October 2024",
        description: "Join us for a traditional celebration of the harvest season with displays of produce from the estate farms, demonstrations of rural crafts, and seasonal food and drink. A family-friendly event connecting visitors to the agricultural heritage of Belvoir.",
        image: activitiesImage,
        features: [
          { icon: faChild, text: "Family-Friendly" },
          { icon: faUtensils, text: "Food & Drink" },
          { icon: faTicketAlt, text: "Special Event" }
        ]
      },
      {
        id: 3,
        title: "Halloween at the Castle",
        date: "Late October 2024",
        description: "Brave the haunted halls of Belvoir Castle this Halloween with our special after-dark tours exploring the castle's ghostly legends and mysterious past. For families, our daytime Halloween trail offers spooky fun suitable for younger visitors.",
        image: castleImage,
        features: [
          { icon: faChild, text: "Family Option" },
          { icon: faUsers, text: "Evening Tours" },
          { icon: faTicketAlt, text: "Special Event" }
        ]
      }
    ],
    winter: [
      {
        id: 1,
        title: "Christmas at Belvoir",
        date: "November - December 2024",
        description: "Experience the magic of Belvoir Castle transformed for Christmas with spectacular decorations, twinkling lights, and festive displays throughout the State Rooms. Our themed experience changes each year, creating a truly unforgettable Christmas tradition.",
        image: castleImage,
        features: [
          { icon: faChild, text: "Family-Friendly" },
          { icon: faCamera, text: "Photography" },
          { icon: faTicketAlt, text: "Special Event" }
        ]
      },
      {
        id: 2,
        title: "Winter Woodland Walks",
        date: "December 2024 - February 2025",
        description: "Discover the serene beauty of Belvoir's landscape in winter with our guided woodland walks. Explore frost-covered gardens, spot winter wildlife, and learn about how the estate prepares for the colder months, followed by warming refreshments.",
        image: gardensImage,
        features: [
          { icon: faUsers, text: "Guided Tours" },
          { icon: faCamera, text: "Photography" },
          { icon: faUtensils, text: "Refreshments" }
        ]
      },
      {
        id: 3,
        title: "Festive Afternoon Teas",
        date: "December 2024",
        description: "Indulge in our special festive afternoon tea featuring seasonal treats and Christmas-inspired delicacies. Served in the historic surroundings of the castle, this is the perfect way to celebrate the season with friends and family.",
        image: activitiesImage,
        features: [
          { icon: faUtensils, text: "Dining Experience" },
          { icon: faUsers, text: "Social" },
          { icon: faTicketAlt, text: "Special Event" }
        ]
      }
    ]
  };
  
  // Year-round activities
  const yearRoundActivities = [
    {
      icon: faCamera,
      title: "Photography Tours",
      description: "Capture the beauty of Belvoir Castle and its grounds with our specialized photography tours, available throughout the year and tailored to the changing seasons."
    },
    {
      icon: faChild,
      title: "Adventure Playground",
      description: "Our adventure playground offers fun for children of all ages with climbing frames, slides, and interactive play areas set within the beautiful castle grounds."
    },
    {
      icon: faUsers,
      title: "Guided Castle Tours",
      description: "Explore the rich history and magnificent architecture of Belvoir Castle with our expert guides, offering insights into centuries of history and royal connections."
    }
  ];
  
  return (
    <SeasonalContainer>
      <ParallaxBanner 
        title="Seasonal Activities"
        subtitle="Experience Belvoir Castle Throughout the Year"
        backgroundImage={castleImage}
      />
      
      <ContentContainer>
        <SectionTitle>Activities for Every Season</SectionTitle>
        <IntroText>
          Belvoir Castle offers a rich calendar of seasonal activities and events throughout the year, 
          allowing visitors to experience the estate's unique charm in spring, summer, autumn, and winter. 
          From blooming gardens to festive celebrations, there's always something special to discover.
        </IntroText>
        
        <SeasonTabs>
          <SeasonTab 
            active={activeSeason === 'spring'} 
            onClick={() => setActiveSeason('spring')}
            color="#8BC34A"
          >
            <FontAwesomeIcon icon={faLeaf} />
            Spring
          </SeasonTab>
          <SeasonTab 
            active={activeSeason === 'summer'} 
            onClick={() => setActiveSeason('summer')}
            color="#FFC107"
          >
            <FontAwesomeIcon icon={faSun} />
            Summer
          </SeasonTab>
          <SeasonTab 
            active={activeSeason === 'autumn'} 
            onClick={() => setActiveSeason('autumn')}
            color="#FF9800"
          >
            <FontAwesomeIcon icon={faTree} />
            Autumn
          </SeasonTab>
          <SeasonTab 
            active={activeSeason === 'winter'} 
            onClick={() => setActiveSeason('winter')}
            color="#90CAF9"
          >
            <FontAwesomeIcon icon={faSnowflake} />
            Winter
          </SeasonTab>
        </SeasonTabs>
        
        <ActivitiesGrid>
          {seasonalActivities[activeSeason].map(activity => (
            <ActivityCard key={activity.id}>
              <ActivityImage image={activity.image} />
              <ActivityContent>
                <ActivityTitle>{activity.title}</ActivityTitle>
                <ActivityDate>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                  {activity.date}
                </ActivityDate>
                <ActivityDescription>{activity.description}</ActivityDescription>
                <ActivityFeatures>
                  {activity.features.map((feature, index) => (
                    <ActivityFeature key={index}>
                      <FontAwesomeIcon icon={feature.icon} />
                      {feature.text}
                    </ActivityFeature>
                  ))}
                </ActivityFeatures>
                <ActivityButton>
                  <FontAwesomeIcon icon={faTicketAlt} />
                  Book Now
                </ActivityButton>
              </ActivityContent>
            </ActivityCard>
          ))}
        </ActivitiesGrid>
        
        <HighlightSection>
          <HighlightContent>
            <HighlightTitle>Plan Your Visit Around Our Special Events</HighlightTitle>
            <HighlightDescription>
              Throughout the year, Belvoir Castle hosts a variety of special events, from seasonal celebrations to 
              themed weekends and exclusive experiences. Check our events calendar to plan your visit and make the most of your time at Belvoir.
            </HighlightDescription>
            <HighlightButton>
              <FontAwesomeIcon icon={faCalendarAlt} />
              View Events Calendar
            </HighlightButton>
          </HighlightContent>
        </HighlightSection>
        
        <YearRoundSection>
          <SectionTitle>Year-Round Activities</SectionTitle>
          <IntroText>
            In addition to our seasonal offerings, Belvoir Castle provides a range of activities and experiences available throughout the year.
          </IntroText>
          
          <YearRoundGrid>
            {yearRoundActivities.map((activity, index) => (
              <YearRoundCard key={index}>
                <IconWrapper>
                  <FontAwesomeIcon icon={activity.icon} />
                </IconWrapper>
                <YearRoundTitle>{activity.title}</YearRoundTitle>
                <YearRoundDescription>{activity.description}</YearRoundDescription>
              </YearRoundCard>
            ))}
          </YearRoundGrid>
        </YearRoundSection>
      </ContentContainer>
      
      <Footer />
    </SeasonalContainer>
  );
};

export default SeasonalActivities; 