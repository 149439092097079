export const theme = {
  colors: {
    primary: '#101d40',
    accent: '#9d6d49',
    offWhite: '#f8f7f4',
    greyBackground: '#f5f5f5',
  },
  fonts: {
    main: "'Cormorant Garamond', serif"
  },
  shadows: {
    light: '0 2px 4px rgba(0,0,0,0.1)'
  }
}; 