export const initializeMetaPixel = () => {
  // Create fbq as a global function
  window.fbq = function() {
    window.fbq.callMethod ? 
    window.fbq.callMethod.apply(window.fbq, arguments) : 
    window.fbq.queue.push(arguments)
  };

  // Initialize fbq
  if (!window._fbq) window._fbq = window.fbq;
  window.fbq.push = window.fbq;
  window.fbq.loaded = true;
  window.fbq.version = '2.0';
  window.fbq.queue = [];

  // Load the Facebook pixel script
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://connect.facebook.net/en_US/fbevents.js';
  document.head.appendChild(script);

  // Initialize the pixel with your ID
  window.fbq('init', '506484575057004');
  window.fbq('track', 'PageView');
};

export const trackPageView = () => {
  if (window.fbq) {
    window.fbq('track', 'PageView');
  }
};

export const trackEvent = (eventName, eventData) => {
  if (window.fbq) {
    window.fbq('track', eventName, eventData);
  }
}; 