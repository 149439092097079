import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBed, 
  faBath, 
  faUsers, 
  faWifi, 
  faParking, 
  faMapMarkedAlt,
  faHome,
  faStar,
  faUtensils,
  faCoffee,
  faTv,
  faSnowflake
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import { useStaySection } from '../../../hooks/useStaySection';
import ParallaxBanner from '../../common/Parralax-banner';
import NewsletterSignUp from '../../common/Newsletter-sign';
import valeHouseBanImage from '../../../assets/banner/valeHouseban2.webp';
import valeHouseImage from '../../../assets/holidays/vale-house.webp';

const ValeHouseContainer = styled.div`
  min-height: 100vh;
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  letter-spacing: 2px;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const PropertyGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  margin: 4rem 0;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const PropertyCard = styled.div`
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const PropertyImage = styled.div`
  height: 300px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const PropertyContent = styled.div`
  padding: 2rem;
`;

const PropertyTitle = styled.h3`
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const PropertyDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const PropertyDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  
  svg {
    color: ${props => props.theme.colors.accent};
  }
`;

const AmenitiesList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 1.5rem;
  
  li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    
    svg {
      color: ${props => props.theme.colors.accent};
    }
  }
`;

const BookButton = styled.button`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  
  &:hover {
    background-color: ${props => props.theme.colors.primary};
  }
`;

const HighlightSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 4rem 2rem;
  text-align: center;
  margin: 5rem 0;
`;

const HighlightContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const HighlightTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: white;
  margin-bottom: 1.5rem;
`;

const HighlightText = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 4rem 0;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  text-align: center;
  padding: 2rem;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  
  svg {
    color: white;
    font-size: 32px;
  }
`;

const FeatureTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const InfoSection = styled.div`
  margin: 5rem 0;
  text-align: center;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const InfoCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const InfoTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const InfoText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const ValeHouse = () => {
  const navigate = useNavigate();
  useStaySection();

  return (
    <ValeHouseContainer>
      <ParallaxBanner 
        welcomeText="Experience"
        mainTitle="Vale House"
        subtitle="LUXURY ACCOMMODATION IN THE HEART OF BELVOIR"
        image={valeHouseBanImage}
      />
      
      <ContentContainer>
        <SectionTitle>Elegant Living in the Vale</SectionTitle>
        <IntroText>
          Discover the perfect blend of historic charm and modern comfort at Vale House. This beautifully 
          appointed accommodation offers spacious rooms, stunning views, and all the amenities you need for 
          a memorable stay in the Vale of Belvoir.
        </IntroText>
        
        <PropertyGrid>
          <PropertyCard>
            <PropertyImage image={valeHouseImage} />
            <PropertyContent>
              <PropertyTitle>Vale House</PropertyTitle>
              <PropertyDescription>
                Our flagship accommodation offers luxurious living spaces with stunning views of the Vale. 
                Perfect for families or groups seeking comfort and style in a historic setting.
              </PropertyDescription>
              <PropertyDetails>
                <DetailItem>
                  <FontAwesomeIcon icon={faBed} />
                  <span>4 Bedrooms</span>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faBath} />
                  <span>3 Bathrooms</span>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faUsers} />
                  <span>Up to 8 guests</span>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faMapMarkedAlt} />
                  <span>Vale of Belvoir</span>
                </DetailItem>
              </PropertyDetails>
              <AmenitiesList>
                <li>
                  <FontAwesomeIcon icon={faWifi} />
                  <span>Free WiFi</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faParking} />
                  <span>Private parking</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faTv} />
                  <span>Smart TV</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faSnowflake} />
                  <span>Air conditioning</span>
                </li>
              </AmenitiesList>
              <BookButton href="https://www.bookeddirectly.com/g/leicestershire/stunning-9-bedroom-property-sleeping-up-to-23/a2c053" target="_blank" rel="noopener noreferrer">
                Book Now
              </BookButton>
            </PropertyContent>
          </PropertyCard>
        </PropertyGrid>

        <HighlightSection>
          <HighlightContent>
            <HighlightTitle>A Home Away from Home</HighlightTitle>
            <HighlightText>
              Vale House combines the elegance of a historic property with the comforts of modern living. 
              Enjoy spacious rooms, fully equipped kitchen, and stunning views of the Vale of Belvoir. 
              Perfect for families and groups looking for a luxurious stay in the heart of Belvoir.
            </HighlightText>
          </HighlightContent>
        </HighlightSection>

        <SectionTitle>Your Stay at Vale House</SectionTitle>
        <FeaturesGrid>
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faHome} />
            </IconWrapper>
            <FeatureTitle>Spacious Living</FeatureTitle>
            <FeatureDescription>
              Enjoy generous living spaces with high ceilings and large windows, perfect for relaxing 
              and entertaining.
            </FeatureDescription>
          </FeatureCard>
          
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faUtensils} />
            </IconWrapper>
            <FeatureTitle>Fully Equipped Kitchen</FeatureTitle>
            <FeatureDescription>
              Prepare meals in style with our modern kitchen, complete with all the appliances and 
              cookware you need.
            </FeatureDescription>
          </FeatureCard>
          
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faStar} />
            </IconWrapper>
            <FeatureTitle>Luxury</FeatureTitle>
            <FeatureDescription>
              Experience comfort with premium furnishings, high-quality linens, and modern amenities 
              throughout the house.
            </FeatureDescription>
          </FeatureCard>
        </FeaturesGrid>

        <InfoSection>
          <SectionTitle>Essential Information</SectionTitle>
          <InfoGrid>
            <InfoCard>
              <InfoTitle>Check-in & Check-out</InfoTitle>
              <InfoText>
                <strong>Check-in:</strong> From 4:00 PM
              </InfoText>
              <InfoText>
                <strong>Check-out:</strong> By 10:00 AM
              </InfoText>
              <InfoText>
                Early check-in and late check-out available upon request, subject to availability.
              </InfoText>
            </InfoCard>
            
            <InfoCard>
              <InfoTitle>What's Included</InfoTitle>
              <InfoText>
                • All bed linen and towels
              </InfoText>
              <InfoText>
                • Welcome pack with local treats
              </InfoText>
              <InfoText>
                • Fully equipped kitchen
              </InfoText>
              <InfoText>
                • Free WiFi and parking
              </InfoText>
            </InfoCard>
          </InfoGrid>
        </InfoSection>

        <div style={{ margin: '4rem 0' }}>
          <NewsletterSignUp />
        </div>
      </ContentContainer>
      
      <Footer />
    </ValeHouseContainer>
  );
};

export default ValeHouse; 