import React from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faHome, 
  faBed, 
  faBath, 
  faUsers, 
  faMapMarkerAlt,
  faInfoCircle,
  faWifi, 
  faParking, 
  faUtensils,
  faSwimmingPool,
  faSpa,
  faHiking,
  faLeaf
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useStaySection } from '../../../hooks/useStaySection';
import { Helmet } from 'react-helmet-async';

// Import existing assets as placeholders
import castleImage from '../../../assets/castle.png';
import accommodationImage from '../../../assets/accommodation.jpg';
import activitiesImage from '../../../assets/activities.jpg';
import tearoomImage from '../../../assets/tearoom-interior.jpg';
import gardensImage from '../../../assets/gardens.png';
import cottagesBanImage from '../../../assets/banner/croxtonBan.webp';

// Import images
import parkHouseImage from '../../../assets/holidays/park-house.webp';
import parkLodgeImage from '../../../assets/holidays/park-lodge.webp';
import lodgeCottageImage from '../../../assets/holidays/lodge-cottage.webp';

const CottagesContainer = styled.div`
  min-height: 100vh;
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const FeatureSection = styled.div`
  display: flex;
  margin: 5rem 0;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  
  @media (max-width: 992px) {
    flex-direction: ${props => props.imagePosition === 'right' ? 'column-reverse' : 'column'};
  }
`;

const FeatureImage = styled.div`
  flex: 1;
  min-height: 500px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  
  @media (max-width: 992px) {
    min-height: 300px;
  }
`;

const FeatureContent = styled.div`
  flex: 1;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const FeatureTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const FeatureDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const CottagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
`;

const CottageCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const CottageImage = styled.div`
  height: 250px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const CottageContent = styled.div`
  padding: 2rem;
`;

const CottageTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const CottageDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: ${props => props.theme.colors.text};
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 1.5rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  color: ${props => props.theme.colors.text};
  
  svg {
    color: ${props => props.theme.colors.accent};
  }
`;

const LocationInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.text};
  
  svg {
    color: ${props => props.theme.colors.accent};
  }
`;

const BookButton = styled.a`
  display: inline-block;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  text-decoration: none;
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  font-weight: 600;
  
  &:hover {
    background-color: ${props => props.theme.colors.primary};
  }
`;

const HighlightSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 4rem 2rem;
  text-align: center;
  margin: 5rem 0;
`;

const HighlightContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const HighlightTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: white;
  margin-bottom: 1.5rem;
`;

const HighlightText = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 4rem 0;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  text-align: center;
  padding: 2rem;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  
  svg {
    color: white;
    font-size: 32px;
  }
`;

const FeatureCardTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const FeatureCardDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const ContactInfo = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  svg {
    color: ${props => props.theme.colors.accent};
  }
`;

const Cottages = () => {
  const navigate = useNavigate();
  useStaySection();

  return (
    <>
      <Helmet>
        <title>Luxury Holiday Cottages | Belvoir Castle Estate</title>
        <meta name="description" content="Stay in our luxury holiday cottages on the Belvoir Castle Estate. Choose from Croxton Park House, Lodge Cottage, and more. Perfect for family breaks and romantic getaways." />
        <meta name="keywords" content="Belvoir Castle accommodation, holiday cottages, luxury stays, Croxton Park House" />
        <link rel="canonical" href="https://www.belvoircastle.com/stay/cottages" />
        <meta property="og:title" content="Luxury Holiday Cottages | Belvoir Castle Estate" />
        <meta property="og:description" content="Experience luxury stays in our historic estate cottages." />
      </Helmet>
      <CottagesContainer>
        <ParallaxBanner 
          title="Holiday Cottages"
          subtitle="Luxury Country Retreats in the Heart of Belvoir"
          image={cottagesBanImage}
        />
        
        <ContentContainer>
          <SectionTitle>Our Holiday Cottages</SectionTitle>
          <IntroText>
            Experience the perfect blend of luxury and comfort in our carefully curated selection of holiday cottages. 
            Each property offers unique character and stunning views of the Belvoir estate.
          </IntroText>
          
          <FeatureSection imagePosition="left">
            <FeatureImage image={accommodationImage} />
            <FeatureContent>
              <FeatureTitle>Luxury Country Retreats</FeatureTitle>
              <FeatureDescription>
                Our holiday cottages offer the perfect blend of historic charm and modern comfort, 
                providing an idyllic setting for your stay in the heart of the Belvoir estate.
              </FeatureDescription>
              <FeatureDescription>
                Each property has been carefully restored and furnished to maintain its historic character 
                while offering all the amenities you need for a comfortable stay.
              </FeatureDescription>
            </FeatureContent>
          </FeatureSection>
          
          <FeaturesGrid>
            <FeatureCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faHome} />
              </IconWrapper>
              <FeatureCardTitle>Historic Charm</FeatureCardTitle>
              <FeatureCardDescription>
                Each cottage has been carefully restored to maintain its historic character while offering modern comforts.
              </FeatureCardDescription>
            </FeatureCard>
            
            <FeatureCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faLeaf} />
              </IconWrapper>
              <FeatureCardTitle>Stunning Views</FeatureCardTitle>
              <FeatureCardDescription>
                Enjoy breathtaking views of the Belvoir estate and surrounding countryside from your cottage.
              </FeatureCardDescription>
            </FeatureCard>
            
            <FeatureCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faUtensils} />
              </IconWrapper>
              <FeatureCardTitle>Fully Equipped</FeatureCardTitle>
              <FeatureCardDescription>
                Each cottage features a fully equipped kitchen and comfortable living spaces for your convenience.
              </FeatureCardDescription>
            </FeatureCard>
          </FeaturesGrid>
          
          <CottagesGrid>
            <CottageCard>
              <CottageImage image={parkHouseImage} />
              <CottageContent>
                <CottageTitle>Croxton Park House</CottageTitle>
                <CottageDescription>
                  A stunning Georgian house offering luxury accommodation for up to 10 guests. 
                  Enjoy the perfect blend of historic charm and modern comfort.
                </CottageDescription>
                <FeaturesList>
                  <FeatureItem>
                    <FontAwesomeIcon icon={faBed} />
                    <span>5 Bedrooms</span>
                  </FeatureItem>
                  <FeatureItem>
                    <FontAwesomeIcon icon={faBath} />
                    <span>4 Bathrooms</span>
                  </FeatureItem>
                  <FeatureItem>
                    <FontAwesomeIcon icon={faUsers} />
                    <span>Sleeps 10</span>
                  </FeatureItem>
                </FeaturesList>
                <LocationInfo>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <span>Croxton Park, Vale of Belvoir</span>
                </LocationInfo>
                <BookButton href="https://www.bookeddirectly.com/g/croxton-kerrial/5-bedroom-manor-with-idyllic-countryside-views/66b33c" target="_blank" rel="noopener noreferrer">
                  Book Now
                </BookButton>
              </CottageContent>
            </CottageCard>
            
            <CottageCard>
              <CottageImage image={parkLodgeImage} />
              <CottageContent>
                <CottageTitle>Croxton Park Lodge</CottageTitle>
                <CottageDescription>
                  A charming lodge offering comfortable accommodation for up to 8 guests. 
                  Perfect for families looking for a peaceful retreat.
                </CottageDescription>
                <FeaturesList>
                  <FeatureItem>
                    <FontAwesomeIcon icon={faBed} />
                    <span>4 Bedrooms</span>
                  </FeatureItem>
                  <FeatureItem>
                    <FontAwesomeIcon icon={faBath} />
                    <span>3 Bathrooms</span>
                  </FeatureItem>
                  <FeatureItem>
                    <FontAwesomeIcon icon={faUsers} />
                    <span>Sleeps 8</span>
                  </FeatureItem>
                </FeaturesList>
                <LocationInfo>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <span>Croxton Park, Vale of Belvoir</span>
                </LocationInfo>
                <BookButton href="https://www.bookeddirectly.com/g/croxton-kerrial/8-bedroom-lodge-on-a-beautiful-private-estate/ac44b6" target="_blank" rel="noopener noreferrer">
                  Book Now
                </BookButton>
              </CottageContent>
            </CottageCard>
            
            <CottageCard>
              <CottageImage image={lodgeCottageImage} />
              <CottageContent>
                <CottageTitle>Lodge Cottage</CottageTitle>
                <CottageDescription>
                  A cozy cottage ideal for smaller groups or couples. 
                  Enjoy the peaceful surroundings and beautiful views.
                </CottageDescription>
                <FeaturesList>
                  <FeatureItem>
                    <FontAwesomeIcon icon={faBed} />
                    <span>2 Bedrooms</span>
                  </FeatureItem>
                  <FeatureItem>
                    <FontAwesomeIcon icon={faBath} />
                    <span>2 Bathrooms</span>
                  </FeatureItem>
                  <FeatureItem>
                    <FontAwesomeIcon icon={faUsers} />
                    <span>Sleeps 4</span>
                  </FeatureItem>
                </FeaturesList>
                <LocationInfo>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <span>Croxton Park, Vale of Belvoir</span>
                </LocationInfo>
                <BookButton href="https://www.bookeddirectly.com/g/belvoir/beautiful-lodge-cottage-near-belvoir-castle/e357c8" target="_blank" rel="noopener noreferrer">
                  Book Now
                </BookButton>
              </CottageContent>
            </CottageCard>
          </CottagesGrid>
          
          <HighlightSection>
            <HighlightContent>
              <HighlightTitle>Property Features</HighlightTitle>
              <HighlightText>
                All our cottages come equipped with modern amenities while maintaining their historic charm. 
                Enjoy comfortable living spaces, fully equipped kitchens, and stunning views of the estate.
              </HighlightText>
              <FeaturesList>
                <FeatureItem>
                  <FontAwesomeIcon icon={faWifi} />
                  <span>Free WiFi</span>
                </FeatureItem>
                <FeatureItem>
                  <FontAwesomeIcon icon={faParking} />
                  <span>Private Parking</span>
                </FeatureItem>
                <FeatureItem>
                  <FontAwesomeIcon icon={faUtensils} />
                  <span>Fully Equipped Kitchen</span>
                </FeatureItem>
                <FeatureItem>
                  <FontAwesomeIcon icon={faHiking} />
                  <span>Walking Trails Nearby</span>
                </FeatureItem>
              </FeaturesList>
            </HighlightContent>
          </HighlightSection>
          
          <ContactInfo>
            <ContactItem>
              <FontAwesomeIcon icon={faInfoCircle} />
              <span>07917 910 131</span>
            </ContactItem>
            <ContactItem>
              <FontAwesomeIcon icon={faInfoCircle} />
              <span>holidays@belvoircastle.com</span>
            </ContactItem>
          </ContactInfo>
        </ContentContainer>
        
        <Footer />
      </CottagesContainer>
    </>
  );
};

export default Cottages; 