import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCalendarAlt, 
  faTicketAlt, 
  faInfoCircle, 
  faQuoteLeft,
  faChevronLeft,
  faChevronRight,
  faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';
import { useWhatsOnSection } from '../../../hooks/useWhatsOnSection';
import { Link } from 'react-router-dom';
import SEO from '../../common/SEO';

// Import placeholder images - replace with actual exhibition images
import castleImage from '../../../assets/castle.jpg';
import stateImage from '../../../assets/state-dining.jpg';
import elizabethImage from '../../../assets/elizabeth-saloon.jpg';
import regentsImage from '../../../assets/regents-gallery.jpg';
import ballroomImage from '../../../assets/ballroom.jpg';
import motherhoodBanner from '../../../assets/banner/motherhoodBan.webp';

const ExhibitionsContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const HighlightSection = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  padding: 4rem 2rem;
  margin: 4rem 0;
  text-align: center;
`;

const HighlightContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const HighlightQuote = styled.blockquote`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  font-style: italic;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
  line-height: 1.5;
  position: relative;
  padding: 0 2rem;
  
  svg {
    color: ${props => props.theme.colors.accent};
    font-size: 2rem;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const HighlightAuthor = styled.p`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.accent};
  font-weight: 600;
`;

const ExhibitionInfoCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto 4rem;
`;

const CardContent = styled.div`
  padding: 2rem;
`;

const CardTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  
  svg {
    color: ${props => props.theme.colors.accent};
    margin-right: 1rem;
    font-size: 1.2rem;
  }
`;

const InfoText = styled.span`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.primary};
`;

const ExhibitionDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin: 2rem 0;
`;

const TicketButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  text-decoration: none;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  font-weight: 600;
  border: 2px solid ${props => props.theme.colors.accent};
  transition: all 0.3s ease;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    text-decoration: none;
  }
  
  svg {
    margin-right: 0.5rem;
  }
`;

const CarouselSection = styled.div`
  margin: 5rem 0;
  position: relative;
`;

const CarouselContainer = styled.div`
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
`;

const CarouselTrack = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  transform: translateX(${props => props.translateValue}px);
`;

const CarouselSlide = styled.div`
  min-width: 100%;
  position: relative;
`;

const SlideImage = styled.div`
  height: 500px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  
  @media (max-width: 768px) {
    height: 350px;
  }
`;

const SlideCaption = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 1rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const SlideTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
`;

const SlideDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5;
`;

const CarouselButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: white;
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  svg {
    color: ${props => props.theme.colors.primary};
    font-size: 1.2rem;
  }
  
  &.prev {
    left: 20px;
  }
  
  &.next {
    right: 20px;
  }
  
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    
    &.prev {
      left: 10px;
    }
    
    &.next {
      right: 10px;
    }
  }
`;

const CarouselIndicators = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const Indicator = styled.button`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.active ? props.theme.colors.accent : '#ccc'};
  margin: 0 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: ${props => props.active ? props.theme.colors.accent : '#999'};
  }
`;

const TimelineSection = styled.div`
  margin: 5rem 0;
`;

const Timeline = styled.div`
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem 0;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 4px;
    background-color: ${props => props.theme.colors.accent};
    transform: translateX(-50%);
  }
  
  @media (max-width: 768px) {
    &::before {
      left: 30px;
    }
  }
`;

const TimelineItem = styled.div`
  display: flex;
  justify-content: ${props => props.position === 'left' ? 'flex-start' : 'flex-end'};
  padding: 2rem 0;
  width: 100%;
  position: relative;
  
  @media (max-width: 768px) {
    justify-content: flex-start;
    padding-left: 60px;
  }
`;

const TimelineContent = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  padding: 1.5rem;
  width: 45%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    width: 20px;
    height: 20px;
    background-color: ${props => props.theme.colors.accent};
    border-radius: 50%;
    
    ${props => props.position === 'left' ? `
      right: -60px;
    ` : `
      left: -60px;
    `}
    
    transform: translateY(-50%);
  }
  
  @media (max-width: 768px) {
    width: 100%;
    
    &::before {
      left: -45px;
    }
  }
`;

const TimelineDate = styled.div`
  font-weight: 700;
  color: ${props => props.theme.colors.accent};
  margin-bottom: 0.5rem;
`;

const TimelineTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
`;

const TimelineText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const FeaturedArtifactsSection = styled.div`
  margin: 5rem 0;
`;

const ArtifactsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ArtifactCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;
  
  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
`;

const ArtifactImage = styled.div`
  height: 250px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const ArtifactContent = styled.div`
  padding: 1.5rem;
`;

const ArtifactTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
`;

const ArtifactDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 0.5rem;
`;

const ArtifactDate = styled.p`
  font-size: 0.9rem;
  font-style: italic;
  color: ${props => props.theme.colors.secondary};
`;

const VisitorExperienceSection = styled.div`
  margin: 5rem 0;
`;

const ExperienceContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ExperienceList = styled.div`
  margin-top: 2rem;
`;

const ExperienceItem = styled.div`
  display: flex;
  margin-bottom: 2rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ExperienceIcon = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;
  
  svg {
    color: white;
    font-size: 1.8rem;
  }
  
  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const ExperienceContent = styled.div`
  flex: 1;
`;

const ExperienceTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
`;

const ExperienceDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const Exhibitions = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [translateValue, setTranslateValue] = useState(0);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [selectedExhibit, setSelectedExhibit] = useState(null);
  const carouselRef = useRef(null);
  
  // Use the What's On section hook
  useWhatsOnSection();
  
  // Exhibition slides data
  const slides = [
    {
      image: ballroomImage,
      title: "The Ballroom",
      description: "The main exhibition space showcasing an exquisite collection of dresses worn by the Duchesses of Rutland through the generations."
    },
    {
      image: elizabethImage,
      title: "The Elizabeth Saloon",
      description: "Featuring historical information and stories about the Duchesses who wore these remarkable dresses."
    },
    {
      image: regentsImage,
      title: "The Regent's Gallery",
      description: "Discover the historical context and family history behind the exhibition pieces."
    },
    {
      image: stateImage,
      title: "State Dining Room",
      description: "Additional historical information and family stories relating to the exhibited items."
    }
  ];
  
  // Timeline data
  const timelineItems = [
    {
      position: "left",
      date: "1700s",
      title: "Early Maternal Influences",
      text: "Explore how the Duchesses of Rutland shaped the castle and estate through their maternal influence in the 18th century."
    },
    {
      position: "right",
      date: "1800s",
      title: "Victorian Motherhood",
      text: "Discover the Victorian ideals of motherhood as exemplified by the 5th Duchess and her contemporaries at Belvoir."
    },
    {
      position: "left",
      date: "1900s",
      title: "Modern Transitions",
      text: "Learn how the role of mothers at Belvoir evolved through two World Wars and societal changes in the 20th century."
    },
    {
      position: "right",
      date: "Present Day",
      title: "Contemporary Perspectives",
      text: "Experience the current Duchess's vision of motherhood and her influence on Belvoir Castle today."
    }
  ];
  
  // Replace interactiveExhibits with featuredArtifacts
  const featuredArtifacts = [
    {
      id: 1,
      image: elizabethImage,
      title: "The Duchess's Locket",
      description: "A gold locket containing miniature portraits of the 5th Duchess of Rutland and her children, worn close to her heart.",
      date: "Circa 1800"
    },
    {
      id: 2,
      image: regentsImage,
      title: "Family Bible",
      description: "The Manners family Bible with handwritten records of births, christenings, and family milestones spanning generations.",
      date: "1750-1950"
    },
    {
      id: 3,
      image: ballroomImage,
      title: "Christening Gown",
      description: "An exquisite hand-embroidered christening gown worn by generations of Manners children, including the current Duke.",
      date: "Late 18th Century"
    },
    {
      id: 4,
      image: stateImage,
      title: "Duchess's Correspondence",
      description: "Letters between the 8th Duchess and her children during their education abroad, revealing intimate family bonds.",
      date: "1920s"
    }
  ];
  
  // Update carousel width on window resize
  useEffect(() => {
    const updateCarouselWidth = () => {
      if (carouselRef.current) {
        setCarouselWidth(carouselRef.current.clientWidth);
      }
    };
    
    updateCarouselWidth();
    window.addEventListener('resize', updateCarouselWidth);
    
    return () => {
      window.removeEventListener('resize', updateCarouselWidth);
    };
  }, []);
  
  // Update translate value when current slide changes
  useEffect(() => {
    setTranslateValue(-currentSlide * carouselWidth);
  }, [currentSlide, carouselWidth]);
  
  const nextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };
  
  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };
  
  const goToSlide = (index) => {
    setCurrentSlide(index);
  };
  
  const openExhibitModal = (exhibit) => {
    setSelectedExhibit(exhibit);
    document.body.style.overflow = 'hidden';
  };
  
  const closeExhibitModal = () => {
    setSelectedExhibit(null);
    document.body.style.overflow = 'auto';
  };
  
  return (
    <ExhibitionsContainer>
      <SEO 
        title="Motherhood at Belvoir - Special Exhibition"
        description="Explore our special exhibition 'Motherhood at Belvoir' featuring historic dresses worn by the Duchesses of Rutland through generations. Running until May 11th, 2025."
        keywords="Belvoir Castle exhibition, Motherhood at Belvoir, Duchess dresses, historic exhibition, castle history, Rutland family history"
        canonicalUrl="https://www.belvoircastle.com/whats-on/exhibitions"
        ogImage={motherhoodBanner}
        structuredData={{
          "@context": "https://schema.org",
          "@type": "ExhibitionEvent",
          "name": "Motherhood at Belvoir",
          "description": "Explore our special exhibition 'Motherhood at Belvoir' featuring historic dresses worn by the Duchesses of Rutland through generations.",
          "image": motherhoodBanner,
          "startDate": "2025-01-01",
          "endDate": "2025-05-11",
          "location": {
            "@type": "Place",
            "name": "Belvoir Castle",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Belvoir Castle",
              "addressLocality": "Grantham",
              "addressRegion": "Leicestershire",
              "postalCode": "NG32 1PE",
              "addressCountry": "UK"
            }
          },
          "offers": {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "GBP",
            "availability": "https://schema.org/InStock",
            "validFrom": "2025-01-01",
            "validThrough": "2025-05-11"
          },
          "performer": {
            "@type": "Organization",
            "name": "Belvoir Castle",
            "sameAs": [
              "https://www.belvoircastle.com",
              "https://www.facebook.com/belvoircastle",
              "https://www.instagram.com/belvoircastle"
            ]
          }
        }}
      />
      <ParallaxBanner 
        title="Motherhood at Belvoir"
        subtitle="A Special Exhibition Running Until May 11th"
        image={motherhoodBanner}
      />
      
      <ContentContainer>
        <SectionTitle>Motherhood Through the Ages</SectionTitle>
        <IntroText>
          Step into the magnificent Ballroom of Belvoir Castle to discover a stunning collection of dresses worn by 
          the Duchesses through the generations. This special exhibition showcases the personal wardrobes of these 
          remarkable women, while the surrounding State Rooms provide fascinating historical context and family stories.
        </IntroText>
        
        <ExhibitionInfoCard>
          <CardContent>
            <CardTitle>Exhibition Details</CardTitle>
            <InfoItem>
              <FontAwesomeIcon icon={faCalendarAlt} />
              <InfoText>Running until May 11th, 2025</InfoText>
            </InfoItem>
            <InfoItem>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <InfoText>Main exhibition in the Ballroom, with supporting displays throughout the State Rooms</InfoText>
            </InfoItem>
            <InfoItem>
              <FontAwesomeIcon icon={faTicketAlt} />
              <InfoText>Included with standard Castle admission</InfoText>
            </InfoItem>
            <InfoItem>
              <FontAwesomeIcon icon={faInfoCircle} />
              <InfoText>Guided tours available daily at 11:00am and 2:00pm</InfoText>
            </InfoItem>
            <ExhibitionDescription>
              The centerpiece of this exhibition is the stunning collection of dresses displayed in the Ballroom, 
              each with its own story to tell. As you move through the State Rooms, you'll discover the rich history 
              behind these garments and the women who wore them, offering a unique glimpse into the lives of the 
              Duchesses of Rutland through the ages.
            </ExhibitionDescription>
            <TicketButton to="/whats-on/exhibition-booking">
              <FontAwesomeIcon icon={faTicketAlt} />
              Book Castle Tickets
            </TicketButton>
          </CardContent>
        </ExhibitionInfoCard>
        
        <HighlightSection>
          <HighlightContent>
            <HighlightQuote>
              <FontAwesomeIcon icon={faQuoteLeft} />
              This exhibition celebrates the often overlooked influence of mothers in shaping not just their families, 
              but the very fabric of Belvoir Castle itself. Their stories deserve to be told and their legacies honoured.
            </HighlightQuote>
            <HighlightAuthor>— The Duchess of Rutland</HighlightAuthor>
          </HighlightContent>
        </HighlightSection>
        
        <CarouselSection>
          <SectionTitle>Exhibition Highlights</SectionTitle>
          <IntroText>
            Discover the key spaces and collections featured in our "Motherhood at Belvoir" exhibition.
          </IntroText>
          
          <CarouselContainer ref={carouselRef}>
            <CarouselTrack translateValue={translateValue}>
              {slides.map((slide, index) => (
                <CarouselSlide key={index}>
                  <SlideImage image={slide.image} />
                  <SlideCaption>
                    <SlideTitle>{slide.title}</SlideTitle>
                    <SlideDescription>{slide.description}</SlideDescription>
                  </SlideCaption>
                </CarouselSlide>
              ))}
            </CarouselTrack>
            
            <CarouselButton 
              className="prev" 
              onClick={prevSlide} 
              disabled={currentSlide === 0}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </CarouselButton>
            
            <CarouselButton 
              className="next" 
              onClick={nextSlide} 
              disabled={currentSlide === slides.length - 1}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </CarouselButton>
          </CarouselContainer>
          
          <CarouselIndicators>
            {slides.map((_, index) => (
              <Indicator 
                key={index} 
                active={currentSlide === index} 
                onClick={() => goToSlide(index)} 
              />
            ))}
          </CarouselIndicators>
        </CarouselSection>
        
        <TimelineSection>
          <SectionTitle>A Journey Through Time</SectionTitle>
          <IntroText>
            Explore how motherhood at Belvoir Castle has evolved through the centuries.
          </IntroText>
          
          <Timeline>
            {timelineItems.map((item, index) => (
              <TimelineItem key={index} position={item.position}>
                <TimelineContent position={item.position}>
                  <TimelineDate>{item.date}</TimelineDate>
                  <TimelineTitle>{item.title}</TimelineTitle>
                  <TimelineText>{item.text}</TimelineText>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </TimelineSection>
        
        <VisitorExperienceSection>
          <SectionTitle>Visitor Experience</SectionTitle>
          <IntroText>
            Enhance your visit to the "Motherhood at Belvoir" exhibition with these special experiences.
          </IntroText>
          
          <ExperienceContainer>
            <ExperienceList>
              <ExperienceItem>
                <ExperienceIcon>
                  <FontAwesomeIcon icon={faTicketAlt} />
                </ExperienceIcon>
                <ExperienceContent>
                  <ExperienceTitle>Guided Tours</ExperienceTitle>
                  <ExperienceDescription>
                    Join our expert guides for an in-depth exploration of the exhibition. Tours run daily at 11:00am and 2:00pm, 
                    offering fascinating insights into the maternal figures who shaped Belvoir Castle's history.
                  </ExperienceDescription>
                </ExperienceContent>
              </ExperienceItem>
              
      
              <ExperienceItem>
                <ExperienceIcon>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </ExperienceIcon>
                <ExperienceContent>
                  <ExperienceTitle>Exhibition Guide</ExperienceTitle>
                  <ExperienceDescription>
                    Pick up our beautifully illustrated exhibition guide, featuring detailed information about the artifacts on display 
                    and the stories behind them.
                  </ExperienceDescription>
                </ExperienceContent>
              </ExperienceItem>
            </ExperienceList>
          </ExperienceContainer>
        </VisitorExperienceSection>
      </ContentContainer>
      
      <Footer />
    </ExhibitionsContainer>
  );
};

export default Exhibitions; 