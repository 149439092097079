import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCrown, 
  faCouch, 
  faPalette, 
  faGem, 
  faClock,
  faStore
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';

// Import placeholder image - replace with actual shop image
import retailImage from '../../../assets/retail3.webp';
import retailBanner from '../../../assets/banner/retailBan2.webp';
// Import custom hook to force retail section
import { useRetailSection } from '../../../hooks/useRetailSection';

const DuchessContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const ComingSoonBanner = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 3rem 2rem;
  text-align: center;
  margin: 2rem 0 4rem;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    clip-path: polygon(0 0, 100% 100%, 0 100%);
  }
`;

const ComingSoonContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`;

const ComingSoonTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.8rem;
  color: white;
  margin-bottom: 1rem;
`;

const ComingSoonDate = styled.div`
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: ${props => props.theme.colors.accent};
`;

const ComingSoonText = styled.p`
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const CurrentTimeDisplay = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 3rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
`;

const OpeningTimesCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto 4rem;
`;

const CardImage = styled.div`
  height: 250px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  position: relative;
`;

const CardContent = styled.div`
  padding: 2rem;
`;

const CardTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0;
`;

const StatusIndicator = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.isOpen ? '#4CAF50' : '#F44336'};
  margin-right: 8px;
`;

const StatusText = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: ${props => props.isOpen ? '#4CAF50' : '#F44336'};
  display: flex;
  align-items: center;
`;

const TimeInfo = styled.div`
  margin-bottom: 1.5rem;
`;

const DayLabel = styled.div`
  color: ${props => props.theme.colors.secondary};
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
`;

const Hours = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: 1.6rem;
  font-weight: 500;
`;

const Note = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: 0.9rem;
  font-style: italic;
  margin-top: 0.5rem;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 4rem 0;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  text-align: center;
  padding: 2rem;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  
  svg {
    color: white;
    font-size: 32px;
  }
`;

const FeatureTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const EmailSignupSection = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  padding: 3rem 2rem;
  border-radius: 8px;
  text-align: center;
  margin: 4rem 0;
`;

const EmailSignupTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const EmailSignupText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto 2rem;
`;

const EmailForm = styled.form`
  display: flex;
  max-width: 500px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const EmailInput = styled.input`
  flex-grow: 1;
  padding: 0.8rem 1rem;
  border: 2px solid ${props => props.theme.colors.primary};
  border-radius: 4px 0 0 4px;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.accent};
  }
  
  @media (max-width: 768px) {
    border-radius: 4px;
  }
`;

const SubmitButton = styled.button`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: 2px solid transparent;
  border-radius: 50px;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
    transform: translateY(-2px);
  }
  
  @media (max-width: 768px) {
    border-radius: 50px;
  }
`;

const StatusBadge = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  background-color: ${props => props.isOpen ? 'rgba(76, 175, 80, 0.85)' : 'rgba(244, 67, 54, 0.85)'};
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  backdrop-filter: blur(4px);
`;

const DuchessCollection = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [email, setEmail] = useState('');
  
  // Use custom hook to ensure we stay in retail section
  useRetailSection();
  
  // Update current time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    
    return () => clearInterval(timer);
  }, []);
  
  // Format current time
  const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const currentDay = currentTime.toLocaleDateString('en-US', { weekday: 'long' });
  
  // Shop opening hours
  const openingHours = {
    days: "Daily",
    times: "9:00 - 17:00",
    note: "Last entry at 16:30"
  };
  
  // Function to check if the shop is currently open
  const isShopOpen = () => {
    // Parse opening hours
    const timeRange = openingHours.times.split('-').map(t => t.trim());
    if (timeRange.length !== 2) return false;
    
    // Convert times to 24-hour format for comparison
    const [openHour, openMinute] = timeRange[0].split(':').map(Number);
    const [closeHour, closeMinute] = timeRange[1].split(':').map(Number);
    
    const openTime = new Date();
    openTime.setHours(openHour, openMinute || 0, 0);
    
    const closeTime = new Date();
    closeTime.setHours(closeHour, closeMinute || 0, 0);
    
    // Check if current time is between open and close times
    return currentTime >= openTime && currentTime <= closeTime;
  };
  
  const handleEmailSubmit = (e) => {
    e.preventDefault();
    // In a real implementation, this would send the email to a database or service
    console.log('Email submitted:', email);
    alert('Thank you for your interest! We\'ll notify you when our online shop launches.');
    setEmail('');
  };
  
  return (
    <DuchessContainer>
      <ParallaxBanner 
        title="The Duchess Collection"
        subtitle="Elegant fashion and homeware inspired by Belvoir Castle"
        image={retailBanner}
      />
      
      <ContentContainer>
        <SectionTitle>The Duchess Collection</SectionTitle>
        <IntroText>
          The Duchess Collection offers a curated selection of elegant fashion and homeware inspired by the rich heritage and timeless beauty of Belvoir Castle. 
          Each piece reflects the refined taste and style of the Duchess of Rutland, bringing a touch of castle luxury into your home and wardrobe. 
          From exquisite silk scarves featuring patterns from the castle's historic wallpaper to handcrafted home accessories adorned with motifs from our 
          renowned art collection, every item tells a story of Belvoir's aristocratic heritage.
        </IntroText>
        
        <ComingSoonBanner>
          <ComingSoonContent>
            <ComingSoonTitle>Online Shop Coming Soon</ComingSoonTitle>
            <ComingSoonDate>May 2025</ComingSoonDate>
            <ComingSoonText>
              We're excited to announce that The Duchess Collection will be available online in May 2025. 
              Soon you'll be able to browse and purchase our exclusive fashion and homeware pieces from anywhere in the world, 
              bringing the elegance of Belvoir Castle into your everyday life.
            </ComingSoonText>
          </ComingSoonContent>
        </ComingSoonBanner>
        
        <CurrentTimeDisplay>
          Current Time: {formattedTime} on {currentDay}
        </CurrentTimeDisplay>
        
        <OpeningTimesCard>
          <CardImage image={retailImage}>
            <StatusBadge isOpen={isShopOpen()}>
              <FontAwesomeIcon icon={faClock} />
              {isShopOpen() ? 'Open Now' : 'Closed'}
            </StatusBadge>
          </CardImage>
          <CardContent>
            <CardTitle>The Duchess Collection Opening Hours</CardTitle>
            <TimeInfo>
              <DayLabel>{openingHours.days}</DayLabel>
              <Hours>{openingHours.times}</Hours>
              <Note>{openingHours.note}</Note>
            </TimeInfo>
            <p>
              Visit The Duchess Collection in person to explore our full range of products. Located in the Retail Village at Belvoir Castle, 
              our boutique offers a welcoming atmosphere where you can discover our exclusive designs.
            </p>
          </CardContent>
        </OpeningTimesCard>
        
        <SectionTitle>Our Collections</SectionTitle>
        <IntroText>
          The Duchess Collection features carefully crafted fashion and homeware pieces that embody the elegance and heritage of Belvoir Castle, 
          with designs inspired by the castle's rich history and stunning surroundings.
        </IntroText>
        
        <FeaturesGrid>
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faCrown} />
            </IconWrapper>
            <FeatureTitle>Elegant Apparel</FeatureTitle>
            <FeatureDescription>
              Timeless fashion pieces including scarves, wraps, and accessories crafted from the finest materials and featuring designs inspired by the castle's art collection.
            </FeatureDescription>
          </FeatureCard>
          
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faCouch} />
            </IconWrapper>
            <FeatureTitle>Luxury Homeware</FeatureTitle>
            <FeatureDescription>
              Exquisite home accessories including cushions, throws, and tableware that bring the elegance of Belvoir Castle into your living space.
            </FeatureDescription>
          </FeatureCard>
          
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faPalette} />
            </IconWrapper>
            <FeatureTitle>Heritage-Inspired Designs</FeatureTitle>
            <FeatureDescription>
              Each piece tells a story, with patterns and motifs drawn from the castle's architecture, gardens, and centuries of history.
            </FeatureDescription>
          </FeatureCard>
        </FeaturesGrid>
        
        <EmailSignupSection>
          <EmailSignupTitle>Be the First to Know</EmailSignupTitle>
          <EmailSignupText>
            Sign up to receive updates about our online shop launch and be the first to access our exclusive collection of fashion and homeware pieces online. 
            As a subscriber, you'll receive early access to new collections and special offers.
          </EmailSignupText>
          <EmailForm onSubmit={handleEmailSubmit}>
            <EmailInput 
              type="email" 
              placeholder="Your email address" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <SubmitButton type="submit">Notify Me</SubmitButton>
          </EmailForm>
        </EmailSignupSection>
        
        <SectionTitle>Visit Our Boutique</SectionTitle>
        <IntroText>
          While you wait for our online shop, we invite you to visit The Duchess Collection boutique in person. 
          Located in the Retail Village at Belvoir Castle, our shop offers a curated selection of elegant fashion and homeware pieces 
          that embody the timeless beauty and heritage of the castle.
        </IntroText>
      </ContentContainer>
      
      <Footer />
    </DuchessContainer>
  );
};

export default DuchessCollection; 