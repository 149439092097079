import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCoffee, 
  faUtensils, 
  faClock, 
  faLeaf, 
  faWineGlassAlt 
} from '@fortawesome/free-solid-svg-icons';
import castleImage from '../../../assets/castle.png';

// Import placeholder images - replace with actual images
import teaRoomInterior from '../../../assets/tearoom-interior.jpg'; // Replace with actual image
import breakfastImage from '../../../assets/breakfast.jpg'; // Replace with actual image
import lunchImage from '../../../assets/lunch.jpg'; // Replace with actual image
import afternoonTeaImage from '../../../assets/afternoon-tea.jpg'; // Replace with actual image
import cakesImage from '../../../assets/cakes.jpg'; // Replace with actual image
import tearoomBanner from '../../../assets/banner/tearoomBan.webp';
const GET_TEAROOM_DATA = gql`
  query GetTeaRoomData {
    siteSettings(where: {title: "Global Site Settings"}) {
      nodes {
        siteSettingsFields {
          contactInfo {
            phone
            email
          }
        }
      }
    }
  }
`;

const AviaryTearoomContainer = styled.div`
  min-height: 100vh;
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const FeatureSection = styled.div`
  display: flex;
  margin: 5rem 0;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  
  @media (max-width: 992px) {
    flex-direction: ${props => props.imagePosition === 'right' ? 'column-reverse' : 'column'};
  }
`;

const FeatureImage = styled.div`
  flex: 1;
  min-height: 500px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  
  @media (max-width: 992px) {
    min-height: 300px;
  }
`;

const FeatureContent = styled.div`
  flex: 1;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const FeatureTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const FeatureDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const FeatureButton = styled(Link)`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  text-decoration: none;
  border: 2px solid transparent;
  border-radius: 25px;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  align-self: flex-start;
  transition: all 0.3s ease;
  display: inline-block;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const MenuSection = styled.div`
  margin: 5rem 0;
`;

const MenuTabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  flex-wrap: wrap;
`;

const MenuTab = styled.button`
  background: ${props => props.active ? props.theme.colors.accent : 'transparent'};
  color: ${props => props.active ? 'white' : props.theme.colors.accent};
  border: 2px solid ${props => props.active ? props.theme.colors.accent : props.theme.colors.accent};
  padding: 0.8rem 1.5rem;
  margin: 0.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 25px;
  
  &:hover {
    background: ${props => props.active ? props.theme.colors.accent : 'transparent'};
    color: ${props => props.active ? 'white' : props.theme.colors.accent};
  }
`;

const MenuGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const MenuItem = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const MenuItemTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  
  span {
    color: ${props => props.theme.colors.accent};
  }
`;

const MenuItemDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 0.5rem;
`;

const MenuItemTags = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

const MenuItemTag = styled.span`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
`;

const HighlightSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 4rem 2rem;
  text-align: center;
  margin: 5rem 0;
`;

const HighlightContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const HighlightTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: white;
  margin-bottom: 1.5rem;
`;

const HighlightText = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const HighlightButton = styled(Link)`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  text-decoration: none;
  border: 2px solid transparent;
  border-radius: 25px;
  padding: 1rem 2.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 4rem 0;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  text-align: center;
  padding: 2rem;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  
  svg {
    color: white;
    font-size: 32px;
  }
`;

const FeatureCardTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const FeatureCardDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const GallerySection = styled.div`
  margin: 5rem 0;
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const GalleryImage = styled.div`
  height: 300px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  transition: transform 0.3s ease;
  cursor: pointer;
  
  &:hover {
    transform: scale(1.03);
  }
`;

const InfoSection = styled.div`
  margin: 5rem 0;
  text-align: center;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const InfoCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const InfoTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const InfoText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const TeaRoomLink = styled(Link)`
  display: inline-block;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  padding: 1rem 2rem;
  border: 2px solid transparent;
  border-radius: 25px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  margin: 4rem 0;
  text-align: center;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const AviaryTearoom = () => {
  const { loading, error, data } = useQuery(GET_TEAROOM_DATA);
  const [activeTab, setActiveTab] = React.useState('breakfast');
  
  // Extract contact info with fallback values
  const contactInfo = data?.siteSettings?.nodes?.[0]?.siteSettingsFields?.contactInfo || {
    phone: "01476 871001",
    email: "tearoom@belvoircastle.com"
  };
  
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  
  const renderMenuItems = () => {
    switch(activeTab) {
      case 'breakfast':
        return (
          <MenuGrid>
            <MenuItem>
              <MenuItemTitle>
                BUTTERMILK PANCAKES <span>£10.95</span>
              </MenuItemTitle>
              <MenuItemDescription>
                Mixed berries, honeycomb butter & maple syrup
              </MenuItemDescription>
              <MenuItemDescription>
                add bacon £2.00
              </MenuItemDescription>
              <MenuItemTags>
                <MenuItemTag>v</MenuItemTag>
              </MenuItemTags>
            </MenuItem>
            
            <MenuItem>
              <MenuItemTitle>
                SMASHED AVOCADO <span>£12.50</span>
              </MenuItemTitle>
              <MenuItemDescription>
                On toasted sourdough bread with poached egg
              </MenuItemDescription>
              <MenuItemDescription>
                add Belhaven smoked salmon £3.00
              </MenuItemDescription>
              <MenuItemTags>
                <MenuItemTag>v</MenuItemTag>
                <MenuItemTag>vg*</MenuItemTag>
                <MenuItemTag>gf*</MenuItemTag>
              </MenuItemTags>
            </MenuItem>
            
            <MenuItem>
              <MenuItemTitle>
                SCRAMBLED EGGS <span>£12.95</span>
              </MenuItemTitle>
              <MenuItemDescription>
                On toasted sourdough bread with poached egg
              </MenuItemDescription>
              <MenuItemDescription>
                add Belhaven smoked salmon £3.00
              </MenuItemDescription>
              <MenuItemDescription>
                add bacon £2.00
              </MenuItemDescription>
              <MenuItemTags>
                <MenuItemTag>v</MenuItemTag>
                <MenuItemTag>gf*</MenuItemTag>
              </MenuItemTags>
            </MenuItem>
            
            <MenuItem>
              <MenuItemTitle>
                BREAKFAST ROLL <span>£6.50</span>
              </MenuItemTitle>
              <MenuItemDescription>
                Bacon or sausage
              </MenuItemDescription>
              <MenuItemDescription>
                add egg £2.00
              </MenuItemDescription>
              <MenuItemTags>
                <MenuItemTag>gf*</MenuItemTag>
              </MenuItemTags>
            </MenuItem>
          </MenuGrid>
        );
      
      case 'lunch':
        return (
          <MenuGrid>
            <MenuItem>
              <MenuItemTitle>
                SOUP OF THE DAY <span>£9.00</span>
              </MenuItemTitle>
              <MenuItemDescription>
                Served with sourdough & Netherend butter
              </MenuItemDescription>
              <MenuItemTags>
                <MenuItemTag>v</MenuItemTag>
                <MenuItemTag>gf</MenuItemTag>
              </MenuItemTags>
            </MenuItem>
            
            <MenuItem>
              <MenuItemTitle>
                CHICKEN WINGS <span>£11.50</span>
              </MenuItemTitle>
              <MenuItemDescription>
                Barbecue sauce, spring onion & sesame
              </MenuItemDescription>
            </MenuItem>
            
            <MenuItem>
              <MenuItemTitle>
                BELHAVEN SMOKED SALMON <span>£12.95</span>
              </MenuItemTitle>
              <MenuItemDescription>
                Toasted sourdough, horseradish cream
              </MenuItemDescription>
              <MenuItemTags>
                <MenuItemTag>v</MenuItemTag>
                <MenuItemTag>gf*</MenuItemTag>
              </MenuItemTags>
            </MenuItem>
            
            <MenuItem>
              <MenuItemTitle>
                CHICKEN CAESAR SALAD <span>£15.50</span>
              </MenuItemTitle>
              <MenuItemDescription>
                Cos lettuce, aged parmesan, egg, croutons
              </MenuItemDescription>
              <MenuItemTags>
                <MenuItemTag>gf*</MenuItemTag>
              </MenuItemTags>
            </MenuItem>
          </MenuGrid>
        );
      
      case 'sandwiches':
        return (
          <MenuGrid>
            <MenuItem>
              <MenuItemTitle>
                CHICKEN SCHNITZEL BAGUETTE <span>£15.00</span>
              </MenuItemTitle>
              <MenuItemDescription>
                Dill mayo & sun blushed tomatoes
              </MenuItemDescription>
              <MenuItemDescription>
                Served with coleslaw & skin-on fries
              </MenuItemDescription>
              <MenuItemTags>
                <MenuItemTag>gf*</MenuItemTag>
              </MenuItemTags>
            </MenuItem>
            
            <MenuItem>
              <MenuItemTitle>
                GRILLED HALLOUMI <span>£15.00</span>
              </MenuItemTitle>
              <MenuItemDescription>
                Roasted red pepper, rocket and aioli in a brioche
              </MenuItemDescription>
              <MenuItemDescription>
                Served with coleslaw & skin-on fries
              </MenuItemDescription>
              <MenuItemTags>
                <MenuItemTag>v</MenuItemTag>
                <MenuItemTag>gf*</MenuItemTag>
              </MenuItemTags>
            </MenuItem>
            
            <MenuItem>
              <MenuItemTitle>
                BELHAVEN SMOKED SALMON <span>£15.00</span>
              </MenuItemTitle>
              <MenuItemDescription>
                Gherkins & cream cheese on sourdough
              </MenuItemDescription>
              <MenuItemDescription>
                Served with coleslaw & skin-on fries
              </MenuItemDescription>
              <MenuItemTags>
                <MenuItemTag>v</MenuItemTag>
                <MenuItemTag>gf*</MenuItemTag>
              </MenuItemTags>
            </MenuItem>
            
            <MenuItem>
              <MenuItemTitle>
                ROAST BEEF <span>£15.00</span>
              </MenuItemTitle>
              <MenuItemDescription>
                Horseradish cream & rocket salad
              </MenuItemDescription>
              <MenuItemDescription>
                Served with coleslaw & skin-on fries
              </MenuItemDescription>
              <MenuItemTags>
                <MenuItemTag>gf*</MenuItemTag>
              </MenuItemTags>
            </MenuItem>
          </MenuGrid>
        );
      
      case 'desserts':
        return (
          <MenuGrid>
            <MenuItem>
              <MenuItemTitle>
                WARM CHOCOLATE BROWNIE <span>£6.00</span>
              </MenuItemTitle>
              <MenuItemDescription>
                Vanilla ice cream
              </MenuItemDescription>
              <MenuItemTags>
                <MenuItemTag>v</MenuItemTag>
              </MenuItemTags>
            </MenuItem>
            
            <MenuItem>
              <MenuItemTitle>
                SEASONAL FRUIT CRUMBLE <span>£6.00</span>
              </MenuItemTitle>
              <MenuItemDescription>
                Crème anglaise
              </MenuItemDescription>
              <MenuItemTags>
                <MenuItemTag>v</MenuItemTag>
              </MenuItemTags>
            </MenuItem>
            
            <MenuItem>
              <MenuItemTitle>
                CAKES <span>from £4.50</span>
              </MenuItemTitle>
              <MenuItemDescription>
                Please view our cake selection on the counter
              </MenuItemDescription>
              <MenuItemTags>
                <MenuItemTag>v</MenuItemTag>
                <MenuItemTag>vg*</MenuItemTag>
                <MenuItemTag>gf*</MenuItemTag>
              </MenuItemTags>
            </MenuItem>
          </MenuGrid>
        );
      
      default:
        return null;
    }
  };
  
  return (
    <AviaryTearoomContainer>
      <ParallaxBanner 
        title="The Aviary Tearoom"
        subtitle="A Culinary Haven at Belvoir Castle"
        image={tearoomBanner}
      />
      
      <ContentContainer>
        <SectionTitle>Welcome to The Aviary Tearoom</SectionTitle>
        <IntroText>
          Nestled in the stunning surroundings of Belvoir Castle, the newly refurbished Aviary Tearoom 
          is the perfect place to relax and enjoy a delicious treat. With its charming decor and comfortable seating, 
          the tearoom offers a tranquil escape from the hustle and bustle of daily life.
        </IntroText>
        
        <FeatureSection imagePosition="left">
          <FeatureImage image={afternoonTeaImage} />
          <FeatureContent>
            <FeatureTitle>A Heritage-Inspired Dining Experience</FeatureTitle>
            <FeatureDescription>
              The Aviary Tearoom draws inspiration from Belvoir Castle's rich history and heritage. 
              Our menu celebrates local produce and traditional recipes with a contemporary twist, 
              offering visitors a taste of Belvoir's culinary legacy in elegant surroundings.
            </FeatureDescription>
            <FeatureDescription>
              From hearty breakfasts to light lunches and indulgent treats, every dish is prepared with care 
              using the finest ingredients, many sourced from the Belvoir estate and surrounding countryside.
            </FeatureDescription>
          </FeatureContent>
        </FeatureSection>
        
        <FeaturesGrid>
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faCoffee} />
            </IconWrapper>
            <FeatureCardTitle>Artisan Coffee & Teas</FeatureCardTitle>
            <FeatureCardDescription>
              Enjoy our selection of premium coffees and specialty teas, carefully selected to complement our menu 
              and provide the perfect accompaniment to your visit.
            </FeatureCardDescription>
          </FeatureCard>
          
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faLeaf} />
            </IconWrapper>
            <FeatureCardTitle>Locally Sourced Ingredients</FeatureCardTitle>
            <FeatureCardDescription>
              We take pride in using fresh, seasonal ingredients from local suppliers and the Belvoir estate, 
              ensuring quality and supporting our community.
            </FeatureCardDescription>
          </FeatureCard>
          
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faClock} />
            </IconWrapper>
            <FeatureCardTitle>All-Day Dining</FeatureCardTitle>
            <FeatureCardDescription>
              From breakfast to afternoon tea, we offer a range of dining options throughout the day, 
              perfect for any time of your visit to Belvoir Castle.
            </FeatureCardDescription>
          </FeatureCard>
        </FeaturesGrid>
        
        <HighlightSection>
          <HighlightContent>
            <HighlightTitle>Experience Our Signature Afternoon Tea</HighlightTitle>
            <HighlightText>
              Indulge in our exquisite Afternoon Tea, a celebration of Belvoir's heritage featuring 
              estate-sourced ingredients and artisanal delicacies. Each item tells a story of our rich history 
              and connection to the land, creating a truly memorable experience.
            </HighlightText>
            <HighlightButton to="/castle-dining/afternoon-tea-booking">Book Afternoon Tea</HighlightButton>
          </HighlightContent>
        </HighlightSection>
        
        <MenuSection>
          <SectionTitle>Our Menu</SectionTitle>
          <IntroText>
            Explore our seasonal menu featuring fresh, locally-sourced ingredients and heritage-inspired recipes. 
            From hearty breakfasts to light lunches and indulgent treats, there's something for everyone to enjoy.
          </IntroText>
          
          <MenuTabs>
            <MenuTab 
              active={activeTab === 'breakfast'} 
              onClick={() => handleTabChange('breakfast')}
            >
              Breakfast
            </MenuTab>
            <MenuTab 
              active={activeTab === 'lunch'} 
              onClick={() => handleTabChange('lunch')}
            >
              Small Plates
            </MenuTab>
            <MenuTab 
              active={activeTab === 'sandwiches'} 
              onClick={() => handleTabChange('sandwiches')}
            >
              Sandwiches
            </MenuTab>
            <MenuTab 
              active={activeTab === 'desserts'} 
              onClick={() => handleTabChange('desserts')}
            >
              Desserts
            </MenuTab>
          </MenuTabs>
          
          {renderMenuItems()}
        </MenuSection>
        
        <FeatureSection imagePosition="right">
          <FeatureContent>
            <FeatureTitle>The Perfect Setting for Any Occasion</FeatureTitle>
            <FeatureDescription>
              Whether you're enjoying a quiet moment alone, catching up with friends, or celebrating a special occasion, 
              the Aviary Tearoom provides the perfect backdrop. Our elegant interior offers 
              stunning views of the castle grounds, enhancing your dining experience.
            </FeatureDescription>
            <FeatureDescription>
              For those special celebrations, consider our signature Afternoon Tea experience, 
              a truly memorable way to mark any occasion.
            </FeatureDescription>
            <FeatureButton to="/castle-dining/afternoon-tea-booking">Book Afternoon Tea</FeatureButton>
          </FeatureContent>
          <FeatureImage image={breakfastImage} />
        </FeatureSection>
        
        <GallerySection>
          <SectionTitle>A Glimpse of The Aviary Tearoom</SectionTitle>
          <GalleryGrid>
            <GalleryImage image={breakfastImage} />
            <GalleryImage image={teaRoomInterior} />
            <GalleryImage image={lunchImage} />
            <GalleryImage image={afternoonTeaImage} />
            <GalleryImage image={cakesImage} />
            <GalleryImage image={teaRoomInterior} />
          </GalleryGrid>
        </GallerySection>
        
        <InfoSection>
          <SectionTitle>Visiting Information</SectionTitle>
          <InfoGrid>
            <InfoCard>
              <InfoTitle>Opening Hours</InfoTitle>
              <InfoText>
                <strong>Monday to Sunday:</strong> 10:00am - 5:00pm
              </InfoText>
              <InfoText>
                <strong>Breakfast:</strong> 10:00am - 11:30am
              </InfoText>
              <InfoText>
                <strong>Lunch:</strong> 12:00pm - 4:00pm
              </InfoText>
              <InfoText>
                <strong>Afternoon Tea:</strong> 12:00pm - 5:00pm (pre-booking recommended)
              </InfoText>
            </InfoCard>
            
            <InfoCard>
              <InfoTitle>Reservations</InfoTitle>
              <InfoText>
                While walk-ins are welcome for general dining, we recommend booking in advance for Afternoon Tea 
                to ensure availability, especially during busy periods.
              </InfoText>
              <InfoText>
                <strong>Phone:</strong> <a href={`tel:${contactInfo.phone}`} style={{ color: theme.colors.accent }}>{contactInfo.phone}</a>
              </InfoText>
              <InfoText>
                <strong>Email:</strong> <a href={`mailto:${contactInfo.email}`} style={{ color: theme.colors.accent }}>{contactInfo.email}</a>
              </InfoText>
            </InfoCard>
          </InfoGrid>
        </InfoSection>
        
        <div style={{ textAlign: 'center', margin: '4rem 0' }}>
          <TeaRoomLink to="/castle-dining/afternoon-tea-booking">
            Book Afternoon Tea
          </TeaRoomLink>
        </div>
      </ContentContainer>
      
      <Footer />
    </AviaryTearoomContainer>
  );
};

export default AviaryTearoom;