import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faEnvelope, faTools } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const MembersContainer = styled.div`
  min-height: 100vh;
  padding-top: 180px;
  background: linear-gradient(135deg, ${props => props.theme.colors.offWhite} 0%, #ffffff 100%);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    right: -20%;
    width: 100%;
    height: 100%;
    background: ${props => props.theme.colors.primary};
    transform: rotate(-15deg);
    border-radius: 50% 50% 0 0;
    opacity: 0.1;
    z-index: 0;
  }
`;

const LoginContainer = styled.div`
  max-width: 500px;
  width: 90%;
  margin: 2rem auto;
  padding: 2rem;
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;

  @media (max-width: 576px) {
    padding: 1.5rem;
    width: 95%;
  }

  &::before {
    content: '';
    position: absolute;
    top: -20px;
    left: -20px;
    right: -20px;
    bottom: -20px;
    border: 2px solid ${props => props.theme.colors.accent};
    border-radius: 30px;
    opacity: 0.1;
    z-index: -1;
  }
`;

const Title = styled.h1`
  font-family: ${props => props.theme.fonts.main};
  color: ${props => props.theme.colors.primary};
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: ${props => props.theme.colors.accent};
    border-radius: 2px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const InputGroup = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem 1rem 1rem 3rem;
  border: 2px solid ${props => props.theme.colors.greyBackground};
  border-radius: 10px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: ${props => props.theme.colors.offWhite};
  box-sizing: border-box;

  @media (max-width: 576px) {
    padding: 0.8rem 0.8rem 0.8rem 2.5rem;
    font-size: 0.95rem;
  }

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.accent};
    background: white;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: ${props => props.theme.colors.primary};
  opacity: 0.5;

  @media (max-width: 576px) {
    left: 0.8rem;
    font-size: 0.9em;
  }
`;

const RememberMe = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
`;

const Checkbox = styled.input`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

const Label = styled.label`
  color: ${props => props.theme.colors.text};
  font-size: 0.9rem;
  cursor: pointer;
`;

const SubmitButton = styled.button`
  background: ${props => props.theme.colors.accent};
  color: white;
  padding: 1rem 2rem;
  border: 2px solid transparent;
  border-radius: 25px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
  width: 100%;

  &:hover {
    background: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const ForgotPassword = styled(Link)`
  color: ${props => props.theme.colors.accent};
  text-decoration: none;
  font-size: 0.9rem;
  text-align: right;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

const MaintenanceOverlay = styled.div`
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  padding: 2rem;
  text-align: center;
  opacity: ${props => props.visible ? 1 : 0};
  transition: opacity 0.3s ease;
  pointer-events: ${props => props.visible ? 'auto' : 'none'};
`;

const MaintenanceIcon = styled(FontAwesomeIcon)`
  font-size: 3rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const MaintenanceTitle = styled.h2`
  color: ${props => props.theme.colors.primary};
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const MaintenanceText = styled.p`
  color: ${props => props.theme.colors.text};
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 600px;
  margin-bottom: 2rem;
`;

const OldMembershipLink = styled.a`
  color: ${props => props.theme.colors.accent};
  text-decoration: none;
  font-size: 1.1rem;
  padding: 0.8rem 1.5rem;
  border: 2px solid ${props => props.theme.colors.accent};
  border-radius: 25px;
  transition: all 0.3s ease;

  &:hover {
    background: ${props => props.theme.colors.accent};
    color: white;
  }
`;

const MembersArea = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    rememberMe: false
  });
  const [showMaintenance, setShowMaintenance] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMaintenance(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login logic here
    console.log('Form submitted:', formData);
  };

  return (
    <MembersContainer>
      <MaintenanceOverlay visible={showMaintenance}>
        <MaintenanceIcon icon={faTools} />
        <MaintenanceTitle>Maintenance Notice</MaintenanceTitle>
        <MaintenanceText>
          We are currently working on the new members area where you will be able to manage your membership, 
          track points, digital coffee card and access exclusive membership content and experiences.
        </MaintenanceText>
        <OldMembershipLink 
          href="https://online1.venpos.net/MyAccount/account/login/a1933681-03d5-4b86-ae71-e7ad0853978a"
          target="_blank"
          rel="noopener noreferrer"
        >
          Access Old Membership Area
        </OldMembershipLink>
      </MaintenanceOverlay>
      
      <LoginContainer>
        <Title>Members Area</Title>
        <Form onSubmit={handleSubmit}>
          <InputGroup>
            <Icon icon={faEnvelope} />
            <Input
              type="email"
              name="email"
              placeholder="Email Address"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </InputGroup>
          
          <InputGroup>
            <Icon icon={faLock} />
            <Input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </InputGroup>

          <RememberMe>
            <Checkbox
              type="checkbox"
              name="rememberMe"
              checked={formData.rememberMe}
              onChange={handleChange}
            />
            <Label>Remember me</Label>
          </RememberMe>

          <ForgotPassword to="/forgot-password">
            Forgot your password?
          </ForgotPassword>

          <SubmitButton type="submit">
            Sign In
          </SubmitButton>
        </Form>
      </LoginContainer>
    </MembersContainer>
  );
};

export default MembersArea; 