import React from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPaw, 
  faUtensils, 
  faStore, 
  faTree, 
  faBan,
  faDog,
  faHeart
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';

// Import images
import dogImage from '../../../assets/dog3.jpg';

const DogFriendlyContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const FeaturesSection = styled.div`
  margin: 5rem 0;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const FeatureContent = styled.div`
  padding: 2rem;
  text-align: center;
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  color: white;
  font-size: 32px;
`;

const FeatureTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const HighlightSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 5rem 2rem;
  margin: 5rem 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    clip-path: polygon(0 0, 100% 100%, 0 100%);
  }
`;

const HighlightContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const HighlightTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: white;
  margin-bottom: 2rem;
`;

const HighlightText = styled.p`
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const InfoSection = styled.div`
  margin: 5rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const InfoColumn = styled.div`
  flex: 1;
  min-width: 300px;
`;

const InfoTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  position: relative;
  padding-left: 2rem;
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 30px;
    background-color: ${props => props.theme.colors.accent};
    border-radius: 5px;
  }
`;

const InfoList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const InfoItem = styled.li`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  padding-left: 2rem;
  position: relative;
  
  &::before {
    content: '•';
    position: absolute;
    left: 0;
    color: ${props => props.theme.colors.accent};
    font-size: 1.5rem;
  }
`;

const InfoText = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
`;

const DogFriendly = () => {
  const { ref: featuresRef, inView: featuresInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  const { ref: highlightRef, inView: highlightInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  const { ref: infoRef, inView: infoInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  return (
    <DogFriendlyContainer>
      <ParallaxBanner 
        title="Dog Friendly"
        subtitle="Welcome to Belvoir Castle"
        image={dogImage}
      />
      
      <ContentContainer>
        <SectionTitle>Dog Friendly at Belvoir Castle</SectionTitle>
        <IntroText>
          We love dogs at Belvoir Castle! We welcome well-behaved dogs in many areas of our estate, 
          making it the perfect destination for a day out with your four-legged friend.
        </IntroText>
        
        <FeaturesSection ref={featuresRef}>
          <SectionTitle>Where Dogs Are Welcome</SectionTitle>
          <FeaturesGrid>
            <FeatureCard>
              <FeatureContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faTree} />
                </IconWrapper>
                <FeatureTitle>Grounds</FeatureTitle>
                <FeatureDescription>
                  Dogs are welcome to explore our beautiful grounds with you. 
                  Enjoy a leisurely walk through our stunning gardens and parkland.
                </FeatureDescription>
              </FeatureContent>
            </FeatureCard>
            
            <FeatureCard>
              <FeatureContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faUtensils} />
                </IconWrapper>
                <FeatureTitle>Dining Areas</FeatureTitle>
                <FeatureDescription>
                  Dogs are welcome in our outdoor dining areas at the Visitor Hub, 
                  Belvoir Bistro, and Balloon Bar. Perfect for a refreshment break!
                </FeatureDescription>
              </FeatureContent>
            </FeatureCard>
            
            <FeatureCard>
              <FeatureContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faStore} />
                </IconWrapper>
                <FeatureTitle>Retail Village</FeatureTitle>
                <FeatureDescription>
                  Your furry friend can join you while you explore the Belvoir Retail Village. 
                  Many of our shops are dog-friendly.
                </FeatureDescription>
              </FeatureContent>
            </FeatureCard>
          </FeaturesGrid>
        </FeaturesSection>
        
        <HighlightSection ref={highlightRef}>
          <HighlightContent>
            <IconWrapper>
              <FontAwesomeIcon icon={faHeart} />
            </IconWrapper>
            <HighlightTitle>We Love Dogs!</HighlightTitle>
            <HighlightText>
              At Belvoir Castle, we're committed to making our estate as dog-friendly as possible. 
              We have exciting plans to enhance our dog-friendly facilities in the near future, 
              so watch this space for more dog-friendly updates!
            </HighlightText>
          </HighlightContent>
        </HighlightSection>
        
        <InfoSection ref={infoRef}>
          <InfoColumn>
            <InfoTitle>Important Information</InfoTitle>
            <InfoText>
              To ensure a safe and enjoyable experience for all our visitors, please note the following:
            </InfoText>
            <InfoList>
              <InfoItem>Dogs must be kept on a lead at all times</InfoItem>
              <InfoItem>Please clean up after your dog</InfoItem>
              <InfoItem>Guide dogs are welcome throughout the estate</InfoItem>
              <InfoItem>Water bowls are available at our dining areas</InfoItem>
            </InfoList>
          </InfoColumn>
          
          <InfoColumn>
            <InfoTitle>Restricted Areas</InfoTitle>
            <InfoText>
              For the safety and comfort of all our visitors, dogs are not permitted in:
            </InfoText>
            <InfoList>
              <InfoItem>The Castle (except guide dogs)</InfoItem>
              <InfoItem>The Farm Shop</InfoItem>
              <InfoItem>The Adventure Playground</InfoItem>
            </InfoList>
          </InfoColumn>
        </InfoSection>
      </ContentContainer>
      
      <Footer />
    </DogFriendlyContainer>
  );
};

export default DogFriendly; 