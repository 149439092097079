import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faClock, 
  faUtensils,
  faCalendar,
  faPhone,
  faLeaf,
  faMapMarkerAlt,
  faWineGlass,
  faTicketAlt,
  faPercent,
  faMobileAlt,
  faCoffee,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';
import ParallaxBanner from '../../common/Parralax-banner';
import Footer from '../../Footer/Footer';
import seasoningVideo from '../../../assets/seasoning.mp4';
import breakfastImg from '../../../assets/breakfast.webp';
import eggsImg from '../../../assets/eggs.webp';
import chickenImg from '../../../assets/chicken.webp';
import coffeeImg from '../../../assets/coffee.webp';
import toastImg from '../../../assets/toast.webp';
import balloonImage from '../../../assets/balloon.jpg';
import balloonBar from '../../../assets/ballonBar.webp';
import castleGin from '../../../assets/castleGin.webp';
import cocktail from '../../../assets/cocktail.webp';
import garnishCocktail from '../../../assets/garnishCocktail.webp';
import lager from '../../../assets/lager.webp';
import makingCocktail from '../../../assets/makingCocktail.webp';
import tapas from '../../../assets/tapas.webp';
import bistroImage from '../../../assets/bistro.webp';
import { Link } from 'react-router-dom';
import SEO from '../../common/SEO';

const BistroContainer = styled.div`
  min-height: 100vh;
  padding-top: 120px;
`;

const VideoHero = styled.div`
  position: relative;
  height: 90vh;
  overflow: hidden;
  
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.6) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  z-index: 2;
`;

const HeroTitle = styled.h1`
  font-size: 5rem;
  font-family: ${props => props.theme.fonts.main};
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  
  @media (max-width: 768px) {
    font-size: 3.5rem;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.8rem;
  max-width: 800px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  line-height: 1.4;
  padding: 0 2rem;
  
  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

const ContentContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const Section = styled.section`
  margin: 6rem 0;
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
`;

const MenuGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 3rem;
  margin-top: 3rem;
`;

const MenuCategory = styled.div`
  background: white;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
`;

const CategoryTitle = styled.h3`
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  border-bottom: 2px solid ${props => props.theme.colors.accent};
  padding-bottom: 1rem;
`;

const MenuItem = styled.div`
  margin-bottom: 1.5rem;
`;

const ItemTitle = styled.h4`
  font-size: 1.2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const ItemPrice = styled.span`
  color: ${props => props.theme.colors.accent};
  font-weight: bold;
`;

const ItemDescription = styled.p`
  color: ${props => props.theme.colors.secondary};
  font-size: 1rem;
  line-height: 1.4;
`;

const VideoGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const VideoCard = styled.div`
  position: relative;
  height: 300px;
  overflow: hidden;
  border-radius: 15px;
  
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const InfoCard = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);

  svg {
    font-size: 2rem;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 1rem;
  }
`;

const BookingSection = styled.div`
  background: ${props => props.theme.colors.primary};
  color: white;
  padding: 4rem 0;
  text-align: center;
  margin: 4rem 0;
`;

const BookingTitle = styled.h2`
  font-size: 3rem;
  margin-bottom: 1.5rem;
  font-family: ${props => props.theme.fonts.main};
`;

const BookingDescription = styled.p`
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto 2rem;
  line-height: 1.6;
`;

const BookingButton = styled.a`
  display: inline-block;
  background: ${props => props.theme.colors.accent};
  color: white;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  
  &:hover {
    background: ${props => props.theme.colors.primary};
    transform: translateY(-2px);
  }
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  margin: 4rem 0;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  background: white;
  padding: 3rem;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  text-align: center;
  
  svg {
    font-size: 2.5rem;
    color: ${props => props.theme.colors.accent};
    margin-bottom: 1.5rem;
  }
  
  h3 {
    font-size: 1.8rem;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: ${props => props.theme.colors.secondary};
  }
`;

const MenuSection = styled.div`
  margin: 5rem 0;
`;

const MenuTabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  flex-wrap: wrap;
`;

const MenuTab = styled.button`
  background: ${props => props.active ? props.theme.colors.accent : 'transparent'};
  color: ${props => props.active ? 'white' : props.theme.colors.primary};
  border: 2px solid ${props => props.active ? props.theme.colors.accent : props.theme.colors.primary};
  padding: 0.8rem 1.5rem;
  margin: 0.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50px;
  
  &:hover {
    background: ${props => props.active ? props.theme.colors.accent : props.theme.colors.greyBackground};
  }
`;

const MenuItemTags = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

const MenuItemTag = styled.span`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
`;

const SundayLunchBanner = styled.div`
  background: ${props => props.theme.colors.accent};
  color: white;
  padding: 2rem;
  border-radius: 15px;
  text-align: center;
  margin-bottom: 2rem;

  h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  strong {
    font-size: 1.4rem;
  }
`;

const ScrollIndicator = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  cursor: pointer;
  animation: bounce 2s infinite;

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateX(-50%) translateY(0);
    }
    40% {
      transform: translateX(-50%) translateY(-10px);
    }
    60% {
      transform: translateX(-50%) translateY(-5px);
    }
  }

  svg {
    width: 40px;
    height: 40px;
    fill: none;
    stroke: white;
    stroke-width: 2;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.3));
    opacity: 0.8;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }
`;

const MembershipSection = styled.section`
  background: ${props => props.theme.colors.greyBackground};
  padding: 4rem 0;
  text-align: center;
`;

const MembershipCard = styled.div`
  background: white;
  max-width: 800px;
  margin: 0 auto;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const MembershipIcon = styled.div`
  width: 80px;
  height: 80px;
  background: ${props => props.theme.colors.accent};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  
  svg {
    color: white;
    font-size: 2.5rem;
  }
`;

const ComingSoonBadge = styled.span`
  display: inline-block;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  margin-left: 0.5rem;
  vertical-align: middle;
`;

const MembershipButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: 25px;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: ${props => props.theme.shadows.light};
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    background-color: ${props => {
      // Darken the accent color slightly on hover
      const color = props.theme.colors.accent;
      return `${color}dd`;
    }};
  }
  
  &:active {
    transform: translateY(0);
  }
  
  svg {
    margin-left: 0.5rem;
  }
`;

const IntroText = styled.p`
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
`;

const BenefitsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
`;

const BenefitItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const BenefitText = styled.span`
  margin-left: 0.5rem;
`;

const GallerySection = styled.section`
  padding: 6rem 0;
  background: white;
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 300px);
  gap: 1.5rem;
  padding: 0 2rem;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 250px);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 200px);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 200px);
  }
`;

const GalleryItem = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  grid-column: ${props => props.$large ? 'span 2' : 'span 1'};
  grid-row: ${props => props.$large ? 'span 2' : 'span 1'};

  &:hover {
    transform: scale(1.02);
  }
`;

const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
`;

const GalleryOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 1.5rem;
  text-align: center;
`;

const GalleryTitle = styled.h3`
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
`;

const BelvoirBistro = () => {
  const [activeTab, setActiveTab] = React.useState('breakfast');

  const breakfastItems = [
    {
      title: "Benedict with ham",
      price: "14.00",
      description: "Eggs on sourdough & hollandaise sauce"
    },
    {
      title: "Florentine with spinach",
      price: "12.00",
      description: "Eggs on sourdough & hollandaise sauce"
    },
    {
      title: "Egg Royale with smoked salmon",
      price: "15.00",
      description: "Eggs on sourdough & hollandaise sauce"
    },
    {
      title: "Full Breakfast",
      price: "13.95",
      description: "Egg of your choice, dry cured bacon, sausage, black pudding, baked beans, grilled tomato, mushroom, toast and Netherend butter"
    },
    {
      title: "Veggie Breakfast",
      price: "13.00",
      description: "Poached eggs, smashed avocado, kimchi, on toasted sourdough (vegan option available)"
    }
  ];

  const sweetsItems = [
    {
      title: "Buttermilk Pancakes & Berries",
      price: "12.95",
      description: "Served with berries, clotted cream & maple syrup"
    },
    {
      title: "Buttermilk Pancakes & Bacon",
      price: "13.95",
      description: "Served with smoked bacon and maple syrup"
    },
    {
      title: "French Toast",
      price: "8.25",
      description: "Mixed berries, maple syrup (add Nutella +2.00)"
    }
  ];

  const sundayLunchItems = [
    {
      title: "Three Course Sunday Lunch",
      price: "36.00",
      description: "Choose from our selection of starters, mains, and desserts for a traditional Sunday dining experience"
    },

  ];

  const lightBitesItems = [
    {
      title: "Beef Brisket Roll",
      price: "9.50",
      description: "House coleslaw"
    },
    {
      title: "Soup of the day",
      price: "8.00",
      description: "Sourdough bread & Netherend butter"
    },
    {
      title: "Chicken wings",
      price: "7.00",
      description: "Hot & spicy or barbecue"
    },
    {
      title: "Chicken Caesar Salad",
      price: "17.00",
      description: "Cos lettuce, aged parmesan, egg, croutons"
    },
    {
      title: "Beetroot Salad",
      price: "12.00",
      description: "Goat's curd, toasted seeds, balsamic"
    },
    {
      title: "Belhaven Smoked Salmon",
      price: "11.00",
      description: "Toasted sourdough, horseradish cream"
    }
  ];

  const sandwichesItems = [
    {
      title: "Chicken Club",
      price: "14.95",
      description: "Chicken breast, bacon, lettuce, tomato, egg, mayo & skin on fries"
    },
    {
      title: "Battered Fish",
      price: "13.95",
      description: "Haddock, tartare sauce, fresh rocket on sourdough, skin on fries"
    },
    {
      title: "Reuben Sandwich",
      price: "16.95",
      description: "Salted beef, Swiss cheese, sauerkraut & skin on fries"
    },
    {
      title: "Croque Monsieur / Madame",
      price: "15.95 / 17.45",
      description: "Sourdough, Gruyère cheese, ham, Dijon mustard, herb salad, parmesan, seasonal salad"
    },
    {
      title: "Veggie Sandwich",
      price: "14.95",
      description: "Braised red cabbage, Portobello mushroom, gherkins & melted cheese, skin on fries"
    }
  ];

  const nibblesItems = [
    {
      title: "Gordal Olives",
      price: "5.00",
      description: ""
    },
    {
      title: "Sourdough & Butter",
      price: "5.00",
      description: ""
    },
    {
      title: "Chorizo & Mustard Dressing",
      price: "6.00",
      description: ""
    }
  ];

  const classicsItems = [
    {
      title: "Fish & Chips",
      price: "17.95",
      description: "Haddock, crushed peas, tartare & skin on fries"
    },
    {
      title: "Smashed Burger",
      price: "17.95",
      description: "Mature Cheddar, lettuce, tomato, gherkins & skin on fries"
    },
    {
      title: "Plant-Based Burger",
      price: "15.95",
      description: "Gherkins, spinach, sliced beef tomato, mushroom & skin on fries"
    },
    {
      title: "Chicken Schnitzel",
      price: "18.00",
      description: "Skin on fries, coleslaw"
    },
    {
      title: "Pumpkin & Ricotta Tortellini",
      price: "14.95",
      description: "With pomodoro sauce & parmesan"
    }
  ];

  const sidesItems = [
    {
      title: "Skin on fries",
      price: "5.15",
      description: ""
    },
    {
      title: "Truffle & parmesan skin on fries",
      price: "7.65",
      description: ""
    },
    {
      title: "Sweet potato fries",
      price: "5.15",
      description: ""
    },
    {
      title: "Seasonal greens",
      price: "5.15",
      description: ""
    }
  ];

  const dessertsItems = [
    {
      title: "Chocolate Trifle",
      price: "8.50",
      description: "With honeycomb"
    },
    {
      title: "Seasonal Crumble",
      price: "7.50",
      description: "With vanilla custard"
    },
    {
      title: "Slice of Cake",
      price: "6.50",
      description: "Please view our Counter selection"
    },
    {
      title: "Selection of 3 British Isle Cheeses",
      price: "12.95",
      description: "Belvoir chutney, crackers"
    }
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderMenuItems = () => {
    switch(activeTab) {
      case 'breakfast':
        return (
          <MenuGrid>
            <MenuCategory>
              <CategoryTitle>Eggs & Savory</CategoryTitle>
              {breakfastItems.map((item, index) => (
                <MenuItem key={index}>
                  <ItemTitle>
                    {item.title}
                    <ItemPrice>£{item.price}</ItemPrice>
                  </ItemTitle>
                  <ItemDescription>{item.description}</ItemDescription>
                  <MenuItemTags>
                    {item.title.toLowerCase().includes('veggie') && <MenuItemTag>v</MenuItemTag>}
                    {item.description.toLowerCase().includes('vegan') && <MenuItemTag>vg*</MenuItemTag>}
                  </MenuItemTags>
                </MenuItem>
              ))}
            </MenuCategory>
            <MenuCategory>
              <CategoryTitle>Sweet Treats</CategoryTitle>
              {sweetsItems.map((item, index) => (
                <MenuItem key={index}>
                  <ItemTitle>
                    {item.title}
                    <ItemPrice>£{item.price}</ItemPrice>
                  </ItemTitle>
                  <ItemDescription>{item.description}</ItemDescription>
                  <MenuItemTags>
                    <MenuItemTag>v</MenuItemTag>
                  </MenuItemTags>
                </MenuItem>
              ))}
            </MenuCategory>
          </MenuGrid>
        );

      case 'light-bites':
        return (
          <MenuGrid>
            <MenuCategory>
              <CategoryTitle>Nibbles</CategoryTitle>
              {nibblesItems.map((item, index) => (
                <MenuItem key={index}>
                  <ItemTitle>
                    {item.title}
                    <ItemPrice>£{item.price}</ItemPrice>
                  </ItemTitle>
                  {item.description && <ItemDescription>{item.description}</ItemDescription>}
                </MenuItem>
              ))}
            </MenuCategory>
            <MenuCategory>
              <CategoryTitle>Light Bites</CategoryTitle>
              {lightBitesItems.map((item, index) => (
                <MenuItem key={index}>
                  <ItemTitle>
                    {item.title}
                    <ItemPrice>£{item.price}</ItemPrice>
                  </ItemTitle>
                  <ItemDescription>{item.description}</ItemDescription>
                </MenuItem>
              ))}
            </MenuCategory>
          </MenuGrid>
        );

      case 'sandwiches':
        return (
          <MenuGrid>
            <MenuCategory>
              <CategoryTitle>Sandwiches & Rolls</CategoryTitle>
              {sandwichesItems.map((item, index) => (
                <MenuItem key={index}>
                  <ItemTitle>
                    {item.title}
                    <ItemPrice>£{item.price}</ItemPrice>
                  </ItemTitle>
                  <ItemDescription>{item.description}</ItemDescription>
                  <MenuItemTags>
                    {item.title.toLowerCase().includes('veggie') && <MenuItemTag>v</MenuItemTag>}
                  </MenuItemTags>
                </MenuItem>
              ))}
            </MenuCategory>
          </MenuGrid>
        );

      case 'mains':
        return (
          <MenuGrid>
            <MenuCategory>
              <CategoryTitle>Bistro Classics</CategoryTitle>
              {classicsItems.map((item, index) => (
                <MenuItem key={index}>
                  <ItemTitle>
                    {item.title}
                    <ItemPrice>£{item.price}</ItemPrice>
                  </ItemTitle>
                  <ItemDescription>{item.description}</ItemDescription>
                  <MenuItemTags>
                    {item.title.toLowerCase().includes('plant') && <MenuItemTag>vg</MenuItemTag>}
                  </MenuItemTags>
                </MenuItem>
              ))}
            </MenuCategory>
            <MenuCategory>
              <CategoryTitle>Sides</CategoryTitle>
              {sidesItems.map((item, index) => (
                <MenuItem key={index}>
                  <ItemTitle>
                    {item.title}
                    <ItemPrice>£{item.price}</ItemPrice>
                  </ItemTitle>
                  {item.description && <ItemDescription>{item.description}</ItemDescription>}
                  <MenuItemTags>
                    <MenuItemTag>v</MenuItemTag>
                  </MenuItemTags>
                </MenuItem>
              ))}
            </MenuCategory>
          </MenuGrid>
        );

      case 'sunday':
        return (
          <MenuGrid>
            <MenuCategory>
              <SundayLunchBanner>
                <h3>Sunday Lunch at Belvoir Bistro</h3>
                <p>EVERY SUNDAY</p>
                <p>12 NOON - 3:30PM</p>
                <strong>£36 for Three Courses</strong>
              </SundayLunchBanner>
              <CategoryTitle>Traditional Sunday Roasts</CategoryTitle>
              {sundayLunchItems.map((item, index) => (
                <MenuItem key={index}>
                  <ItemTitle>
                    {item.title}
                    {item.price !== "included" && <ItemPrice>£{item.price}</ItemPrice>}
                  </ItemTitle>
                  <ItemDescription>{item.description}</ItemDescription>
                  <MenuItemTags>
                    {item.title.toLowerCase().includes('nut') && <MenuItemTag>v</MenuItemTag>}
                  </MenuItemTags>
                </MenuItem>
              ))}
            </MenuCategory>
          </MenuGrid>
        );

      case 'desserts':
        return (
          <MenuGrid>
            <MenuCategory>
              <CategoryTitle>Sweet Endings</CategoryTitle>
              {dessertsItems.map((item, index) => (
                <MenuItem key={index}>
                  <ItemTitle>
                    {item.title}
                    <ItemPrice>£{item.price}</ItemPrice>
                  </ItemTitle>
                  <ItemDescription>{item.description}</ItemDescription>
                  <MenuItemTags>
                    <MenuItemTag>v</MenuItemTag>
                  </MenuItemTags>
                </MenuItem>
              ))}
            </MenuCategory>
          </MenuGrid>
        );

      default:
        return null;
    }
  };

  return (
    <BistroContainer>
      <SEO 
        title="Belvoir Bistro - Castle Dining Experience"
        description="Enjoy fresh, locally-sourced cuisine at the Belvoir Bistro. Experience casual fine dining in the historic setting of Belvoir Castle with seasonal menus and spectacular views."
        keywords="Belvoir Bistro, castle dining, Leicestershire restaurants, fine dining, local cuisine, historic restaurant"
        canonicalUrl="https://www.belvoircastle.com/retail-village/dining/belvoir-bistro"
        ogImage={bistroImage}
        structuredData={{
          "@context": "https://schema.org",
          "@type": "Restaurant",
          "name": "Belvoir Bistro",
          "description": "Enjoy fresh, locally-sourced cuisine at the Belvoir Bistro. Experience casual fine dining in the historic setting of Belvoir Castle with seasonal menus and spectacular views.",
          "image": bistroImage,
          "servesCuisine": "British, Local",
          "priceRange": "££",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Belvoir Castle",
            "addressLocality": "Grantham",
            "addressRegion": "Leicestershire",
            "postalCode": "NG32 1PE",
            "addressCountry": "UK"
          },
          "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            "opens": "09:00",
            "closes": "17:00"
          },
          "menu": "https://www.belvoircastle.com/retail-village/dining/belvoir-bistro/menu",
          "acceptsReservations": true,
          "telephone": "+441476871001",
          "sameAs": [
            "https://www.facebook.com/belvoircastle",
            "https://www.instagram.com/belvoircastle",
            "https://twitter.com/belvoircastle"
          ]
        }}
      />
      <VideoHero>
        <video 
          autoPlay 
          loop 
          muted 
          playsInline
          controls={false}
          preload="auto"
          style={{ opacity: 0.9 }}
        >
          <source src={seasoningVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <VideoOverlay>
          <HeroTitle>The Belvoir Bistro</HeroTitle>
          <HeroSubtitle>A taste of tradition at the heart of Belvoir retail village</HeroSubtitle>
        </VideoOverlay>
        <ScrollIndicator onClick={() => window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })}>
          <svg viewBox="0 0 24 24">
            <path d="M12 5L12 19M12 19L5 12M12 19L19 12" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </ScrollIndicator>
      </VideoHero>
      
      <ContentContainer>
        <BookingSection>
          <BookingTitle>Reserve Your Table</BookingTitle>
          <BookingDescription>
            Join us for a memorable dining experience at Belvoir Castle's premier restaurant. 
            Book your table now through our trusted partner TheFork.
          </BookingDescription>
          <BookingButton 
            href="https://widget.thefork.com/en-GB/410bf720-448e-4ce5-b145-25e8a61839c4?utm_source=belvoircastle.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Book Now
          </BookingButton>
        </BookingSection>

        <Section>
          <SectionTitle>Our Philosophy</SectionTitle>
          <FeatureGrid>
            <FeatureCard>
              <FontAwesomeIcon icon={faLeaf} />
              <h3>Local & Seasonal</h3>
              <p>
                We pride ourselves on sourcing ingredients directly from the Belvoir Castle Estate 
                and trusted local suppliers. Our menu changes with the seasons, ensuring the 
                freshest produce and supporting our local community.
              </p>
            </FeatureCard>
            <FeatureCard>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <h3>Castle Heritage</h3>
              <p>
                Located in the heart of Belvoir Castle, our bistro combines centuries of history 
                with modern culinary excellence. Enjoy breathtaking views while savoring dishes 
                inspired by traditional British cuisine.
              </p>
            </FeatureCard>
            <FeatureCard>
              <FontAwesomeIcon icon={faUtensils} />
              <h3>Culinary Excellence</h3>
              <p>
                Our talented chefs blend traditional techniques with contemporary flair, 
                creating dishes that celebrate the rich culinary heritage of Belvoir Castle 
                while embracing modern gastronomy.
              </p>
            </FeatureCard>
            <FeatureCard>
              <FontAwesomeIcon icon={faWineGlass} />
              <h3>Carefully Curated</h3>
              <p>
                From our house-made breads to our selection of local ales and wines, 
                every element of your dining experience has been thoughtfully chosen 
                to reflect the excellence of Belvoir Castle.
              </p>
            </FeatureCard>
          </FeatureGrid>
        </Section>

        <MenuSection>
          <SectionTitle>Our Menu</SectionTitle>
          <MenuTabs>
            <MenuTab 
              active={activeTab === 'breakfast'} 
              onClick={() => handleTabChange('breakfast')}
            >
              Breakfast
            </MenuTab>
            <MenuTab 
              active={activeTab === 'light-bites'} 
              onClick={() => handleTabChange('light-bites')}
            >
              Light Bites
            </MenuTab>
            <MenuTab 
              active={activeTab === 'sandwiches'} 
              onClick={() => handleTabChange('sandwiches')}
            >
              Sandwiches
            </MenuTab>
            <MenuTab 
              active={activeTab === 'mains'} 
              onClick={() => handleTabChange('mains')}
            >
              Mains & Sides
            </MenuTab>
            <MenuTab 
              active={activeTab === 'sunday'} 
              onClick={() => handleTabChange('sunday')}
            >
              Sunday Lunch
            </MenuTab>
            <MenuTab 
              active={activeTab === 'desserts'} 
              onClick={() => handleTabChange('desserts')}
            >
              Desserts
            </MenuTab>
          </MenuTabs>
          
          {renderMenuItems()}
        </MenuSection>

        <GallerySection>
          <SectionTitle>Our Kitchen</SectionTitle>
          <GalleryGrid>
            <GalleryItem $large>
              <GalleryImage src={chickenImg} alt="Perfectly cooked chicken dish" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Culinary Excellence</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={eggsImg} alt="Fresh eggs benedict" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Breakfast Classics</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={breakfastImg} alt="Full breakfast spread" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Morning Delights</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={toastImg} alt="Artisanal toast" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Fresh Baked Goods</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={coffeeImg} alt="Freshly brewed coffee" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Barista Coffee</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={eggsImg} alt="Perfectly poached eggs" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Breakfast Perfection</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
          </GalleryGrid>
        </GallerySection>

        <Section>
          <SectionTitle>Visit Us</SectionTitle>
          <InfoGrid>
            <InfoCard>
              <FontAwesomeIcon icon={faClock} />
              <h3>Opening Hours</h3>
              <p>Monday - Saturday<br />8:00 - 17:00<br />Sunday<br />10:00 - 16:00</p>
            </InfoCard>
            <InfoCard>
              <FontAwesomeIcon icon={faCalendar} />
              <h3>Sunday Lunch</h3>
              <p>Served 12:00 - 3:30PM<br />Three courses £36<br />Booking recommended</p>
            </InfoCard>
            <InfoCard>
              <FontAwesomeIcon icon={faPhone} />
              <h3>Reservations</h3>
              <p>Book online or call us<br />01234 567890</p>
            </InfoCard>
          </InfoGrid>
        </Section>

        <MembershipSection>
          <MembershipCard>
            <MembershipIcon>
              <FontAwesomeIcon icon={faTicketAlt} />
            </MembershipIcon>
            <SectionTitle>Friends of Belvoir Benefits</SectionTitle>
            <IntroText>
              Scan your membership card to enjoy exclusive benefits:
            </IntroText>
            <BenefitsList>
              <BenefitItem>
                <FontAwesomeIcon icon={faPercent} />
                <BenefitText>10% discount on all food and beverages</BenefitText>
              </BenefitItem>
              <BenefitItem>
                <FontAwesomeIcon icon={faMobileAlt} />
                <BenefitText>
                  Earn loyalty points with every visit
                  <ComingSoonBadge>Summer 2025</ComingSoonBadge>
                </BenefitText>
              </BenefitItem>
              <BenefitItem>
                <FontAwesomeIcon icon={faCoffee} />
                <BenefitText>
                  Digital coffee card rewards
                  <ComingSoonBadge>Summer 2025</ComingSoonBadge>
                </BenefitText>
              </BenefitItem>
            </BenefitsList>
            <MembershipButton to="/memberships">
              Become a Friend of Belvoir <FontAwesomeIcon icon={faArrowRight} />
            </MembershipButton>
          </MembershipCard>
        </MembershipSection>
      </ContentContainer>
      <Footer />
    </BistroContainer>
  );
};

export default BelvoirBistro; 