import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import "react-datepicker/dist/react-datepicker.css";
import { venposService } from '../../../services/venposService';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';

// Import images
import castleImage from '../../../assets/castle2.png';

const BookTicketsContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const BookingContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const BookingHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;
  
  h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
  }
  
  p {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const BookingForm = styled.div`
  background: white;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.light};
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: ${({ theme }) => theme.colors.accent};
    border-radius: 12px 12px 0 0;
  }
`;

const TicketSection = styled.div`
  margin-bottom: 3rem;
  
  h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const TicketType = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyBackground};
  transition: background-color 0.3s ease;
  
  &:last-child {
    border-bottom: none;
  }
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.offWhite};
  }
  
  h3 {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 0.5rem;
  }
  
  p {
    color: #666;
    margin-bottom: 0.5rem;
    
    &:last-child {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.colors.accent};
      font-weight: 600;
    }
  }
`;

const QuantitySelector = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  background: ${({ theme }) => theme.colors.offWhite};
  padding: 0.5rem;
  border-radius: 8px;
  
  button {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${({ theme }) => theme.colors.accent};
    background: white;
    color: ${({ theme }) => theme.colors.accent};
    border-radius: 50%;
    cursor: pointer;
    font-size: 1.2rem;
    transition: all 0.2s ease;
    
    &:hover {
      background: ${({ theme }) => theme.colors.accent};
      color: white;
    }
  }
  
  span {
    min-width: 2rem;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const DateSection = styled.div`
  margin-bottom: 3rem;
  
  h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Calendar = styled.div`
  margin-top: 2rem;
`;

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  
  button {
    background: none;
    border: none;
    color: ${({ theme }) => theme.colors.accent};
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
    
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.5rem;
`;

const WeekDay = styled.div`
  text-align: center;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0.5rem;
`;

const CalendarLegend = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  
  span {
    width: 1rem;
    height: 1rem;
    border-radius: 4px;
    background: ${({ color }) => color};
  }
`;

const Day = styled.button`
  aspect-ratio: 1;
  border: none;
  background: ${({ availability, isSelected, theme }) => {
    if (isSelected) return theme.colors.accent;
    switch (Number(availability)) {
      case 1: return 'white';    // Available
      case 2: return '#ffd700';  // Limited Availability
      case 3: return '#90ee90';  // Booking Duration
      case 4: return '#e0e0e0';  // Not Available
      default: return theme.colors.greyBackground;
    }
  }};
  color: ${({ isSelected, availability }) => 
    isSelected 
      ? 'white' 
      : Number(availability) === 4
        ? '#999' 
        : '#000'};
  border-radius: 8px;
  cursor: ${({ availability }) => Number(availability) === 1 ? 'pointer' : 'not-allowed'};
  transition: all 0.2s ease;
  
  &:hover {
    transform: ${({ availability }) => Number(availability) === 1 ? 'scale(1.05)' : 'none'};
    background: ${({ availability, isSelected, theme }) => 
      !isSelected && Number(availability) === 1 ? theme.colors.offWhite : ''};
  }
`;

const TotalSection = styled.div`
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 2px solid ${({ theme }) => theme.colors.greyBackground};
  text-align: right;
  
  h3 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const ContinueButton = styled.button`
  background: ${({ theme }) => theme.colors.accent};
  color: white;
  padding: 1.2rem 3rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.fonts.main};
  transition: all 0.3s ease;
  
  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  }
  
  &:disabled {
    background: #ccc;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  background: #ffe6e6;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  text-align: center;
`;

const AccordionSection = styled.div`
  margin-bottom: 1rem;
`;

const AccordionHeader = styled.div`
  background: white;
  color: inherit;
  padding: 1.5rem;
  border-radius: ${({ isActive }) => isActive ? '12px 12px 0 0' : '12px'};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme, isActive }) => 
    isActive ? theme.colors.accent : theme.colors.greyBackground};
  
  h2 {
    margin: 0;
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.accent};
  }
`;

const AccordionContent = styled.div`
  background: white;
  padding: ${({ isActive }) => isActive ? '2rem' : '0'};
  border-radius: 0 0 12px 12px;
  max-height: ${({ isActive }) => isActive ? '2000px' : '0'};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  border: ${({ isActive }) => isActive ? '1px solid' : '0px solid'};
  border-color: ${({ theme }) => theme.colors.accent};
  border-top: none;
`;

const BookTickets = () => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [availableDates, setAvailableDates] = useState({});
  const locationId = '978';
  const pageId = 'b0f1a62a-a34a-4c4e-a88d-6fda03a19279';
  const [activeSection, setActiveSection] = useState('tickets');

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        setLoading(true);
        // Log the request parameters
        console.log('Web Sale Items Request:', {
          locationId,
          pageId,
          date: selectedDate?.toISOString().split('T')[0] || new Date().toISOString().split('T')[0],
          headers: {
            'Accept': 'application/json'
          }
        });
        
        const response = await venposService.getWebSaleItems(
          locationId,
          pageId,
          selectedDate?.toISOString().split('T')[0] || new Date().toISOString().split('T')[0]
        );
        
        // Log the full response
        console.log('Web Sale Items Response:', response);
        
        if (response.data?.webSaleItems) {
          setTickets(response.data.webSaleItems);
        }
      } catch (err) {
        console.error('Error fetching tickets:', err);
        setError('Failed to load tickets. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchTickets();
  }, [selectedDate]);

  useEffect(() => {
    const fetchAvailableDates = async () => {
      try {
        // Build an array of selected items
        const selectedItems = tickets
          .filter(ticket => (ticket.quantity || 0) > 0)
          .map(ticket => ({
            pageType: 'WebPage',
            pageId: pageId,
            fixedDonationAmount: ticket.fixedDonationAmount || 0,
            giftAidEligible: ticket.giftAidEligible || false,
            webSaleItemId: ticket.webSaleItemId,
            quantity: ticket.quantity,
            webSaleItemTypeID: ticket.webSaleItemTypeID,
            name: ticket.name,
            isSysGift: ticket.isSysGift || false,
            isSysMemberCard: ticket.isSysMemberCard || false
          }));

        // If nothing is selected, do not fetch the calendar
        if (selectedItems.length === 0) {
          return;
        }

        const response = await venposService.getAvailableDates(
          locationId,
          pageId,
          {
            pageType: 'WebPage',
            selectedDate: new Date().toISOString().split('T')[0],
            dateSelectorType: 'OnScreenCalendar',
            lng: 'en'
          },
          selectedItems // Pass our selected items here
        );
        
        console.log('Calendar response in component:', response);
        
        if (!response.isError && response.data?.calendar) {
          const datesMap = {};
          response.data.calendar.forEach(dateInfo => {
            const dateStr = dateInfo.date;
            datesMap[dateStr] = dateInfo.availability;
          });
          console.log('Setting available dates:', datesMap);
          setAvailableDates(datesMap);
        } else {
          console.error('Invalid calendar response:', response);
          setError('Failed to load available dates. Invalid response format.');
        }
      } catch (err) {
        console.error('Error fetching available dates:', err);
        setError('Failed to load available dates. Please try again later.');
      }
    };

    // Only fetch calendar data if there's at least one ticket selected
    if (tickets.some(ticket => (ticket.quantity || 0) > 0)) {
      fetchAvailableDates();
    }
  }, [tickets, locationId, pageId]);

  const getDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const days = new Date(year, month + 1, 0).getDate();
    const firstDay = new Date(year, month, 1).getDay();
    return { days, firstDay };
  };

  const handlePrevMonth = () => {
    setCurrentMonth(prev => new Date(prev.getFullYear(), prev.getMonth() - 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(prev => new Date(prev.getFullYear(), prev.getMonth() + 1));
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const getDateAvailability = (date) => {
    const dateString = date.toISOString().split('T')[0];
    // If no availability is found, default to 4 (Not Available) for styling
    return availableDates[dateString] || 4;
  };

  const renderCalendar = () => {
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const { days, firstDay } = getDaysInMonth(currentMonth);

    return (
      <Calendar>
        <CalendarHeader>
          <button onClick={handlePrevMonth}>&lt;</button>
          <h3>
            {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
          </h3>
          <button onClick={handleNextMonth}>&gt;</button>
        </CalendarHeader>
        <CalendarGrid>
          {weekDays.map(day => (
            <WeekDay key={day}>{day}</WeekDay>
          ))}
          {[...Array(firstDay)].map((_, index) => (
            <Day key={`empty-${index}`} availability={0} />
          ))}
          {[...Array(days)].map((_, index) => {
            const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), index + 1);
            const availability = getDateAvailability(date);
            const isSelected = selectedDate &&
              date.getDate() === selectedDate.getDate() &&
              date.getMonth() === selectedDate.getMonth() &&
              date.getFullYear() === selectedDate.getFullYear();

            return (
              <Day
                key={index}
                availability={availability}
                isSelected={isSelected}
                onClick={() => availability === 1 && handleDateSelect(date)}
                disabled={availability !== 1}
              >
                {index + 1}
              </Day>
            );
          })}
        </CalendarGrid>
        <CalendarLegend>
          <LegendItem color="#e0e0e0">
            <span></span>Not Available
          </LegendItem>
          <LegendItem color="#ffd700">
            <span></span>Limited Availability
          </LegendItem>
          <LegendItem color="#90ee90">
            <span></span>Booking Duration
          </LegendItem>
          <LegendItem color="white">
            <span></span>Available
          </LegendItem>
        </CalendarLegend>
      </Calendar>
    );
  };

  const handleQuantityChange = (ticketId, change) => {
    setTickets(tickets.map(ticket => {
      if (ticket.webSaleItemId === ticketId) {
        const newQuantity = (ticket.quantity || 0) + change;
        return {
          ...ticket,
          quantity: Math.max(0, Math.min(newQuantity, ticket.maxPurchaseQty))
        };
      }
      return ticket;
    }));
  };

  const calculateTotal = () => {
    return tickets.reduce((total, ticket) => {
      return total + (ticket.price * (ticket.quantity || 0));
    }, 0);
  };

  const handleContinue = async () => {
    if (!selectedDate) {
      setError('Please select a date for your visit');
      return;
    }

    const basketItems = tickets
      .filter(ticket => (ticket.quantity || 0) > 0)
      .map(ticket => ({
        webSaleItemId: ticket.webSaleItemId,
        quantity: ticket.quantity,
        webSaleItemTypeID: ticket.webSaleItemTypeID,
        selectedDate: selectedDate.toISOString().split('T')[0]
      }));

    if (basketItems.length === 0) {
      setError('Please select at least one ticket');
      return;
    }

    try {
      navigate('/checkout', { state: { basketItems } });
    } catch (err) {
      setError('Failed to process booking. Please try again.');
    }
  };

  const canProceedToDate = tickets.some(ticket => (ticket.quantity || 0) > 0);

  const handleSectionClick = (section) => {
    if (section === 'date' && !canProceedToDate) {
      setError('Please select at least one ticket first');
      return;
    }
    setActiveSection(activeSection === section ? null : section);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <BookTicketsContainer>
      <ParallaxBanner 
        title="Book Your Tickets"
        subtitle="Plan Your Visit to Belvoir Castle"
        image={castleImage}
      />
      <BookingContent>
        <BookingHeader>
          <h1>Book Your Tickets</h1>
          <p>Select your tickets and preferred date</p>
        </BookingHeader>

        <BookingForm>
          <AccordionSection>
            <AccordionHeader 
              isActive={activeSection === 'tickets'}
              onClick={() => handleSectionClick('tickets')}
            >
              <h2>1. Select Tickets</h2>
              {activeSection !== 'tickets' && canProceedToDate && '✓'}
            </AccordionHeader>
            <AccordionContent isActive={activeSection === 'tickets'}>
              {tickets.map(ticket => (
                <TicketType key={ticket.webSaleItemId}>
                  <div>
                    <h3>{ticket.name}</h3>
                    <p>{ticket.description}</p>
                    <p>£{ticket.price.toFixed(2)}</p>
                  </div>
                  <QuantitySelector>
                    <button onClick={() => handleQuantityChange(ticket.webSaleItemId, -1)}>-</button>
                    <span>{ticket.quantity || 0}</span>
                    <button onClick={() => handleQuantityChange(ticket.webSaleItemId, 1)}>+</button>
                  </QuantitySelector>
                </TicketType>
              ))}
            </AccordionContent>
          </AccordionSection>

          <AccordionSection>
            <AccordionHeader 
              isActive={activeSection === 'date'}
              onClick={() => handleSectionClick('date')}
            >
              <h2>2. Select Visit Date</h2>
              {activeSection !== 'date' && selectedDate && '✓'}
            </AccordionHeader>
            <AccordionContent isActive={activeSection === 'date'}>
              {renderCalendar()}
            </AccordionContent>
          </AccordionSection>

          {error && <ErrorMessage>{error}</ErrorMessage>}

          <TotalSection>
            <h3>Total: £{calculateTotal().toFixed(2)}</h3>
            <ContinueButton 
              onClick={handleContinue}
              disabled={!selectedDate || !canProceedToDate}
            >
              Continue to Checkout
            </ContinueButton>
          </TotalSection>
        </BookingForm>
      </BookingContent>
      <Footer />
    </BookTicketsContainer>
  );
};

export default BookTickets; 