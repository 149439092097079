import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faWineGlassAlt, 
  faLeaf, 
  faUtensils, 
  faGift, 
  faClock
} from '@fortawesome/free-solid-svg-icons';
import SEO from '../../common/SEO';

// Import placeholder images - replace with actual images
// Replace with actual image
import salmonImage from '../../../assets/salmon.jpg'; // Replace with actual image
import eggImage from '../../../assets/egg.jpg'; // Replace with actual image
import chickenImage from '../../../assets/chicken.jpg'; // Replace with actual image
import beefImage from '../../../assets/beef.jpg'; // Replace with actual image
import wineImage from '../../../assets/wine.jpg'; // Replace with actual image
import bakewellImage from '../../../assets/bakewell.jpg'; // Replace with actual image
import sconeImage from '../../../assets/scone.jpg'; // Replace with actual image
import teaRoomImage from '../../../assets/tearoom.jpg'; // Replace with actual image
import castleImage from '../../../assets/castle.png';
import tearoomBanner from '../../../assets/banner/tearoomBan.webp';
const GET_CONTACT_DATA = gql`
  query GetContactData {
    siteSettings(where: {title: "Global Site Settings"}) {
      nodes {
        siteSettingsFields {
          contactInfo {
            phone
            email
          }
        }
      }
    }
  }
`;

const AfternoonTeaContainer = styled.div`
  min-height: 100vh;
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const HighlightSection = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  padding: 4rem 2rem;
  margin: 4rem 0;
  text-align: center;
`;

const HighlightContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const HighlightQuote = styled.blockquote`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  font-style: italic;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
  line-height: 1.5;
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const HighlightAuthor = styled.p`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.accent};
  font-weight: 600;
`;

const MenuSection = styled.div`
  margin: 5rem 0;
`;

const MenuGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2.5rem;
  margin-top: 3rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const MenuCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
`;

const MenuImage = styled.div`
  height: 250px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const MenuContent = styled.div`
  padding: 2rem;
`;

const MenuTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const MenuDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: ${props => props.theme.colors.primary};
`;

const ExperienceSection = styled.div`
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ExperienceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ExperienceCard = styled.div`
  text-align: center;
  padding: 2rem;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  
  svg {
    color: white;
    font-size: 32px;
  }
`;

const ExperienceTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const ExperienceDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const BookingSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 4rem 2rem;
  text-align: center;
  margin: 5rem 0;
`;

const BookingContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const BookingTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: white;
  margin-bottom: 1.5rem;
`;

const BookingText = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const BookingButton = styled.button`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: 2px solid transparent;
  border-radius: 25px;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const GiftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 0;
  padding: 0 2rem;
`;

const GiftCard = styled.div`
  display: flex;
  max-width: 1000px;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const GiftImage = styled.div`
  flex: 1;
  min-height: 300px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  
  @media (max-width: 768px) {
    min-height: 250px;
  }
`;

const GiftContent = styled.div`
  flex: 1;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const GiftTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const GiftDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const GiftButton = styled.button`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: 2px solid transparent;
  border-radius: 25px;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  align-self: flex-start;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const GallerySection = styled.div`
  margin: 5rem 0;
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const GalleryImage = styled.div`
  height: 300px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  transition: transform 0.3s ease;
  cursor: pointer;
  
  &:hover {
    transform: scale(1.03);
  }
`;

const TeaRoomLink = styled(Link)`
  display: inline-block;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  padding: 1rem 2rem;
  border: 2px solid transparent;
  border-radius: 25px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  margin: 4rem 0;
  text-align: center;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 4rem 0;
`;

const VoucherSection = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  padding: 4rem 2rem;
  margin: 4rem 0;
  text-align: center;
`;

const VoucherContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const VoucherTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const VoucherText = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const VoucherButton = styled(Link)`
  display: inline-block;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  padding: 1rem 2rem;
  border-radius: 25px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const AfternoonTea = () => {
  const { loading, error, data } = useQuery(GET_CONTACT_DATA);
  const [showBookingModal, setShowBookingModal] = useState(false);
  
  // Extract contact info with fallback values
  const contactInfo = data?.siteSettings?.nodes?.[0]?.siteSettingsFields?.contactInfo || {
    phone: "01476 871001",
    email: "dining@belvoircastle.com"
  };
  
  const handleBooking = () => {
    // In a real implementation, this would open a booking form or redirect to a booking page
    setShowBookingModal(true);
    console.log('Booking initiated');
  };
  
  const handleGiftVoucher = () => {
    // In a real implementation, this would redirect to a gift voucher purchase page
    console.log('Gift voucher purchase initiated');
  };
  
  return (
    <AfternoonTeaContainer>
      <SEO 
        title="Luxury Afternoon Tea Experience"
        description="Indulge in a traditional afternoon tea experience at Belvoir Castle. Enjoy freshly baked scones, delicate sandwiches, and fine teas in our historic Aviary Tearoom."
        keywords="afternoon tea, Belvoir Castle tea, luxury afternoon tea, Aviary Tearoom, castle dining, Leicestershire afternoon tea"
        canonicalUrl="https://www.belvoircastle.com/castle-dining/afternoon-tea"
        ogImage={tearoomBanner}
        structuredData={{
          "@context": "https://schema.org",
          "@type": "Restaurant",
          "name": "Aviary Tearoom at Belvoir Castle",
          "description": "Indulge in a traditional afternoon tea experience at Belvoir Castle. Enjoy freshly baked scones, delicate sandwiches, and fine teas in our historic Aviary Tearoom.",
          "image": tearoomBanner,
          "servesCuisine": "British",
          "priceRange": "£££",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Belvoir Castle",
            "addressLocality": "Grantham",
            "addressRegion": "Leicestershire",
            "postalCode": "NG32 1PE",
            "addressCountry": "UK"
          },
          "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            "opens": "12:00",
            "closes": "16:00"
          },
          "menu": "https://www.belvoircastle.com/castle-dining/afternoon-tea/menu",
          "acceptsReservations": true,
          "telephone": "+441476871001"
        }}
      />
      <ParallaxBanner 
        title="Afternoon Tea"
        subtitle="A Royal Experience at Belvoir Castle"
        image={tearoomBanner}
      />
      
      <ContentContainer>
        <SectionTitle>A Taste of Belvoir Heritage</SectionTitle>
        <IntroText>
          Step into a world of elegance and tradition with our exquisite Afternoon Tea experience at Belvoir Castle. 
          Each delicacy tells a story of our rich heritage, featuring ingredients sourced from our estate and local artisans. 
          Join us for a journey through time, taste, and the timeless tradition of afternoon tea.
        </IntroText>
        
        <HighlightSection>
          <HighlightContent>
            <HighlightQuote>
              "Our Afternoon Tea is not merely a meal, but a celebration of Belvoir's history, 
              our connection to the land, and the artisanal traditions that have flourished here for centuries."
            </HighlightQuote>
            <HighlightAuthor>— The Duchess of Rutland</HighlightAuthor>
          </HighlightContent>
        </HighlightSection>
        
        <MenuSection>
          <SectionTitle>Our Signature Selection</SectionTitle>
          <IntroText>
            Each item on our menu has been carefully crafted to showcase the finest ingredients, 
            many of which are sourced directly from the Belvoir estate and surrounding countryside.
          </IntroText>
          
          <MenuGrid>
            <MenuCard>
              <MenuImage image={salmonImage} />
              <MenuContent>
                <MenuTitle>Belhaven Smoked Salmon</MenuTitle>
                <MenuDescription>
                  Delicately smoked in the traditional Belhaven style, this premium Scottish salmon is paired with velvety cream cheese and chive butter, 
                  creating a smooth and indulgent flavour profile. Infused with a touch of lemon zest, this dish celebrates the timeless elegance of 
                  Belvoir Castle, offering a taste of royal refinement with every bite.
                </MenuDescription>
              </MenuContent>
            </MenuCard>
            
            <MenuCard>
              <MenuImage image={eggImage} />
              <MenuContent>
                <MenuTitle>Belvoir Hen Egg Mayonnaise</MenuTitle>
                <MenuDescription>
                  Our exquisite Belvoir Hen Eggs, laid fresh on the estate, are gently boiled to perfection and served with our house-made 
                  Belvoir Hen Egg Mayonnaise. This indulgent dish is complemented by wild watercress, hand-harvested from the Belvoir grounds, 
                  bringing together local, farm-fresh ingredients in a celebration of the estate's natural beauty and heritage.
                </MenuDescription>
              </MenuContent>
            </MenuCard>
            
            <MenuCard>
              <MenuImage image={chickenImage} />
              <MenuContent>
                <MenuTitle>Royal Coronation Chicken</MenuTitle>
                <MenuDescription>
                  Inspired by royal tradition, this modern take on Coronation Chicken features tender, free-range chicken marinated in a fragrant 
                  blend of spices, topped with a zesty citrus herb dressing and toasted almonds. The dish evokes the elegance of Belvoir Castle's 
                  royal connections, offering a perfect balance of spice, sweetness, and subtle crunch, capturing the essence of both the past and present.
                </MenuDescription>
              </MenuContent>
            </MenuCard>
            
            <MenuCard>
              <MenuImage image={beefImage} />
              <MenuContent>
                <MenuTitle>Hertfordshire Salted Beef & Gherkins</MenuTitle>
                <MenuDescription>
                  Sourced from the rolling pastures of Hertfordshire, this premium salted beef brings a bold, rich flavour to our dish, 
                  perfectly complemented by tangy gherkins. Served on freshly baked artisan rye bread, with a touch of mustard and handpicked herbs, 
                  this dish pays homage to the Duchess' homeland, offering a taste of local excellence from the heart of England.
                </MenuDescription>
              </MenuContent>
            </MenuCard>
            
            <MenuCard>
              <MenuImage image={sconeImage} />
              <MenuContent>
                <MenuTitle>Freshly Baked Scones</MenuTitle>
                <MenuDescription>
                  Our signature scones are baked fresh daily using a centuries-old recipe passed down through generations at Belvoir Castle. 
                  Served warm with clotted cream from a local dairy and our estate-made seasonal preserves, these light and fluffy delights 
                  are the cornerstone of our Afternoon Tea experience.
                </MenuDescription>
              </MenuContent>
            </MenuCard>
            
            <MenuCard>
              <MenuImage image={bakewellImage} />
              <MenuContent>
                <MenuTitle>Luxury Raspberry Bakewell</MenuTitle>
                <MenuDescription>
                  Our elevated take on the classic Bakewell tart features handpicked raspberries from the Belvoir kitchen gardens, 
                  folded into a rich almond frangipane and encased in delicate, buttery pastry. This quintessentially British treat 
                  has been reimagined with premium ingredients to create a dessert worthy of the castle's grand heritage.
                </MenuDescription>
              </MenuContent>
            </MenuCard>
          </MenuGrid>
        </MenuSection>
        
        <ExperienceSection>
          <SectionTitle>The Belvoir Afternoon Tea Experience</SectionTitle>
          <IntroText>
            More than just tea and treats, our Afternoon Tea is a journey through the rich history and heritage of Belvoir Castle.
          </IntroText>
          
          <ExperienceGrid>
            <ExperienceCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faWineGlassAlt} />
              </IconWrapper>
              <ExperienceTitle>Belvoir Sparkling Wine</ExperienceTitle>
              <ExperienceDescription>
                Enhance your experience with our estate-produced Belvoir Sparkling Wine, crafted from grapes grown in our very own vineyards. 
                The perfect accompaniment to our Afternoon Tea, offering notes of crisp apple and delicate floral undertones.
              </ExperienceDescription>
            </ExperienceCard>
            
            <ExperienceCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faLeaf} />
              </IconWrapper>
              <ExperienceTitle>Estate-Sourced Ingredients</ExperienceTitle>
              <ExperienceDescription>
                Many ingredients in our Afternoon Tea are sourced directly from the Belvoir estate, including eggs from our hens, 
                herbs from our kitchen garden, and seasonal fruits that inspire our preserves and pastries.
              </ExperienceDescription>
            </ExperienceCard>
            
            <ExperienceCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faUtensils} />
              </IconWrapper>
              <ExperienceTitle>Artisanal Craftsmanship</ExperienceTitle>
              <ExperienceDescription>
                Each item is meticulously prepared by our skilled pastry chefs, who blend traditional techniques with contemporary flair 
                to create an Afternoon Tea that honors Belvoir's heritage while delighting modern palates.
              </ExperienceDescription>
            </ExperienceCard>
            
            <ExperienceCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faGift} />
              </IconWrapper>
              <ExperienceTitle>Perfect for Special Occasions</ExperienceTitle>
              <ExperienceDescription>
                Our Afternoon Tea makes for an unforgettable celebration, whether it's a birthday, anniversary, or simply a luxurious treat. 
                Gift vouchers are available for a truly memorable present.
              </ExperienceDescription>
            </ExperienceCard>
            
            <ExperienceCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faClock} />
              </IconWrapper>
              <ExperienceTitle>Historic Setting</ExperienceTitle>
              <ExperienceDescription>
                Enjoy your Afternoon Tea in the elegant surroundings of the Aviary Tearoom, with stunning views of the castle grounds 
                and centuries of history all around you.
              </ExperienceDescription>
            </ExperienceCard>
          </ExperienceGrid>
        </ExperienceSection>
        
        <BookingSection>
          <BookingContent>
            <BookingTitle>Reserve Your Afternoon Tea Experience</BookingTitle>
            <BookingText>
              Indulge in the timeless tradition of Afternoon Tea at Belvoir Castle. Advance booking is recommended 
              to secure your preferred date and time. Our Afternoon Tea is served daily from 12:00pm to 4:00pm.
            </BookingText>
            <BookingButton as={Link} to="/castle-dining/afternoon-tea-booking">Book Now</BookingButton>
          </BookingContent>
        </BookingSection>
        
        <GiftSection>
          <SectionTitle>The Perfect Gift</SectionTitle>
          <GiftCard>
            <GiftImage image={wineImage} />
            <GiftContent>
              <GiftTitle>Gift an Unforgettable Experience</GiftTitle>
              <GiftDescription>
                Our Afternoon Tea gift vouchers make the perfect present for any occasion. Beautifully presented in a 
                luxury gift wallet, they offer the recipient a truly memorable experience in the stunning surroundings 
                of Belvoir Castle. Add a glass of our estate-produced Belvoir Sparkling Wine for that extra special touch.
              </GiftDescription>
              <GiftButton onClick={handleGiftVoucher}>Purchase Gift Voucher</GiftButton>
            </GiftContent>
          </GiftCard>
        </GiftSection>

        <VoucherSection>
          <VoucherContent>
            <VoucherTitle>Redeem Your Afternoon Tea Voucher</VoucherTitle>
            <VoucherText>
              Have you received an Afternoon Tea gift voucher? Click below to redeem your voucher and book your experience.
            </VoucherText>
            <VoucherButton to="/voucher-redemption">
              Redeem Voucher
            </VoucherButton>
          </VoucherContent>
        </VoucherSection>
        
        <GallerySection>
          <SectionTitle>A Glimpse of Our Afternoon Tea</SectionTitle>
          <GalleryGrid>
            <GalleryImage image={salmonImage} />
            <GalleryImage image={teaRoomImage} />
            <GalleryImage image={sconeImage} />
            <GalleryImage image={wineImage} />
            <GalleryImage image={bakewellImage} />
            <GalleryImage image={chickenImage} />
          </GalleryGrid>
        </GallerySection>
        
        <ButtonContainer>
          <TeaRoomLink to="/castle-dining/aviary-tearoom">
            Visit the Aviary Tearoom
          </TeaRoomLink>
        </ButtonContainer>
      </ContentContainer>
      
      <Footer />
    </AfternoonTeaContainer>
  );
};

export default AfternoonTea;