import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faMapMarkerAlt, 
  faCar, 
  faDirections, 
  faParking,
  faClock,
  faPhone,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import { useStaySection } from '../../../hooks/useStaySection';

import ValeHouseImage from '../../../assets/holidays/vale-house.webp';
import CroxtonParkImage from '../../../assets/holidays/croxton1.webp';
import GlampingImage from '../../../assets/holidays/glamping.webp';
import DuckCampsiteImage from '../../../assets/holidays/duck-camping.jpeg';

const GettingHereContainer = styled.div`
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const LocationGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const LocationCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const LocationImage = styled.div`
  height: 250px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const LocationContent = styled.div`
  padding: 2rem;
`;

const LocationTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  svg {
    color: ${props => props.theme.colors.accent};
  }
`;

const LocationDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const LocationDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: 1.5rem;
  
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  svg {
    color: ${props => props.theme.colors.accent};
  }
`;

const DetailText = styled.p`
  font-size: 1rem;
  line-height: 1.4;
`;

const MapSection = styled.div`
  margin: 5rem 0;
  text-align: center;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 600px;
  margin-top: 2rem;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const LocationList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0;
`;

const LocationButton = styled.button`
  background-color: ${props => props.active ? props.theme.colors.accent : props.theme.colors.greyBackground};
  color: ${props => props.active ? 'white' : props.theme.colors.primary};
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${props => props.theme.colors.accent};
    color: white;
  }
`;

const MarkerIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
  animation: bounce 1s infinite;
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
`;

const InfoWindow = styled.div`
  padding: 1rem;
  max-width: 250px;
`;

const InfoWindowTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
`;

const InfoWindowText = styled.p`
  font-size: 0.9rem;
  line-height: 1.4;
  color: ${props => props.theme.colors.primary};
`;

const locations = [
  {
    name: "Vale House",
    position: { lat: 52.895671, lng: -0.785918 },
    description: "Luxury accommodation in the historic Engine Yard",
    address: "Engine Yard, Belvoir Castle, Grantham, NG32 1PE"
  },
  {
    name: "Glamping",
    position: { lat: 52.896563, lng: -0.784970 },
    description: "Bell Tents with stunning views",
    address: "Belvoir Castle Estate, Grantham, NG32 1PE"
  },
  {
    name: "The Duck Campsite",
    position: { lat: 52.907390, lng: -0.749068 },
    description: "Camping pitches and hardstanding options",
    address: "The Duck Campsite, Woolsthorpe, Grantham, NG32 1PE"
  },
  {
    name: "Croxton Park",
    position: { lat: 52.839915, lng: -0.779718 },
    description: "Collection of holiday cottages",
    address: "Croxton Park, Croxton Kerrial, Grantham, NG32 1PE"
  }
];

const GettingHere = () => {
  useStaySection();
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const markersRef = useRef([]);
  const infoWindowsRef = useRef([]);
  const [activeLocation, setActiveLocation] = useState(null);
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);

  useEffect(() => {
    // Check if Google Maps is already loaded
    if (window.google) {
      setIsGoogleMapsLoaded(true);
      initializeMap();
      return;
    }

    // Load Google Maps script
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDG4S0FFyiaKQB5bn6iFIX8XuYgW0I3uZY`;
    script.async = true;
    script.defer = true;
    
    script.onload = () => {
      setIsGoogleMapsLoaded(true);
      initializeMap();
    };

    script.onerror = () => {
      console.error('Failed to load Google Maps script');
      // You might want to show a fallback message to the user here
    };

    document.head.appendChild(script);

    return () => {
      if (document.head.contains(script)) {
        document.head.removeChild(script);
      }
    };
  }, []);

  const initializeMap = () => {
    if (!window.google || !mapRef.current) return;

    try {
      // Initialize map
      const mapOptions = {
        center: { lat: 52.895671, lng: -0.785918 }, // Center on Vale House
        zoom: 12,
        styles: [
          {
            featureType: "all",
            elementType: "labels.text.fill",
            stylers: [{ color: "#000000" }]
          }
        ]
      };

      mapInstanceRef.current = new window.google.maps.Map(mapRef.current, mapOptions);

      // Create markers for each location
      locations.forEach((location, index) => {
        // Define custom SVG icons for each location type
        const icon = {
          path: window.google.maps.SymbolPath.CIRCLE,
          scale: 8,
          fillColor: "#FF6B6B",
          fillOpacity: 0.8,
          strokeColor: "#FFFFFF",
          strokeWeight: 2
        };

        // Customize icon based on location type
        if (location.name === "Vale House") {
          icon.path = window.google.maps.SymbolPath.CIRCLE;
          icon.scale = 8;
          icon.fillColor = "#4A90E2";
          icon.fillOpacity = 0.8;
          icon.strokeColor = "#FFFFFF";
          icon.strokeWeight = 2;
        } else if (location.name === "Glamping") {
          icon.path = window.google.maps.SymbolPath.CIRCLE;
          icon.scale = 8;
          icon.fillColor = "#50E3C2";
          icon.fillOpacity = 0.8;
          icon.strokeColor = "#FFFFFF";
          icon.strokeWeight = 2;
        } else if (location.name === "The Duck Campsite") {
          icon.path = window.google.maps.SymbolPath.CIRCLE;
          icon.scale = 8;
          icon.fillColor = "#F5A623";
          icon.fillOpacity = 0.8;
          icon.strokeColor = "#FFFFFF";
          icon.strokeWeight = 2;
        } else if (location.name === "Croxton Park") {
          icon.path = window.google.maps.SymbolPath.CIRCLE;
          icon.scale = 8;
          icon.fillColor = "#9013FE";
          icon.fillOpacity = 0.8;
          icon.strokeColor = "#FFFFFF";
          icon.strokeWeight = 2;
        }

        const marker = new window.google.maps.Marker({
          position: location.position,
          map: mapInstanceRef.current,
          icon: icon
        });

        const infoWindow = new window.google.maps.InfoWindow({
          content: `
            <div style="padding: 15px; min-width: 250px;">
              <h4 style="margin: 0 0 8px 0; font-family: 'Playfair Display', serif; font-size: 16px;">${location.name}</h4>
              <p style="margin: 0 0 8px 0; font-size: 14px;">${location.description}</p>
              <p style="margin: 0 0 15px 0; font-size: 12px; color: #666;">${location.address}</p>
              <button onclick="window.open('https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(location.address)}', '_blank')" 
                      style="background-color: #4A90E2; color: white; border: none; padding: 10px 20px; border-radius: 4px; cursor: pointer; font-size: 14px; width: 100%; font-weight: 600; transition: background-color 0.3s ease;">
                Get Directions
              </button>
            </div>
          `
        });

        marker.addListener('click', () => {
          try {
            // Close all other info windows
            infoWindowsRef.current.forEach((iw, i) => {
              if (i !== index) iw.close();
            });
            infoWindow.open(mapInstanceRef.current, marker);
            setActiveLocation(index);
          } catch (error) {
            console.error('Error handling marker click:', error);
          }
        });

        markersRef.current.push(marker);
        infoWindowsRef.current.push(infoWindow);
      });
    } catch (error) {
      console.error('Error initializing map:', error);
    }
  };

  const handleLocationClick = (index) => {
    try {
      if (!mapInstanceRef.current || !markersRef.current[index] || !infoWindowsRef.current[index]) {
        console.warn('Map elements not ready');
        return;
      }
      
      const marker = markersRef.current[index];
      const infoWindow = infoWindowsRef.current[index];
      
      // Close all other info windows
      infoWindowsRef.current.forEach((iw, i) => {
        if (i !== index) iw.close();
      });
      
      infoWindow.open(mapInstanceRef.current, marker);
      mapInstanceRef.current.panTo(marker.getPosition());
      mapInstanceRef.current.setZoom(14); // Zoom in when location is selected
      setActiveLocation(index);
    } catch (error) {
      console.error('Error handling location click:', error);
    }
  };

  return (
    <GettingHereContainer>
      <ParallaxBanner 
        title="Getting Here"
        subtitle="Find Your Way to Belvoir Holidays"
        backgroundImage="/path-to-your-banner-image.jpg"
      />
      
      <ContentContainer>
        <SectionTitle>Locations & Directions</SectionTitle>
        <IntroText>
          Belvoir Holidays offers accommodations across multiple locations on the Belvoir Castle Estate. 
          Find detailed directions and information for each property below.
        </IntroText>
        
        <LocationGrid>
          <LocationCard>
            <LocationImage image={ValeHouseImage} />
            <LocationContent>
              <LocationTitle>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                Vale House
              </LocationTitle>
              <LocationDescription>
                Located in the historic Engine Yard at Belvoir Castle, Vale House offers luxury accommodation 
                with stunning views of the castle and surrounding countryside.
              </LocationDescription>
              <LocationDetails>
                <DetailItem>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <DetailText>Engine Yard, Belvoir Castle, Grantham, NG32 1PE</DetailText>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faPhone} />
                  <DetailText>07917 910 131</DetailText>
                </DetailItem>
              </LocationDetails>
            </LocationContent>
          </LocationCard>
          
          <LocationCard>
            <LocationImage image={CroxtonParkImage} />
            <LocationContent>
              <LocationTitle>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                Croxton Park
              </LocationTitle>
              <LocationDescription>
                Our collection of holiday cottages in Croxton Park, including Croxton Park House, 
                Curlew's Nest, Croxton Park Lodge, and Lodge Cottage.
              </LocationDescription>
              <LocationDetails>
                <DetailItem>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <DetailText>Croxton Park, Croxton Kerrial, Grantham, NG32 1PE</DetailText>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faPhone} />
                  <DetailText>07917 910 131</DetailText>
                </DetailItem>
              </LocationDetails>
            </LocationContent>
          </LocationCard>
          
          <LocationCard>
            <LocationImage image={GlampingImage} />
            <LocationContent>
              <LocationTitle>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                Glamping
              </LocationTitle>
              <LocationDescription>
                Our glamping site featuring Bell Tents, offering a unique camping 
                experience with stunning views of the Vale of Belvoir.
              </LocationDescription>
              <LocationDetails>
                <DetailItem>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <DetailText>Belvoir Castle Estate, Grantham, NG32 1PE</DetailText>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faPhone} />
                  <DetailText>07917 910 131</DetailText>
                </DetailItem>
              </LocationDetails>
            </LocationContent>
          </LocationCard>
          
          <LocationCard>
            <LocationImage image={DuckCampsiteImage} />
            <LocationContent>
              <LocationTitle>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                The Duck Campsite
              </LocationTitle>
              <LocationDescription>
                Located next to The Duck pub in Woolsthorpe, offering camping pitches and hardstanding 
                options for motorhomes and caravans.
              </LocationDescription>
              <LocationDetails>
                <DetailItem>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <DetailText>The Duck Campsite, Woolsthorpe, Grantham, NG32 1PE</DetailText>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faPhone} />
                  <DetailText>07917 910 131</DetailText>
                </DetailItem>
              </LocationDetails>
            </LocationContent>
          </LocationCard>
        </LocationGrid>
        
        <MapSection>
          <SectionTitle>Interactive Map</SectionTitle>
          <IntroText>
            Use our interactive map to explore all accommodation locations on the Belvoir Castle Estate.
          </IntroText>
          
          <LocationList>
            {locations.map((location, index) => (
              <LocationButton
                key={location.name}
                active={activeLocation === index}
                onClick={() => handleLocationClick(index)}
              >
                {location.name}
              </LocationButton>
            ))}
          </LocationList>
          
          <MapContainer ref={mapRef} />
        </MapSection>
      </ContentContainer>
      
      <Footer />
    </GettingHereContainer>
  );
};

export default GettingHere; 