import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faLandmark, 
  faCrown, 
  faHistory, 
  faBook,
  faCalendarAlt,
  faMapMarkedAlt,
  faCompass
} from '@fortawesome/free-solid-svg-icons';
import { theme } from '../../../styles/theme';

// Import images
import castleImage from '../../../assets/castle.png';
import gardensImage from '../../../assets/gardens.png';
import capabilityImage from '../../../assets/capability.webp';

import bannerImage from '../../../assets/banner/roomBan.webp';
import regentsGallery from '../../../assets/regents-gallery.jpg';
import elizabethSaloon from '../../../assets/elizabeth-saloon.jpg';
import ballroom from '../../../assets/ballroom.jpg';
import stateDining from '../../../assets/state-dining.jpg';
import guardRoom from '../../../assets/guards.webp';
import artImage from '../../../assets/art.webp';
import kingsRoom from '../../../assets/kings.webp';
// Stylroed Components
const CastleContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const GardenTabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto 3rem;
  gap: 0.8rem;
  padding: 0 1rem;
`;

const GardenTab = styled.button`
  background: ${props => props.active ? props.theme.colors.accent : 'transparent'};
  color: ${props => props.active ? 'white' : props.theme.colors.primary};
  border: 2px solid ${props => props.active ? props.theme.colors.accent : props.theme.colors.primary};
  padding: 0.7rem 1.2rem;
  font-size: 0.95rem;
  font-weight: 600;
  cursor: pointer;
  border-radius: 25px;
  white-space: nowrap;
  
  &:hover {
    background: ${props => props.active ? props.theme.colors.accent : props.theme.colors.greyBackground};
  }
`;

const GardenContent = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  padding: 3rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
  gap: 3rem;
  
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const GardenText = styled.div`
  flex: 1;
`;

const GardenTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const GardenDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
`;

const GardenImageContainer = styled.div`
  flex: 1;
  min-width: 300px;
  height: 400px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    height: 300px;
  }
`;

const GardenImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease-in-out;
  opacity: 0.99; /* Fix for Safari flickering */
  transform: translateZ(0); /* Force GPU acceleration */
  will-change: transform; /* Optimize for animations */
`;

const HighlightSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 5rem 2rem;
  margin: 5rem 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    clip-path: polygon(0 0, 100% 100%, 0 100%);
  }
`;

const HighlightContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const HighlightTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: white;
  margin-bottom: 2rem;
`;

const HighlightText = styled.p`
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  color: white;
  font-size: 32px;
`;

const CTAButton = styled.a`
  display: inline-block;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1rem 2rem;
  border: 2px solid transparent;
  border-radius: 25px;
  text-decoration: none;
  transition: all 0.3s ease;
  margin-top: 1rem;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const FeatureSection = styled.div`
  display: flex;
  margin: 5rem 0;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  
  @media (max-width: 992px) {
    flex-direction: ${props => props.imagePosition === 'right' ? 'column-reverse' : 'column'};
  }
`;

const FeatureImage = styled.div`
  flex: 1;
  min-height: 500px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  
  @media (max-width: 992px) {
    min-height: 300px;
  }
`;

const FeatureContent = styled.div`
  flex: 1;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const FeatureTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const FeatureDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const GallerySection = styled.div`
  margin: 5rem 0;
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 250px);
  gap: 1.5rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 200px);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 200px);
  }
`;

const GalleryItem = styled.div`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
`;

const GalleryOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

const GalleryTitle = styled.h5`
  color: white;
  font-size: 1.5rem;
  text-align: center;
  padding: 1rem;
`;

const GET_CASTLE_PAGE = gql`
  query GetCastlePage {
    page(id: "castle-and-state-rooms", idType: URI) {
      id
      title
      castlePage {
        heroSection {
          welcomeText
          mainTitle
          subtitle
          heroImage {
            node {
              sourceUrl
            }
          }
        }
        descriptionSection {
          title
          description
          buttonText
        }
        roomsSection {
          title
          description
          image {
            node {
              sourceUrl
            }
          }
        }
        guidedTours {
          sectionTitle
          sectionDescription
          sectionImage {
            node {
              sourceUrl
            }
          }
          bookingInfo
          buttonText
        }
      }
    }
    siteSetting(id: "site-settings", idType: SLUG) {
      openingHours {
        castle {
          days
          times
          note
        }
        gardens {
          days
          times
          note
        }
        retail {
          days
          times
          note
        }
      }
    }
    guidedTours(first: 10) {
      nodes {
        title
        featuredImage {
          node {
            sourceUrl
          }
        }
        guidedTourDetails {
          tourDescription
          tourDuration
          tourPrice
        }
      }
    }
  }
`;

const CastleStateRooms = () => {
  const { loading, error, data } = useQuery(GET_CASTLE_PAGE);
  const [activeRoom, setActiveRoom] = useState('guard');
  
  const handleRoomChange = (room) => {
    setActiveRoom(room);
  };
  
  const renderRoomContent = () => {
    switch(activeRoom) {
      case 'guard':
        return (
          <GardenContent>
            <GardenText>
              <GardenTitle>Guard Room</GardenTitle>
              <GardenDescription>
                The Guard Room serves as the grand entrance to the State Rooms. This impressive space 
                features a collection of arms and armour, alongside historic military memorabilia 
                associated with the Rutland family.
              </GardenDescription>
            </GardenText>
            <GardenImageContainer>
              <GardenImage src={guardRoom} alt="Guard Room at Belvoir Castle" />
            </GardenImageContainer>
          </GardenContent>
        );
      
      case 'elizabeth':
        return (
          <GardenContent reverse>
            <GardenText>
              <GardenTitle>Elizabeth Saloon</GardenTitle>
              <GardenDescription>
                Named after the 5th Duchess of Rutland, this opulent room showcases the finest 
                examples of Regency decoration. The walls are adorned with silk damask, and the 
                ceiling features intricate gilded details.
              </GardenDescription>
            </GardenText>
            <GardenImageContainer>
              <GardenImage src={elizabethSaloon} alt="Elizabeth Saloon at Belvoir Castle" />
            </GardenImageContainer>
          </GardenContent>
        );

      case 'regents':
        return (
          <GardenContent>
            <GardenText>
              <GardenTitle>Regent's Gallery</GardenTitle>
              <GardenDescription>
                The Regent's Gallery is one of the castle's most impressive spaces, featuring 
                a stunning collection of 18th and 19th-century paintings. The gallery was named 
                in honor of the Prince Regent, later George IV.
              </GardenDescription>
            </GardenText>
            <GardenImageContainer>
              <GardenImage src={regentsGallery} alt="Regent's Gallery at Belvoir Castle" />
            </GardenImageContainer>
          </GardenContent>
        );

      case 'ballroom':
        return (
          <GardenContent reverse>
            <GardenText>
              <GardenTitle>Ballroom</GardenTitle>
              <GardenDescription>
                The Ballroom boasts a magnificent ceiling modelled on Lincoln Cathedral, 
                designed by Sir John Thoroton. This grand space has hosted countless 
                memorable celebrations throughout the centuries.
              </GardenDescription>
            </GardenText>
            <GardenImageContainer>
              <GardenImage src={ballroom} alt="Ballroom at Belvoir Castle" />
            </GardenImageContainer>
          </GardenContent>
        );

      case 'dining':
        return (
          <GardenContent>
            <GardenText>
              <GardenTitle>State Dining Room</GardenTitle>
              <GardenDescription>
                The State Dining Room features a magnificent ceiling holding 91 entirely unique flowers, 
                based on the Church of Santa Maria Maggiore in Rome. This impressive room continues 
                to host grand dinners and special occasions.
              </GardenDescription>
            </GardenText>
            <GardenImageContainer>
              <GardenImage src={stateDining} alt="State Dining Room at Belvoir Castle" />
            </GardenImageContainer>
          </GardenContent>
        );

      case 'kings':
        return (
          <GardenContent>
            <GardenText>
              <GardenTitle>King's Room</GardenTitle>
              <GardenDescription>
                The King's Room is one of the castle's most prestigious chambers, having hosted 
                numerous royal visitors throughout history. This opulent room features exquisite 
                period furniture, rich tapestries, and remarkable architectural details that reflect 
                its royal heritage. The room has welcomed distinguished guests including Queen Victoria 
                and King George IV, maintaining its status as a symbol of Belvoir Castle's historic 
                royal connections.
              </GardenDescription>
            </GardenText>
            <GardenImageContainer>
              <GardenImage src={kingsRoom} alt="King's Room at Belvoir Castle" />
            </GardenImageContainer>
          </GardenContent>
        );

      default:
        return null;
    }
  };

  return (
    <CastleContainer>
      <ParallaxBanner 
        mainTitle="Castle State Rooms"
        subtitle="Experience Centuries of Royal Heritage"
        image={bannerImage}
      />
      
      <ContentContainer>
        <SectionTitle>The State Rooms of Belvoir Castle</SectionTitle>
        <IntroText>
          Step into the magnificent State Rooms of Belvoir Castle, where centuries of history, 
          art, and royal heritage come together in stunning displays of architectural and 
          decorative excellence.
        </IntroText>
        
        <GardenTabs>
          <GardenTab 
            active={activeRoom === 'guard'} 
            onClick={() => handleRoomChange('guard')}
          >
            Guard Room
          </GardenTab>
          <GardenTab 
            active={activeRoom === 'elizabeth'} 
            onClick={() => handleRoomChange('elizabeth')}
          >
            Elizabeth Saloon
          </GardenTab>
          <GardenTab 
            active={activeRoom === 'regents'} 
            onClick={() => handleRoomChange('regents')}
          >
            Regent's Gallery
          </GardenTab>
          <GardenTab 
            active={activeRoom === 'ballroom'} 
            onClick={() => handleRoomChange('ballroom')}
          >
            Ballroom
          </GardenTab>
          <GardenTab 
            active={activeRoom === 'dining'} 
            onClick={() => handleRoomChange('dining')}
          >
            State Dining Room
          </GardenTab>
          <GardenTab 
            active={activeRoom === 'kings'} 
            onClick={() => handleRoomChange('kings')}
          >
            King's Room
          </GardenTab>
        </GardenTabs>
        
        {renderRoomContent()}
        
        <HighlightSection>
          <HighlightContent>
            <IconWrapper>
              <FontAwesomeIcon icon={faCrown} />
            </IconWrapper>
            <HighlightTitle>A Royal Heritage</HighlightTitle>
            <HighlightText>
              Discover the rooms that have hosted royalty through the centuries, from 
              Queen Victoria to modern-day monarchs. Each room tells a story of Britain's 
              aristocratic heritage and the Rutland family's place in history.
            </HighlightText>
            <CTAButton href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=b0f1a62a-a34a-4c4e-a88d-6fda03a19279&type=WebPage&locationId=978&mainWebPageId=e39e1d45-8941-4502-8c92-bb7082a9ddbf&startId=e39e1d45-8941-4502-8c92-bb7082a9ddbf">
              Book a Tour
            </CTAButton>
          </HighlightContent>
        </HighlightSection>
        
        <FeatureSection imagePosition="left">
          <FeatureImage image={artImage} />
          <FeatureContent>
            <FeatureTitle>Art & Collections</FeatureTitle>
            <FeatureDescription>
              The Castle houses an impressive collection of artworks, including paintings by 
              renowned artists, historic furniture, and priceless artifacts that tell the 
              story of British aristocratic life through the centuries.
            </FeatureDescription>
          </FeatureContent>
        </FeatureSection>
        
        <HighlightSection style={{ backgroundColor: theme.colors.accent }}>
          <HighlightContent>
            <IconWrapper style={{ backgroundColor: theme.colors.primary }}>
              <FontAwesomeIcon icon={faBook} />
            </IconWrapper>
            <HighlightTitle>Plan Your Visit</HighlightTitle>
            <HighlightText>
              Experience the grandeur of Belvoir Castle's State Rooms for yourself. 
              Join our expert guides for a fascinating journey through centuries of history.
            </HighlightText>
            <CTAButton href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=b0f1a62a-a34a-4c4e-a88d-6fda03a19279&type=WebPage&locationId=978&mainWebPageId=e39e1d45-8941-4502-8c92-bb7082a9ddbf&startId=e39e1d45-8941-4502-8c92-bb7082a9ddbf" style={{ backgroundColor: theme.colors.primary }}>
              Book Tickets
            </CTAButton>
          </HighlightContent>
        </HighlightSection>
      </ContentContainer>
      
      <Footer />
    </CastleContainer>
  );
};

export default CastleStateRooms; 