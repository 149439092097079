import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faClock, 
  faBus, 
  faTicketAlt,
  faArrowRight,
  faCalendarAlt,
  faUsers,
  faMapMarkerAlt,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';

// Import images
import castleImage from '../../../assets/castle2.png';
import busTourImage from '../../../assets/gardens.png';
import castleTourImage from '../../../assets/generalTour.webp';
import banner from '../../../assets/banner/tourBan.webp';

const ToursContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 3px;
    background-color: ${props => props.theme.colors.accent};
    margin: 1rem auto 0;
  }
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const ToursGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const TourCard = styled.div`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  
  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
`;

const TourImage = styled.div`
  height: 300px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const TourContent = styled.div`
  padding: 2rem;
`;

const TourTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  svg {
    color: ${props => props.theme.colors.accent};
  }
`;

const TourDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 2rem;
`;

const TourDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
  padding: 1.5rem;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  color: ${props => props.theme.colors.primary};
  font-size: 1.1rem;

  svg {
    color: ${props => props.theme.colors.accent};
  }
`;

const PriceTag = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

const BookButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  text-decoration: none;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  font-weight: 600;
  transition: all 0.3s ease;
  border: 2px solid ${props => props.theme.colors.accent};
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
  }
  
  svg {
    margin-left: 0.5rem;
  }
`;

const InfoBox = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  padding: 2rem;
  border-radius: 12px;
  margin-top: 4rem;
  text-align: center;
`;

const InfoText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const Tours = () => {
  return (
    <ToursContainer>
      <ParallaxBanner 
        title="Castle Tours"
        subtitle="Discover Belvoir Castle with Our Expert Guides"
        image={banner}
      />
      
      <ContentContainer>
        <SectionTitle>Daily Castle Tours</SectionTitle>
        <IntroText>
          Experience the rich history and fascinating stories of Belvoir Castle through our daily guided tours. 
          Our knowledgeable guides will take you through the magnificent state rooms and share the castle's 
          remarkable history with you.
        </IntroText>
        
        <ToursGrid>
          <TourCard>
            <TourImage image={castleTourImage} />
            <TourContent>
              <TourTitle>
                <FontAwesomeIcon icon={faClock} />
                Daily Castle Tours
              </TourTitle>
              <PriceTag>£5 per person</PriceTag>
              <TourDescription>
                Join our expert guides for a comprehensive tour of Belvoir Castle's state rooms. 
                Learn about the castle's history, architecture, and the fascinating stories of its inhabitants.
              </TourDescription>
              <TourDetails>
                <DetailItem>
                  <FontAwesomeIcon icon={faClock} />
                  <div>
                    <strong>Times:</strong><br />
                    11:00 & 14:00
                  </div>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faUsers} />
                  <div>
                    <strong>Duration:</strong><br />
                    1 ½ hours
                  </div>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faTicketAlt} />
                  <div>
                    <strong>Price:</strong><br />
                    £5 per person
                  </div>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                  <div>
                    <strong>Available:</strong><br />
                    Daily
                  </div>
                </DetailItem>
              </TourDetails>
              <BookButton 
                href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=b0f1a62a-a34a-4c4e-a88d-6fda03a19279&type=WebPage&locationId=978&mainWebPageId=b0f1a62a-a34a-4c4e-a88d-6fda03a19279&startId=e39e1d45-8941-4502-8c92-bb7082a9ddbf" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                Book Tour <FontAwesomeIcon icon={faArrowRight} />
              </BookButton>
            </TourContent>
          </TourCard>

          <TourCard>
            <TourImage image={busTourImage} />
            <TourContent>
              <TourTitle>
                <FontAwesomeIcon icon={faBus} />
                Parkland Bus Tour
              </TourTitle>
              <PriceTag>£5 per person</PriceTag>
              <TourDescription>
                Explore the stunning parkland designed by Capability Brown. This guided bus tour takes you 
                through the estate's beautiful landscape, offering unique views and insights into the 
                parkland's history and design.
              </TourDescription>
              <TourDetails>
                <DetailItem>
                  <FontAwesomeIcon icon={faClock} />
                  <div>
                    <strong>Times:</strong><br />
                    12:00, 13:30 & 15:00
                  </div>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faUsers} />
                  <div>
                    <strong>Duration:</strong><br />
                    1 hour
                  </div>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faTicketAlt} />
                  <div>
                    <strong>Price:</strong><br />
                    £5 per person
                  </div>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                  <div>
                    <strong>Available:</strong><br />
                    Thu - Sun
                  </div>
                </DetailItem>
              </TourDetails>
              <BookButton to="/tour-booking">
                Book Tour <FontAwesomeIcon icon={faArrowRight} />
              </BookButton>
            </TourContent>
          </TourCard>
        </ToursGrid>

        <InfoBox>
          <InfoText>
            <FontAwesomeIcon icon={faInfoCircle} /> Tours can be added to your booking when purchasing tickets.
            Tours depart from the castle entrance.
          </InfoText>
        </InfoBox>

        {/* New Featured Tours Section */}
        <SectionTitle style={{ marginTop: '4rem' }}>Discover More Tours</SectionTitle>
        <IntroText>
          Looking for a more specialized experience? Explore our featured tours, including the Film Lover's Tour, 
          Art Collection Tour, and other exclusive experiences.
        </IntroText>
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
          <BookButton to="/whats-on/featured-tours">
            View Featured Tours <FontAwesomeIcon icon={faArrowRight} />
          </BookButton>
        </div>
      </ContentContainer>
      
      <Footer />
    </ToursContainer>
  );
};

export default Tours; 