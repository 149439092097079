import React from 'react';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWheelchair, faEarListen, faEye } from '@fortawesome/free-solid-svg-icons';

// Import images
import castleImage from '../../../assets/castle2.png';

const GET_ACCESSIBILITY_DATA = gql`
  query GetAccessibilityData {
    siteSettings(where: {title: "Global Site Settings"}) {
      nodes {
        siteSettingsFields {
          contactInfo {
            phone
            email
          }
        }
      }
    }
  }
`;

const AccessibilityContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 100%;
  margin-bottom: 3rem;
  text-align: left;
`;

const AccessibilitySection = styled.div`
  margin-bottom: 3rem;
`;

const AccessibilityCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const CardTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const CardText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const AccessibilityList = styled.ul`
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
  
  li {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 0.5rem;
  }
`;

const ContactCard = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border-radius: 8px;
  padding: 2rem;
  margin-top: 3rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const ContactTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: white;
  margin-bottom: 1rem;
`;

const ContactText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  color: white;
  
  a {
    color: white;
    text-decoration: underline;
    font-weight: 600;
    
    &:hover {
      text-decoration: none;
    }
  }
`;

const AccessibilityMap = styled.div`
  margin: 3rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const MapCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  
  h4 {
    font-family: ${props => props.theme.fonts.main};
    font-size: 1.5rem;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 1rem;
  }
  
  ul {
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    
    li {
      font-size: 1.1rem;
      line-height: 1.6;
      margin-bottom: 0.5rem;
    }
  }
`;

// Create a better icon approach using Font Awesome
const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  
  svg {
    color: white;
    font-size: 28px;
  }
`;

// Font Awesome icons
const MobilityIcon = () => (
  <IconWrapper>
    <FontAwesomeIcon icon={faWheelchair} />
  </IconWrapper>
);

const HearingIcon = () => (
  <IconWrapper>
    <FontAwesomeIcon icon={faEarListen} />
  </IconWrapper>
);

const VisionIcon = () => (
  <IconWrapper>
    <FontAwesomeIcon icon={faEye} />
  </IconWrapper>
);

const Accessibility = () => {
  const { loading, error, data } = useQuery(GET_ACCESSIBILITY_DATA);
  
  // Extract contact info with fallback values
  const contactInfo = data?.siteSettings?.nodes?.[0]?.siteSettingsFields?.contactInfo || {
    phone: "01476 871001",
    email: "info@belvoircastle.com"
  };
  
  return (
    <AccessibilityContainer>
      <ParallaxBanner 
        title="Accessibility"
        subtitle="Making Belvoir Castle Accessible to All"
        image={castleImage}
      />
      
      <ContentContainer>
        <SectionTitle>Our Commitment to Accessibility</SectionTitle>
        <IntroText>
          Belvoir Castle is committed to ensuring that all visitors can enjoy their experience with us. 
          As a historic building dating back several centuries, we face some inherent challenges in providing 
          full accessibility throughout the castle. However, we are continually working to improve access 
          where possible and provide alternative experiences where physical access is limited.
        </IntroText>
        
        <AccessibilitySection>
          <SectionTitle>Castle Accessibility</SectionTitle>
          <AccessibilityCard>
            <CardTitle>Castle Ground Floor</CardTitle>
            <CardText>
              Due to the historic nature of Belvoir Castle, accessibility is limited to the ground floor only. 
              The upper floors are accessible only via staircases, with no lift access available due to the 
              building's protected status and structural limitations.
            </CardText>
            <CardText>
              The ground floor includes:
            </CardText>
            <AccessibilityList>
              <li>The Guard Room</li>
              <li>The Entrance Hall</li>
              <li>The Old Kitchen</li>
              <li>The Aviary Tea Room</li>
              <li>The Gift Shop</li>
              <li>Accessible toilet facilities</li>
            </AccessibilityList>
            <CardText>
              For visitors unable to access the upper floors, we offer a comprehensive guidebook
              which can be borrowed free of charge from the Visitor Hub. These guides 
              feature high-quality photography, videos, and information about the rooms and collections on the 
              upper floors.
            </CardText>
          </AccessibilityCard>
          
          <AccessibilityCard>
            <CardTitle>Gardens & Grounds</CardTitle>
            <CardText>
              Parts of the gardens are accessible to wheelchair users and those with limited mobility, though 
              some areas feature steep slopes, steps, and uneven terrain due to the castle's hillside location.
            </CardText>
            <CardText>
              Accessible areas include:
            </CardText>
            <AccessibilityList>
              <li>The formal gardens nearest to the castle</li>
              <li>The Japanese Garden (via an alternative route)</li>
              <li>The Rose Garden</li>
              <li>The main pathways through the woodland</li>
            </AccessibilityList>
            <CardText>
              We have created a designated accessible route through the gardens, which is marked on our 
              visitor maps available at the ticket office.
            </CardText>
          </AccessibilityCard>
          
          <AccessibilityCard>
            <CardTitle>Retail Village</CardTitle>
            <CardText>
              The Retail Village is fully accessible, with level access to all shops and facilities. 
              Wide doorways and smooth surfaces make this area suitable for wheelchair users and those 
              with limited mobility.
            </CardText>
          </AccessibilityCard>
        </AccessibilitySection>
        
        <AccessibilityMap>
          <MapCard>
            <MobilityIcon />
            <h4>Mobility Access</h4>
            <ul>
              <li>Designated accessible parking spaces close to the castle entrance (please collect a parking permit from the ticket office)</li>
              <li>Wheelchair loan service (subject to availability, pre-booking advised)</li>
              <li>Ground floor access to the castle</li>
              <li>Accessible toilets at the castle entrance, gardens, and retail village</li>
              <li>Smooth pathways in designated garden areas</li>
            </ul>
          </MapCard>
          
          <MapCard>
            <HearingIcon />
            <h4>Hearing Impairment</h4>
            <ul>
              <li>Written guides available for all tours</li>
              <li>Visual information displays throughout the castle</li>
              <li>Staff trained to assist visitors with hearing impairments</li>
            </ul>
          </MapCard>
          
          <MapCard>
            <VisionIcon />
            <h4>Visual Impairment</h4>
            <ul>
              <li>Large print guides available</li>
              <li>Guide dogs welcome throughout the estate</li>
            </ul>
          </MapCard>
        </AccessibilityMap>
        
        <AccessibilitySection>
          <SectionTitle>Additional Services</SectionTitle>
          <AccessibilityCard>
            <CardTitle>Accessible Parking</CardTitle>
            <CardText>
              Designated accessible parking spaces are available close to the main entrance. These spaces 
              are clearly marked and are approximately 50 meters from the ticket office. We can also issue a parking permit for the car park at the top of the hill next to the castle.
            </CardText>
          </AccessibilityCard>
          
          <AccessibilityCard>
            <CardTitle>Assistance Dogs</CardTitle>
            <CardText>
              Assistance dogs are welcome throughout Belvoir Castle, including in the castle itself, 
              gardens, and retail village. Water bowls are available at various locations.
            </CardText>
          </AccessibilityCard>
          
          <AccessibilityCard>
            <CardTitle>Accessible Toilets</CardTitle>
            <CardText>
              Accessible toilet facilities are available at the castle entrance, in the gardens, and at 
              the retail village. All accessible toilets are equipped with emergency pull cords and 
              support rails.
            </CardText>
          </AccessibilityCard>
          
       
        </AccessibilitySection>
        
        <AccessibilitySection>
          <SectionTitle>Planning Your Visit</SectionTitle>
          <AccessibilityCard>
            <CardTitle>Pre-Visit Information</CardTitle>
            <CardText>
              We recommend contacting us in advance if you have specific accessibility requirements. 
              Our staff can provide detailed information about accessibility and help plan your visit 
              to ensure the best possible experience.
            </CardText>
            <CardText>
              For visitors with mobility challenges, we recommend visiting on weekdays when the castle 
              and grounds are generally less crowded.
            </CardText>
          </AccessibilityCard>
          
          <AccessibilityCard>
            <CardTitle>Carers and Companions</CardTitle>
            <CardText>
              We offer free admission for essential carers accompanying disabled visitors. Please bring 
              relevant documentation (such as a disability living allowance letter or blue badge) to 
              qualify for this concession.
            </CardText>
          </AccessibilityCard>
        </AccessibilitySection>
        
        <ContactCard>
          <ContactTitle>Contact Our Inclusion Officer</ContactTitle>
          <ContactText>
            If you have any specific accessibility requirements or questions that aren't addressed here, 
            please contact our dedicated Inclusion Officer who will be happy to assist you in planning 
            your visit.
          </ContactText>
          <ContactText>
            Phone: <a href={`tel:${contactInfo.phone}`}>{contactInfo.phone}</a>
          </ContactText>
          <ContactText>
            Email: <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
          </ContactText>
          <ContactText>
            We welcome feedback on our accessibility provisions as we are committed to continually 
            improving the experience for all our visitors.
          </ContactText>
        </ContactCard>
      </ContentContainer>
      
      <Footer />
    </AccessibilityContainer>
  );
};

export default Accessibility; 