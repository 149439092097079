import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUtensils, 
  faGift, 
  faGlassCheers, 
  faCamera, 
  faHotTub, 
  faPray, 
  faDice, 
  faUsers, 
  faSearch, 
  faHeart, 
  faSpa, 
  faBowArrow, 
  faWineGlassAlt, 
  faBasketShopping, 
  faMicrophone, 
  faDragon,
  faStar
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import NewsletterSignUp from '../../common/Newsletter-sign';
import { useStaySection } from '../../../hooks/useStaySection';
import ReCAPTCHA from 'react-google-recaptcha';

// Import images
import castleImage from '../../../assets/castle2.png';

const ExperiencesContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  letter-spacing: 2px;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const CategoryTabs = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
`;

const CategoryTab = styled.button`
  background: ${props => props.active ? props.theme.colors.accent : 'transparent'};
  color: ${props => props.active ? 'white' : props.theme.colors.accent};
  border: 2px solid ${props => props.active ? props.theme.colors.accent : props.theme.colors.accent};
  padding: 0.8rem 1.5rem;
  margin: 0.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 25px;
  
  &:hover {
    background: ${props => props.active ? props.theme.colors.accent : 'transparent'};
    color: ${props => props.active ? 'white' : props.theme.colors.accent};
  }
`;

const ExperiencesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 4rem 0;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ExperienceCard = styled.div`
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const ExperienceImage = styled.div`
  height: 200px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const ExperienceContent = styled.div`
  padding: 2rem;
`;

const ExperienceTitle = styled.h3`
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const ExperienceDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const ExperienceFeatures = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 1.5rem;
  
  li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    
    svg {
      color: ${props => props.theme.colors.accent};
    }
  }
`;

const BookButton = styled.button`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: 2px solid transparent;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 25px;
  cursor: pointer;
  width: 100%;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const HighlightSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 4rem 2rem;
  text-align: center;
  margin: 5rem 0;
`;

const HighlightContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const HighlightTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: white;
  margin-bottom: 1.5rem;
`;

const HighlightText = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const EnquiryForm = styled.div`
  background: white;
  border-radius: 8px;
  padding: 3rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 4rem;
`;

const FormTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
  text-align: center;
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  font-weight: 600;
  color: ${props => props.theme.colors.primary};
`;

const Input = styled.input`
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
`;

const Select = styled.select`
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
`;

const TextArea = styled.textarea`
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  min-height: 150px;
  resize: vertical;
`;

const ExperienceCheckboxes = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background: ${props => props.theme.colors.greyBackground};
  border-radius: 4px;
`;

const SubmitButton = styled.button`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: 2px solid transparent;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 25px;
  cursor: pointer;
  width: 100%;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const ReCaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

const experiences = {
  dining: [
    {
      title: "Breakfast Experience",
      description: "Start your day with a delicious breakfast in our elegant dining room, featuring locally sourced ingredients and stunning views.",
      image: "https://belvoirestateholidays.com/wp-content/uploads/2024/02/breakfast.jpg",
      features: ["Full English breakfast", "Continental options", "Fresh pastries", "Local produce"]
    },
    {
      title: "Breakfast Hampers",
      description: "Enjoy a private breakfast in your accommodation with our carefully curated breakfast hampers.",
      image: "https://belvoirestateholidays.com/wp-content/uploads/2024/02/breakfast-hamper.jpg",
      features: ["Local delicacies", "Fresh bread", "Artisan jams", "Estate honey"]
    },
    {
      title: "Private Dining",
      description: "Experience exclusive dining in our historic rooms, with personalized menus and attentive service.",
      image: "https://belvoirestateholidays.com/wp-content/uploads/2024/02/private-dining.jpg",
      features: ["Custom menus", "Wine pairing", "Private room", "Personal chef"]
    }
  ],
  entertainment: [
    {
      title: "Balloon Bar & Bistro Parties",
      description: "Celebrate in style with our unique party packages in the Balloon Bar and Belvoir Bistro.",
      image: "https://belvoirestateholidays.com/wp-content/uploads/2024/02/balloon-bar.jpg",
      features: ["Dedicated space", "Custom catering", "Bar service", "Entertainment options"]
    },
    {
      title: "Casino Experience",
      description: "Host an exciting casino night with professional dealers and authentic gaming tables.",
      image: "https://belvoirestateholidays.com/wp-content/uploads/2024/02/casino.jpg",
      features: ["Professional dealers", "Multiple tables", "Prizes", "Catering options"]
    },
    {
      title: "Karaoke Nights",
      description: "Add fun to your celebration with our karaoke machine hire and dedicated space.",
      image: "https://belvoirestateholidays.com/wp-content/uploads/2024/02/karaoke.jpg",
      features: ["Professional equipment", "Song library", "Private room", "Bar service"]
    }
  ],
  activities: [
    {
      title: "Photography Packages",
      description: "Capture your special moments with our professional photography packages in stunning locations.",
      image: "https://belvoirestateholidays.com/wp-content/uploads/2024/02/photography.jpg",
      features: ["Professional photographer", "Multiple locations", "Digital gallery", "Print options"]
    },
    {
      title: "Archery Experience",
      description: "Learn the ancient art of archery with our expert instructors in the castle grounds.",
      image: "https://belvoirestateholidays.com/wp-content/uploads/2024/02/archery.jpg",
      features: ["Expert tuition", "All equipment provided", "Safety briefing", "Group sessions"]
    },
    {
      title: "Cocktail Making",
      description: "Master the art of mixology with our expert bartenders in a fun, interactive session.",
      image: "https://belvoirestateholidays.com/wp-content/uploads/2024/02/cocktails.jpg",
      features: ["Expert guidance", "Premium spirits", "Take-home recipes", "Group activities"]
    }
  ],
  wellness: [
    {
      title: "Hot Tub Experience",
      description: "Relax in our private hot tubs with stunning views of the estate.",
      image: "https://belvoirestateholidays.com/wp-content/uploads/2024/02/hot-tub.jpg",
      features: ["Private setting", "Scenic views", "Towels provided", "Refreshments"]
    },
    {
      title: "Yoga Sessions",
      description: "Find your inner peace with yoga sessions in our tranquil surroundings.",
      image: "https://belvoirestateholidays.com/wp-content/uploads/2024/02/yoga.jpg",
      features: ["Expert instructors", "All levels welcome", "Equipment provided", "Scenic location"]
    },
    {
      title: "Spa & Wellness",
      description: "Indulge in our range of spa treatments and wellness experiences.",
      image: "https://belvoirestateholidays.com/wp-content/uploads/2024/02/spa.jpg",
      features: ["Professional therapists", "Luxury treatments", "Private rooms", "Wellness packages"]
    }
  ],
  special: [
    {
      title: "Murder Mystery Experience",
      description: "Solve a thrilling mystery in the historic setting of Belvoir Castle.",
      image: "https://belvoirestateholidays.com/wp-content/uploads/2024/02/murder-mystery.jpg",
      features: ["Interactive story", "Costumed actors", "Dinner included", "Prizes"]
    },
    {
      title: "Hen Party Packages",
      description: "Celebrate in style with our specially curated hen party experiences.",
      image: "https://belvoirestateholidays.com/wp-content/uploads/2024/02/hen-party.jpg",
      features: ["Custom activities", "Accommodation", "Dining options", "Entertainment"]
    },
    {
      title: "Exotic Animal Experience",
      description: "Get up close with our collection of exotic animals in a private session.",
      image: "https://belvoirestateholidays.com/wp-content/uploads/2024/02/exotic-animals.jpg",
      features: ["Expert handlers", "Multiple species", "Photo opportunities", "Educational content"]
    }
  ]
};

const Experiences = () => {
  const [activeCategory, setActiveCategory] = useState('dining');
  const [selectedExperiences, setSelectedExperiences] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    preferredDate: '',
    guests: '',
    preferredTime: '',
    additionalRequirements: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [captchaValue, setCaptchaValue] = useState(null);
  useStaySection();

  // Add console log to debug the site key
  console.log('reCAPTCHA Site Key:', process.env.REACT_APP_RECAPTCHA_SITE_KEY);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!captchaValue) {
      setSubmitStatus({
        type: 'error',
        message: 'Please complete the CAPTCHA verification.'
      });
      return;
    }

    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const response = await fetch('http://localhost:8888/Website/wordpress/wp-json/belvoir/v1/experience-enquiry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          ...formData,
          selectedExperiences,
          captchaValue
        })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to submit enquiry');
      }

      setSubmitStatus({
        type: 'success',
        message: 'Thank you for your enquiry. We will be in touch shortly.'
      });
      
      // Reset form
      setFormData({
        name: '',
        email: '',
        phone: '',
        preferredDate: '',
        guests: '',
        preferredTime: '',
        additionalRequirements: ''
      });
      setSelectedExperiences([]);
      setCaptchaValue(null);
      
    } catch (error) {
      console.error('Submission error:', error);
      setSubmitStatus({
        type: 'error',
        message: error.message || 'Something went wrong. Please try again.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleExperienceToggle = (experience) => {
    setSelectedExperiences(prev => {
      if (prev.includes(experience)) {
        return prev.filter(e => e !== experience);
      }
      return [...prev, experience];
    });
  };

  return (
    <ExperiencesContainer>
      <ParallaxBanner 
        title="Experiences at Belvoir Castle"
        subtitle="Create Unforgettable Memories"
        image={castleImage}
      />
      
      <ContentContainer>
        <SectionTitle>Unique Experiences at Belvoir Castle</SectionTitle>
        <IntroText>
          From intimate dining experiences to thrilling activities, discover our range of carefully curated 
          experiences designed to make your stay at Belvoir Castle truly memorable.
        </IntroText>

        <CategoryTabs>
          <CategoryTab 
            active={activeCategory === 'dining'} 
            onClick={() => setActiveCategory('dining')}
          >
            Dining
          </CategoryTab>
          <CategoryTab 
            active={activeCategory === 'entertainment'} 
            onClick={() => setActiveCategory('entertainment')}
          >
            Entertainment
          </CategoryTab>
          <CategoryTab 
            active={activeCategory === 'activities'} 
            onClick={() => setActiveCategory('activities')}
          >
            Activities
          </CategoryTab>
          <CategoryTab 
            active={activeCategory === 'wellness'} 
            onClick={() => setActiveCategory('wellness')}
          >
            Wellness
          </CategoryTab>
          <CategoryTab 
            active={activeCategory === 'special'} 
            onClick={() => setActiveCategory('special')}
          >
            Special Events
          </CategoryTab>
        </CategoryTabs>

        <ExperiencesGrid>
          {experiences[activeCategory].map((experience, index) => (
            <ExperienceCard key={index}>
              <ExperienceImage image={experience.image} />
              <ExperienceContent>
                <ExperienceTitle>{experience.title}</ExperienceTitle>
                <ExperienceDescription>{experience.description}</ExperienceDescription>
                <ExperienceFeatures>
                  {experience.features.map((feature, i) => (
                    <li key={i}>
                      <FontAwesomeIcon icon={faStar} />
                      {feature}
                    </li>
                  ))}
                </ExperienceFeatures>
              </ExperienceContent>
            </ExperienceCard>
          ))}
        </ExperiencesGrid>

        <EnquiryForm>
          <FormTitle>Enquire About Experiences</FormTitle>
          <form onSubmit={handleSubmit}>
            <FormGrid>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Your name"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Your email"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>Phone</Label>
                <Input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Your phone number"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>Preferred Date</Label>
                <Input
                  type="date"
                  name="preferredDate"
                  value={formData.preferredDate}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>Number of Guests</Label>
                <Input
                  type="number"
                  name="guests"
                  value={formData.guests}
                  onChange={handleInputChange}
                  min="1"
                  placeholder="Number of guests"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>Preferred Time</Label>
                <Select
                  name="preferredTime"
                  value={formData.preferredTime}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select time</option>
                  <option value="morning">Morning</option>
                  <option value="afternoon">Afternoon</option>
                  <option value="evening">Evening</option>
                </Select>
              </FormGroup>
            </FormGrid>

            <Label>Select Experiences</Label>
            <ExperienceCheckboxes>
              {Object.values(experiences).flat().map((experience, index) => (
                <CheckboxGroup key={index}>
                  <input
                    type="checkbox"
                    id={`experience-${index}`}
                    checked={selectedExperiences.includes(experience.title)}
                    onChange={() => handleExperienceToggle(experience.title)}
                  />
                  <label htmlFor={`experience-${index}`}>{experience.title}</label>
                </CheckboxGroup>
              ))}
            </ExperienceCheckboxes>

            <FormGroup>
              <Label>Additional Requirements</Label>
              <TextArea
                name="additionalRequirements"
                value={formData.additionalRequirements}
                onChange={handleInputChange}
                placeholder="Tell us about any special requirements or preferences..."
              />
            </FormGroup>

            <ReCaptchaWrapper>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LeP8wArAAAAAEF-wVDICNGHfRo9hDA70aLQeDnB'}
                onChange={handleCaptchaChange}
                theme="light"
              />
            </ReCaptchaWrapper>

            {submitStatus && (
              <div style={{
                margin: '1rem 0',
                padding: '1rem',
                borderRadius: '4px',
                backgroundColor: submitStatus.type === 'success' ? '#e0f2e9' : '#fde7e7',
                color: submitStatus.type === 'success' ? '#2e7d32' : '#d32f2f'
              }}>
                {submitStatus.message}
              </div>
            )}

            <SubmitButton type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit Enquiry'}
            </SubmitButton>
          </form>
        </EnquiryForm>

        <div style={{ margin: '4rem 0' }}>
          <NewsletterSignUp />
        </div>
      </ContentContainer>
      
      <Footer />
    </ExperiencesContainer>
  );
};

export default Experiences; 