import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTicketAlt, 
  faCalendarAlt, 
  faPercent, 
  faCoffee,
  faParking,
  faMobileAlt,
  faGift,
  faArrowRight,
  faCheck,
  faShieldAlt
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';

// Import images from existing assets
import castleImage from '../../../assets/castle2.png';
import gardensImage from '../../../assets/gardens.png';

const MembershipsContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  letter-spacing: 1px;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

const Tab = styled.button`
  background-color: ${props => props.active ? props.theme.colors.accent : 'transparent'};
  color: ${props => props.active ? 'white' : props.theme.colors.accent};
  border: 2px solid ${props => props.theme.colors.accent};
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 200px;
  
  &:first-child {
    border-radius: 50px 0 0 50px;
  }
  
  &:last-child {
    border-radius: 0 50px 50px 0;
  }
  
  &:hover {
    background-color: ${props => props.active ? props.theme.colors.accent : 'transparent'};
    color: ${props => props.active ? 'white' : props.theme.colors.accent};
  }
  
  @media (max-width: 768px) {
    border-radius: 50px !important;
    width: 100%;
    max-width: 300px;
  }
`;

const MembershipCard = styled.div`
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
`;

const MembershipHeader = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 2rem;
  text-align: center;
`;

const MembershipTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  margin-bottom: 0.5rem;
`;

const MembershipSubtitle = styled.p`
  font-size: 1.2rem;
  opacity: 0.9;
`;

const MembershipContent = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const PricingTable = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const PricingRow = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.colors.greyBackground};
  
  &:last-child {
    border-bottom: none;
  }
  
  &:nth-child(odd) {
    background-color: ${props => props.theme.colors.greyBackground};
  }
  
  &:first-child {
    background-color: ${props => props.theme.colors.primary};
    color: white;
    font-weight: 600;
  }
`;

const PricingCell = styled.div`
  padding: 1rem;
  flex: 1;
  text-align: ${props => props.align || 'left'};
  
  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 0.8rem;
  }
`;

const BenefitsList = styled.div`
  margin-bottom: 2rem;
`;

const BenefitItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  
  svg {
    color: ${props => props.theme.colors.accent};
    min-width: 20px;
    margin-right: 1rem;
    font-size: 1.2rem;
  }
`;

const BenefitText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: auto;
  flex-wrap: wrap;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ActionButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: 2px solid transparent;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1rem 2rem;
  border-radius: 25px;
  text-decoration: none;
  transition: all 0.3s ease;
  flex: 1;
  text-align: center;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
  
  svg {
    margin-left: 10px;
  }
`;

const SecondaryButton = styled(ActionButton)`
  background-color: transparent;
  color: ${props => props.theme.colors.accent};
  border: 2px solid ${props => props.theme.colors.accent};
  
  &:hover {
    background-color: ${props => props.theme.colors.accent};
    color: white;
  }
`;

const HighlightSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 5rem 2rem;
  margin: 5rem 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    clip-path: polygon(0 0, 100% 100%, 0 100%);
  }
`;

const HighlightContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const HighlightTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: white;
  margin-bottom: 2rem;
  letter-spacing: 1px;
`;

const HighlightText = styled.p`
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 4rem 0;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  padding: 2rem;
  text-align: center;
`;

const FeatureIcon = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 50%;
  color: ${props => props.theme.colors.accent};
  font-size: 32px;
`;

const FeatureTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const FeatureText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const NoteText = styled.p`
  font-size: 0.9rem;
  line-height: 1.6;
  font-style: italic;
  margin-top: 1rem;
  color: ${props => props.theme.colors.primary};
  opacity: 0.8;
`;

const ComingSoonBadge = styled.span`
  display: inline-block;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  margin-left: 1rem;
  vertical-align: middle;
`;

const StyledEmailLink = styled.a`
  color: ${props => props.theme.colors.accent};
  text-decoration: none;
  transition: color 0.3s ease;
  
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

const Memberships = () => {
  const [activeTab, setActiveTab] = useState('castle');
  
  const castleBenefits = [
    {
      icon: faTicketAlt,
      text: 'Unlimited access to the castle, parkland, gardens and playground on all of our open days'
    },
    {
      icon: faCalendarAlt,
      text: 'Free access to all Belvoir\'s own events (excluding evening held concerts)'
    },
    {
      icon: faPercent,
      text: '10% off purchases at Vale View Cafe, Belvoir Bistro, Gift Shop, Farm shop, Outside Bar and Kitchen, Balloon Bar and Duchess Collection'
    },
    {
      icon: faParking,
      text: 'Free parking for one vehicle in the main ticket office car park'
    },
    {
      icon: faPercent,
      text: '10% off Belvoir Estate \'Stay with Us\' rental properties'
    },
    {
      icon: faMobileAlt,
      text: 'Receive points on the Belvoir Castle app and personal offers'
    },
    {
      icon: faCoffee,
      text: 'Digital coffee stamps in our new state-of-the-art app coming Spring 2025'
    }
  ];
  
  const parklandBenefits = [
    {
      icon: faTicketAlt,
      text: 'Unlimited access to the parkland, gardens and playground on all of our open days'
    },
    {
      icon: faCalendarAlt,
      text: 'Free access to outdoor events'
    },
    {
      icon: faPercent,
      text: '10% off purchases at Vale View Cafe, Belvoir Bistro, Gift Shop, Farm shop, Outside Bar and Kitchen, Balloon Bar and Duchess Collection'
    },
    {
      icon: faParking,
      text: 'Free parking for one vehicle in the main ticket office car park'
    },
    {
      icon: faPercent,
      text: '10% off Belvoir Estate \'Stay with Us\' rental properties'
    },
    {
      icon: faMobileAlt,
      text: 'Receive points on the Belvoir Castle app and personal offers'
    },
    {
      icon: faCoffee,
      text: 'Digital coffee stamps in our new state-of-the-art app coming Spring 2025'
    }
  ];
  
  const castlePricing = [
    { type: 'Adult', price: '£52' },
    { type: 'Family (1 adult, 3 children)', price: '£75' },
    { type: 'Family (2 adults, 3 children)', price: '£125' },
    { type: 'Child (aged 3 - 16)', price: '£20' },
    { type: 'Child (under 3)', price: 'Free' }
  ];
  
  const parklandPricing = [
    { type: 'Adult', price: '£25' },
    { type: 'Family (1 adult, 3 children)', price: '£65' },
    { type: 'Family (2 adults, 3 children)', price: '£85' },
    { type: 'Child (aged 3 - 16)', price: '£15' },
    { type: 'Child (under 3)', price: 'Free' }
  ];
  
  return (
    <MembershipsContainer>
      <ParallaxBanner 
        title="Memberships"
        subtitle="Become a Friend of Belvoir Castle"
        image={castleImage}
      />
      
      <ContentContainer>
        <SectionTitle>Friends of Belvoir Memberships</SectionTitle>
        <IntroText>
          Enjoy unlimited access to the castle, parkland and gardens when we are open and a range of other benefits 
          by becoming a Friend of Belvoir Castle. Our friends not only get to enjoy one of the UK's finest stately homes 
          but also play a vital role in supporting the work of Belvoir Castle.
        </IntroText>
        
        <TabsContainer>
          <Tab 
            active={activeTab === 'castle'} 
            onClick={() => setActiveTab('castle')}
          >
            Castle Membership
          </Tab>
          <Tab 
            active={activeTab === 'parkland'} 
            onClick={() => setActiveTab('parkland')}
          >
            Parkland Membership
          </Tab>
        </TabsContainer>
        
        {activeTab === 'castle' && (
          <MembershipCard>
            <MembershipHeader>
              <MembershipTitle>Castle Membership</MembershipTitle>
              <MembershipSubtitle>Friends of Belvoir, Castle, Gardens & Playground</MembershipSubtitle>
            </MembershipHeader>
            <MembershipContent>
              <PricingTable>
                <PricingRow>
                  <PricingCell>Membership Type</PricingCell>
                  <PricingCell align="right">Annual Price</PricingCell>
                </PricingRow>
                {castlePricing.map((item, index) => (
                  <PricingRow key={index}>
                    <PricingCell>{item.type}</PricingCell>
                    <PricingCell align="right">{item.price}</PricingCell>
                  </PricingRow>
                ))}
              </PricingTable>
              
              <BenefitsList>
                {castleBenefits.map((benefit, index) => (
                  <BenefitItem key={index}>
                    <FontAwesomeIcon icon={benefit.icon} />
                    <BenefitText>{benefit.text}</BenefitText>
                  </BenefitItem>
                ))}
              </BenefitsList>
              
              <NoteText>
                *Castle pass includes all outdoor events plus indoor events: Halloween and Christmas at The Engine Yard.
              </NoteText>
              <NoteText>
                Collect your Membership Pass during your first visit as a member on arrival at the Visitor Hub.
              </NoteText>
              
              <ButtonContainer>
                <ActionButton href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=37bd858f-4f04-4e83-a469-05274db0d546&type=WebPage&locationId=978&mainWebPageId=37bd858f-4f04-4e83-a469-05274db0d546&startId=37bd858f-4f04-4e83-a469-05274db0d546">
                  Become a Castle Friend Today <FontAwesomeIcon icon={faArrowRight} />
                </ActionButton>
                <SecondaryButton href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=439912c8-3e01-405b-82b5-6a787f45013d&type=WebPage&locationId=978&mainWebPageId=439912c8-3e01-405b-82b5-6a787f45013d&startId=439912c8-3e01-405b-82b5-6a787f45013d">
                  Give as a Gift <FontAwesomeIcon icon={faGift} />
                </SecondaryButton>
              </ButtonContainer>
            </MembershipContent>
          </MembershipCard>
        )}
        
        {activeTab === 'parkland' && (
          <MembershipCard>
            <MembershipHeader>
              <MembershipTitle>Parkland Membership</MembershipTitle>
              <MembershipSubtitle>Friends of Belvoir, Gardens & Playground</MembershipSubtitle>
            </MembershipHeader>
            <MembershipContent>
              <PricingTable>
                <PricingRow>
                  <PricingCell>Membership Type</PricingCell>
                  <PricingCell align="right">Annual Price</PricingCell>
                </PricingRow>
                {parklandPricing.map((item, index) => (
                  <PricingRow key={index}>
                    <PricingCell>{item.type}</PricingCell>
                    <PricingCell align="right">{item.price}</PricingCell>
                  </PricingRow>
                ))}
              </PricingTable>
              
              <BenefitsList>
                {parklandBenefits.map((benefit, index) => (
                  <BenefitItem key={index}>
                    <FontAwesomeIcon icon={benefit.icon} />
                    <BenefitText>{benefit.text}</BenefitText>
                  </BenefitItem>
                ))}
              </BenefitsList>
              
              <NoteText>
                *Parkland pass includes outdoor events only. Please note all events are one time access and for parkland members an upgrade fee is required to enter the Castle.
              </NoteText>
              <NoteText>
                Collect your Membership Pass during your first visit as a member on arrival at the Ticket Office or our new Visitors Center.
              </NoteText>
              
              <ButtonContainer>
                <ActionButton href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=9d7c060b-6088-4bc1-8def-7bddb9b87ef9&type=WebPage&locationId=978&mainWebPageId=9d7c060b-6088-4bc1-8def-7bddb9b87ef9&startId=9d7c060b-6088-4bc1-8def-7bddb9b87ef9">
                  Become a Parkland Friend Today <FontAwesomeIcon icon={faArrowRight} />
                </ActionButton>
                <SecondaryButton href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=03496a0f-3859-453a-95aa-4afaa3652e77&type=WebPage&locationId=978&mainWebPageId=03496a0f-3859-453a-95aa-4afaa3652e77&startId=03496a0f-3859-453a-95aa-4afaa3652e77">
                  Give as a Gift <FontAwesomeIcon icon={faGift} />
                </SecondaryButton>
              </ButtonContainer>
            </MembershipContent>
          </MembershipCard>
        )}
        
        <HighlightSection>
          <HighlightContent>
            <HighlightTitle>Supporting Belvoir's Heritage</HighlightTitle>
            <HighlightText>
              Funds generated from the Friends scheme are invested in the conservation and upkeep of the House and Grounds, 
              helping sustain and preserve them for future generations.
            </HighlightText>
            <HighlightText>
              By becoming a Friend of Belvoir, you're not just gaining access to a magnificent estate - 
              you're helping to protect an important piece of British heritage.
            </HighlightText>
          </HighlightContent>
        </HighlightSection>
        
        <SectionTitle>Membership Benefits</SectionTitle>
        <IntroText>
          Enjoy these exclusive benefits when you become a Friend of Belvoir Castle
        </IntroText>
        
        <FeaturesGrid>
          <FeatureCard>
            <FeatureIcon>
              <FontAwesomeIcon icon={faTicketAlt} />
            </FeatureIcon>
            <FeatureTitle>Unlimited Access</FeatureTitle>
            <FeatureText>
              Visit as often as you like throughout the year on our open days. Explore the castle, gardens, 
              and adventure playground at your leisure.
            </FeatureText>
          </FeatureCard>
          
          <FeatureCard>
            <FeatureIcon>
              <FontAwesomeIcon icon={faCalendarAlt} />
            </FeatureIcon>
            <FeatureTitle>Special Events</FeatureTitle>
            <FeatureText>
              Enjoy free access to Belvoir's own events throughout the year, from seasonal celebrations 
              to artisan fairs and family fun days.
            </FeatureText>
          </FeatureCard>
          
          <FeatureCard>
            <FeatureIcon>
              <FontAwesomeIcon icon={faPercent} />
            </FeatureIcon>
            <FeatureTitle>Exclusive Discounts</FeatureTitle>
            <FeatureText>
              Save 10% on food, drinks, and shopping across all our outlets with your membership card, 
              plus enjoy special offers on accommodation.
            </FeatureText>
          </FeatureCard>
          
          <FeatureCard>
            <FeatureIcon>
              <FontAwesomeIcon icon={faMobileAlt} />
            </FeatureIcon>
            <FeatureTitle>Digital Rewards</FeatureTitle>
            <FeatureText>
              Earn points and receive personalised offers through our app.
              <ComingSoonBadge>Summer 2025</ComingSoonBadge>
            </FeatureText>
          </FeatureCard>
          
          <FeatureCard>
            <FeatureIcon>
              <FontAwesomeIcon icon={faCoffee} />
            </FeatureIcon>
            <FeatureTitle>Coffee Rewards</FeatureTitle>
            <FeatureText>
              Collect digital coffee stamps with every purchase and enjoy free drinks as a reward.
              <ComingSoonBadge>Summer 2025</ComingSoonBadge>
            </FeatureText>
          </FeatureCard>
          
          <FeatureCard>
            <FeatureIcon>
              <FontAwesomeIcon icon={faShieldAlt} />
            </FeatureIcon>
            <FeatureTitle>Heritage Support</FeatureTitle>
            <FeatureText>
              Your membership directly contributes to the conservation and upkeep of Belvoir Castle, 
              helping preserve this historic treasure.
            </FeatureText>
          </FeatureCard>
        </FeaturesGrid>
        
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
          <IntroText>
            If you would like to know more or have any questions, please contact our membership team on 
            01476 871001 or email <StyledEmailLink href="mailto:fob@belvoircastle.com">fob@belvoircastle.com</StyledEmailLink>
          </IntroText>
          <NoteText>Terms and Conditions Apply</NoteText>
        </div>
      </ContentContainer>
      
      <Footer />
    </MembershipsContainer>
  );
};

export default Memberships;
