import React from 'react';
import styled from 'styled-components';

const SectionWrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.offWhite};
  padding: 4rem 1rem;
`;

const ContentContainer = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10rem;
  align-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  
  align-items: center;
  height: 100%;

  h2 {
    font-size: 3.5rem;
    line-height: 1.2;
    margin-bottom: 2rem;
    font-family: ${({ theme }) => theme.fonts.main};
    color: ${({ theme }) => theme.colors.primary};
    text-align: left;
    
    @media (max-width: 768px) {
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
    }
  }
`;

const RightColumn = styled.div`
  p {
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 2rem;
    font-size: 1.1rem;
    line-height: 1.6;
  }
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.accent};
  color: white;
  border: 2px solid transparent;
  padding: 0.8rem 2rem;
  border-radius: 25px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.accent};
    border-color: ${({ theme }) => theme.colors.accent};
  }
`;

const ColumnX2 = ({ title, description, buttonText, buttonAction }) => {
  return (
    <SectionWrapper>
      <ContentContainer>
        <LeftColumn>
          <h2>{title}</h2>
        </LeftColumn>
        <RightColumn>
          <p>{description}</p>
          {buttonText && (
            <Button onClick={buttonAction}>
              {buttonText}
            </Button>
          )}
        </RightColumn>
      </ContentContainer>
    </SectionWrapper>
  );
};

export default ColumnX2;
