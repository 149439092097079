import React, { useState } from 'react';
import styled from 'styled-components';

const NewsletterContainer = styled.div`
  background-color: ${props => props.theme.colors.primary};
  padding: 2.5rem 4rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.3);
  font-family: ${props => props.theme.fonts.main};
  
  @media (max-width: 1024px) {
    padding: 2rem;
  }
  
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    gap: 1.5rem;
  }
`;

const TextContainer = styled.div`
  h2 {
    font-family: ${props => props.theme.fonts.main};
    font-size: 3rem;
    margin: 0;
    letter-spacing: 0.5px;
    font-weight: 600;
    
    @media (max-width: 768px) {
      font-size: 2.2rem;
    }
  }
  
  p {
    margin: 0.5rem 0 0 0;
    font-size: 1.3rem;
    opacity: 0.9;
    letter-spacing: 0.3px;
    
    @media (max-width: 768px) {
      font-size: 1.1rem;
    }
  }
`;

const FormContainer = styled.form`
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-left: 1rem;
  
  @media (max-width: 1024px) {
    gap: 1rem;
    margin-left: 0.5rem;
  }
  
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
`;

const Input = styled.input`
  padding: 0.9rem 1.2rem;
  width: 170px;
  border: none;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.08);
  color: white;
  font-size: 1rem;
  transition: all 0.2s ease;
  font-family: ${props => props.theme.fonts.main};
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
    font-family: ${props => props.theme.fonts.main};
  }
  
  &:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.15);
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem;
  }
`;

const SubmitButton = styled.button`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: none;
  padding: 0.9rem 2.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1px;
  transition: all 0.2s ease;
  font-family: ${props => props.theme.fonts.main};
  
  &:hover {
    opacity: 0.9;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem;
  }
`;

const NewsletterSignUp = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });
  const [status, setStatus] = useState({
    submitting: false,
    error: null,
    success: false
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ submitting: true, error: null, success: false });

    try {
      const response = await fetch('https://staging.belvoircastle.com/wp/wp-json/newsletter/v1/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        const data = await response.json();
        setStatus({
          submitting: false,
          error: null,
          success: true
        });
        setFormData({ firstName: '', lastName: '', email: '' });
      } else {
        const error = await response.json();
        throw new Error(error.message || 'Subscription failed');
      }
    } catch (error) {
      setStatus({
        submitting: false,
        error: error.message || 'An error occurred while subscribing. Please try again.',
        success: false
      });
    }
  };

  return (
    <NewsletterContainer>
      <TextContainer>
        <h2>Keep up to date</h2>
        <p>Sign up to be the first to hear about upcoming events</p>
      </TextContainer>
      
      <FormContainer onSubmit={handleSubmit}>
        <Input 
          type="text" 
          name="firstName"
          placeholder="First Name" 
          value={formData.firstName}
          onChange={handleChange}
        />
        <Input 
          type="text" 
          name="lastName"
          placeholder="Last Name" 
          value={formData.lastName}
          onChange={handleChange}
        />
        <Input 
          type="email" 
          name="email"
          placeholder="Email" 
          value={formData.email}
          onChange={handleChange}
        />
        <SubmitButton 
          type="submit" 
          disabled={status.submitting}
        >
          {status.submitting ? 'SIGNING UP...' : 'SIGN UP'}
        </SubmitButton>
        
        {status.error && (
          <div style={{ color: 'red', marginTop: '10px' }}>{status.error}</div>
        )}
        {status.success && (
          <div style={{ color: 'green', marginTop: '10px' }}>
            Thank you for subscribing! Please check your email to confirm your subscription.
          </div>
        )}
      </FormContainer>
    </NewsletterContainer>
  );
};

export default NewsletterSignUp;
