import React from 'react';
import { useQuery } from '@apollo/client';
import Hero from '../../common/Hero';
import ImageSlider from '../../common/Image-slider';
import styled from 'styled-components';
import castleImage from '../../../assets/castle.png';
import gardensImage from '../../../assets/gardens.png';
import retailImage from '../../../assets/retail.png';
import playgroundImage from '../../../assets/play.png';
import WhatsOn from '../../common/What-on';
import NewsletterSignUp from '../../common/Newsletter-sign';
import ColumnX2 from '../../common/Column-x2';
import retailVideo from '../../../assets/retail-village.mp4';
import ImageSlant from '../../common/image-slant';
import Footer from '../../Footer/Footer';
import { gql } from '@apollo/client';
import { processEvents, GET_EVENTS_QUERY } from '../../../utils/eventUtils';
import { useNavigate } from 'react-router-dom';

import farmshopImage from '../../../assets/farmshop1.webp';
import bistroImage from '../../../assets/bistro.webp';
import animalParkImage from '../../../assets/animal.webp';
import pizzaImage from '../../../assets/pizza.webp';
import balloonImage from '../../../assets/ballonBar.webp';
import shopDineImage from '../../../assets/retail3.webp';
import goatImage from '../../../assets/goat.webp';



const HomeContainer = styled.div`
  min-height: 100vh;
  padding-top: 120px;
`;

const HomeSlider = styled(ImageSlider)`
  @media (max-width: 768px) {
    margin-top: 7rem;
  }
`;

const StyledNewsletterSignUp = styled(NewsletterSignUp)`
  margin: 30px 0;
`;

const GET_RETAIL_SETTINGS = gql`
  query GetSiteSettings {
    siteSettings(where: {title: "Global Site Settings"}) {
      nodes {
        title
        siteSettings {
          retailVillageSection {
            heroSection {
              welcomeText
              mainTitle
              subtitle
              heroVideo {
                node {
                  sourceUrl
                }
              }
            }
            infoColumns {
              column1 {
                title
                days
                times
                note
              }
              column2 {
                title
                days
                times
                note
              }
              column3 {
                title
                days
                times
                note
              }
            }
            sliderContent {
              slide1 {
                title
                image {
                  node {
                    sourceUrl
                  }
                }
              }
              slide2 {
                title
                image {
                  node {
                    sourceUrl
                  }
                }
              }
              slide3 {
                title
                image {
                  node {
                    sourceUrl
                  }
                }
              }
              slide4 {
                title
                image {
                  node {
                    sourceUrl
                  }
                }
              }
            }
            eventsSection {
              title
              eventsContent {
                title
                date
                description
                link
                linkText
                image {
                  node {
                    sourceUrl
                  }
                }
              }
            }
            columnX2Section {
              title
              description
              buttonText
            }
            imageSlantSections {
              title
              description
              buttonText
              imagePosition
              image {
                node {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;

const RetailVillage = () => {
  const { loading: settingsLoading, error: settingsError, data: settingsData } = useQuery(GET_RETAIL_SETTINGS);
  const navigate = useNavigate();
  
  // Add debug logging
  console.log('Settings Loading:', settingsLoading);
  console.log('Settings Error:', settingsError);
  console.log('Settings Data:', settingsData);

  // Get the settings data
  const retailSettings = settingsData?.siteSettings?.nodes?.[0]?.siteSettings?.retailVillageSection || {};
  console.log('Retail Settings:', retailSettings);

  // Fetch events data
  const { loading: eventsLoading, error: eventsError, data: eventsData } = useQuery(GET_EVENTS_QUERY);

  // Update default slider content with proper navigation
  const defaultSliderContent = [
    {
      image: animalParkImage,
      title: "Animal Park",
      action: () => navigate('/retail-village/animal-park')
    },
    {
      image: farmshopImage,
      title: "Farm Shop",
      action: () => navigate('/retail-village/shops/farm-shop')
    },
    {
      image: bistroImage,
      title: "Belvoir Bistro",
      action: () => navigate('/retail-village/dining/belvoir-bistro')
    },
    {
      image: balloonImage,
      title: "Balloon Bar",
      action: () => navigate('/retail-village/dining/balloon-bar')
    },
    {
      image: pizzaImage,
      title: "Woodfired Pizza",
      action: () => navigate('/retail-village/dining/woodfired-pizza')
    }
  ];

  // Update slider content actions
  const sliderContent = retailSettings.sliderContent
    ? [
        retailSettings.sliderContent.slide1 && {
          image: retailSettings.sliderContent.slide1.image?.node?.sourceUrl || retailImage,
          title: retailSettings.sliderContent.slide1.title || "Animal Park",
          action: () => navigate('/retail-village/animal-park')
        },
        retailSettings.sliderContent.slide2 && {
          image: retailSettings.sliderContent.slide2.image?.node?.sourceUrl || retailImage,
          title: retailSettings.sliderContent.slide2.title || "Farm Shop",
          action: () => navigate('/retail-village/shops/farm-shop')
        },
        retailSettings.sliderContent.slide3 && {
          image: retailSettings.sliderContent.slide3.image?.node?.sourceUrl || retailImage,
          title: retailSettings.sliderContent.slide3.title || "Belvoir Bistro",
          action: () => navigate('/retail-village/dining/belvoir-bistro')
        },
        retailSettings.sliderContent.slide4 && {
          image: retailSettings.sliderContent.slide4.image?.node?.sourceUrl || retailImage,
          title: retailSettings.sliderContent.slide4.title || "Balloon Bar",
          action: () => navigate('/retail-village/dining/balloon-bar')
        }
      ].filter(Boolean)
    : defaultSliderContent;

  // Process WordPress events data into the format your component expects
  const eventsContent = eventsLoading ? [] : 
    eventsData && eventsData.events && eventsData.events.nodes ? 
    processEvents(eventsData.events.nodes).filter(event => 
      // Filter to only show Retail Village events
      event.location === "RETAIL VILLAGE"
    ) : [];

  // Update hero buttons with proper navigation
  const defaultHeroInfo = {
    videoSrc: retailVideo,
    welcomeText: "Welcome to",
    mainTitle: "Belvoir Retail Village",
    subtitle: "SHOPPING, DINING & WILDLIFE IN HISTORIC SURROUNDINGS",
    buttons: [
      {
        text: "Animal Park",
        onClick: () => navigate('/retail-village/animal-park')
      },
      {
        text: "View Shops",
        onClick: () => navigate('/retail-village/shops')
      }
    ],
    infoColumns: [
      {
        title: "Animal Park",
        items: ["Coming Soon"]
      },
      {
        title: "Shopping Hours",
        items: ["Mon - Sun", "09:30 - 17:30"]
      },
      {
        title: "Balloon Bar",
        items: ["Thu - Sat", "17:00 - 23:00"]
      }
    ]
  };

  // Use settings data for hero info if available
  const heroInfo = {
    videoSrc: retailSettings.heroSection?.heroVideo?.node?.sourceUrl || retailVideo,
    welcomeText: retailSettings.heroSection?.welcomeText || "Welcome to",
    mainTitle: retailSettings.heroSection?.mainTitle || "Belvoir Retail Village",
    subtitle: retailSettings.heroSection?.subtitle || "SHOPPING, DINING & WILDLIFE IN HISTORIC SURROUNDINGS",
    buttons: defaultHeroInfo.buttons,
    infoColumns: retailSettings.infoColumns
    



      ? [
          retailSettings.infoColumns.column1 && {
            title: retailSettings.infoColumns.column1.title,
            items: [retailSettings.infoColumns.column1.days, retailSettings.infoColumns.column1.times],
            note: retailSettings.infoColumns.column1.note
          },
          retailSettings.infoColumns.column2 && {
            title: retailSettings.infoColumns.column2.title,
            items: [retailSettings.infoColumns.column2.days, retailSettings.infoColumns.column2.times],
            note: retailSettings.infoColumns.column2.note
          },
          retailSettings.infoColumns.column3 && {
            title: retailSettings.infoColumns.column3.title,
            items: [retailSettings.infoColumns.column3.days, retailSettings.infoColumns.column3.times],
            note: retailSettings.infoColumns.column3.note
          }
        ].filter(Boolean)
      : defaultHeroInfo.infoColumns
  };

  // Get column x2 section data from settings
  const columnX2Title = retailSettings.columnX2Section?.title || "A Unique Shopping Experience";
  const columnX2Description = retailSettings.columnX2Section?.description || 
    "Discover our carefully curated selection of boutique shops, artisan food producers, and local craftspeople. The Retail Village offers a distinctive shopping experience in the historic grounds of Belvoir Castle.";
  const columnX2ButtonText = retailSettings.columnX2Section?.buttonText || "View Shops";

  // Get image slant sections from settings
  const imageSlantSections = retailSettings.imageSlantSections?.length > 0
    ? retailSettings.imageSlantSections
    : [
        {
          title: "Meet Our Animals",
          description: "Discover our diverse collection of animals at the Animal Park, from exotic species to local wildlife. A perfect day out for families and nature enthusiasts.",
          buttonText: "Visit Animal Park",
          imagePosition: "left",
          image: { node: { sourceUrl: goatImage } }
        },
        {
          title: "Shopping & Dining",
          description: "Explore our carefully curated selection of shops and enjoy a variety of dining options, from casual bites to elegant dining experiences.",
          buttonText: "View Shops",
          imagePosition: "right",
          image: { node: { sourceUrl: shopDineImage } }
        }
      ];

  return (
    <HomeContainer>
      <Hero {...heroInfo} />
      <HomeSlider slides={sliderContent} />
      {eventsLoading ? (
        <p>Loading events...</p>
      ) : eventsError ? (
        <p>Error loading events: {eventsError.message}</p>
      ) : eventsContent.length === 0 ? (
        <WhatsOn 
          title={retailSettings.eventsSection?.title || "What's On at The Retail Village"} 
          events={[]} 
          emptyMessage="No upcoming events at the Retail Village. Check back soon for new events!"
        />
      ) : (
        <WhatsOn 
          title={retailSettings.eventsSection?.title || "What's On at The Retail Village"} 
          events={eventsContent} 
        />
      )}

      {/* Add new WhatsOn section for all events */}
      {eventsLoading ? (
        <p>Loading events...</p>
      ) : eventsError ? (
        <p>Error loading events: {eventsError.message}</p>
      ) : (
        <WhatsOn 
          title="Don't Miss Out at Belvoir Castle" 
          events={eventsData && eventsData.events && eventsData.events.nodes ? 
            processEvents(eventsData.events.nodes).filter(event => 
              // Show all events except Retail Village ones (to avoid duplication)
              event.location !== "RETAIL VILLAGE"
            ) : []
          } 
          emptyMessage="No other events currently scheduled at Belvoir Castle."
        />
      )}

      <ColumnX2 
        title={columnX2Title}
        description={columnX2Description}
        buttonText={columnX2ButtonText}
        buttonAction={() => navigate('/retail-village/shops')}
      />
      <div style={{ margin: '80px 0' }}>
        <NewsletterSignUp />
      </div>
      {imageSlantSections.map((section, index) => (
        <ImageSlant 
          key={index}
          image={section.image?.node?.sourceUrl || (index === 0 ? retailImage : gardensImage)}
          title={section.title}
          description={section.description}
          buttonText={section.buttonText}
          buttonAction={() => {
            // Map different sections to appropriate routes
            const routes = {
              'Visit Animal Park': '/retail-village/animal-park',
              'View Shops': '/retail-village/shops',
              'View Dining': '/retail-village/dining',
              'Learn More': '/retail-village'
            };
            navigate(routes[section.buttonText] || '/retail-village');
          }}
          imagePosition={section.imagePosition || (index % 2 === 0 ? 'left' : 'right')}
        />
      ))}
      <Footer />
    </HomeContainer>
  );
};

export default RetailVillage;
