import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCalendarAlt, 
  faTicketAlt, 
  faUserFriends,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { useWhatsOnSection } from '../../../hooks/useWhatsOnSection';

// Import images
import castleImage from '../../../assets/castle2.png';
import eventsImage from '../../../assets/activities.jpg';
import exhibitionsImage from '../../../assets/state-dining.jpg';
import toursImage from '../../../assets/elizabeth-saloon.jpg';

const WhatsOnContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 3px;
    background-color: ${props => props.theme.colors.accent};
    margin: 1rem auto 0;
  }
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const ActivitiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ActivityCard = styled(Link)`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  text-decoration: none;
  color: inherit;
  
  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
`;

const ActivityImage = styled.div`
  height: 250px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const ActivityContent = styled.div`
  padding: 2rem;
`;

const ActivityTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const ActivityDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 1.5rem;
`;

const ActivityButton = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  font-weight: 600;
  transition: all 0.3s ease;
  border: 2px solid ${props => props.theme.colors.accent};
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
  }
  
  svg {
    margin-right: 0.5rem;
  }
`;

const HighlightSection = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  padding: 4rem 2rem;
  margin: 5rem 0;
  text-align: center;
  border-radius: 12px;
`;

const HighlightContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const HighlightTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const HighlightDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 2rem;
`;

const HighlightButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: 25px;
  font-weight: 600;
  transition: all 0.3s ease;
  border: 2px solid ${props => props.theme.colors.accent};
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
  }
  
  svg {
    margin-left: 0.5rem;
  }
`;

const WhatsOn = () => {
  // Use the What's On section hook
  useWhatsOnSection();
  
  return (
    <WhatsOnContainer>
      <ParallaxBanner 
        title="What's On at Belvoir Castle"
        subtitle="Discover Events, Exhibitions, and Featured Tours"
        image={castleImage}
      />
      
      <ContentContainer>
        <SectionTitle>Experience Belvoir Castle</SectionTitle>
        <IntroText>
          From special events and exhibitions to guided tours, there's always something 
          exciting happening at Belvoir Castle. Explore our calendar of events and plan your visit today.
        </IntroText>
        
        <ActivitiesGrid>
          <ActivityCard to="/whats-on/events">
            <ActivityImage image={eventsImage} />
            <ActivityContent>
              <ActivityTitle>Events</ActivityTitle>
              <ActivityDescription>
                Discover our exciting calendar of events throughout the year, from seasonal celebrations 
                to special themed weekends, concerts, and more. There's something for everyone at Belvoir Castle.
              </ActivityDescription>
              <ActivityButton>
                <FontAwesomeIcon icon={faCalendarAlt} />
                View Events
              </ActivityButton>
            </ActivityContent>
          </ActivityCard>
          
          <ActivityCard to="/whats-on/exhibitions">
            <ActivityImage image={exhibitionsImage} />
            <ActivityContent>
              <ActivityTitle>Exhibitions</ActivityTitle>
              <ActivityDescription>
                Explore our fascinating exhibitions that showcase the rich history, art, and culture of 
                Belvoir Castle. Current exhibition: "Motherhood at Belvoir" - a journey through generations.
              </ActivityDescription>
              <ActivityButton>
                <FontAwesomeIcon icon={faTicketAlt} />
                View Exhibitions
              </ActivityButton>
            </ActivityContent>
          </ActivityCard>
          
          <ActivityCard to="/whats-on/featured-tours">
            <ActivityImage image={toursImage} />
            <ActivityContent>
              <ActivityTitle>Featured Tours</ActivityTitle>
              <ActivityDescription>
                Experience the castle's rich history with our expert guides. From state room tours to 
                behind-the-scenes experiences, discover the stories that make Belvoir Castle unique.
              </ActivityDescription>
              <ActivityButton>
                <FontAwesomeIcon icon={faUserFriends} />
                Explore Tours
              </ActivityButton>
            </ActivityContent>
          </ActivityCard>
        </ActivitiesGrid>
        
        <HighlightSection>
          <HighlightContent>
            <HighlightTitle>Plan Your Visit to Belvoir Castle</HighlightTitle>
            <HighlightDescription>
              Make the most of your visit to Belvoir Castle by planning ahead. Check our opening times, 
              book tickets in advance, and discover all the amazing experiences waiting for you.
            </HighlightDescription>
            <HighlightButton to="/your-visit">
              Plan Your Visit <FontAwesomeIcon icon={faArrowRight} />
            </HighlightButton>
          </HighlightContent>
        </HighlightSection>
      </ContentContainer>
      
      <Footer />
    </WhatsOnContainer>
  );
};

export default WhatsOn; 