import React from 'react';
import { useQuery } from '@apollo/client';
import Hero from '../../common/Hero';
import ImageSlider from '../../common/Image-slider';
import styled from 'styled-components';
import castleImage from '../../../assets/castle.png';
import gardensImage from '../../../assets/gardens.png';
import playgroundImage from '../../../assets/play.png';
import WhatsOn from '../../common/What-on';
import NewsletterSignUp from '../../common/Newsletter-sign';
import ColumnX2 from '../../common/Column-x2';
import castleVideo from '../../../assets/Belvoir-Castle.mp4';
import ImageSlant from '../../common/image-slant';
import Footer from '../../Footer/Footer';
import { processEvents, GET_EVENTS_QUERY } from '../../../utils/eventUtils';
import { gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import farmshopImage from '../../../assets/farmshop.webp';
import retailImage from '../../../assets/retail1.webp';
import interiorImage from '../../../assets/interior.webp';
import { Helmet } from 'react-helmet-async';

const HomeContainer = styled.div`
  min-height: 100vh;
  padding-top: 120px;
`;

const HomeSlider = styled(ImageSlider)`
  @media (max-width: 768px) {
    margin-top: 7rem;
  }
`;

const StyledNewsletterSignUp = styled(NewsletterSignUp)`
  margin: 30px 0;
`;

const GET_SITE_SETTINGS = gql`
  query GetSiteSettings {
    siteSettings(where: {title: "Global Site Settings"}) {
      nodes {
        title
        siteSettingsFields {
          openingHours {
            castle {
              days
              times
              note
            }
            gardens {
              days
              times
              note
            }
            retail {
              days
              times
              note
            }
          }
          homeHero {
            welcomeText
            mainTitle
            subtitle
            heroVideo {
              node {
                sourceUrl
              }
            }
            sliderContent {
              slide1 {
                image {
                  node {
                    sourceUrl
                  }
                }
                title
                url
              }
              slide2 {
                image {
                  node {
                    sourceUrl
                  }
                }
                title
                url
              }
              slide3 {
                image {
                  node {
                    sourceUrl
                  }
                }
                title
                url
              }
              slide4 {
                image {
                  node {
                    sourceUrl
                  }
                }
                title
                url
              }
            }
          }
          familySection {
            title
            description
          }
        }
      }
    }
  }
`;

const GET_OPENING_HOURS = gql`
  query GetOpeningHours {
    siteSettings {
      nodes {
        siteSettingsFields {
          openingHours {
            castle {
              days
              times
              note
            }
            gardens {
              days
              times
              note
            }
            retail {
              days
              times
              note
            }
          }
        }
      }
    }
  }
`;

const Home = () => {
  const navigate = useNavigate();
  const { loading: settingsLoading, error: settingsError, data: settingsData } = useQuery(GET_SITE_SETTINGS, {
    fetchPolicy: 'network-only'
  });
  
  console.log('Settings Loading:', settingsLoading);
  console.log('Settings Error:', settingsError);
  console.log('Settings Data:', settingsData);
  if (settingsError) {
    console.error('GraphQL Error Details:', settingsError.graphQLErrors);
    console.error('Network Error Details:', settingsError.networkError);
  }

  const settings = settingsData?.siteSettings?.nodes?.[0]?.siteSettingsFields || {};
  console.log('Processed Settings:', settings);

  const { data: hoursData } = useQuery(GET_OPENING_HOURS);

  const openingHoursData = hoursData?.siteSettings?.nodes?.[0]?.siteSettingsFields?.openingHours || {};
  console.log('Opening hours from dedicated query:', openingHoursData);

  const { loading: eventsLoading, error: eventsError, data: eventsData } = useQuery(GET_EVENTS_QUERY);

  const defaultSliderContent = [
    {
      image: castleImage,
      title: "Castle and State Rooms",
      action: () => navigate('/about/castle-and-state-rooms')
    },
    {
      image: gardensImage,
      title: "Formal Gardens and Woodland",
      action: () => navigate('/about/gardens')
    },
    {
      image: retailImage,
      title: "Belvoir Castle Retail Village",
      action: () => navigate('/retail-village')
    },
    {
      image: playgroundImage,
      title: "Adventure Playground",
      action: () => navigate('/about/adventure-playground')
    }
  ];

  const sliderContent = settings.homeHero?.sliderContent
    ? [
        settings.homeHero.sliderContent.slide1 && {
          image: settings.homeHero.sliderContent.slide1.image?.node?.sourceUrl || castleImage,
          title: settings.homeHero.sliderContent.slide1.title || "Castle and State Rooms",
          action: () => navigate(settings.homeHero.sliderContent.slide1.url || '/about/castle-and-state-rooms')
        },
        settings.homeHero.sliderContent.slide2 && {
          image: settings.homeHero.sliderContent.slide2.image?.node?.sourceUrl || gardensImage,
          title: settings.homeHero.sliderContent.slide2.title || "Formal Gardens and Woodland",
          action: () => navigate(settings.homeHero.sliderContent.slide2.url || '/about/gardens')
        },
        settings.homeHero.sliderContent.slide3 && {
          image: settings.homeHero.sliderContent.slide3.image?.node?.sourceUrl || retailImage,
          title: settings.homeHero.sliderContent.slide3.title || "Retail Village",
          action: () => navigate(settings.homeHero.sliderContent.slide3.url || '/retail-village')
        },
        settings.homeHero.sliderContent.slide4 && {
          image: settings.homeHero.sliderContent.slide4.image?.node?.sourceUrl || playgroundImage,
          title: settings.homeHero.sliderContent.slide4.title || "Adventure Playground",
          action: () => navigate(settings.homeHero.sliderContent.slide4.url || '/about/adventure-playground')
        }
      ].filter(Boolean)
    : defaultSliderContent;

  const eventsContent = eventsLoading ? [] : 
    eventsData && eventsData.events && eventsData.events.nodes ? 
    processEvents(eventsData.events.nodes) : [];

  const heroInfo = {
    videoSrc: settings.homeHero?.heroVideo?.node?.sourceUrl || castleVideo,
    welcomeText: settings.homeHero?.welcomeText || "Welcome to",
    mainTitle: settings.homeHero?.mainTitle || "Belvoir Castle",
    subtitle: settings.homeHero?.subtitle || "HISTORIC GEM IN THE HEART OF ENGLAND",
    buttons: [
      {
        text: "Plan Your Visit",
        onClick: () => navigate('/your-visit')
      },
      {
        text: "Book Tickets",
        onClick: () => navigate('/your-visit/day-tickets')
      }
    ],
    infoColumns: [
      {
        title: "Castle",
        items: [
          openingHoursData.castle?.days || "Mon - Sun",
          openingHoursData.castle?.times || "10:00 - 16:00"
        ],
        note: openingHoursData.castle?.note || "Last entry at 15:30"
      },
      {
        title: "Gardens & Playground",
        items: [
          openingHoursData.gardens?.days || "Mon - Sun",
          openingHoursData.gardens?.times || "09:00 - 17:00"
        ],
        note: openingHoursData.gardens?.note || "Last entry at 16:30"
      },
      {
        title: "Retail Village",
        items: [
          openingHoursData.retail?.days || "Mon - Sun",
          openingHoursData.retail?.times || "9:00 - 17:00"
        ],
        note: openingHoursData.retail?.note || ""
      }
    ]
  };

  console.log('Opening hours from settings:', openingHoursData);
  console.log('Castle days:', openingHoursData.castle?.days);
  console.log('Castle times:', openingHoursData.castle?.times);
  console.log('Castle note:', openingHoursData.castle?.note);
  console.log('Final heroInfo:', heroInfo);

  return (
    <>
      <Helmet>
        <title>Belvoir Castle - Historic Castle & Gardens in Leicestershire</title>
        <meta name="description" content="Visit Belvoir Castle, a historic castle and gardens in Leicestershire. Experience guided tours, events, afternoon tea, and stunning gardens. Book your visit today." />
        <meta name="keywords" content="Belvoir Castle, historic castle, Leicestershire castle, castle tours, castle gardens, UK heritage" />
        <link rel="canonical" href="https://www.belvoircastle.com" />
        <meta property="og:title" content="Belvoir Castle - Historic Castle & Gardens" />
        <meta property="og:description" content="Experience the grandeur of Belvoir Castle. Book tours, events, and visits today." />
        <meta property="og:image" content="[Your Castle Image URL]" />
        <meta property="og:url" content="https://www.belvoircastle.com" />
      </Helmet>
      <HomeContainer>
        <Hero {...heroInfo} />
        <HomeSlider slides={sliderContent} />
        {eventsLoading ? (
          <p>Loading events...</p>
        ) : eventsError ? (
          <p>Error loading events: {eventsError.message}</p>
        ) : (
          <WhatsOn title="What's On at Belvoir Castle" events={eventsContent} />
        )}
        <ColumnX2 
          title={settings.familySection?.title || "An Unforgettable Family Day Out"}
          description={settings.familySection?.description || "Discover the magic of Belvoir Castle with a day filled with adventure, history, and stunning countryside views. Explore grand interiors, wander through beautiful gardens, and enjoy family-friendly activities throughout the year. Whether you're exploring our woodland trails, indulging in delicious treats at the Belvoir Retail Village, or experiencing one of our exciting seasonal events, there's something for everyone to enjoy."}
          buttonText="Plan Your Visit"
          buttonAction={() => navigate('/your-visit')}
        />
        <div style={{ margin: '80px 0' }}>
          <NewsletterSignUp />
        </div>
        <ImageSlant 
          image={interiorImage}
          title="Discover Our Castle"
          description="Step into centuries of history at Belvoir Castle. Experience the grandeur of our State Rooms and immerse yourself in the stories of those who lived here."
          buttonText="Learn More"
          buttonAction={() => navigate('/about/castle-and-state-rooms')}
          imagePosition="left"
        />
        <ImageSlant 
          image={gardensImage}
          title="Beautiful Gardens"
          description="Explore our meticulously maintained gardens, featuring stunning landscapes and seasonal blooms throughout the year."
          buttonText="View Gardens"
          buttonAction={() => navigate('/about/gardens')}
          imagePosition="right"
        />
        <Footer />
      </HomeContainer>
    </>
  );
};

export default Home;
