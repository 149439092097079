import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCrown, 
  faLeaf, 
  faTicketAlt,
  faUsers,
  faArrowRight 
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';

import castleImage from '../../../assets/castle2.png';
import gardensImage from '../../../assets/gardens.png';
import banner from '../../../assets/banner/roomBan.webp';

const RedemptionContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 3px;
    background-color: ${props => props.theme.colors.accent};
    margin: 1rem auto 0;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const OptionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const OptionCard = styled.div`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const OptionImage = styled.div`
  height: 250px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const OptionContent = styled.div`
  padding: 2rem;
  text-align: center;
`;

const OptionTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const OptionDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 2rem;
`;

const RedeemButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: 25px;
  font-weight: 600;
  border: 2px solid ${props => props.theme.colors.accent};
  transition: all 0.3s ease;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
  }
  
  svg {
    margin-left: 0.5rem;
  }
`;

const VoucherRedemption = () => {
  return (
    <RedemptionContainer>
      <ParallaxBanner 
        title="Redeem Your Gift Voucher"
        subtitle="Select the experience you'd like to book"
        image={banner}
      />
      
      <ContentContainer>
        <SectionTitle>Choose Your Experience</SectionTitle>
        <IntroText>
          Select the experience that matches your gift voucher. For Afternoon Tea vouchers, please select both your entry ticket and afternoon tea in the basket to match your voucher. Your voucher code will be applied during checkout to redeem your gift.
        </IntroText>
        
        <OptionsGrid>
          <OptionCard>
            <OptionImage image={castleImage} />
            <OptionContent>
              <OptionTitle>
                <FontAwesomeIcon icon={faCrown} />
                Afternoon Tea with Castle Entry
              </OptionTitle>
              <OptionDescription>
                Enjoy our exquisite Afternoon Tea service with full access to the Castle and Gardens.
              </OptionDescription>
              <RedeemButton href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=b0f1a62a-a34a-4c4e-a88d-6fda03a19279&type=WebPage&locationId=978&mainWebPageId=e39e1d45-8941-4502-8c92-bb7082a9ddbf&startId=e39e1d45-8941-4502-8c92-bb7082a9ddbf">
                Select & Redeem <FontAwesomeIcon icon={faArrowRight} />
              </RedeemButton>
            </OptionContent>
          </OptionCard>

          <OptionCard>
            <OptionImage image={gardensImage} />
            <OptionContent>
              <OptionTitle>
                <FontAwesomeIcon icon={faLeaf} />
                Afternoon Tea with Gardens Entry
              </OptionTitle>
              <OptionDescription>
                Experience our Afternoon Tea service with access to our beautiful Gardens.
              </OptionDescription>
              <RedeemButton href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=40ca044f-534f-4b7f-83ea-b4156f0633d3&type=WebPage&locationId=978&mainWebPageId=b0f1a62a-a34a-4c4e-a88d-6fda03a19279&startId=e39e1d45-8941-4502-8c92-bb7082a9ddbf">
                Select & Redeem <FontAwesomeIcon icon={faArrowRight} />
              </RedeemButton>
            </OptionContent>
          </OptionCard>

          <OptionCard>
            <OptionImage image={castleImage} />
            <OptionContent>
              <OptionTitle>
                <FontAwesomeIcon icon={faUsers} />
                Friends of Belvoir Membership
              </OptionTitle>
              <OptionDescription>
                Redeem your gift of unlimited access to the Castle, Gardens, and special events.
              </OptionDescription>
              <RedeemButton href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=37bd858f-4f04-4e83-a469-05274db0d546&type=WebPage&locationId=978&mainWebPageId=37bd858f-4f04-4e83-a469-05274db0d546&startId=37bd858f-4f04-4e83-a469-05274db0d546">
                Select & Redeem <FontAwesomeIcon icon={faArrowRight} />
              </RedeemButton>
            </OptionContent>
          </OptionCard>

          <OptionCard>
            <OptionImage image={gardensImage} />
            <OptionContent>
              <OptionTitle>
                <FontAwesomeIcon icon={faTicketAlt} />
                Parkland Membership
              </OptionTitle>
              <OptionDescription>
                Redeem your gift of unlimited access to the Gardens and outdoor events.
              </OptionDescription>
              <RedeemButton href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=9d7c060b-6088-4bc1-8def-7bddb9b87ef9&type=WebPage&locationId=978&mainWebPageId=9d7c060b-6088-4bc1-8def-7bddb9b87ef9&startId=9d7c060b-6088-4bc1-8def-7bddb9b87ef9">
                Select & Redeem <FontAwesomeIcon icon={faArrowRight} />
              </RedeemButton>
            </OptionContent>
          </OptionCard>
        </OptionsGrid>
      </ContentContainer>
      
      <Footer />
    </RedemptionContainer>
  );
};

export default VoucherRedemption; 