import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

const TopNavContainer = styled.nav`
  background-color: ${props => props.theme.colors.primary};
  padding: 0.5rem 8rem;
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 1024px) {
    padding: 0.5rem 2rem;
    gap: 1.5rem;
  }

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    justify-content: space-between;
    position: relative;
  }
`;

const TopNavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-family: ${props => props.theme.fonts.main};
  font-size: 1rem;
  font-weight: 500;
  padding: 0.25rem 1rem;
  transition: all 0.2s ease;
  letter-spacing: 1.5px;
  position: relative;
  
  &.active {
    color: ${props => props.theme.colors.accent || '#FFD700'};
    &::after {
      width: 100%;
      background-color: ${props => props.theme.colors.accent || '#FFD700'};
    }
  }

  &:hover {
    opacity: 1;
    transform: translateY(-2px);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 2px;
    background-color: white;
    transition: width 0.3s ease;
  }

  &:hover::after {
    width: 100%;
  }

  &:active {
    transform: translateY(1px);
  }
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  
  @media (max-width: 768px) {
    display: block;
  }
`;

const NavLinksContainer = styled.div`
  display: flex;
  gap: 2rem;
  
  @media (max-width: 768px) {
    display: ${props => props.isOpen ? 'flex' : 'none'};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: ${props => props.theme.colors.primary};
    padding: 1rem;
    gap: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const TopNav = ({ currentSection, onSectionChange }) => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState(currentSection);

  // Update active section when currentSection prop changes
  useEffect(() => {
    setActiveSection(currentSection);
  }, [currentSection]);

  // Listen for custom events to force section
  useEffect(() => {
    const handleForceRetailSection = (event) => {
      if (event.detail && event.detail.section === 'retail') {
        setActiveSection('retail');
        onSectionChange('retail');
      }
    };

    const handleForceStaySection = (event) => {
      if (event.detail && event.detail.section === 'stay') {
        setActiveSection('stay');
        onSectionChange('stay');
      }
    };

    // Add event listeners
    document.addEventListener('forceRetailSection', handleForceRetailSection);
    document.addEventListener('forceStaySection', handleForceStaySection);

    // Clean up
    return () => {
      document.removeEventListener('forceRetailSection', handleForceRetailSection);
      document.removeEventListener('forceStaySection', handleForceStaySection);
    };
  }, [onSectionChange]);

  const handleSectionChange = (section) => {
    setActiveSection(section);
    onSectionChange(section);
    setIsMenuOpen(false);
  };

  return (
    <TopNavContainer>
      <MenuButton onClick={() => setIsMenuOpen(!isMenuOpen)}>☰</MenuButton>
      <NavLinksContainer isOpen={isMenuOpen}>
        <TopNavLink 
          to="/" 
          onClick={() => handleSectionChange('castle')}
          className={activeSection === 'castle' ? 'active' : ''}
        >
          THE CASTLE
        </TopNavLink>
        <TopNavLink 
          to="/retail-village" 
          onClick={() => handleSectionChange('retail')}
          className={activeSection === 'retail' ? 'active' : ''}
        >
          RETAIL VILLAGE
        </TopNavLink>
        <TopNavLink 
          to="/stay" 
          onClick={() => handleSectionChange('stay')}
          className={activeSection === 'stay' ? 'active' : ''}
        >
          STAY
        </TopNavLink>
      </NavLinksContainer>
    </TopNavContainer>
  );
};

export default TopNav; 