import { gql } from '@apollo/client';
import castleImage from '../assets/castle.png';
import gardensImage from '../assets/gardens.png';
import retailImage from '../assets/retail.png';
import playgroundImage from '../assets/play.png';
import styled from 'styled-components';

// Helper function to parse dates in various formats
export function parseDate(dateStr) {
  if (!dateStr) return null;
  
  // If it's already a Date object
  if (dateStr instanceof Date) return dateStr;
  
  // If it's a timestamp number
  if (typeof dateStr === 'number') return new Date(dateStr);
  
  // If it's a string
  if (typeof dateStr === 'string') {
    // Check if it's already in ISO format (yyyy-mm-dd)
    if (dateStr.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return new Date(dateStr);
    }
    
    // Parse dd/mm/yyyy format
    const dmyMatch = dateStr.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
    if (dmyMatch) {
      return new Date(dmyMatch[3], dmyMatch[2] - 1, dmyMatch[1]);
    }
    
    // Parse WordPress date format (e.g., "February 26, 2025")
    const date = new Date(dateStr);
    if (!isNaN(date.getTime())) {
      return date;
    }
  }
  
  console.error("Could not parse date:", dateStr);
  return null;
}

// Process WordPress events data into the format your components expect
export function processEvents(events, customImages = {}) {
  if (!events) return [];
  
  // Use provided custom images or fall back to defaults
  const images = {
    castle: customImages.castle || castleImage,
    gardens: customImages.gardens || gardensImage,
    retail: customImages.retail || retailImage,
    playground: customImages.playground || playgroundImage
  };
  
  return events.map(event => {
    // Add debug logging to see what data we're getting
    console.log("Processing event:", event);
    
    // Determine the default image based on location
    let location = Array.isArray(event.eventDetails.eventLocation) 
      ? event.eventDetails.eventLocation[0] 
      : event.eventDetails.eventLocation;
    
    const defaultImage = location === "CASTLE" ? images.castle :
                         location === "PARKLAND" || location === "GARDENS" ? images.gardens :
                         location === "RETAIL VILLAGE" ? images.retail :
                         images.playground;
    
    // Use the featured image if available, otherwise use the default
    const eventImage = event.featuredImage?.node?.sourceUrl || defaultImage;
    
    // Format the date for display
    let formattedDate = '';
    
    try {
      // Get start date
      const startDateStr = event.eventDetails.eventDate;
      
      // If no start date is provided, use the post date
      if (!startDateStr) {
        // Extract date from the post date (which includes time)
        const postDate = new Date(event.date);
        formattedDate = postDate.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        });
      } else {
        const startDate = parseDate(startDateStr);
        
        // Check if date is valid
        if (!startDate || isNaN(startDate.getTime())) {
          formattedDate = event.date ? new Date(event.date).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
          }) : "Date TBD";
        } else {
          // Format start date
          const startDay = startDate.getDate();
          const startMonth = startDate.toLocaleString('en-GB', { month: 'short' });
          const startYear = startDate.getFullYear();
          
          // Check if there's an end date
          if (event.eventDetails.eventEndDate) {
            const endDateStr = event.eventDetails.eventEndDate;
            const endDate = parseDate(endDateStr);
            
            // Check if end date is valid
            if (endDate && !isNaN(endDate.getTime())) {
              // Format end date
              const endDay = endDate.getDate();
              const endMonth = endDate.toLocaleString('en-GB', { month: 'short' });
              const endYear = endDate.getFullYear();
              
              // If same year, only show year once at the end
              if (startYear === endYear) {
                // If same month, format as "25 - 30 Nov 2024"
                if (startMonth === endMonth) {
                  formattedDate = `${startDay} - ${endDay} ${startMonth} ${endYear}`;
                } else {
                  // Different months, format as "25 Nov - 23 Dec 2024"
                  formattedDate = `${startDay} ${startMonth} - ${endDay} ${endMonth} ${endYear}`;
                }
              } else {
                // Different years, format as "25 Nov 2024 - 23 Jan 2025"
                formattedDate = `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
              }
            } else {
              // End date is invalid, just use start date
              formattedDate = `${startDay} ${startMonth} ${startYear}`;
            }
          } else {
            // Single day event
            formattedDate = `${startDay} ${startMonth} ${startYear}`;
          }
        }
      }
    } catch (e) {
      console.error("Error formatting date:", e);
      // Fallback to original date
      formattedDate = event.date ? new Date(event.date).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }) : "Date TBD";
    }
    
    // Check if we need to determine event type from tags or title
    let eventType = 'regular';
    if (event.eventDetails.eventTags && Array.isArray(event.eventDetails.eventTags)) {
      // If there's a "tour" tag, mark as tour
      if (event.eventDetails.eventTags.includes('tour')) {
        eventType = 'tour';
      }
    } else if (event.title.toLowerCase().includes('tour')) {
      // Fallback: check if title contains "tour"
      eventType = 'tour';
    }
    
    // Get ticket tier display name
    const getTicketTierName = (tier) => {
      switch(tier) {
        case 'super_early_bird': return 'Super Early Bird';
        case 'early_bird': return 'Early Bird';
        case 'advanced': return 'Advanced';
        case 'standard': return 'Standard';
        default: return '';
      }
    };
    
    // Format ticket tier for display
    const ticketTierDisplay = event.eventDetails.eventTicketTier ? 
      getTicketTierName(event.eventDetails.eventTicketTier) : '';
    
    // Safely get description - FIX FOR NULL ERROR
    let description = "No description available";
    if (event.eventDetails.eventShortDescription) {
      description = event.eventDetails.eventShortDescription;
    } else if (event.eventDetails.eventDescription) {
      description = event.eventDetails.eventDescription.slice(0, 150) + '...';
    }
    
    return {
      id: event.id,
      title: event.title,
      date: formattedDate,
      description: description,
      fullDescription: event.eventDetails.eventDescription || "No description available",
      location: location,
      image: eventImage,
      ticketTier: ticketTierDisplay,
      eventType: eventType,
      eventTags: event.eventDetails.eventTags || [],
      isFeatured: event.eventDetails.eventIsFeatured || false,
      eventDetails: event.eventDetails || {},
      link: `/event/${event.id}`,
      linkText: 'Read More'
    };
  });
}

// GraphQL query for events - UPDATED to match actual schema field names
export const GET_EVENTS_QUERY = gql`
  query GetEvents {
    events(first: 8) {
      nodes {
        id
        title
        date
        featuredImage {
          node {
            sourceUrl
          }
        }
        eventDetails {
          eventLocation
          eventDescription
          eventShortDescription
          eventDate
          eventEndDate
          eventTicketTier
          eventTags
          eventIsFeatured
        }
      }
    }
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

const Tag = styled.span`
  background-color: #f0f0f0;
  color: #333;
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 12px;
  text-transform: capitalize;
`; 