import React from 'react';
import styled from 'styled-components';
import Footer from '../../Footer/Footer';

const CookiesContainer = styled.div`
  min-height: 100vh;
  padding-top: 120px;
  background-color: ${props => props.theme.colors.offWhite};
`;

const ContentContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 4rem 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
    margin: 2rem 1rem;
  }
`;

const Title = styled.h1`
  font-family: ${props => props.theme.fonts.main};
  color: ${props => props.theme.colors.primary};
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: 2rem;

  h2 {
    color: ${props => props.theme.colors.primary};
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  h3 {
    color: ${props => props.theme.colors.primary};
    font-size: 1.4rem;
    margin: 1.5rem 0 1rem;
  }

  p {
    line-height: 1.6;
    margin-bottom: 1rem;
    color: ${props => props.theme.colors.text};
  }

  ul {
    margin-left: 2rem;
    margin-bottom: 1rem;

    li {
      line-height: 1.6;
      margin-bottom: 0.5rem;
      color: ${props => props.theme.colors.text};
    }
  }
`;

const Cookies = () => {
  return (
    <CookiesContainer>
      <ContentContainer>
        <Title>Cookie Policy</Title>
        
        <Section>
          <h2>1. What Are Cookies</h2>
          <p>
            Cookies are small text files that are placed on your computer or mobile device when you visit our website. They help us provide you with a better experience by enabling us to monitor which pages you find useful and which you do not.
          </p>
        </Section>

        <Section>
          <h2>2. How We Use Cookies</h2>
          <p>
            We use cookies for the following purposes:
          </p>
          <ul>
            <li>Essential cookies: Required for the website to function properly</li>
            <li>Analytics cookies: Help us understand how visitors interact with our website</li>
            <li>Functionality cookies: Remember your preferences and settings</li>
            <li>Marketing cookies: Track your online activity to help advertisers deliver more relevant advertising</li>
          </ul>
        </Section>

        <Section>
          <h2>3. Types of Cookies We Use</h2>
          <h3>Essential Cookies</h3>
          <ul>
            <li>Session cookies: Maintain your session while browsing</li>
            <li>Security cookies: Protect your account and personal information</li>
          </ul>

          <h3>Analytics Cookies</h3>
          <ul>
            <li>Google Analytics: Track website usage and visitor behavior</li>
            <li>Performance monitoring: Help us identify and fix technical issues</li>
          </ul>

          <h3>Functionality Cookies</h3>
          <ul>
            <li>Language preferences</li>
            <li>Currency settings</li>
            <li>Booking preferences</li>
          </ul>

          <h3>Marketing Cookies</h3>
          <ul>
            <li>Social media integration</li>
            <li>Advertising tracking</li>
            <li>Email marketing preferences</li>
          </ul>
        </Section>

        <Section>
          <h2>4. Managing Cookies</h2>
          <p>
            You can control and/or delete cookies as you wish. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. However, if you do this, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.
          </p>
        </Section>

        <Section>
          <h2>5. Third-Party Cookies</h2>
          <p>
            We use services from third parties that may also set cookies on your device. These include:
          </p>
          <ul>
            <li>Google Analytics</li>
            <li>Social media platforms</li>
            <li>Payment processors</li>
            <li>Advertising networks</li>
          </ul>
        </Section>

        <Section>
          <h2>6. Updates to This Policy</h2>
          <p>
            We may update this Cookie Policy from time to time. Any changes will be posted on this page with an updated revision date.
          </p>
        </Section>

        <Section>
          <h2>7. Contact Us</h2>
          <p>
            If you have any questions about our use of cookies, please contact us at:
          </p>
          <p>
            Email: info@belvoircastle.com<br />
            Address: Belvoir Castle, Grantham, Leicestershire, NG32 1PE
          </p>
        </Section>
      </ContentContainer>
      <Footer />
    </CookiesContainer>
  );
};

export default Cookies; 