import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPaw, 
  faCalendarAlt, 
  faClock, 
  faTicketAlt,
  faChild,
  faLeaf,
  faHeart
} from '@fortawesome/free-solid-svg-icons';
import ParallaxBanner from '../../common/Parralax-banner';
import Footer from '../../Footer/Footer';
import Button from '../../Button/Button';
import { useNavigate } from 'react-router-dom';
import { useRetailSection } from '../../../hooks/useRetailSection';
import animalMap from '../../../assets/animal-map.png';
import castleImage from '../../../assets/castle2.png';
import animalBanner from '../../../assets/animalBan.webp';
// Import placeholder image - replace with actual animal park images
import animalParkImage from '../../../assets/retail.png';
import SEO from '../../common/SEO';

const AnimalParkContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const ComingSoonBanner = styled.div`
  background: ${props => props.theme.colors.primary};
  color: white;
  padding: 3rem 2rem;
  border-radius: 12px;
  text-align: center;
  margin: 2rem 0 4rem;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);

  &::before {
    content: '🐑';
    position: absolute;
    font-size: 8rem;
    opacity: 0.1;
    top: -20px;
    left: -20px;
    transform: rotate(-15deg);
  }

  &::after {
    content: '🐴';
    position: absolute;
    font-size: 16rem;
    opacity: 0.2;
    top: -60px;
    right: -60px;
    transform: rotate(15deg);
  }
`;

const OpeningDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 2rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const DetailItem = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ComingSoonText = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 3.5rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const OpeningDate = styled.div`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.accent};
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
`;

const Highlight = styled.span`
  color: ${props => props.theme.colors.accent};
  font-weight: bold;
`;

const IconWrapper = styled.div`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.accent};
`;

const DetailTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
`;

const DetailText = styled.p`
  font-size: 1rem;
  opacity: 0.9;
`;

const Section = styled.section`
  margin-bottom: 4rem;
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
  text-align: center;
`;

const IntroText = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 2rem;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 4rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  text-align: center;
`;

const FeatureTitle = styled.h3`
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
  font-weight: 600;
`;

const FeatureText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 0;
`;

const MapSection = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  padding: 3rem;
  border-radius: 8px;
  margin-bottom: 4rem;
`;

const MapContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
`;

const MapImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

const MapArea = styled.div`
  position: absolute;
  background-color: ${props => props.theme.colors.accent}10;
  border: 2px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${props => props.theme.colors.accent}30;
    border-color: ${props => props.theme.colors.accent};
    
    & > div {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
`;

const AreaTooltip = styled.div`
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%) translateY(10px);
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.15);
  width: max-content;
  min-width: 200px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 10;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
`;

const TooltipTitle = styled.h4`
  font-size: 1.2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
`;

const TooltipText = styled.p`
  font-size: 0.9rem;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 0.5rem;
`;

const MapLegend = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid ${props => props.theme.colors.greyBackground};
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: ${props => props.theme.colors.secondary};
  padding: 0.5rem 1rem;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.colors.accent}20;
    color: ${props => props.theme.colors.accent};
  }
`;

const PricingSection = styled.div`
  text-align: center;
  margin-bottom: 4rem;
`;

const PriceCard = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  max-width: 400px;
  margin: 2rem auto;
  text-align: center;

  button {
    background-color: ${props => props.theme.colors.accent};
    color: white;
    border: 2px solid transparent;
    border-radius: 50px;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    
    &:hover {
      background-color: transparent;
      color: ${props => props.theme.colors.accent};
      border-color: ${props => props.theme.colors.accent};
      transform: translateY(-2px);
    }
  }
`;

const Price = styled.div`
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin: 1rem 0;
  font-weight: bold;
`;

const PriceTitle = styled.h3`
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const PriceText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 1.5rem;
`;

const CTASection = styled.div`
  text-align: center;
  padding: 2rem;
  margin: -2rem auto 2rem;
  max-width: 800px;
`;

const CTAButton = styled.a`
  display: inline-block;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1rem 3rem;
  border-radius: 50px;
  text-decoration: none;
  border: 2px solid ${props => props.theme.colors.accent};
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);

  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0,0,0,0.15);
  }
`;

const AnimalPark = () => {
  const navigate = useNavigate();
  useRetailSection();

  const features = [
    {
      icon: faPaw,
      title: "Meet Our Animals",
      text: "Get up close with our friendly farm animals, including sheep, goats, chickens, and more."
    },
    {
      icon: faChild,
      title: "Feeding",
      text: "Join us for hands-on feeding sessions and learn more about how we care for our animals."
    }
    ,
    {
      icon: faLeaf,
      title: "Natural Setting",
      text: "Beautiful countryside location within the historic Belvoir Castle estate."
    }
  ];

  const mapAreas = [
    {
      id: 'chickens',
      title: 'Chicken Area',
      description: 'Meet our free-range chickens and collect fresh eggs',
      animals: 'Variety of heritage breed chickens',
      top: '25%',
      left: '10%',
      width: '15%',
      height: '15%'
    },
    {
      id: 'pigs',
      title: 'Pig Paddock',
      description: 'Watch our happy pigs roll in the mud',
      animals: 'Gloucestershire Old Spots and Kunekune pigs',
      top: '35%',
      left: '20%',
      width: '20%',
      height: '20%'
    },
    {
      id: 'petting',
      title: 'Petting Shed',
      description: 'Get up close with our friendly small animals',
      animals: 'Guinea pigs, rabbits, and other small pets',
      top: '45%',
      left: '30%',
      width: '15%',
      height: '15%'
    },
    {
      id: 'cows',
      title: 'Cow Field',
      description: 'See our dairy and beef cattle grazing',
      animals: 'Jersey and Holstein cows',
      top: '20%',
      right: '20%',
      width: '25%',
      height: '20%'
    },
    {
      id: 'goats',
      title: 'Goat Mountain',
      description: 'Watch our playful goats climb and play',
      animals: 'Pygmy and Anglo-Nubian goats',
      top: '45%',
      right: '30%',
      width: '20%',
      height: '15%'
    },
    {
      id: 'sheep',
      title: 'Sheep Meadow',
      description: 'Peaceful grazing area for our woolly friends',
      animals: 'Suffolk and Leicester Longwool sheep',
      top: '60%',
      right: '25%',
      width: '20%',
      height: '20%'
    },
    {
      id: 'donkeys',
      title: 'Donkey Den',
      description: 'Meet our friendly donkeys',
      animals: 'Mediterranean miniature donkeys',
      top: '75%',
      right: '35%',
      width: '15%',
      height: '15%'
    },
    {
      id: 'ponies',
      title: 'Pony Paddock',
      description: 'Home to our gentle ponies',
      animals: 'Shetland and Welsh ponies',
      bottom: '15%',
      right: '15%',
      width: '20%',
      height: '20%'
    }
  ];

  return (
    <AnimalParkContainer>
      <SEO 
        title="Belvoir Animal Park - Family Fun & Farm Animals"
        description="Visit Belvoir Castle's Animal Park, opening Easter 2025. Meet friendly farm animals, enjoy feeding sessions, and create memorable family experiences in our beautiful countryside setting."
        keywords="Belvoir Animal Park, farm animals, family activities, petting zoo, animal experiences, Leicestershire attractions"
        canonicalUrl="https://www.belvoircastle.com/retail-village/animal-park"
        ogImage={animalBanner}
        structuredData={{
          "@context": "https://schema.org",
          "@type": "Zoo",
          "name": "Belvoir Animal Park",
          "description": "Visit Belvoir Castle's Animal Park, opening Easter 2025. Meet friendly farm animals, enjoy feeding sessions, and create memorable family experiences in our beautiful countryside setting.",
          "image": animalBanner,
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Belvoir Castle",
            "addressLocality": "Grantham",
            "addressRegion": "Leicestershire",
            "postalCode": "NG32 1PE",
            "addressCountry": "UK"
          },
          "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            "opens": "10:00",
            "closes": "16:00"
          },
          "priceRange": "£",
          "telephone": "+441476871001",
          "sameAs": [
            "https://www.facebook.com/belvoircastle",
            "https://www.instagram.com/belvoircastle",
            "https://twitter.com/belvoircastle"
          ]
        }}
      />
      <ParallaxBanner 
        title="Belvoir Animal Park"
        subtitle="A New Family Adventure Coming Easter 2025"
        image={animalBanner}
      />
      
      <ContentContainer>
        <ComingSoonBanner>
          <ComingSoonText>Opening This Easter!</ComingSoonText>
          <OpeningDate>Easter 2025</OpeningDate>
          <DetailText style={{ fontSize: '1.2rem', marginBottom: '2rem' }}>
            Get ready for an unforgettable experience meeting our wonderful animals
          </DetailText>
          
          <OpeningDetails>
            <DetailItem>
              <IconWrapper>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </IconWrapper>
              <DetailTitle>Opening Date</DetailTitle>
              <DetailText>From Easter Weekend<br />18th April 2025</DetailText>
            </DetailItem>
            
            <DetailItem>
              <IconWrapper>
                <FontAwesomeIcon icon={faTicketAlt} />
              </IconWrapper>
              <DetailTitle>Special Offer</DetailTitle>
              <DetailText>Tickets<br />£4.50 per person</DetailText>
            </DetailItem>
            
            <DetailItem>
              <IconWrapper>
                <FontAwesomeIcon icon={faClock} />
              </IconWrapper>
              <DetailTitle>Opening Hours</DetailTitle>
              <DetailText>Daily 10:00 - 16:00<br />Last entry 15:30</DetailText>
            </DetailItem>
          </OpeningDetails>
        </ComingSoonBanner>

        <CTASection>
          <CTAButton href="https://online1.venpos.net/VenposJourneyUI/?LocationId=978&Id=663d1f36-1db4-4e09-961d-21885b7fa2ca">
            Book Your Visit Now
          </CTAButton>
        </CTASection>

        <Section>
          <SectionTitle>Welcome to Belvoir Animal Park</SectionTitle>
          <IntroText>
            Get ready for an exciting new addition to the Belvoir Castle Retail Village. 
            Our Animal Park offers visitors the chance to meet friendly farm animals in a 
            beautiful countryside setting, creating memorable experiences for the whole family.
          </IntroText>
        </Section>

        <FeaturesGrid>
          {features.map((feature, index) => (
            <FeatureCard key={index}>
              <IconWrapper>
                <FontAwesomeIcon icon={feature.icon} />
              </IconWrapper>
              <FeatureTitle>{feature.title}</FeatureTitle>
              <FeatureText>{feature.text}</FeatureText>
            </FeatureCard>
          ))}
        </FeaturesGrid>

        <MapSection>
          <SectionTitle>Explore Our Animal Park</SectionTitle>
          <IntroText>
            Take a look at our park map to see all the wonderful animals 
            you'll meet during your visit to Belvoir Animal Park.
          </IntroText>
          
          <MapContainer>
            <MapImage src={animalMap} alt="Animal Park Map" />
          </MapContainer>
        </MapSection>

        <PricingSection>
          <SectionTitle>Opening Offers</SectionTitle>
          <IntroText>
            Be among the first to experience Belvoir Animal Park with our special opening prices.
          </IntroText>
          <PriceCard>
            <PriceTitle>Opening Special</PriceTitle>
            <Price>£4.50</Price>
            <PriceText>Per person | Children under 3 free</PriceText>
            <Button onClick={() => navigate('/book-tickets')}>
              Book Tickets
            </Button>
          </PriceCard>
        </PricingSection>

        <Section>
          <SectionTitle>Opening Hours</SectionTitle>
          <IntroText>
            Opening Easter 2025<br />
            Daily: 10:00 - 16:00<br />
            Last entry: 15:30
          </IntroText>
        </Section>
      </ContentContainer>
      
      <Footer />
    </AnimalParkContainer>
  );
};

export default AnimalPark; 