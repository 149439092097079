import React from 'react';
import styled from 'styled-components';
import Footer from '../../Footer/Footer';

const PrivacyContainer = styled.div`
  min-height: 100vh;
  padding-top: 120px;
  background-color: ${props => props.theme.colors.offWhite};
`;

const ContentContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 4rem 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
    margin: 2rem 1rem;
  }
`;

const Title = styled.h1`
  font-family: ${props => props.theme.fonts.main};
  color: ${props => props.theme.colors.primary};
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: 2rem;

  h2 {
    color: ${props => props.theme.colors.primary};
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  p {
    line-height: 1.6;
    margin-bottom: 1rem;
    color: ${props => props.theme.colors.text};
  }

  ul {
    margin-left: 2rem;
    margin-bottom: 1rem;

    li {
      line-height: 1.6;
      margin-bottom: 0.5rem;
      color: ${props => props.theme.colors.text};
    }
  }
`;

const Privacy = () => {
  return (
    <PrivacyContainer>
      <ContentContainer>
        <Title>Privacy Policy</Title>
        
        <Section>
          <h2>1. Introduction</h2>
          <p>
            At Belvoir Castle, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.
          </p>
        </Section>

        <Section>
          <h2>2. Information We Collect</h2>
          <h3>Personal Information</h3>
          <ul>
            <li>Name and contact information</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Payment information</li>
            <li>Booking and reservation details</li>
          </ul>

          <h3>Non-Personal Information</h3>
          <ul>
            <li>Browser type</li>
            <li>Device information</li>
            <li>IP address</li>
            <li>Pages visited</li>
            <li>Time spent on website</li>
          </ul>
        </Section>

        <Section>
          <h2>3. How We Use Your Information</h2>
          <p>
            We use the information we collect to:
          </p>
          <ul>
            <li>Process your bookings and reservations</li>
            <li>Send you confirmation emails and updates</li>
            <li>Improve our website and services</li>
            <li>Communicate with you about events and promotions</li>
            <li>Comply with legal obligations</li>
          </ul>
        </Section>

        <Section>
          <h2>4. Information Sharing</h2>
          <p>
            We do not sell or rent your personal information to third parties. We may share your information with:
          </p>
          <ul>
            <li>Service providers who assist in our operations</li>
            <li>Payment processors for secure transactions</li>
            <li>Legal authorities when required by law</li>
          </ul>
        </Section>

        <Section>
          <h2>5. Data Security</h2>
          <p>
            We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
          </p>
        </Section>

        <Section>
          <h2>6. Your Rights</h2>
          <p>
            You have the right to:
          </p>
          <ul>
            <li>Access your personal information</li>
            <li>Correct inaccurate data</li>
            <li>Request deletion of your data</li>
            <li>Opt-out of marketing communications</li>
            <li>Object to data processing</li>
          </ul>
        </Section>

        <Section>
          <h2>7. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us at:
          </p>
          <p>
            Email: info@belvoircastle.com<br />
            Address: Belvoir Castle, Grantham, Leicestershire, NG32 1PE
          </p>
        </Section>
      </ContentContainer>
      <Footer />
    </PrivacyContainer>
  );
};

export default Privacy; 