import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCampground, 
  faBed, 
  faUtensils, 
  faWifi, 
  faParking, 
  faMapMarkedAlt,
  faFire,
  faStar,
  faMoon,
  faShower,
  faCoffee,
  faTent,
  faTree,
  faMountain,
  faWineGlassAlt,
  faLeaf,
  faDog,
  faWheelchair
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import { useStaySection } from '../../../hooks/useStaySection';
import ParallaxBanner from '../../common/Parralax-banner';
import NewsletterSignUp from '../../common/Newsletter-sign';
import campingBanImage from '../../../assets/banner/garden.webp';
const CampingContainer = styled.div`
  min-height: 100vh;
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  letter-spacing: 2px;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const CampingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  margin: 4rem 0;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const CampingCard = styled.div`
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const CampingImage = styled.div`
  height: 300px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const CampingContent = styled.div`
  padding: 2rem;
`;

const CampingTitle = styled.h3`
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const CampingDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const CampingDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  
  svg {
    color: ${props => props.theme.colors.accent};
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 1.5rem;
  
  li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    
    svg {
      color: ${props => props.theme.colors.accent};
    }
  }
`;

const BookButton = styled.button`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  
  &:hover {
    background-color: ${props => props.theme.colors.primary};
  }
`;

const HighlightSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 4rem 2rem;
  text-align: center;
  margin: 5rem 0;
`;

const HighlightContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const HighlightTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: white;
  margin-bottom: 1.5rem;
`;

const HighlightText = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 4rem 0;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  text-align: center;
  padding: 2rem;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  
  svg {
    color: white;
    font-size: 32px;
  }
`;

const FeatureTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const InfoSection = styled.div`
  margin: 5rem 0;
  text-align: center;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const InfoCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const InfoTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const InfoText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const RelatedSection = styled.div`
  margin-top: 4rem;
  text-align: center;
`;

const RelatedTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const RelatedLink = styled.button`
  background: ${props => props.theme.colors.accent};
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background 0.3s ease;
  
  &:hover {
    background: ${props => props.theme.colors.primary};
  }
`;

const Camping = () => {
  const navigate = useNavigate();
  useStaySection();

  return (
    <CampingContainer>
      <ParallaxBanner 
        welcomeText="Experience"
        mainTitle="Camping"
        subtitle="PURE NATURE IN THE VALE OF BELVOIR"
        image={campingBanImage}
      />
      
      <ContentContainer>
        <SectionTitle>Traditional Camping Experience</SectionTitle>
        <IntroText>
          Experience the authentic joy of camping in the heart of the Vale of Belvoir. Our camping site offers 
          spacious pitches with stunning views, perfect for those who love the traditional camping experience 
          in a beautiful natural setting.
        </IntroText>
        
        <CampingGrid>
          <CampingCard>
            <CampingImage image="https://belvoirestateholidays.com/wp-content/uploads/2024/02/camping-pitch.jpg" />
            <CampingContent>
              <CampingTitle>Camping Pitches</CampingTitle>
              <CampingDescription>
                Our spacious camping pitches are perfect for tents of all sizes. Each pitch offers stunning 
                views of the surrounding countryside and easy access to all facilities.
              </CampingDescription>
              <CampingDetails>
                <DetailItem>
                  <FontAwesomeIcon icon={faTent} />
                  <span>All tent sizes</span>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faMapMarkedAlt} />
                  <span>Vale of Belvoir</span>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faShower} />
                  <span>Modern facilities</span>
                </DetailItem>
      
              </CampingDetails>
              <FeatureList>
                <li>
                  <FontAwesomeIcon icon={faWifi} />
                  <span>Free WiFi</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faParking} />
                  <span>Vehicle parking</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faUtensils} />
                  <span>BBQ area</span>
                </li>
              </FeatureList>
              <BookButton>
                Booking Coming Soon
              </BookButton>
            </CampingContent>
          </CampingCard>

          <CampingCard>
            <CampingImage image="https://belvoirestateholidays.com/wp-content/uploads/2024/02/hardstanding.jpg" />
            <CampingContent>
              <CampingTitle>Hardstanding Pitches</CampingTitle>
              <CampingDescription>
                Our hardstanding pitches are perfect for motorhomes and caravans. Each pitch includes 
                electric hook-up and easy access to all amenities.
              </CampingDescription>
              <CampingDetails>
                <DetailItem>
                  <FontAwesomeIcon icon={faCampground} />
                  <span>Electric hook-up</span>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faMapMarkedAlt} />
                  <span>Vale of Belvoir</span>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faShower} />
                  <span>Modern facilities</span>
                </DetailItem>
          
              </CampingDetails>
              <FeatureList>
                <li>
                  <FontAwesomeIcon icon={faWifi} />
                  <span>Free WiFi</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faParking} />
                  <span>Vehicle parking</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faUtensils} />
                  <span>BBQ area</span>
                </li>
              </FeatureList>
              <BookButton>
                Booking Coming Soon
              </BookButton>
            </CampingContent>
          </CampingCard>
        </CampingGrid>

        <HighlightSection>
          <HighlightContent>
            <HighlightTitle>Nature at Your Doorstep</HighlightTitle>
            <HighlightText>
              Our camping site offers the perfect opportunity to immerse yourself in nature. Wake up to birdsong, 
              enjoy peaceful walks, and spend evenings around the campfire. It's the ideal way to experience 
              the great outdoors in the heart of the Vale of Belvoir.
            </HighlightText>
          </HighlightContent>
        </HighlightSection>

        <SectionTitle>Nearby Amenities</SectionTitle>
        <CampingCard style={{ maxWidth: '800px', margin: '0 auto' }}>
          <CampingImage image="https://theduckwoolsthorpe.co.uk/wp-content/uploads/2024/02/duck-pub.jpg" />
          <CampingContent>
            <CampingTitle>The Duck at Woolsthorpe</CampingTitle>
            <CampingDescription>
              Just a short walk from our camping site, The Duck at Woolsthorpe offers delicious food, 
              great drinks, and a warm welcome. Perfect for a meal out or a refreshing drink after a day of camping.
            </CampingDescription>
            <CampingDetails>
              <DetailItem>
                <FontAwesomeIcon icon={faUtensils} />
                <span>Pub classics</span>
              </DetailItem>
              <DetailItem>
                <FontAwesomeIcon icon={faWineGlassAlt} />
                <span>Great drinks</span>
              </DetailItem>
              <DetailItem>
                <FontAwesomeIcon icon={faLeaf} />
                <span>Beer garden</span>
              </DetailItem>
              <DetailItem>
                <FontAwesomeIcon icon={faWifi} />
                <span>Free WiFi</span>
              </DetailItem>
            </CampingDetails>
            <FeatureList>
              <li>
                <FontAwesomeIcon icon={faDog} />
                <span>Dog friendly</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faParking} />
                <span>Car park</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faWheelchair} />
                <span>Wheelchair accessible</span>
              </li>
            </FeatureList>
            <BookButton>
              Booking Coming Soon
            </BookButton>
          </CampingContent>
        </CampingCard>

        <SectionTitle>The Camping Experience</SectionTitle>
        <FeaturesGrid>
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faTree} />
            </IconWrapper>
            <FeatureTitle>Natural Setting</FeatureTitle>
            <FeatureDescription>
              Camp in a beautiful natural environment with mature trees and open spaces. Perfect for those 
              seeking a peaceful outdoor experience.
            </FeatureDescription>
          </FeatureCard>
          
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faMountain} />
            </IconWrapper>
            <FeatureTitle>Scenic Views</FeatureTitle>
            <FeatureDescription>
              Enjoy breathtaking views of Belvoir Castle and the rolling hills of the Vale of Belvoir from 
              your camping pitch.
            </FeatureDescription>
          </FeatureCard>
          
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faFire} />
            </IconWrapper>
            <FeatureTitle>Campfire Evenings</FeatureTitle>
            <FeatureDescription>
              Gather around your campfire for cozy evenings under the stars. Perfect for creating lasting 
              memories with family and friends.
            </FeatureDescription>
          </FeatureCard>
        </FeaturesGrid>

        <RelatedSection>
          <RelatedTitle>Want a more luxurious camping experience?</RelatedTitle>
          <RelatedLink onClick={() => navigate('/accommodations/glamping')}>
            Check out our Bell Tents at the Glamping site
          </RelatedLink>
        </RelatedSection>

        <InfoSection>
          <SectionTitle>Essential Information</SectionTitle>
          <InfoGrid>
            <InfoCard>
              <InfoTitle>Check-in & Check-out</InfoTitle>
              <InfoText>
                <strong>Check-in:</strong> From 2:00 PM
              </InfoText>
              <InfoText>
                <strong>Check-out:</strong> By 12:00 PM
              </InfoText>
              <InfoText>
                Early check-in and late check-out available upon request, subject to availability.
              </InfoText>
            </InfoCard>
            
            <InfoCard>
              <InfoTitle>What's Included</InfoTitle>
              <InfoText>
                • Access to modern shower facilities
              </InfoText>
        
              <InfoText>
                • Designated BBQ area
              </InfoText>
              <InfoText>
                • Vehicle parking near pitch
              </InfoText>
            </InfoCard>
          </InfoGrid>
        </InfoSection>

        <div style={{ margin: '4rem 0' }}>
          <NewsletterSignUp />
        </div>
      </ContentContainer>
      
      <Footer />
    </CampingContainer>
  );
};

export default Camping; 