import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faRing, 
  faCrown, 
  faGlassCheers, 
  faUtensils, 
  faBed, 
  faCamera
} from '@fortawesome/free-solid-svg-icons';

// Import placeholder images - replace with actual images
import weddingHero from '../../../assets/wedding-hero.jpg'; // Replace with actual image
import castleExterior from '../../../assets/castle.png'; 
import regentsGallery from '../../../assets/regents-gallery.jpg'; // Replace with actual image
import elizabethSaloon from '../../../assets/elizabeth-saloon.jpg'; // Replace with actual image
import ballroom from '../../../assets/ballroom.jpg'; // Replace with actual image
import roseGarden from '../../../assets/rose-garden.jpg'; // Replace with actual image
import stateDining from '../../../assets/state-dining.jpg'; // Replace with actual image
import oldKitchen from '../../../assets/old-kitchen.jpg'; // Replace with actual image
import accommodation from '../../../assets/accommodation.jpg'; // Replace with actual image
import activities from '../../../assets/activities.jpg'; // Replace with actual image
import weddingBanner from '../../../assets/banner/roomBan.webp';
import valeHouseImage from '../../../assets/holidays/vale-house.webp';
import parkHouseImage from '../../../assets/holidays/park-house.webp';
import parkLodgeImage from '../../../assets/holidays/park-lodge.webp';
import lodgeCottageImage from '../../../assets/holidays/lodge-cottage.webp';

const GET_WEDDING_DATA = gql`
  query GetWeddingData {
    siteSettings(where: {title: "Global Site Settings"}) {
      nodes {
        siteSettingsFields {
          contactInfo {
            phone
            email
          }
        }
      }
    }
  }
`;

const WeddingContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const SubTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.accent};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const HighlightSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 5rem 2rem;
  margin: 5rem 0;
  text-align: center;
`;

const HighlightContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const HighlightTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: white;
  margin-bottom: 2rem;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const HighlightText = styled.p`
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const FeatureSection = styled.div`
  display: flex;
  margin: 5rem 0;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  
  @media (max-width: 992px) {
    flex-direction: ${props => props.imagePosition === 'right' ? 'column-reverse' : 'column'};
  }
`;

const FeatureImage = styled.div`
  flex: 1;
  min-height: 500px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  
  @media (max-width: 992px) {
    min-height: 300px;
  }
`;

const FeatureContent = styled.div`
  flex: 1;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const FeatureTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const FeatureDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const PackagesSection = styled.div`
  margin: 5rem 0;
`;

const PackageTabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  flex-wrap: wrap;
`;

const PackageTab = styled.button`
  background: ${props => props.active ? props.theme.colors.accent : 'transparent'};
  color: ${props => props.active ? 'white' : props.theme.colors.primary};
  border: 2px solid ${props => props.active ? props.theme.colors.accent : props.theme.colors.primary};
  padding: 1rem 2rem;
  margin: 0.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 25px;
  
  &:hover {
    background: ${props => props.active ? props.theme.colors.accent : props.theme.colors.greyBackground};
  }
`;

const PackageContent = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  padding: 3rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const PackageTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
  text-align: center;
`;

const PackageSubtitle = styled.p`
  font-size: 1.1rem;
  font-style: italic;
  text-align: center;
  margin-bottom: 2rem;
  color: ${props => props.theme.colors.accent};
`;

const PackageList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 0 2rem 0;
  
  li {
    position: relative;
    padding-left: 2rem;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    line-height: 1.6;
    
    &:before {
      content: "✓";
      position: absolute;
      left: 0;
      color: ${props => props.theme.colors.accent};
      font-weight: bold;
    }
  }
`;

const PackageNote = styled.p`
  font-size: 1rem;
  font-style: italic;
  margin-top: 2rem;
  text-align: center;
`;

const VenueSection = styled.div`
  margin: 5rem 0;
`;

const VenueGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const VenueCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;
  
  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
`;

const VenueImage = styled.div`
  height: 300px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const VenueContent = styled.div`
  padding: 2rem;
`;

const VenueTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const VenueDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const AccommodationSection = styled.div`
  margin: 5rem 0;
`;

const AccommodationGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const AccommodationCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
`;

const AccommodationImage = styled.div`
  height: 200px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const AccommodationContent = styled.div`
  padding: 1.5rem;
`;

const AccommodationTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.3rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
`;

const AccommodationDetails = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 0.5rem;
`;

const AccommodationDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
`;

const ActivitiesSection = styled.div`
  margin: 5rem 0;
`;

const ActivitiesContent = styled.div`
  display: flex;
  margin-top: 3rem;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const ActivitiesImage = styled.div`
  flex: 1;
  min-height: 400px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  
  @media (max-width: 992px) {
    min-height: 300px;
  }
`;

const ActivitiesText = styled.div`
  flex: 1;
  padding: 3rem;
`;

const ActivitiesList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 2rem 0;
  
  li {
    position: relative;
    padding-left: 2rem;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    line-height: 1.6;
    
    &:before {
      content: "•";
      position: absolute;
      left: 0;
      color: ${props => props.theme.colors.accent};
      font-weight: bold;
      font-size: 1.5rem;
    }
  }
`;

const ContactSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 4rem 2rem;
  text-align: center;
  margin: 5rem 0 0 0;
`;

const ContactContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const ContactTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: white;
  margin-bottom: 1.5rem;
`;

const ContactText = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const ContactButton = styled.a`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  text-decoration: none;
  border: 2px solid transparent;
  border-radius: 25px;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  
  svg {
    color: white;
    font-size: 32px;
  }
`;

const Wedding = () => {
  const { loading, error, data } = useQuery(GET_WEDDING_DATA);
  const [activePackage, setActivePackage] = useState('regal');
  
  // Extract contact info with fallback values
  const contactInfo = data?.siteSettings?.nodes?.[0]?.siteSettingsFields?.contactInfo || {
    phone: "01476 871001",
    email: "weddings@belvoircastle.com"
  };
  
  const handlePackageChange = (packageType) => {
    setActivePackage(packageType);
  };
  
  const renderPackageContent = () => {
    switch(activePackage) {
      case 'regal':
        return (
          <PackageContent>
            <PackageTitle>Regal Elegance Wedding Package</PackageTitle>
            <PackageSubtitle>Supplements for exclusive use apply in Castle open season. Price on application.</PackageSubtitle>
            <PackageList>
              <li>Exclusive hire of the Castle and State Rooms for you and your guests</li>
              <li>King's Suite for the Bridal Party to prepare in on the morning of the wedding, including prosecco, pastries, fresh fruit</li>
              <li>Shoot Room for Groom's Party to prepare in, with refreshments</li>
              <li>The Ballroom or Rose Garden for your Civil Ceremony</li>
              <li>Reception drinks served in the Guards Room or Rose Garden</li>
              <li>Photographs in the Rose Garden, Elizabeth Saloon or Regents Gallery</li>
              <li>Wedding Breakfast served in the State Dining Room</li>
              <li>Wedding toast</li>
              <li>Evening reception in the Old Kitchen</li>
              <li>Bar in the Servants Hall</li>
              <li>Overnight bed & breakfast stay in the Duchess Bedroom</li>
              <li>The firing of the Duke's 7 French Cannons</li>
              <li>Use of the Duke's Sword for cutting the wedding cake</li>
              <li>A pre-wedding menu tasting</li>
              <li>A dedicated wedding co-ordinator to help on the day</li>
            </PackageList>
          </PackageContent>
        );
      
      case 'timeless':
        return (
          <PackageContent>
            <PackageTitle>Timeless Grace Wedding Package</PackageTitle>
            <PackageSubtitle>Supplements for exclusive use apply in Castle open season. Price on application.</PackageSubtitle>
            <PackageList>
              <li>Exclusive hire of the Castle and State Rooms for you and your guests</li>
              <li>The Guard Room or Rose Garden for your Civil Ceremony</li>
              <li>Reception drinks served in the Guards Room</li>
              <li>Photographs in the Rose Garden or the Carriage Landing Staircase</li>
              <li>Wedding breakfast served in the State Dining Room</li>
              <li>Wedding toast</li>
              <li>Evening reception in the Old Kitchen</li>
              <li>Bar in the Servant's Hall</li>
              <li>Dedicated wedding co-ordinator to help on the day</li>
              <li>Pre-wedding menu tasting</li>
            </PackageList>
          </PackageContent>
        );
      
      default:
        return null;
    }
  };
  
  return (
    <WeddingContainer>
      <ParallaxBanner 
        title="Weddings at Belvoir Castle"
        subtitle="Where History and Luxury Create an Unforgettable Experience"
        image={weddingBanner}
      />
      
      <ContentContainer>
        <SectionTitle>Celebrate Your Wedding in the Grandeur of Belvoir Castle</SectionTitle>
        <IntroText>
          With exclusive hire of the Castle and State Rooms, your special day will be steeped in aristocratic 
          splendour, reflecting centuries of noble tradition. Experience the magic of a truly historic setting 
          for the most important day of your life.
        </IntroText>
        
        <HighlightSection>
          <HighlightContent>
            <IconWrapper>
              <FontAwesomeIcon icon={faCrown} />
            </IconWrapper>
            <HighlightTitle>In the Land of Monarchs and Statesmen</HighlightTitle>
            <HighlightText>
              Belvoir Castle, the ancestral home of the Dukes of Rutland since the 11th century, has hosted British royalty, 
              including King Henry VIII, Queen Victoria, and Queen Elizabeth II. Your wedding will take place in a setting 
              that has welcomed monarchs and statesmen, offering a unique connection to England's storied past.
            </HighlightText>
          </HighlightContent>
        </HighlightSection>
        
        <VenueSection>
          <SectionTitle>Included in Your Wedding Package</SectionTitle>
          <SubTitle>Exclusive hire of the Castle which includes the following State rooms</SubTitle>
          
          <VenueGrid>
            <VenueCard>
              <VenueImage image={regentsGallery} />
              <VenueContent>
                <VenueTitle>The Regents Gallery</VenueTitle>
                <VenueDescription>
                  A fine example of a Regency period room. The space is 131 feet long, with a large bay in the centre 
                  of the room housed within the stunning tower guests see as they approach the Castle from the drive.
                </VenueDescription>
              </VenueContent>
            </VenueCard>
            
            <VenueCard>
              <VenueImage image={elizabethSaloon} />
              <VenueContent>
                <VenueTitle>The Elizabeth Saloon</VenueTitle>
                <VenueDescription>
                  A French Rococo-style room containing many fabulous features. The room is named after Elizabeth, 
                  the 5th Duchess, whose Carara marble statue, by Matthew Wyatt, stands at the end of the room.
                </VenueDescription>
              </VenueContent>
            </VenueCard>
            
            <VenueCard>
              <VenueImage image={ballroom} />
              <VenueContent>
                <VenueTitle>The Ballroom</VenueTitle>
                <VenueDescription>
                  Civil Ceremony in the Ballroom boasting a magnificent ceiling modelled on Lincoln Cathedral, 
                  designed by Sir John Thoroton.
                </VenueDescription>
              </VenueContent>
            </VenueCard>
            
            <VenueCard>
              <VenueImage image={roseGarden} />
              <VenueContent>
                <VenueTitle>The Rose Garden</VenueTitle>
                <VenueDescription>
                  A breathtakingly beautiful setting that provides the perfect backdrop for wedding photographs. 
                  This enchanting garden, home to over 800 roses, meticulously designed and maintained, bursts with 
                  vibrant colours and intoxicating fragrances, creating a romantic atmosphere that captures the essence 
                  of your special day.
                </VenueDescription>
              </VenueContent>
            </VenueCard>
            
            <VenueCard>
              <VenueImage image={stateDining} />
              <VenueContent>
                <VenueTitle>The State Dining Room</VenueTitle>
                <VenueDescription>
                  Dine in the State Dining Room, where the magnificent ceiling holds 91 entirely unique flowers, 
                  based on the Church of Santa Maria Maggiore in Rome.
                </VenueDescription>
              </VenueContent>
            </VenueCard>
            
            <VenueCard>
              <VenueImage image={oldKitchen} />
              <VenueContent>
                <VenueTitle>The Old Kitchen</VenueTitle>
                <VenueDescription>
                  With its original open range, spit, and collection of copper and pewter pots, provides a rustic 
                  and historical ambiance that creates a unique and charming setting for an evening wedding reception 
                  where platters will be served.
                </VenueDescription>
              </VenueContent>
            </VenueCard>
          </VenueGrid>
        </VenueSection>
        
        <PackagesSection>
          <SectionTitle>Wedding Packages</SectionTitle>
          <IntroText>
            Choose from our carefully curated wedding packages, each designed to provide an unforgettable 
            experience in the historic grandeur of Belvoir Castle.
          </IntroText>
          
          <PackageTabs>
            <PackageTab 
              active={activePackage === 'regal'} 
              onClick={() => handlePackageChange('regal')}
            >
              Regal Elegance
            </PackageTab>
            <PackageTab 
              active={activePackage === 'timeless'} 
              onClick={() => handlePackageChange('timeless')}
            >
              Timeless Grace
            </PackageTab>
          </PackageTabs>
          
          {renderPackageContent()}
        </PackagesSection>
        
        <AccommodationSection>
          <SectionTitle>Accommodation Options for Guests</SectionTitle>
          <IntroText>
            We have a range of beautiful properties for guests to book and allow full enjoyment of the celebration.
          </IntroText>
          
          <FeatureSection imagePosition="left">
            <FeatureImage image={accommodation} />
            <FeatureContent>
              <FeatureDescription>
                Arriving the night before the wedding allows guests to relax, explore the picturesque surroundings, 
                and be refreshed for the big day.
              </FeatureDescription>
              <FeatureDescription>
                Staying the night of the wedding ensures they can indulge in the festivities without travel worries, 
                savouring every moment.
              </FeatureDescription>
              <FeatureDescription>
                Extending the stay to the night following the wedding offers a leisurely departure, with the chance 
                to continue celebrating with loved ones or simply enjoy the unique location of each property.
              </FeatureDescription>
              <FeatureDescription>
                This extended stay promises a truly memorable wedding experience amidst the historic grandeur and 
                natural beauty of Belvoir Castle.
              </FeatureDescription>
            </FeatureContent>
          </FeatureSection>
          
          <AccommodationGrid>
            <AccommodationCard>
              <AccommodationImage image={castleExterior} />
              <AccommodationContent>
                <AccommodationTitle>Castle</AccommodationTitle>
                <AccommodationDetails>14 historic bedrooms</AccommodationDetails>
                <AccommodationDescription>
                  Wedding guests will be enchanted by the luxurious accommodations at Belvoir Castle.
                </AccommodationDescription>
              </AccommodationContent>
            </AccommodationCard>
            
            <AccommodationCard>
              <AccommodationImage image={valeHouseImage} />
              <AccommodationContent>
                <AccommodationTitle>Vale House</AccommodationTitle>
                <AccommodationDetails>9 bedrooms</AccommodationDetails>
                <AccommodationDescription>
                  Allow your guests to be immersed in the rich history and luxurious comfort of Vale Cottage, 
                  nestled within the historic Engine Yard on the Belvoir Castle Estate.
                </AccommodationDescription>
              </AccommodationContent>
            </AccommodationCard>
            
            <AccommodationCard>
              <AccommodationImage image={parkHouseImage} />
              <AccommodationContent>
                <AccommodationTitle>Croxton Park House</AccommodationTitle>
                <AccommodationDetails>5 bedrooms</AccommodationDetails>
                <AccommodationDescription>
                  Allow your guests to be immersed in history with a stay at Croxton Park House, a stunning 
                  property within Croxton Park on the Belvoir Castle Estate.
                </AccommodationDescription>
              </AccommodationContent>
            </AccommodationCard>
            
            <AccommodationCard>
              <AccommodationImage image={parkLodgeImage} />
              <AccommodationContent>
                <AccommodationTitle>Croxton Park Lodge</AccommodationTitle>
                <AccommodationDetails>4 rooms</AccommodationDetails>
                <AccommodationDescription>
                  Allow your guests to experience the allure of Croxton Park Lodge, nestled within the historic 
                  Croxton Park on the Belvoir Castle Estate.
                </AccommodationDescription>
              </AccommodationContent>
            </AccommodationCard>
            
            <AccommodationCard>
              <AccommodationImage image={lodgeCottageImage} />
              <AccommodationContent>
                <AccommodationTitle>Lodge Cottage</AccommodationTitle>
                <AccommodationDetails>4 rooms</AccommodationDetails>
                <AccommodationDescription>
                  Allow your guests to discover the charm and historical allure of Lodge Cottage, an enchanting 
                  property on the prestigious Belvoir Castle Estate.
                </AccommodationDescription>
              </AccommodationContent>
            </AccommodationCard>
          </AccommodationGrid>
          
          <div style={{ textAlign: 'center', margin: '3rem 0' }}>
            <SubTitle>Total Number of Bedrooms Across the Estate</SubTitle>
            <IntroText>
              40 bedrooms, sleeping 40 single occupancy, or up to 85 people double or in some cases triple occupancy. 
              All ensuite or private bathrooms except where stated.
            </IntroText>
          </div>
        </AccommodationSection>
        
        <ActivitiesSection>
          <SectionTitle>Enhance Your Stay at Belvoir Castle</SectionTitle>
          <IntroText>
            Guests arriving before or staying on after the wedding can indulge in a variety of delightful 
            activities to enhance their stay at Belvoir Castle.
          </IntroText>
          
          <ActivitiesContent>
            <ActivitiesImage image={activities} />
            <ActivitiesText>
              <ActivitiesList>
                <li>Guided mindfulness meditation session in the serene gardens</li>
                <li>Guided tour of the majestic Castle and its Exquisite Gardens, including the Duchess Garden, the Hermit's </li>
                <li>Guided tour of the majestic Castle and its Exquisite Gardens, including the Duchess Garden, the Hermit's Garden, and the Japanese Woodland</li>
                <li>Clay Pigeon Shoot offering an exciting way to bond with friends and family</li>
                <li>Foraging Walk, discovering the natural bounty of the estate</li>
                <li>Sunset Vineyard Tour, culminating in a Wine Tasting of Belvoir Estate Wines</li>
                <li>Luxurious spa treatments to rejuvenate guests, ensuring they feel their best for the big day</li>
                <li>Private cocktail-making session at the Balloon Bar, adding a fun and sophisticated touch to your guests' stay</li>
              </ActivitiesList>
              <p>
                These activities promise to create unforgettable memories and perfectly complement the wedding celebration. 
                We can tailor an additional activities package to perfectly suit you and your guests!
              </p>
            </ActivitiesText>
          </ActivitiesContent>
        </ActivitiesSection>
        
        <ContactSection>
          <ContactContent>
            <ContactTitle>Begin Your Fairy Tale Wedding Journey</ContactTitle>
            <ContactText>
              Experience the regal elegance and historical richness of Belvoir Castle, ensuring your wedding day 
              is truly extraordinary. Contact our dedicated wedding team to start planning your dream celebration.
            </ContactText>
            <ContactButton href={`mailto:${contactInfo.email}`}>
              Enquire Now
            </ContactButton>
          </ContactContent>
        </ContactSection>
      </ContentContainer>
      
      <Footer />
    </WeddingContainer>
  );
};

export default Wedding;