import ReactGA from 'react-ga4';

export const initGA = (measurementId) => {
  // Initialize the first tracking ID
  ReactGA.initialize(measurementId);
  
  // Initialize the second tracking ID
  ReactGA.initialize('G-BVF8JKZ26L', { testMode: false });
};

export const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};

export const logEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
}; 