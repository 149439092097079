import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faLeaf, 
  faClock, 
  faMapMarkerAlt, 
  faExternalLinkAlt,
  faShoppingBag,
  faUtensils,
  faHome,
  faCrown,
  faTree
} from '@fortawesome/free-solid-svg-icons';
import ParallaxBanner from '../../common/Parralax-banner';
import Footer from '../../Footer/Footer';
import { useRetailSection } from '../../../hooks/useRetailSection';
import Button from '../../../components/Button/Button';

// Import images (replace with actual shop images)
import farmShopImage from '../../../assets/farmshop1.webp';
import duchessImage from '../../../assets/retail1.webp';
import toriImage from '../../../assets/ToriMurphy.webp';
import bumblesImage from '../../../assets/retail2.webp';
import grangeImage from '../../../assets/grange.webp';
import retailBanner from '../../../assets/banner/retailBan3.webp';




const ShopsContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const CurrentTimeDisplay = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 3rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const IntroSection = styled.div`
  text-align: center;
  margin-bottom: 4rem;
`;

const IntroTitle = styled.h1`
  font-family: ${props => props.theme.fonts.main};
  font-size: 3rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const IntroText = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto 2rem;
  color: ${props => props.theme.colors.secondary};
`;

const FeaturedShops = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  margin-bottom: 6rem;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const OtherShops = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ShopCard = styled.div`
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
`;

const FeaturedShopCard = styled(ShopCard)`
  display: flex;
  flex-direction: column;
`;

const ShopImage = styled.div`
  width: 100%;
  height: 200px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  position: relative;
`;

const FeaturedShopImage = styled(ShopImage)`
  height: 250px;
`;

const ShopContent = styled.div`
  padding: 1.5rem;
`;

const ShopTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

const ShopDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  color: ${props => props.theme.colors.secondary};
`;

const StatusBadge = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  background-color: ${props => props.isOpen ? 'rgba(76, 175, 80, 0.85)' : 'rgba(244, 67, 54, 0.85)'};
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  backdrop-filter: blur(4px);
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin: 1.5rem 0;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  color: ${props => props.theme.colors.secondary};
  
  svg {
    color: ${props => props.theme.colors.accent};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  
  button {
    background-color: ${props => props.theme.colors.accent};
    color: white;
    border: 2px solid transparent;
    border-radius: 50px;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    
    &:hover {
      background-color: transparent;
      color: ${props => props.theme.colors.accent};
      border-color: ${props => props.theme.colors.accent};
      transform: translateY(-2px);
    }
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
  text-align: center;
`;

const ShopsOverview = () => {
  const navigate = useNavigate();
  useRetailSection();
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  const isShopOpen = (openTime, closeTime) => {
    const now = currentTime.getHours() * 100 + currentTime.getMinutes();
    const [openHour, openMinute] = openTime.split(':').map(Number);
    const [closeHour, closeMinute] = closeTime.split(':').map(Number);
    const openTimeNum = openHour * 100 + openMinute;
    const closeTimeNum = closeHour * 100 + closeMinute;
    return now >= openTimeNum && now < closeTimeNum;
  };

  const featuredShops = [
    {
      id: 'farm-shop',
      title: 'Farm Shop',
      icon: faLeaf,
      image: farmShopImage,
      description: 'Experience the finest local produce direct from our estate and carefully selected suppliers. Our Farm Shop offers fresh seasonal vegetables, artisanal cheeses, local meats, and specialty foods.',
      openTime: '09:00',
      closeTime: '17:00',
      path: '/shops/farm-shop',
      comingSoon: 'Online shop launching May 2025'
    },
    {
      id: 'duchess-collection',
      title: 'The Duchess Collection',
      icon: faCrown,
      image: duchessImage,
      description: 'Discover our exclusive collection of elegant fashion and homeware inspired by the rich heritage of Belvoir Castle. Each piece is carefully curated by the Duchess of Rutland, featuring designs drawn from the castle\'s historic art collection and architectural details.',
      openTime: '09:00',
      closeTime: '17:00',
      path: '/shops/duchess-collection',
      comingSoon: 'Online boutique launching May 2025'
    }
  ];

  const otherShops = [
    {
      id: 'tori-murphy',
      title: 'Tori Murphy',
      icon: faHome,
      image: toriImage,
      description: 'Contemporary British-made textiles and homeware with timeless design.',
      openTime: '09:30',
      closeTime: '17:00',
      path: '/shops/tori-murphy'
    },
    {
      id: 'bumbles',
      title: 'Bumbles',
      icon: faHome,
      image: bumblesImage,
      description: 'Luxury interior design and homeware, offering a curated selection of beautiful pieces for your home.',
      openTime: '09:00',
      closeTime: '16:30',
      path: '/shops/bumbles'
    },
    {
      id: 'grange',
      title: 'The Grange Garden Centre',
      icon: faTree,
      image: grangeImage,
      description: 'Your destination for quality plants, garden accessories, and expert advice.',
      openTime: '09:00',
      closeTime: '17:00',
      path: '/shops/grange-garden-centre'
    }
  ];

  return (
    <ShopsContainer>
      <ParallaxBanner 
        title="Retail Village at Belvoir Castle"
        subtitle="A unique shopping destination in historic surroundings"
        image={retailBanner}
      />
      
      <ContentContainer>
        <IntroSection>
          <IntroTitle>Our Shops</IntroTitle>
          <IntroText>
            Discover a carefully curated selection of shops offering everything from fresh local produce 
            to luxury fashion, homewares, and garden essentials.
          </IntroText>
        </IntroSection>

        <CurrentTimeDisplay>
          Current Time: {currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
        </CurrentTimeDisplay>

        <FeaturedShops>
          {featuredShops.map(shop => (
            <FeaturedShopCard key={shop.id}>
              <FeaturedShopImage image={shop.image}>
                <StatusBadge isOpen={isShopOpen(shop.openTime, shop.closeTime)}>
                  <FontAwesomeIcon icon={faClock} />
                  {isShopOpen(shop.openTime, shop.closeTime) ? 'Open Now' : 'Closed'}
                </StatusBadge>
              </FeaturedShopImage>
              <ShopContent>
                <ShopTitle>
                  <FontAwesomeIcon icon={shop.icon} />
                  {shop.title}
                </ShopTitle>
                <ShopDescription>{shop.description}</ShopDescription>
                <InfoItem>
                  <FontAwesomeIcon icon={faClock} />
                  {shop.openTime} - {shop.closeTime}
                </InfoItem>
                {shop.comingSoon && (
                  <InfoItem style={{color: props => props.theme.colors.accent, marginTop: '1rem'}}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                    {shop.comingSoon}
                  </InfoItem>
                )}
                <ButtonContainer>
                  <Button onClick={() => navigate(shop.path)}>Visit Shop Page</Button>
                </ButtonContainer>
              </ShopContent>
            </FeaturedShopCard>
          ))}
        </FeaturedShops>

        <OtherShops>
          {otherShops.map(shop => (
            <ShopCard key={shop.id}>
              <ShopImage image={shop.image}>
                <StatusBadge isOpen={isShopOpen(shop.openTime, shop.closeTime)}>
                  <FontAwesomeIcon icon={faClock} />
                  {isShopOpen(shop.openTime, shop.closeTime) ? 'Open Now' : 'Closed'}
                </StatusBadge>
              </ShopImage>
              <ShopContent>
                <ShopTitle>
                  <FontAwesomeIcon icon={shop.icon} />
                  {shop.title}
                </ShopTitle>
                <ShopDescription>{shop.description}</ShopDescription>
                <InfoItem>
                  <FontAwesomeIcon icon={faClock} />
                  {shop.openTime} - {shop.closeTime}
                </InfoItem>
                <ButtonContainer>
                  <Button onClick={() => navigate(shop.path)}>Visit Shop Page</Button>
                </ButtonContainer>
              </ShopContent>
            </ShopCard>
          ))}
        </OtherShops>
      </ContentContainer>
      
      <Footer />
    </ShopsContainer>
  );
};

export default ShopsOverview; 