import styled from 'styled-components';

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.offWhite};
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: ${({ theme }) => theme.shadows.light};

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    background-color: ${({ theme }) => {
      // Darken the accent color slightly on hover
      const color = theme.colors.accent;
      return `${color}dd`;
    }};
  }

  &:active {
    transform: translateY(0);
  }
`;

export default Button;
