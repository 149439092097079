import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faGift, 
  faTicketAlt, 
  faShoppingCart, 
  faExchangeAlt,
  faArrowRight,
  faLaptop
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';

// Import images from existing assets
import castleImage from '../../../assets/castle.png';
import gardensImage from '../../../assets/gardens.png';
import banner from '../../../assets/banner/roomBan.webp';
const GiftVouchersContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  letter-spacing: 1px;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const OptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 3rem;
  width: 100%;
  max-width: 1200px;
  margin: 3rem auto;
  
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
`;

const OptionCard = styled.div`
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 992px) {
    max-width: 90%;
  }
`;

const OptionImage = styled.div`
  height: 200px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const OptionContent = styled.div`
  padding: 2rem;
  text-align: center;
  color: ${props => props.theme.colors.primary};
`;

const OptionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.primary};
`;

const OptionDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const OptionButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.primary ? props.theme.colors.accent : 'transparent'};
  color: ${props => props.primary ? 'white' : props.theme.colors.accent};
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1rem 2rem;
  border: 2px solid ${props => props.primary ? 'transparent' : props.theme.colors.accent};
  border-radius: 25px;
  text-decoration: none;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${props => props.primary ? 'transparent' : props.theme.colors.accent};
    color: ${props => props.primary ? props.theme.colors.accent : 'white'};
    border-color: ${props => props.theme.colors.accent};
  }
  
  svg {
    margin-left: 10px;
  }
`;

const PurchaseButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.primary ? props.theme.colors.accent : 'transparent'};
  color: ${props => props.primary ? 'white' : props.theme.colors.accent};
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1rem 2rem;
  border: 2px solid ${props => props.primary ? 'transparent' : props.theme.colors.accent};
  border-radius: 25px;
  text-decoration: none;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${props => props.primary ? 'transparent' : props.theme.colors.accent};
    color: ${props => props.primary ? props.theme.colors.accent : 'white'};
    border-color: ${props => props.theme.colors.accent};
  }
  
  svg {
    margin-left: 10px;
  }
`;

const IconWrapper = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.primary ? props.theme.colors.accent : props.theme.colors.primary};
  border-radius: 50%;
  color: white;
  font-size: 36px;
`;

const HighlightSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 5rem 2rem;
  margin: 5rem 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    clip-path: polygon(0 0, 100% 100%, 0 100%);
  }
`;

const HighlightContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const HighlightTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: white;
  margin-bottom: 2rem;
  letter-spacing: 1px;
`;

const HighlightText = styled.p`
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const InfoCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  text-align: center;
`;

const InfoTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary};
  margin: 1rem 0;
`;

const InfoText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 3rem;
`;

const GiftVouchers = () => {
  return (
    <GiftVouchersContainer>
      <ParallaxBanner 
        title="Gift Vouchers"
        subtitle="Give the gift of an unforgettable experience at Belvoir Castle"
        image={banner}
      />
      
      <ContentContainer>
        <SectionTitle>Belvoir Castle Gift Vouchers</SectionTitle>
        <IntroText>
          Looking for the perfect gift? Our gift vouchers offer a unique and memorable experience 
          at one of England's most magnificent historic estates. Choose from a variety of options 
          or select a monetary value that can be used across our range of attractions and services.
        </IntroText>
        
        <OptionsContainer>
          <OptionCard>
            <OptionImage image={gardensImage} />
            <OptionContent>
              <IconWrapper primary>
                <FontAwesomeIcon icon={faShoppingCart} />
              </IconWrapper>
              <OptionTitle>Buy Gift Vouchers</OptionTitle>
              <OptionDescription>
                Purchase a gift voucher for someone special. Choose from a variety of experiences 
                including castle tours, garden visits, afternoon tea, and special events.
              </OptionDescription>
              <PurchaseButton 
                href="https://online1.venpos.net/VenPosJourneyUI/HomePageTileView?id=03c1d84c-a813-405c-bc3b-69afdae9e968&locationId=978&mainWebPageId=03c1d84c-a813-405c-bc3b-69afdae9e968&startId=03c1d84c-a813-405c-bc3b-69afdae9e968"
                primary
              >
                Buy Now <FontAwesomeIcon icon={faArrowRight} />
              </PurchaseButton>
            </OptionContent>
          </OptionCard>
          
          <OptionCard>
            <OptionImage image={castleImage} />
            <OptionContent>
              <IconWrapper>
                <FontAwesomeIcon icon={faExchangeAlt} />
              </IconWrapper>
              <OptionTitle>Redeem Your Voucher</OptionTitle>
              <OptionDescription>
                Already have a gift voucher? Redeem it online to book your visit or experience 
                at Belvoir Castle. Enter your voucher code to get started.
              </OptionDescription>
              <OptionButton to="/voucher-redemption">
                Redeem Now <FontAwesomeIcon icon={faArrowRight} />
              </OptionButton>
            </OptionContent>
          </OptionCard>
        </OptionsContainer>
        
        <HighlightSection>
          <HighlightContent>
            <HighlightTitle>ONLINE REDEMPTION ONLY</HighlightTitle>
            <HighlightText>
              Please note that all Belvoir Castle gift vouchers must be redeemed online prior to your visit. 
              This allows us to ensure your experience is prepared and ready for you upon arrival.
            </HighlightText>
            <IconWrapper style={{ backgroundColor: 'white' }}>
              <FontAwesomeIcon icon={faLaptop} style={{ color: theme.colors.primary }} />
            </IconWrapper>
            <HighlightText>
              The online redemption process is simple and quick. Just enter your voucher code, 
              select your preferred date and time, and you'll receive a confirmation email with all the details.
            </HighlightText>
          </HighlightContent>
        </HighlightSection>
        
        <SectionTitle>About Our Gift Vouchers</SectionTitle>
        <IntroText>
          Our gift vouchers make the perfect present for birthdays, anniversaries, 
          Christmas, or any special occasion. Give the gift of a memorable experience.
        </IntroText>
        
        <InfoGrid>
          <InfoCard>
            <FontAwesomeIcon icon={faGift} size="2x" color={theme.colors.accent} />
            <InfoTitle>Perfect Gift</InfoTitle>
            <InfoText>
              Our gift vouchers make the perfect present for birthdays, anniversaries, 
              Christmas, or any special occasion. Give the gift of a memorable experience.
            </InfoText>
          </InfoCard>
          
          <InfoCard>
            <FontAwesomeIcon icon={faTicketAlt} size="2x" color={theme.colors.accent} />
            <InfoTitle>Flexible Options</InfoTitle>
            <InfoText>
              Choose from a variety of experiences or select a monetary value voucher 
              that can be used across our range of attractions and services.
            </InfoText>
          </InfoCard>
          
          <InfoCard>
            <FontAwesomeIcon icon={faExchangeAlt} size="2x" color={theme.colors.accent} />
            <InfoTitle>Easy to Redeem</InfoTitle>
            <InfoText>
              Our vouchers are easy to redeem online. Simply enter your 
              unique code when booking to secure your preferred date and time.
            </InfoText>
          </InfoCard>
        </InfoGrid>
        
        <ButtonContainer>
          <PurchaseButton 
            href="https://online1.venpos.net/VenPosJourneyUI/HomePageTileView?id=03c1d84c-a813-405c-bc3b-69afdae9e968&locationId=978&mainWebPageId=03c1d84c-a813-405c-bc3b-69afdae9e968&startId=03c1d84c-a813-405c-bc3b-69afdae9e968"
            primary 
            style={{ margin: '0 1rem 1rem 0' }}
          >
            Buy Gift Vouchers <FontAwesomeIcon icon={faArrowRight} />
          </PurchaseButton>
          <OptionButton to="/voucher-redemption" style={{ margin: '0 0 1rem 1rem' }}>
            Redeem Your Voucher <FontAwesomeIcon icon={faArrowRight} />
          </OptionButton>
        </ButtonContainer>
      </ContentContainer>
      
      <Footer />
    </GiftVouchersContainer>
  );
};

export default GiftVouchers;
