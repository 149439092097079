import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCalendarAlt, 
  faMapMarkerAlt, 
  faTicketAlt, 
  faSearch,
  faStar,
  faFilter,
  faChevronRight,
  faListUl,
  faCalendarDay,
  faCalendarPlus,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import LoadingSpinner from '../../common/LoadingSpinner';
import { processEvents, GET_EVENTS_QUERY } from '../../../utils/eventUtils';
import { useWhatsOnSection } from '../../../hooks/useWhatsOnSection';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO, startOfMonth, endOfMonth, eachDayOfInterval, isSameDay, addMonths, subMonths } from 'date-fns';

// Import images
import castleImage from '../../../assets/castle.jpg';
import eventsImage from '../../../assets/activities.jpg';

// Updated GraphQL query for featured events - using a simpler approach
const GET_FEATURED_EVENTS_QUERY = gql`
  query GetFeaturedEvents {
    events(where: {metaQuery: {metaArray: [{key: "event_is_featured", value: "1", compare: EQUAL_TO}]}}, first: 3) {
      nodes {
        id
        title
        date
        featuredImage {
          node {
            sourceUrl
          }
        }
        eventDetails {
          eventLocation
          eventDescription
          eventShortDescription
          eventDate
          eventEndDate
          eventTicketTier
          eventTags
          isFeatured
        }
      }
    }
  }
`;

// Styled components
const EventsContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin-bottom: 3rem;
`;

const FeaturedSection = styled.div`
  margin-bottom: 5rem;
`;

const FeaturedGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const MainFeaturedEvent = styled.div`
  grid-row: span 2;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  position: relative;
  
  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  @media (max-width: 992px) {
    grid-row: span 1;
  }
`;

const FeaturedEvent = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  position: relative;
  
  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
`;

const FeaturedBadge = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: 600;
  display: flex;
  align-items: center;
  z-index: 10;
  
  svg {
    margin-right: 0.5rem;
  }
`;

const EventImage = styled.div`
  height: ${props => props.$main ? '400px' : '250px'};
  background-image: url(${props => props.$image});
  background-size: cover;
  background-position: center;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  }
  
  @media (max-width: 768px) {
    height: ${props => props.$main ? '350px' : '250px'};
  }
`;

const EventContent = styled.div`
  padding: 2rem;
`;

const EventTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: ${props => props.$main ? '2rem' : '1.6rem'};
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
  
  @media (max-width: 768px) {
    font-size: ${props => props.$main ? '1.8rem' : '1.5rem'};
  }
`;

const EventInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.colors.secondary};
  
  svg {
    margin-right: 0.5rem;
    color: ${props => props.theme.colors.accent};
  }
`;

const EventDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin: 1.5rem 0;
  color: ${props => props.theme.colors.primary};
`;

const EventButton = styled.button`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: 2px solid transparent;
  border-radius: 25px;
  padding: 0.8rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  
  svg {
    margin-left: 0.5rem;
  }
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const AddToCalendarButton = styled.button`
  background-color: transparent;
  color: ${props => props.theme.colors.accent};
  border: 2px solid ${props => props.theme.colors.accent};
  border-radius: 25px;
  padding: 0.8rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 0.5rem;
  }
  
  &:hover {
    background-color: ${props => props.theme.colors.accent};
    color: white;
  }
`;

const EventsSection = styled.div`
  margin-bottom: 5rem;
`;

const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  width: 300px;
  
  svg {
    color: ${props => props.theme.colors.secondary};
    margin-right: 0.5rem;
  }
  
  input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FilterDropdown = styled.select`
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  background-color: white;
  cursor: pointer;
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.accent};
  }
`;

const EventsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const EventCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  
  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
`;

const CardImage = styled.div`
  height: 200px;
  background-image: url(${props => props.$image});
  background-size: cover;
  background-position: center;
`;

const CardContent = styled.div`
  padding: 1.5rem;
`;

const CardTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
`;

const CardInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.colors.secondary};
  font-size: 0.9rem;
  
  svg {
    margin-right: 0.5rem;
    color: ${props => props.theme.colors.accent};
  }
`;

const CardDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin: 1rem 0;
  color: ${props => props.theme.colors.primary};
`;

const CardButton = styled.button`
  background-color: transparent;
  color: ${props => props.theme.colors.accent};
  border: 2px solid ${props => props.theme.colors.accent};
  border-radius: 25px;
  padding: 0.6rem 1.2rem;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  
  svg {
    margin-left: 0.5rem;
  }
  
  &:hover {
    background-color: ${props => props.theme.colors.accent};
    color: white;
  }
`;

const LoadMoreButton = styled.button`
  background-color: transparent;
  color: ${props => props.theme.colors.primary};
  border: 2px solid ${props => props.theme.colors.primary};
  border-radius: 25px;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 3rem auto 0;
  display: block;
  
  &:hover {
    background-color: ${props => props.theme.colors.primary};
    color: white;
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const NoEventsMessage = styled.div`
  text-align: center;
  padding: 3rem;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  margin: 2rem 0;
  
  h3 {
    font-family: ${props => props.theme.fonts.main};
    font-size: 1.6rem;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: ${props => props.theme.colors.secondary};
  }
`;

// New styled components for calendar view
const ViewToggleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
`;

const ViewToggleButton = styled.button`
  background-color: ${props => props.$active ? props.theme.colors.accent : 'transparent'};
  color: ${props => props.$active ? 'white' : props.theme.colors.primary};
  border: 2px solid ${props => props.theme.colors.accent};
  border-radius: ${props => props.position === 'left' ? '25px 0 0 25px' : '0 25px 25px 0'};
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 0.5rem;
  }
  
  &:hover {
    background-color: ${props => props.$active ? props.theme.colors.accent : props.theme.colors.greyBackground};
  }
`;

const CalendarContainer = styled.div`
  margin: 2rem 0;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  
  h2 {
    margin: 0;
    font-size: 1.8rem;
    color: #1D3557;
  }
`;

const CalendarControls = styled.div`
  display: flex;
  gap: 0.5rem;
  
  button {
    padding: 0.5rem 1rem;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.2s ease;
    
    &:hover {
      background-color: #e0e0e0;
    }
  }
`;

const CalendarDayContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const EventDot = styled.div`
  position: absolute;
  bottom: -5px;
  right: -5px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${props => {
    if (props.$type === 'castle') return '#3498db';
    if (props.$type === 'garden' || props.$type === 'parkland') return '#2ecc71';
    if (props.$type === 'retail') return '#e74c3c';
    return '#95a5a6'; // default/other
  }};
  color: white;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CalendarLegend = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 4px;
`;

const LegendTitle = styled.h4`
  width: 100%;
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
  color: #333;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #555;
`;

const LegendColor = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
`;

// Add global styles for the DatePicker calendar days
const GlobalStyle = createGlobalStyle`
  .castle-event-day {
    background-color: rgba(52, 152, 219, 0.2) !important;
    border-radius: 50%;
    color: #333 !important;
    font-weight: bold;
    
    &:hover {
      background-color: rgba(52, 152, 219, 0.4) !important;
    }
  }
  
  .parkland-event-day {
    background-color: rgba(139, 195, 74, 0.2) !important;
    border-radius: 50%;
    color: #333 !important;
    font-weight: bold;
    
    &:hover {
      background-color: rgba(139, 195, 74, 0.4) !important;
    }
  }
  
  .garden-event-day {
    background-color: rgba(46, 204, 113, 0.2) !important;
    border-radius: 50%;
    color: #333 !important;
    font-weight: bold;
    
    &:hover {
      background-color: rgba(46, 204, 113, 0.4) !important;
    }
  }
  
  .retail-event-day {
    background-color: rgba(231, 76, 60, 0.2) !important;
    border-radius: 50%;
    color: #333 !important;
    font-weight: bold;
    
    &:hover {
      background-color: rgba(231, 76, 60, 0.4) !important;
    }
  }
  
  .other-event-day {
    background-color: rgba(149, 165, 166, 0.2) !important;
    border-radius: 50%;
    color: #333 !important;
    font-weight: bold;
    
    &:hover {
      background-color: rgba(149, 165, 166, 0.4) !important;
    }
  }
  
  .react-datepicker {
    font-family: 'Montserrat', sans-serif;
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  
  .react-datepicker__month-container {
    width: 100%;
  }
  
  .react-datepicker__day {
    margin: 0.2rem;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 50%;
    
    &:hover {
      background-color: #f0f0f0;
    }
  }
  
  .react-datepicker__day--selected {
    background-color: #1D3557 !important;
    color: white !important;
  }
  
  .react-datepicker__header {
    background-color: #f9f9f9;
    border-bottom: none;
  }
  
  .react-datepicker__day-name {
    width: 2.5rem;
    margin: 0.2rem;
  }
`;

// Update the EventTooltip component
const EventTooltip = styled.div`
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  width: 250px;
  z-index: 100;
  opacity: ${props => props.$visible ? 1 : 0};
  visibility: ${props => props.$visible ? 'visible' : 'hidden'};
  transition: opacity 0.2s ease, visibility 0.2s ease;
  
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
`;

const TooltipTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1rem;
  color: ${props => props.theme.colors.primary};
  margin: 0 0 0.5rem 0;
`;

const TooltipInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 0.3rem;
  
  svg {
    margin-right: 0.5rem;
    color: ${props => props.theme.colors.accent};
  }
`;

const TooltipEventsList = styled.div`
  max-height: 150px;
  overflow-y: auto;
  margin-top: 0.5rem;
`;

const TooltipEvent = styled.div`
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
  
  &:last-child {
    border-bottom: none;
  }
`;

// Update the TooltipViewButton component
const TooltipViewButton = styled.button`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: 2px solid transparent;
  border-radius: 25px;
  padding: 0.4rem 0.8rem;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  cursor: pointer;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

// Add this component after all the styled components and before the Events component
const CalendarDay = ({ day, date, events, handleEventClick }) => {
  const dayRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  
  // Skip rendering if no events
  if (events.length === 0) return day;
  
  // Determine event type for the dot color
  let eventType = 'other';
  if (events[0].location) {
    const location = events[0].location.toLowerCase();
    if (location.includes('castle')) {
      eventType = 'castle';
    } else if (location.includes('garden') || location.includes('parkland')) {
      eventType = 'garden';
    } else if (location.includes('retail') || location.includes('shop')) {
      eventType = 'retail';
    }
  }
  
  // Format the date for display
  const formattedDate = format(date, 'EEEE, MMMM d, yyyy');
  
  // Handle button click without closing tooltip
  const handleButtonClick = (eventId, e) => {
    e.stopPropagation(); // Prevent the click from bubbling up
    handleEventClick(eventId);
  };
  
  return (
    <CalendarDayContent 
      ref={dayRef}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {day}
      {events.length > 0 && (
        <EventDot $type={eventType}>
          {events.length > 1 ? events.length : ''}
        </EventDot>
      )}
      
      <EventTooltip $visible={showTooltip}>
        <TooltipTitle>{formattedDate}</TooltipTitle>
        <TooltipInfo>
          <FontAwesomeIcon icon={faCalendarAlt} />
          {events.length} {events.length === 1 ? 'Event' : 'Events'}
        </TooltipInfo>
        
        <TooltipEventsList>
          {events.map((event, index) => (
            <TooltipEvent key={index}>
              <TooltipTitle>{event.title}</TooltipTitle>
              <TooltipInfo>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                {event.location || 'Belvoir Castle'}
              </TooltipInfo>
              {event.ticketTier && (
                <TooltipInfo>
                  <FontAwesomeIcon icon={faTicketAlt} />
                  {event.ticketTier}
                </TooltipInfo>
              )}
              <TooltipViewButton onClick={(e) => handleButtonClick(event.id, e)}>
                View Details
              </TooltipViewButton>
            </TooltipEvent>
          ))}
        </TooltipEventsList>
      </EventTooltip>
    </CalendarDayContent>
  );
};

const Events = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');
  const [displayedEvents, setDisplayedEvents] = useState([]);
  const [visibleCount, setVisibleCount] = useState(6);
  const [viewMode, setViewMode] = useState('list'); // 'list' or 'calendar'
  const [currentDate, setCurrentDate] = useState(new Date());
  const [normalizedEvents, setNormalizedEvents] = useState([]);
  
  // Use the What's On section hook
  useWhatsOnSection();
  
  // Fetch all events
  const { loading: eventsLoading, error: eventsError, data: eventsData } = useQuery(GET_EVENTS_QUERY);
  
  // Fetch featured events - using the first 3 events as featured
  const { loading: featuredLoading, error: featuredError, data: featuredData } = useQuery(GET_FEATURED_EVENTS_QUERY);
  
  // Process events data when it's loaded
  useEffect(() => {
    if (eventsData && eventsData.events && eventsData.events.nodes) {
      const processedEvents = processEvents(eventsData.events.nodes);
      
      // Apply filters
      let filteredEvents = processedEvents;
      
      if (searchTerm) {
        filteredEvents = filteredEvents.filter(event => 
          event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          event.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      
      if (filter !== 'all') {
        filteredEvents = filteredEvents.filter(event => 
          event.location && event.location.toLowerCase() === filter.toLowerCase()
        );
      }
      
      setDisplayedEvents(filteredEvents);
    }
  }, [eventsData, searchTerm, filter]);
  
  // Update the useEffect for normalizing events
  useEffect(() => {
    if (displayedEvents.length > 0) {
      // Create a copy of events with normalized date properties
      const normalized = displayedEvents.map(event => {
        // Create a copy of the event
        const normalizedEvent = { ...event };
        
        try {
          // Extract the date information
          let eventStartDate = null;
          let eventEndDate = null;
          
          // Check if we have event details with dates
          if (event.eventDetails && event.eventDetails.eventDate) {
            eventStartDate = new Date(event.eventDetails.eventDate);
            
            if (event.eventDetails.eventEndDate) {
              eventEndDate = new Date(event.eventDetails.eventEndDate);
            } else {
              eventEndDate = new Date(eventStartDate); // Same day for single-day events
            }
            
            if (!isNaN(eventStartDate.getTime()) && !isNaN(eventEndDate.getTime())) {
              normalizedEvent.normalizedStartDate = eventStartDate;
              normalizedEvent.normalizedEndDate = eventEndDate;
            }
          }
          
          // If we don't have valid dates from eventDetails, try parsing the date string
          if (!normalizedEvent.normalizedStartDate || !normalizedEvent.normalizedEndDate) {
            // Handle date ranges (e.g., "4 - 13 Mar 2025")
            if (event.date && event.date.includes(' - ')) {
              const dateStr = event.date;
              
              // Extract the start day, end day, month, and year
              const match = dateStr.match(/(\d+)\s*-\s*(\d+)\s+([A-Za-z]+)\s+(\d{4})/);
              
              if (match) {
                const startDay = parseInt(match[1], 10);
                const endDay = parseInt(match[2], 10);
                const month = getMonthNumber(match[3]);
                const year = parseInt(match[4], 10);
                
                if (!isNaN(startDay) && !isNaN(endDay) && month !== -1 && !isNaN(year)) {
                  // Create start and end dates
                  eventStartDate = new Date(year, month, startDay);
                  eventEndDate = new Date(year, month, endDay);
                  
                  if (!isNaN(eventStartDate.getTime()) && !isNaN(eventEndDate.getTime())) {
                    normalizedEvent.normalizedStartDate = eventStartDate;
                    normalizedEvent.normalizedEndDate = eventEndDate;
                  }
                }
              }
            } else if (event.date) {
              // Handle single date
              const dateParts = event.date.split(' ');
              if (dateParts.length >= 3) {
                const day = parseInt(dateParts[0], 10);
                const month = getMonthNumber(dateParts[1]);
                const year = parseInt(dateParts[2], 10);
                
                if (!isNaN(day) && month !== -1 && !isNaN(year)) {
                  eventStartDate = new Date(year, month, day);
                  eventEndDate = new Date(year, month, day);
                  
                  if (!isNaN(eventStartDate.getTime()) && !isNaN(eventEndDate.getTime())) {
                    normalizedEvent.normalizedStartDate = eventStartDate;
                    normalizedEvent.normalizedEndDate = eventEndDate;
                  }
                }
              }
            }
          }
          
          // Add a formatted date string for debugging
          if (normalizedEvent.normalizedStartDate && normalizedEvent.normalizedEndDate) {
            normalizedEvent.normalizedDateStr = format(normalizedEvent.normalizedStartDate, 'yyyy-MM-dd');
            
            if (!isSameDay(normalizedEvent.normalizedStartDate, normalizedEvent.normalizedEndDate)) {
              normalizedEvent.normalizedDateStr += ` to ${format(normalizedEvent.normalizedEndDate, 'yyyy-MM-dd')}`;
            }
          }
          
          return normalizedEvent;
        } catch (error) {
          console.error(`Error normalizing date for event ${event.title}:`, error);
          return normalizedEvent;
        }
      });
      
      setNormalizedEvents(normalized);
    }
  }, [displayedEvents]);
  
  // Log events data when calendar view is active
  useEffect(() => {
    if (viewMode === 'calendar' && displayedEvents.length > 0) {
      console.log('Calendar view active with events:', displayedEvents);
      console.log('Normalized events:', normalizedEvents);
      
      // Log a sample event to help with debugging
      if (displayedEvents[0]) {
        const sampleEvent = displayedEvents[0];
        console.log('Sample event:', {
          title: sampleEvent.title,
          date: sampleEvent.date,
          location: sampleEvent.location,
          parsedDate: (() => {
            try {
              const dateParts = sampleEvent.date.split(' ');
              if (dateParts.length >= 3) {
                const eventDay = parseInt(dateParts[0], 10);
                const eventMonth = getMonthNumber(dateParts[1]);
                const eventYear = parseInt(dateParts[2], 10);
                
                if (!isNaN(eventDay) && eventMonth !== -1 && !isNaN(eventYear)) {
                  return new Date(eventYear, eventMonth, eventDay).toISOString();
                }
              }
              return 'Failed to parse date';
            } catch (e) {
              return `Error: ${e.message}`;
            }
          })()
        });
        
        // Test the getEventsForDay function with today's date
        const today = new Date();
        const eventsToday = getEventsForDay(today);
        console.log(`Events for today (${today.toDateString()}):`, eventsToday.length);
      }
    }
  }, [viewMode, displayedEvents, normalizedEvents]);
  
  // Update calendar view when currentDate changes
  useEffect(() => {
    if (viewMode === 'calendar') {
      console.log(`Calendar view updated for ${format(currentDate, 'MMMM yyyy')}`);
      
      // Count events for the current month
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      const eventsThisMonth = normalizedEvents.filter(event => {
        if (event.normalizedStartDate) {
          const eventDate = new Date(event.normalizedStartDate);
          return eventDate.getMonth() === currentMonth && eventDate.getFullYear() === currentYear;
        }
        return false;
      });
      
      console.log(`Found ${eventsThisMonth.length} events for ${format(currentDate, 'MMMM yyyy')}`);
    }
  }, [currentDate, viewMode, normalizedEvents]);
  
  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setVisibleCount(6); // Reset visible count when search changes
  };
  
  // Handle filter change
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setVisibleCount(6); // Reset visible count when filter changes
  };
  
  // Handle load more button click
  const handleLoadMore = () => {
    setVisibleCount(prevCount => prevCount + 6);
  };
  
  // Handle event click
  const handleEventClick = (eventId) => {
    navigate(`/event/${eventId}`);
  };
  
  // Process featured events
  const featuredEvents = featuredData && featuredData.events && featuredData.events.nodes
    ? processEvents(featuredData.events.nodes)
    : [];
  
  // Update the process featured events section to respect the isFeatured flag
  // Replace the effectiveFeaturedEvents constant with this:
  const effectiveFeaturedEvents = featuredEvents.length > 0
    ? featuredEvents
    : displayedEvents.filter(event => event.eventDetails && event.eventDetails.isFeatured).slice(0, 3);
  
  // If still no featured events, use the first 3 regular events as a fallback
  const finalFeaturedEvents = effectiveFeaturedEvents.length > 0
    ? effectiveFeaturedEvents
    : displayedEvents.slice(0, 3);
  
  // Calendar navigation functions
  const goToPreviousMonth = () => {
    setCurrentDate(prevDate => subMonths(prevDate, 1));
  };
  
  const goToNextMonth = () => {
    setCurrentDate(prevDate => addMonths(prevDate, 1));
  };
  
  // Function to generate calendar days
  const generateCalendarDays = () => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(currentDate);
    const startDate = new Date(monthStart);
    startDate.setDate(startDate.getDate() - startDate.getDay()); // Start from Sunday
    
    const endDate = new Date(monthEnd);
    const daysToAdd = 6 - endDate.getDay();
    endDate.setDate(endDate.getDate() + daysToAdd); // End on Saturday
    
    const days = eachDayOfInterval({ start: startDate, end: endDate });
    
    return days;
  };
  
  // Update the getEventsForDay function to handle the case where only end date is available
  const getEventsForDay = (day) => {
    if (!day || normalizedEvents.length === 0) return [];
    
    return normalizedEvents.filter(event => {
      try {
        // If we have both start and end dates, check if day is in range
        if (event.normalizedStartDate && event.normalizedEndDate) {
          const startDate = new Date(event.normalizedStartDate);
          const endDate = new Date(event.normalizedEndDate);
          
          // Add one day to endDate to make the range inclusive
          endDate.setDate(endDate.getDate() + 1);
          
          return day >= startDate && day < endDate;
        }
        
        // If we only have end date (based on the debug info)
        if (!event.normalizedStartDate && event.normalizedEndDate) {
          const endDate = new Date(event.normalizedEndDate);
          return isSameDay(day, endDate);
        }
        
        return false;
      } catch (error) {
        console.error(`Error checking event date for ${event.title}:`, error);
        return false;
      }
    });
  };
  
  // Helper function to convert month abbreviation to number (0-11)
  const getMonthNumber = (monthAbbr) => {
    if (!monthAbbr) return -1;
    
    const months = {
      'Jan': 0, 'Feb': 1, 'Mar': 2, 'Apr': 3, 'May': 4, 'Jun': 5,
      'Jul': 6, 'Aug': 7, 'Sep': 8, 'Oct': 9, 'Nov': 10, 'Dec': 11,
      // Add full month names for better compatibility
      'January': 0, 'February': 1, 'March': 2, 'April': 3, 'May': 4, 'June': 5,
      'July': 6, 'August': 7, 'September': 8, 'October': 9, 'November': 10, 'December': 11
    };
    
    // Handle case insensitivity
    const normalizedMonth = monthAbbr.charAt(0).toUpperCase() + monthAbbr.slice(1).toLowerCase();
    return months[normalizedMonth] !== undefined ? months[normalizedMonth] : -1;
  };
  
  // Function to generate an .ics file for adding to calendar
  const addToCalendar = (event) => {
    // Parse the event date
    const dateParts = event.date.split(' ');
    if (dateParts.length < 3) return;
    
    const eventDay = parseInt(dateParts[0], 10);
    const eventMonth = getMonthNumber(dateParts[1]);
    const eventYear = parseInt(dateParts[2], 10);
    
    if (isNaN(eventDay) || isNaN(eventMonth) || isNaN(eventYear)) return;
    
    const eventDate = new Date(eventYear, eventMonth, eventDay);
    const endDate = new Date(eventDate);
    endDate.setHours(eventDate.getHours() + 2); // Assume 2-hour event if no end time
    
    // Format dates for .ics file
    const formatDate = (date) => {
      return date.toISOString().replace(/-|:|\.\d+/g, '');
    };
    
    const icsContent = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'CALSCALE:GREGORIAN',
      'PRODID:-//Belvoir Castle//Events Calendar//EN',
      'METHOD:PUBLISH',
      'BEGIN:VEVENT',
      `SUMMARY:${event.title}`,
      `DTSTART:${formatDate(eventDate)}`,
      `DTEND:${formatDate(endDate)}`,
      `LOCATION:${event.location || 'Belvoir Castle'}`,
      `DESCRIPTION:${event.description.replace(/\n/g, '\\n')}`,
      `URL:https://belvoircastle.com/event/${event.id}`,
      'STATUS:CONFIRMED',
      `UID:${event.id}@belvoircastle.com`,
      'END:VEVENT',
      'END:VCALENDAR'
    ].join('\r\n');
    
    // Create and download the .ics file
    const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${event.title.replace(/\s+/g, '-').toLowerCase()}.ics`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  // Function to share event to device calendar using Web Share API (for mobile)
  const shareEvent = (event) => {
    if (navigator.share) {
      navigator.share({
        title: event.title,
        text: `${event.title} at ${event.location || 'Belvoir Castle'} on ${event.date}`,
        url: `https://belvoircastle.com/event/${event.id}`
      })
      .catch(error => console.log('Error sharing:', error));
    } else {
      // Fallback to .ics download if Web Share API is not available
      addToCalendar(event);
    }
  };
  
  // Render calendar view
  const renderCalendarView = () => {
    // Define theme colors for the calendar legend
    const themeColors = {
      castle: '#3498db',
      parkland: '#8bc34a', // Light green for parkland
      garden: '#2ecc71',
      retail: '#e74c3c',
      other: '#95a5a6'
    };
    
    // Function to debug events
    const debugEvents = () => {
      console.log('===== EVENT DEBUG INFO =====');
      console.log(`Total events in displayedEvents: ${displayedEvents.length}`);
      console.log(`Total events in normalizedEvents: ${normalizedEvents.length}`);
      
      // Count events by location
      const debugEventsByLocation = {
        castle: 0,
        parkland: 0,
        garden: 0,
        retail: 0,
        other: 0
      };
      
      // Count normalized events with valid dates
      let eventsWithValidDates = 0;
      
      normalizedEvents.forEach(event => {
        // Count by location
        if (event.location) {
          const location = event.location.toLowerCase();
          if (location.includes('castle')) {
            debugEventsByLocation.castle++;
          } else if (location.includes('parkland')) {
            debugEventsByLocation.parkland++;
          } else if (location.includes('garden')) {
            debugEventsByLocation.garden++;
          } else if (location.includes('retail') || location.includes('shop')) {
            debugEventsByLocation.retail++;
          } else {
            debugEventsByLocation.other++;
          }
        } else {
          debugEventsByLocation.other++;
        }
        
        // Count events with valid normalized dates
        if (event.normalizedStartDate && event.normalizedEndDate) {
          eventsWithValidDates++;
        }
      });
      
      console.log('Events by location:', debugEventsByLocation);
      console.log(`Events with valid normalized dates: ${eventsWithValidDates}`);
      
      // Check events for the current month
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      const eventsThisMonth = normalizedEvents.filter(event => {
        if (event.normalizedStartDate) {
          const eventDate = new Date(event.normalizedStartDate);
          return eventDate.getMonth() === currentMonth && eventDate.getFullYear() === currentYear;
        }
        return false;
      });
      
      console.log(`Events for ${format(currentDate, 'MMMM yyyy')}: ${eventsThisMonth.length}`);
      
      // Log the first 3 events with their normalized dates
      console.log('Sample events with normalized dates:');
      normalizedEvents.slice(0, 3).forEach((event, index) => {
        console.log(`Event ${index + 1}: ${event.title}`);
        console.log(`  Original date: ${event.date}`);
        console.log(`  Normalized date: ${event.normalizedDateStr || 'Failed to normalize'}`);
        console.log(`  Start date: ${event.normalizedStartDate ? format(new Date(event.normalizedStartDate), 'yyyy-MM-dd') : 'N/A'}`);
        console.log(`  End date: ${event.normalizedEndDate ? format(new Date(event.normalizedEndDate), 'yyyy-MM-dd') : 'N/A'}`);
      });
      
      console.log('===== END DEBUG INFO =====');
    };
    
    // Count events by location using normalized events for the current month
    const eventsByLocation = {
      castle: 0,
      parkland: 0,
      garden: 0,
      retail: 0,
      other: 0
    };
    
    // Only count events for the current month
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    
    // Improved counting logic that checks both start and end dates
    normalizedEvents.forEach(event => {
      // Skip events without normalized dates
      if (!event.normalizedStartDate && !event.normalizedEndDate) return;
      
      // Check if event occurs in the current month
      let isInCurrentMonth = false;
      
      if (event.normalizedStartDate) {
        const startDate = new Date(event.normalizedStartDate);
        // If start date is in current month/year
        if (startDate.getMonth() === currentMonth && startDate.getFullYear() === currentYear) {
          isInCurrentMonth = true;
        }
      }
      
      if (!isInCurrentMonth && event.normalizedEndDate) {
        const endDate = new Date(event.normalizedEndDate);
        // If end date is in current month/year
        if (endDate.getMonth() === currentMonth && endDate.getFullYear() === currentYear) {
          isInCurrentMonth = true;
        }
      }
      
      // If event spans multiple months, check if current month is within range
      if (!isInCurrentMonth && event.normalizedStartDate && event.normalizedEndDate) {
        const startDate = new Date(event.normalizedStartDate);
        const endDate = new Date(event.normalizedEndDate);
        
        // Create a date for the first day of the current month
        const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
        // Create a date for the last day of the current month
        const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
        
        // Check if any part of the event falls within the current month
        if (
          (startDate <= lastDayOfMonth && endDate >= firstDayOfMonth) ||
          (startDate.getMonth() < currentMonth && endDate.getMonth() > currentMonth && 
           startDate.getFullYear() <= currentYear && endDate.getFullYear() >= currentYear)
        ) {
          isInCurrentMonth = true;
        }
      }
      
      // If the event is in the current month, count it by location
      if (isInCurrentMonth) {
        if (event.location) {
          const location = event.location.toLowerCase();
          if (location.includes('castle')) {
            eventsByLocation.castle++;
          } else if (location.includes('parkland')) {
            eventsByLocation.parkland++;
          } else if (location.includes('garden')) {
            eventsByLocation.garden++;
          } else if (location.includes('retail') || location.includes('shop')) {
            eventsByLocation.retail++;
          } else {
            eventsByLocation.other++;
          }
        } else {
          eventsByLocation.other++;
        }
      }
    });
    
    return (
      <CalendarContainer>
        <CalendarHeader>
          <h2>Events Calendar</h2>
          <CalendarControls>
            <button onClick={() => setCurrentDate(new Date())}>Today</button>
            <button onClick={() => {
              const newDate = new Date(currentDate);
              newDate.setMonth(currentDate.getMonth() - 1);
              setCurrentDate(newDate);
            }}>Previous Month</button>
            <button onClick={() => {
              const newDate = new Date(currentDate);
              newDate.setMonth(currentDate.getMonth() + 1);
              setCurrentDate(newDate);
            }}>Next Month</button>
            <button onClick={debugEvents} style={{ marginLeft: '10px', background: '#f39c12' }}>
              Debug Events
            </button>
          </CalendarControls>
        </CalendarHeader>
        
        <DatePicker
          selected={currentDate}
          onChange={date => setCurrentDate(date)}
          inline
          monthsShown={1}
          dayClassName={date => {
            const events = getEventsForDay(date);
            if (events.length > 0) {
              // Determine the color based on the first event's location
              const firstEvent = events[0];
              if (firstEvent.location) {
                const location = firstEvent.location.toLowerCase();
                if (location.includes('castle')) {
                  return 'castle-event-day';
                } else if (location.includes('parkland')) {
                  return 'parkland-event-day';
                } else if (location.includes('garden')) {
                  return 'garden-event-day';
                } else if (location.includes('retail') || location.includes('shop')) {
                  return 'retail-event-day';
                }
              }
              return 'other-event-day';
            }
            return null;
          }}
          renderDayContents={(day, date) => {
            const events = getEventsForDay(date);
            if (events.length === 0) return day;
            
            return <CalendarDay 
              day={day} 
              date={date} 
              events={events} 
              handleEventClick={handleEventClick} 
            />;
          }}
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
            <div style={{ margin: '0 10px', display: 'flex', justifyContent: 'center' }}>
              <h3>{format(date, 'MMMM yyyy')}</h3>
            </div>
          )}
        />
        
        <CalendarLegend>
          <LegendTitle>Event Types</LegendTitle>
          <LegendItem>
            <LegendColor style={{ backgroundColor: themeColors.castle }} />
            <span>Castle Events ({eventsByLocation.castle})</span>
          </LegendItem>
          <LegendItem>
            <LegendColor style={{ backgroundColor: themeColors.parkland }} />
            <span>Parkland Events ({eventsByLocation.parkland})</span>
          </LegendItem>
          <LegendItem>
            <LegendColor style={{ backgroundColor: themeColors.garden }} />
            <span>Garden Events ({eventsByLocation.garden})</span>
          </LegendItem>
          <LegendItem>
            <LegendColor style={{ backgroundColor: themeColors.retail }} />
            <span>Retail Village Events ({eventsByLocation.retail})</span>
          </LegendItem>
          <LegendItem>
            <LegendColor style={{ backgroundColor: themeColors.other }} />
            <span>Other Events ({eventsByLocation.other})</span>
          </LegendItem>
        </CalendarLegend>
      </CalendarContainer>
    );
  };
  
  // Loading state
  if (eventsLoading && !eventsData) {
    return (
      <EventsContainer>
        <ParallaxBanner 
          title="Events at Belvoir Castle"
          subtitle="Discover What's Happening Throughout the Year"
          backgroundImage={castleImage}
        />
        <ContentContainer>
          <LoadingSpinner />
        </ContentContainer>
        <Footer />
      </EventsContainer>
    );
  }
  
  // Error state - only show error if both queries fail
  if (eventsError && featuredError) {
    console.error("Error loading events:", eventsError || featuredError);
    return (
      <EventsContainer>
        <ParallaxBanner 
          title="Events at Belvoir Castle"
          subtitle="Discover What's Happening Throughout the Year"
          backgroundImage={castleImage}
        />
        <ContentContainer>
          <NoEventsMessage>
            <h3>Error Loading Events</h3>
            <p>We're sorry, but there was an error loading the events. Please try again later.</p>
          </NoEventsMessage>
        </ContentContainer>
        <Footer />
      </EventsContainer>
    );
  }
  
  return (
    <EventsContainer>
      <GlobalStyle />
      <ParallaxBanner 
        title="Events at Belvoir Castle"
        subtitle="Discover What's Happening Throughout the Year"
        backgroundImage={castleImage}
      />
      
      <ContentContainer>
        <FeaturedSection>
          <SectionTitle>Featured Events</SectionTitle>
          <IntroText>
            Discover our highlighted events at Belvoir Castle. From seasonal celebrations to special exhibitions, 
            there's always something exciting happening throughout the year.
          </IntroText>
          
          {finalFeaturedEvents.length > 0 ? (
            <FeaturedGrid>
              {/* Main featured event */}
              <MainFeaturedEvent>
                <FeaturedBadge>
                  <FontAwesomeIcon icon={faStar} />
                  Featured
                </FeaturedBadge>
                <EventImage 
                  $main={true} 
                  $image={finalFeaturedEvents[0].image || eventsImage} 
                />
                <EventContent>
                  <EventTitle $main={true}>{finalFeaturedEvents[0].title}</EventTitle>
                  <EventInfo>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    {finalFeaturedEvents[0].date}
                  </EventInfo>
                  <EventInfo>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    {finalFeaturedEvents[0].location || 'Belvoir Castle'}
                  </EventInfo>
                  <EventDescription>
                    {finalFeaturedEvents[0].description}
                  </EventDescription>
                  <div style={{ display: 'flex', gap: '1rem' }}>
                    <EventButton onClick={() => handleEventClick(finalFeaturedEvents[0].id)}>
                      View Event Details
                      <FontAwesomeIcon icon={faChevronRight} />
                    </EventButton>
                    <AddToCalendarButton onClick={() => shareEvent(finalFeaturedEvents[0])}>
                      <FontAwesomeIcon icon={faCalendarPlus} />
                      Add to Calendar
                    </AddToCalendarButton>
                  </div>
                </EventContent>
              </MainFeaturedEvent>
              
              {/* Secondary featured events */}
              {finalFeaturedEvents.slice(1, 3).map((event, index) => (
                <FeaturedEvent key={index}>
                  <FeaturedBadge>
                    <FontAwesomeIcon icon={faStar} />
                    Featured
                  </FeaturedBadge>
                  <EventImage $image={event.image || eventsImage} />
                  <EventContent>
                    <EventTitle>{event.title}</EventTitle>
                    <EventInfo>
                      <FontAwesomeIcon icon={faCalendarAlt} />
                      {event.date}
                    </EventInfo>
                    <EventInfo>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                      {event.location || 'Belvoir Castle'}
                    </EventInfo>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                      <EventButton onClick={() => handleEventClick(event.id)}>
                        View Details
                        <FontAwesomeIcon icon={faChevronRight} />
                      </EventButton>
                      <AddToCalendarButton onClick={() => shareEvent(event)}>
                        <FontAwesomeIcon icon={faCalendarPlus} />
                      </AddToCalendarButton>
                    </div>
                  </EventContent>
                </FeaturedEvent>
              ))}
            </FeaturedGrid>
          ) : (
            <NoEventsMessage>
              <h3>No Featured Events</h3>
              <p>Check back soon for upcoming featured events at Belvoir Castle.</p>
            </NoEventsMessage>
          )}
        </FeaturedSection>
        
        <EventsSection>
          <SectionTitle>Upcoming Events</SectionTitle>
          
          <FilterSection>
            <SearchBar>
              <FontAwesomeIcon icon={faSearch} />
              <input 
                type="text" 
                placeholder="Search events..." 
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </SearchBar>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <div>
                <FontAwesomeIcon icon={faFilter} style={{ marginRight: '0.5rem' }} />
                <FilterDropdown value={filter} onChange={handleFilterChange}>
                  <option value="all">All Locations</option>
                  <option value="castle">Castle</option>
                  <option value="parkland">Parkland</option>
                  <option value="gardens">Gardens</option>
                  <option value="retail village">Retail Village</option>
                </FilterDropdown>
              </div>
              
              <ViewToggleContainer>
                <ViewToggleButton 
                  $active={viewMode === 'list'} 
                  position="left"
                  onClick={() => setViewMode('list')}
                >
                  <FontAwesomeIcon icon={faListUl} />
                  List
                </ViewToggleButton>
                <ViewToggleButton 
                  $active={viewMode === 'calendar'} 
                  position="right"
                  onClick={() => setViewMode('calendar')}
                >
                  <FontAwesomeIcon icon={faCalendarDay} />
                  Calendar
                </ViewToggleButton>
              </ViewToggleContainer>
            </div>
          </FilterSection>
          
          {viewMode === 'calendar' ? (
            renderCalendarView()
          ) : displayedEvents.length > 0 ? (
            <>
              <EventsGrid>
                {displayedEvents.slice(0, visibleCount).map((event, index) => (
                  <EventCard key={index}>
                    <CardImage $image={event.image || eventsImage} />
                    <CardContent>
                      <CardTitle>{event.title}</CardTitle>
                      <CardInfo>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                        {event.date}
                      </CardInfo>
                      <CardInfo>
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                        {event.location || 'Belvoir Castle'}
                      </CardInfo>
                      {event.ticketTier && (
                        <CardInfo>
                          <FontAwesomeIcon icon={faTicketAlt} />
                          {event.ticketTier}
                        </CardInfo>
                      )}
                      <CardDescription>
                        {event.description}
                      </CardDescription>
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <CardButton onClick={() => handleEventClick(event.id)}>
                          View Details
                          <FontAwesomeIcon icon={faChevronRight} />
                        </CardButton>
                        <CardButton onClick={() => shareEvent(event)}>
                          <FontAwesomeIcon icon={faCalendarPlus} />
                        </CardButton>
                      </div>
                    </CardContent>
                  </EventCard>
                ))}
              </EventsGrid>
              
              {visibleCount < displayedEvents.length && (
                <LoadMoreButton onClick={handleLoadMore}>
                  Load More Events
                </LoadMoreButton>
              )}
            </>
          ) : (
            <NoEventsMessage>
              <h3>No Events Found</h3>
              <p>
                {searchTerm || filter !== 'all' 
                  ? 'No events match your search criteria. Try adjusting your filters.' 
                  : 'Check back soon for upcoming events at Belvoir Castle.'}
              </p>
            </NoEventsMessage>
          )}
        </EventsSection>
      </ContentContainer>
      
      <Footer />
    </EventsContainer>
  );
};

export default Events; 