import React from 'react';
import { useQuery } from '@apollo/client';
import Hero from '../../common/Hero';
import ImageSlider from '../../common/Image-slider';
import styled from 'styled-components';
import castleImage from '../../../assets/castle.png';
import gardensImage from '../../../assets/gardens.png';
import retailImage from '../../../assets/retail.png';
import playgroundImage from '../../../assets/play.png';
import NewsletterSignUp from '../../common/Newsletter-sign';
import ColumnX2 from '../../common/Column-x2';
import stayVideo from '../../../assets/stay.mp4';
import ImageSlant from '../../common/image-slant';
import Footer from '../../Footer/Footer';
import { gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBed, 
  faHome, 
  faCampground, 
  faWifi, 
  faParking, 
  faUtensils,
  faSwimmingPool,
  faSpa,
  faHiking,
  faGlassCheers,
  faCamera,
  faHotTub,
  faPray,
  faDice,
  faUsers,
  faSearch,
  faHeart,
  faBowArrow,
  faWineGlassAlt,
  faBasketShopping,
  faMicrophone,
  faDragon
} from '@fortawesome/free-solid-svg-icons';
import { useStaySection } from '../../../hooks/useStaySection';
import ValeHouseImage from '../../../assets/holidays/vale-house.webp';
import CottagesImage from '../../../assets/holidays/lodges.webp';
import GlampingImage from '../../../assets/holidays/glamping.webp';
import CroxtonParkImage from '../../../assets/holidays/croxton1.webp';


const HomeContainer = styled.div`
  min-height: 100vh;
  padding-top: 120px;
`;

const HomeSlider = styled(ImageSlider)`
  @media (max-width: 768px) {
    margin-top: 7rem;
  }
`;

const StyledNewsletterSignUp = styled(NewsletterSignUp)`
  margin: 30px 0;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  padding: 4rem 2rem;
  background-color: ${props => props.theme.colors.greyBackground};
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  text-align: center;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const FeatureIcon = styled.div`
  width: 60px;
  height: 60px;
  margin: 0 auto 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  
  svg {
    color: white;
    font-size: 24px;
  }
`;

const FeatureTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
`;

const FeatureDescription = styled.p`
  font-size: 0.9rem;
  color: ${props => props.theme.colors.text};
  line-height: 1.5;
`;

const AccommodationTypes = styled.div`
  padding: 4rem 2rem;
  background: white;
`;

const TypesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const TypeCard = styled.div`
  position: relative;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  
  &:hover {
    .overlay {
      background: rgba(0, 0, 0, 0.3);
    }
  }
`;

const TypeImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const TypeOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 2rem;
  transition: background 0.3s ease;
`;

const TypeTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const TypeDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const TypeButton = styled.button`
  background: ${props => props.theme.colors.accent};
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
  
  &:hover {
    background: ${props => props.theme.colors.primary};
  }
`;

const GET_STAY_SETTINGS = gql`
  query GetSiteSettings {
    siteSettings(where: {title: "Global Site Settings"}) {
      nodes {
        title
        siteSettings {
          staySection {
            heroSection {
              welcomeText
              mainTitle
              subtitle
              heroVideo {
                node {
                  sourceUrl
                }
              }
            }
            infoColumns {
              column1 {
                title
                days
                times
                note
              }
              column2 {
                title
                days
                times
                note
              }
              column3 {
                title
                days
                times
                note
              }
            }
            sliderContent {
              slide1 {
                title
                image {
                  node {
                    sourceUrl
                  }
                }
              }
              slide2 {
                title
                image {
                  node {
                    sourceUrl
                  }
                }
              }
              slide3 {
                title
                image {
                  node {
                    sourceUrl
                  }
                }
              }
              slide4 {
                title
                image {
                  node {
                    sourceUrl
                  }
                }
              }
            }
            columnX2Section {
              title
              description
              buttonText
            }
            imageSlantSections {
              title
              description
              buttonText
              imagePosition
              image {
                node {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Stay = () => {
  const { loading: settingsLoading, error: settingsError, data: settingsData } = useQuery(GET_STAY_SETTINGS);
  const navigate = useNavigate();
  const propertiesRef = React.useRef(null);
  useStaySection();
  
  // Get the settings data
  const staySettings = settingsData?.siteSettings?.nodes?.[0]?.siteSettings?.staySection || {};

  const scrollToProperties = () => {
    propertiesRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Update default slider content with proper navigation
  const defaultSliderContent = [
    {
      image: ValeHouseImage,
      title: "Vale House",
      action: () => navigate('/accommodations/vale-house')
    },
    {
      image: CottagesImage,
      title: "Holiday Cottages",
      action: () => navigate('/accommodations/cottages')
    },
    {
      image: GlampingImage,
      title: "Glamping",
      action: () => navigate('/accommodations/glamping')
    },
    {
      image: playgroundImage,
      title: "Experiences",
      action: () => navigate('/experiences')
    }
  ];

  // Update slider content actions
  const sliderContent = staySettings.sliderContent
    ? [
        staySettings.sliderContent.slide1 && {
          image: staySettings.sliderContent.slide1.image?.node?.sourceUrl || castleImage,
          title: staySettings.sliderContent.slide1.title || "Vale House",
          action: () => navigate('/accommodations/vale-house')
        },
        staySettings.sliderContent.slide2 && {
          image: staySettings.sliderContent.slide2.image?.node?.sourceUrl || gardensImage,
          title: staySettings.sliderContent.slide2.title || "Holiday Cottages",
          action: () => navigate('/accommodations/cottages')
        },
        staySettings.sliderContent.slide3 && {
          image: staySettings.sliderContent.slide3.image?.node?.sourceUrl || retailImage,
          title: staySettings.sliderContent.slide3.title || "Glamping",
          action: () => navigate('/accommodations/glamping')
        },
        staySettings.sliderContent.slide4 && {
          image: staySettings.sliderContent.slide4.image?.node?.sourceUrl || playgroundImage,
          title: staySettings.sliderContent.slide4.title || "Experiences",
          action: () => navigate('/experiences')
        }
      ].filter(Boolean)
    : defaultSliderContent;

  // Update hero buttons with proper navigation
  const defaultHeroInfo = {
    videoSrc: stayVideo,
    welcomeText: "Welcome to",
    mainTitle: "Belvoir Holidays",
    subtitle: "LUXURY HOLIDAY ACCOMMODATION IN THE VALE OF BELVOIR",
    buttons: [
      {
        text: "View Properties",
        onClick: scrollToProperties
      },
      {
        text: "Book Now",
        onClick: () => navigate('/booking')
      }
    ],
    infoColumns: [
      {
        title: "Contact",
        items: ["07917 910 131", "holidays@belvoircastle.com"]
      },
      {
        title: "Location",
        items: ["Vale of Belvoir", "Stunning Castle Views"]
      },
      {
        title: "Experience",
        items: ["Unique Stays", "Family Friendly"]
      }
    ]
  };

  // Use settings data for hero info if available
  const heroInfo = {
    videoSrc: staySettings.heroSection?.heroVideo?.node?.sourceUrl || stayVideo,
    welcomeText: staySettings.heroSection?.welcomeText || "Welcome to",
    mainTitle: staySettings.heroSection?.mainTitle || "Belvoir Holidays",
    subtitle: staySettings.heroSection?.subtitle || "LUXURY HOLIDAY ACCOMMODATION IN THE VALE OF BELVOIR",
    buttons: defaultHeroInfo.buttons,
    infoColumns: staySettings.infoColumns
      ? [
          staySettings.infoColumns.column1 && {
            title: staySettings.infoColumns.column1.title,
            items: [staySettings.infoColumns.column1.days, staySettings.infoColumns.column1.times],
            note: staySettings.infoColumns.column1.note
          },
          staySettings.infoColumns.column2 && {
            title: staySettings.infoColumns.column2.title,
            items: [staySettings.infoColumns.column2.days, staySettings.infoColumns.column2.times],
            note: staySettings.infoColumns.column2.note
          },
          staySettings.infoColumns.column3 && {
            title: staySettings.infoColumns.column3.title,
            items: [staySettings.infoColumns.column3.days, staySettings.infoColumns.column3.times],
            note: staySettings.infoColumns.column3.note
          }
        ].filter(Boolean)
      : defaultHeroInfo.infoColumns
  };

  // Get column x2 section data from settings
  const columnX2Title = staySettings.columnX2Section?.title || "Experience Luxury Living";
  const columnX2Description = staySettings.columnX2Section?.description || 
    "Immerse yourself in the grandeur of Belvoir Castle with our carefully curated selection of accommodations. From elegant rooms to cozy cottages and unique glamping experiences, find your perfect stay in our historic surroundings.";
  const columnX2ButtonText = staySettings.columnX2Section?.buttonText || "View Accommodations";

  // Get image slant sections from settings
  const imageSlantSections = staySettings.imageSlantSections?.length > 0
    ? staySettings.imageSlantSections
    : [
        {
          title: "Luxury Rooms",
          description: "Experience the grandeur of Belvoir Castle with our carefully appointed rooms, each offering unique views and historic charm.",
          buttonText: "View Rooms",
          imagePosition: "left",
          image: { node: { sourceUrl: castleImage } }
        },
        {
          title: "Cottages & Glamping",
          description: "Choose from our selection of cozy cottages or unique glamping experiences, perfect for families and couples seeking a different kind of stay.",
          buttonText: "View Options",
          imagePosition: "right",
          image: { node: { sourceUrl: gardensImage } }
        }
      ];

  return (
    <HomeContainer>
      <Hero {...heroInfo} />
      <HomeSlider slides={sliderContent} />
      
      <AccommodationTypes ref={propertiesRef}>
        <ColumnX2 
          title="Welcome to Belvoir Holidays"
          description="The 11th Duke and Duchess of Rutland have lovingly curated a selection of holiday properties to suit the needs of every traveller. From the historic Vale House in the Engine Yard to our beautiful cottages in Croxton Park, and our unique glamping and camping experiences, discover your perfect stay in the Vale of Belvoir."
          buttonText="View Properties"
          buttonAction={scrollToProperties}
        />
        
        <FeaturesGrid>
          <FeatureCard onClick={() => navigate('/accommodations/vale-house')}>
            <FeatureIcon>
              <FontAwesomeIcon icon={faHome} />
            </FeatureIcon>
            <FeatureTitle>Vale House</FeatureTitle>
            <FeatureDescription>Luxury accommodation in the historic Engine Yard</FeatureDescription>
          </FeatureCard>
          
          <FeatureCard onClick={() => navigate('/accommodations/cottages')}>
            <FeatureIcon>
              <FontAwesomeIcon icon={faCampground} />
            </FeatureIcon>
            <FeatureTitle>Holiday Cottages</FeatureTitle>
            <FeatureDescription>Beautiful properties in Croxton Park and beyond</FeatureDescription>
          </FeatureCard>
          
          <FeatureCard onClick={() => navigate('/experiences')}>
            <FeatureIcon>
              <FontAwesomeIcon icon={faGlassCheers} />
            </FeatureIcon>
            <FeatureTitle>Experiences</FeatureTitle>
            <FeatureDescription>From dining to activities, create unforgettable moments</FeatureDescription>
          </FeatureCard>

          <FeatureCard onClick={() => navigate('/about/gardens')}>
            <FeatureIcon>
              <FontAwesomeIcon icon={faHiking} />
            </FeatureIcon>
            <FeatureTitle>Explore</FeatureTitle>
            <FeatureDescription>Capability Brown's landscape and formal gardens</FeatureDescription>
          </FeatureCard>
        </FeaturesGrid>
      </AccommodationTypes>

      <div style={{ margin: '80px 0' }}>
        <NewsletterSignUp />
      </div>
      
      <ImageSlant 
        image={ValeHouseImage}
        title="Vale House"
        description="Experience luxury living in the historic Engine Yard, with stunning views of Belvoir Castle and modern amenities."
        buttonText="View Vale House"
        buttonAction={() => navigate('/accommodations/vale-house')}
        imagePosition="left"
      />
      
      <ImageSlant 
        image={CroxtonParkImage}
        title="Holiday Cottages"
        description="Discover our collection of beautiful cottages in Croxton Park, offering the perfect blend of comfort and character."
        buttonText="View Cottages"
        buttonAction={() => navigate('/accommodations/cottages')}
        imagePosition="right"
      />
      
      <Footer />
    </HomeContainer>
  );
};

export default Stay; 