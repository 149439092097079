import React from 'react';
import styled from 'styled-components';

const Container = styled.section`
  background: white;
  padding: 4rem 0;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 6rem;
  padding: 0 2rem;
  flex-direction: ${props => props.imagePosition === 'right' ? 'row-reverse' : 'row'};

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

const ImageContainer = styled.div`
  flex: 1.2;
  filter: drop-shadow(0 10px 15px rgba(0, 0, 0, 0.15));
`;

const Image = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  clip-path: ${props => props.imagePosition === 'right' 
    ? 'polygon(15% 0, 100% 0, 100% 100%, 0 100%)'
    : 'polygon(0 0, 85% 0, 100% 100%, 0% 100%)'};
  transform: scale(1.02);
`;

const TextContent = styled.div`
  flex: 0.8;
`;

const Title = styled.h2`
  color: ${props => props.theme.colors.primary};
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
`;

const Description = styled.p`
  color: ${props => props.theme.colors.primary};
  font-family: ${props => props.theme.fonts.body};
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const Button = styled.button`
  background: ${props => props.theme.colors.accent};
  color: white;
  border: 2px solid ${props => props.theme.colors.accent};
  padding: 0.8rem 2rem;
  border-radius: 50px;
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: transparent;
    color: ${props => props.theme.colors.accent};
  }
`;

const PricingContainer = styled.div`
  background: ${props => props.theme.colors.background};
  margin-top: -3rem;
  padding: 1.5rem;
`;

const PricingGrid = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0;
  
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const PricingCard = styled.div`
  padding: 1.5rem 2rem;
  position: relative;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    right: 0;
    top: 15%;
    height: 70%;
    width: 1px;
    background: ${props => props.theme.colors.primary}40;
  }

  @media (max-width: 1024px) {
    &:not(:last-child)::after {
      display: none;
    }
    &:not(:last-child) {
      border-bottom: 1px solid ${props => props.theme.colors.primary}40;
      padding: 1rem 2rem;
    }
  }
`;

const TicketType = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  color: ${props => props.theme.colors.primary};
  font-size: 1.8rem;
  margin-bottom: 1rem;
  text-align: left;
`;

const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const PriceLabel = styled.div`
  font-family: ${props => props.theme.fonts.body};
  color: ${props => props.theme.colors.secondary};
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: left;
`;

const Price = styled.div`
  font-family: ${props => props.theme.fonts.main};
  color: ${props => props.theme.colors.primary};
  font-size: 1.8rem;
  font-weight: 500;
  text-align: right;
`;

const BookButton = styled(Button)`
  background: ${props => props.theme.colors.accent};
  color: white;
  border: 2px solid ${props => props.theme.colors.accent};
  padding: 0.8rem 2rem;
  
  &:hover {
    background: transparent;
    color: ${props => props.theme.colors.accent};
  }
`;

const ImageSlantWithPricing = ({ 
  image, 
  title, 
  description, 
  buttonText, 
  buttonAction, 
  imagePosition = 'left',
  pricingData
}) => {
  return (
    <>
      <Container>
        <ContentWrapper imagePosition={imagePosition}>
          <ImageContainer imagePosition={imagePosition}>
            <Image src={image} alt={title} imagePosition={imagePosition} />
          </ImageContainer>
          <TextContent>
            <Title>{title}</Title>
            <Description>{description}</Description>
            <Button onClick={buttonAction}>{buttonText}</Button>
          </TextContent>
        </ContentWrapper>
      </Container>
      
      <PricingContainer>
        <PricingGrid>
          {pricingData.map((ticket, index) => (
            <PricingCard key={index}>
              <TicketType>{ticket.type}</TicketType>
              <PriceRow>
                <PriceLabel>Online Price</PriceLabel>
                <Price>{ticket.onlinePrice}</Price>
              </PriceRow>
              <PriceRow>
                <PriceLabel>Ticket Office</PriceLabel>
                <Price>{ticket.officePrice}</Price>
              </PriceRow>
            </PricingCard>
          ))}
        </PricingGrid>
      </PricingContainer>
    </>
  );
};

export default ImageSlantWithPricing;
