import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.section`
  background: ${props => props.theme.colors.greyBackground};
  padding: 2rem 0;
  width: 100%;
  font-family: ${props => props.theme.fonts.main};
`;

const ContentWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const Title = styled.h2`
  color: ${props => props.theme.colors.primary};
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: left;
`;

const TimesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const TimesCard = styled.div`
  padding: 1.5rem 2rem;
  position: relative;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    right: 0;
    top: 15%;
    height: 70%;
    width: 1px;
    background: ${props => props.theme.colors.primary}40;
  }

  @media (max-width: 768px) {
    &:not(:last-child)::after {
      display: none;
    }
    &:not(:last-child) {
      border-bottom: 1px solid ${props => props.theme.colors.primary}40;
      padding-bottom: 1.5rem;
    }
  }
`;

const VenueName = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  color: ${props => props.theme.colors.primary};
  font-size: 1.8rem;
  margin-bottom: 1rem;
  text-align: left;
`;

const TimeRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const DayLabel = styled.div`
  color: ${props => props.theme.colors.secondary};
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 1px;
  text-align: left;
`;

const Hours = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
`;

const Note = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: 0.9rem;
  font-style: italic;
  margin-top: 0.5rem;
  text-align: left;
`;

const OpeningTimes = ({ columns, title = "Opening Times" }) => {
  // Check if columns is an object with column1, column2, etc. or an array
  const isObjectColumns = columns && typeof columns === 'object' && !Array.isArray(columns);
  
  // Convert object columns to array format
  const columnsArray = isObjectColumns 
    ? Object.keys(columns)
        .filter(key => key.startsWith('column') && columns[key])
        .map(key => ({
          title: columns[key].title || '',
          days: columns[key].days || '',
          times: columns[key].times || '',
          note: columns[key].note || ''
        }))
    : columns || [];

  return (
    <Container>
      <ContentWrapper>
        <Title>{title}</Title>
        <TimesGrid>
          {columnsArray.map((column, index) => (
            <TimesCard key={index}>
              <VenueName>{column.title}</VenueName>
              <TimeRow>
                <DayLabel>{column.days}</DayLabel>
                <Hours>{column.times}</Hours>
                {column.note && <Note>{column.note}</Note>}
              </TimeRow>
            </TimesCard>
          ))}
        </TimesGrid>
      </ContentWrapper>
    </Container>
  );
};

OpeningTimes.propTypes = {
  columns: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  title: PropTypes.string
};

export default OpeningTimes;
