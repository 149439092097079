import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery, gql } from '@apollo/client';
import logo from '../../assets/logo.png';
import { ThemeProvider } from 'styled-components';
import { theme } from '../../styles/theme';
import Button from '../Button/Button';
import TopNav from './TopNav';
import AnnouncementBanner from '../common/AnnouncementBanner';

// GraphQL query to fetch the mega menu structure
const GET_MEGA_MENU = gql`
  query GetMegaMenu {
    siteSettings(where: {id: "site-settings"}) {
      megaMenu {
        sections {
          title
          path
          theme
          items {
            text
            path
            featured
            submenu {
              text
              path
            }
          }
        }
      }
    }
  }
`;

const navConfig = {
  castle: [
    { to: "/your-visit", text: "Your Visit",
      submenu: [
        { to: "/your-visit/day-tickets", text: "Day Tickets" },
        { to: "/your-visit/opening-times", text: "Opening Times" },
        { to: "/your-visit/directions", text: "Directions" },
        { to: "/your-visit/accessibility", text: "Accessibility" },
        { to: "/your-visit/tours", text: "Tours" },
        { to: "/your-visit/groups", text: "Groups" }
      ]
    },
    { to: "/castle-dining", text: "Castle Dining", 
      submenu: [
        { to: "/castle-dining/aviary-tearoom", text: "The Aviary Tearoom" },
        { to: "/castle-dining/afternoon-tea", text: "Afternoon Tea" },
        { to: "/retail-village/dining", text: "Explore Retail Dining" }
      ] 
    },
    { to: "/whats-on", text: "What's On",
      submenu: [
        { to: "/whats-on/events", text: "Events" },
        { to: "/whats-on/exhibitions", text: "Exhibitions" },
        { to: "/whats-on/featured-tours", text: "Featured Tours" }
      ]
    },
    { to: "/private-hire", text: "Hire Now",
      submenu: [
        { to: "/weddings", text: "Weddings" },
        { to: "/private-hire", text: "Private Hire" }
      ]
    },
    { to: "/memberships", text: "Memberships",
      submenu: [
        { to: "/memberships", text: "Join Now" },
        { to: "/members-area", text: "Members Area" }
      ]
    },
    { to: "/journal", text: "Journal" },
    { to: "/about", text: "About",
      submenu: [
        { to: "/about/castle-and-state-rooms", text: "Castle & State Rooms" },
        { to: "/about/history", text: "History" },
        { to: "/about/gardens", text: "Gardens" },
        { to: "/about/adventure-playground", text: "Adventure Playground" },
        { to: "/about/gift-vouchers", text: "Gift Vouchers" },
        { to: "/about/parking", text: "Parking" },
        { to: "/about/dog-friendly", text: "Dog Friendly" }
      ]
    }
  ],
  retail: [
    { to: "/retail-village/shops", text: "Shops",
      submenu: [
        { to: "/retail-village/shops/farm-shop", text: "Farm Shop" },
        { to: "/retail-village/shops/duchess-collection", text: "Duchess Collection" },
        { to: "/retail-village/shops/tori-murphy", text: "Tori Murphy" },
        { to: "/retail-village/shops/bumbles", text: "Bumbles Interior Design" },
        { to: "/retail-village/shops/grange-garden-centre", text: "The Grange Garden Centre" }
      ]
    },
    { to: "/retail-village/animal-park", text: "Animal Park" },
    { to: "/retail-village/dining", text: "Dining",
      submenu: [
        { to: "/retail-village/dining/belvoir-bistro", text: "Belvoir Bistro" },
        { to: "/retail-village/dining/woodfired-pizza", text: "Belvoir Woodfired Pizza" },
        { to: "/retail-village/dining/balloon-bar", text: "Balloon Bar" },
        { to: "/castle-dining", text: "Explore Castle Dining" }
      ]
    },
    { to: "/whats-on/events", text: "Events" },
    { to: "/retail-village/directions", text: "Getting Here" },
  ],
  stay: [
    { to: "/accommodations", text: "Accommodations",
      submenu: [
        { to: "/accommodations/vale-house", text: "Vale House" },
        { to: "/accommodations/cottages", text: "Cottages" },
        { to: "/accommodations/glamping", text: "Glamping" },
        { to: "/accommodations/camping", text: "Camping" }
      ]
    },
    { to: "/booking", text: "Book Now" },
    { to: "/experiences", text: "Experiences" },
    { to: "/getting-here", text: "Getting Here" }
  ],
};

const sectionThemes = {
  castle: {
    navBackground: theme.colors.offWhite,
    linkColor: theme.colors.primary,
    dropdownBackground: '#f9f5f0',
  },
  retail: {
    navBackground: theme.colors.secondary,
    linkColor: theme.colors.white,
    dropdownBackground: '#f0f5f9',
  }
};

const NavContainer = styled.nav`
  background-color: ${props => sectionThemes[props.$currentSection]?.navBackground || props.theme.colors.offWhite};
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: relative;
  transition: top 0.5s ease;

  @media (min-width: 1440px) {
    padding: 1rem 8rem;
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    padding: 1rem 4rem;
  }
`;

const Logo = styled.img`
  height: 70px;
  
  @media (max-width: 768px) {
    height: 50px;
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 70px;

  @media (min-width: 1440px) {
    gap: 2rem;
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    gap: 1.5rem;
  }

  @media (max-width: 1024px) {
    position: fixed;
    flex-direction: column;
    background: ${props => props.theme.colors.offWhite};
    top: 0;
    right: ${({ $isOpen }) => ($isOpen ? '0' : '-100%')};
    height: 100vh;
    width: 300px;
    padding: 2rem;
    gap: 2rem;
    transition: right 0.3s ease-in-out;
    box-shadow: -2px 0 5px rgba(0,0,0,0.1);
    z-index: 1000;
    align-items: flex-start;
  }
`;

const NavItem = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  &:hover .dropdown-menu {
    display: block;
    animation: fadeIn 0.3s ease;
  }

  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
  }

  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }
`;

const NavLink = styled(Link)`
  color: ${props => sectionThemes[props.$currentSection]?.linkColor || props.theme.colors.primary};
  text-decoration: none;
  font-family: ${props => props.theme.fonts.main};
  font-size: 0.9rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${props => props.theme.colors.accent};
    transition: width 0.3s ease;
  }

  &:hover:after {
    width: 100%;
  }

  @media (min-width: 1440px) {
    font-size: 1.4rem;
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    font-size: 1.1rem;
  }
`;

const DropdownMenu = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: ${props => sectionThemes[props.$currentSection]?.dropdownBackground || '#f9f5f0'};
  min-width: 180px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  z-index: 1000;
  padding: 1rem 0;
  border-radius: 4px;
  border-top: 3px solid ${props => props.theme.colors.accent};

  @media (min-width: 1440px) {
    min-width: 220px;
  }

  @media (max-width: 1024px) {
    position: static;
    box-shadow: none;
    padding: ${props => props.$isOpen ? '0.5rem 0 0.5rem 1rem' : '0'};
    max-height: ${props => props.$isOpen ? '500px' : '0'};
    overflow: hidden;
    transition: all 0.3s ease;
    display: block;
    opacity: ${props => props.$isOpen ? '1' : '0'};
    visibility: ${props => props.$isOpen ? 'visible' : 'hidden'};
    background-color: transparent;
    border-top: none;
  }
`;

const DropdownLink = styled(Link)`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  font-family: ${props => props.theme.fonts.main};
  font-size: 1rem;
  padding: 0.7rem 1.5rem;
  display: block;
  transition: all 0.3s ease;
  border-left: 3px solid transparent;

  &:hover {
    background-color: rgba(0,0,0,0.05);
    border-left: 3px solid ${props => props.theme.colors.accent};
    padding-left: 1.8rem;
  }

  @media (min-width: 1440px) {
    font-size: 1.2rem;
  }

  @media (max-width: 1024px) {
    padding: 0.5rem 0;
    border-left: none;
    
    &:hover {
      border-left: none;
      padding-left: 0;
    }
  }
`;

const StyledButton = styled(Button)`
  text-decoration: none;
  font-size: 1rem;
  font-weight: 700;
  padding: 8px 16px;
  margin-left: 1rem;

  @media (min-width: 1440px) {
    font-size: 1.2rem;
    padding: 12px 24px;
    margin-left: 2rem;
  }

  @media (max-width: 1024px) {
    margin-left: 0;
  }
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  z-index: 1001;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  span {
    display: block;
    width: 30px;
    height: 3px;
    background-color: ${props => props.theme.colors.primary};
    transition: all 0.3s ease-in-out;

    &:first-child {
      transform: ${({ $isOpen }) => $isOpen ? 'rotate(45deg) translate(8px, 8px)' : 'rotate(0)'};
    }

    &:nth-child(2) {
      opacity: ${({ $isOpen }) => $isOpen ? '0' : '1'};
    }

    &:last-child {
      transform: ${({ $isOpen }) => $isOpen ? 'rotate(-45deg) translate(8px, -8px)' : 'rotate(0)'};
    }
  }
`;

const CloseButton = styled.button`
  display: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const Overlay = styled.div`
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  
  @media (max-width: 1024px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
`;

const MobileDropdownToggle = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 0.5rem;
  color: ${props => props.theme.colors.primary};
  position: relative;

  @media (max-width: 1024px) {
    display: inline-block;
    width: 30px;
    height: 30px;
    
    &::before {
      content: '>';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) ${props => props.isOpen ? 'rotate(-90deg)' : 'rotate(90deg)'};
      transition: transform 0.3s ease;
      font-size: 14px;
      font-weight: bold;
    }
  }
`;

const NavbarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const NavDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  min-width: 200px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease;
  z-index: 1000;
  
  ${NavItem}:hover & {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
`;

const NavDropdownItem = styled(Link)`
  display: block;
  padding: 0.8rem 1.5rem;
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${props => props.theme.colors.greyBackground};
    color: ${props => props.theme.colors.accent};
  }
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState('castle');
  const [openDropdowns, setOpenDropdowns] = useState({});
  const location = useLocation();
  const { loading, error, data } = useQuery(GET_MEGA_MENU);

  useEffect(() => {
    // Listen for custom events to force section
    const handleForceRetailSection = (event) => {
      if (event.detail && event.detail.section === 'retail') {
        setCurrentSection('retail');
      }
    };

    // Add event listeners
    document.addEventListener('forceRetailSection', handleForceRetailSection);

    // Set initial section based on path
    if (location.pathname.includes('retail-village')) {
      setCurrentSection('retail');
    } else {
      setCurrentSection('castle');
    }

    // Clean up
    return () => {
      document.removeEventListener('forceRetailSection', handleForceRetailSection);
    };
  }, [location]);

  const toggleMenu = () => setIsOpen(!isOpen);
  const closeMenu = () => setIsOpen(false);

  const toggleDropdown = (index) => {
    setOpenDropdowns(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  // Use WordPress data if available, otherwise use hardcoded config
  const menuItems = data?.siteSettings?.megaMenu?.sections?.find(
    section => section.theme === currentSection
  )?.items || navConfig[currentSection];

  return (
    <ThemeProvider theme={theme}>
      <NavbarContainer>
        <AnnouncementBanner />
        <TopNav $currentSection={currentSection} onSectionChange={setCurrentSection} />
        <NavContainer $currentSection={currentSection}>
          <Link to="/">
            <Logo src={logo} alt="Belvoir Castle Logo" />
          </Link>
          <Hamburger $isOpen={isOpen} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </Hamburger>
          <Overlay $isOpen={isOpen} onClick={closeMenu} />
          <NavLinks $isOpen={isOpen}>
            <CloseButton onClick={closeMenu}></CloseButton>
            {menuItems.map((item, index) => (
              <NavItem key={item.to || item.path}>
                <NavLink 
                  to={item.to || item.path} 
                  onClick={item.submenu ? undefined : closeMenu}
                  $currentSection={currentSection}
                >
                  {item.text}
                </NavLink>
                
                {item.submenu && (
                  <>
                    <MobileDropdownToggle 
                      onClick={() => toggleDropdown(index)}
                      isOpen={openDropdowns[index]}
                    />
                    <DropdownMenu 
                      className="dropdown-menu" 
                      $currentSection={currentSection}
                      $isOpen={openDropdowns[index]}
                    >
                      {item.submenu.map(subItem => (
                        <DropdownLink 
                          key={subItem.to || subItem.path} 
                          to={subItem.to || subItem.path} 
                          onClick={closeMenu}
                          $currentSection={currentSection}
                        >
                          {subItem.text}
                        </DropdownLink>
                      ))}
                    </DropdownMenu>
                  </>
                )}
              </NavItem>
            ))}
            <StyledButton as={Link} to="/your-visit/day-tickets" onClick={closeMenu}>
              TICKETS
            </StyledButton>
          </NavLinks>
        </NavContainer>
      </NavbarContainer>
    </ThemeProvider>
  );
};

export default Navbar;
