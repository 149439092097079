import React from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCampground, 
  faBed, 
  faUtensils, 
  faWifi, 
  faParking, 
  faMapMarkedAlt,
  faFire,
  faStar,
  faMoon,
  faShower,
  faCoffee
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import { useStaySection } from '../../../hooks/useStaySection';
import ParallaxBanner from '../../common/Parralax-banner';
import NewsletterSignUp from '../../common/Newsletter-sign';
import glampingBanImage from '../../../assets/banner/glampingBan.webp';
import glampingImage from '../../../assets/holidays/glamping.webp';
import duckCampingImage from '../../../assets/holidays/duck-camping.jpeg';

const GlampingContainer = styled.div`
  min-height: 100vh;
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
 
  letter-spacing: 2px;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const GlampingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  margin: 4rem 0;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const GlampingCard = styled.div`
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const GlampingImage = styled.div`
  height: 300px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const GlampingContent = styled.div`
  padding: 2rem;
`;

const GlampingTitle = styled.h3`
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const GlampingDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const GlampingDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  
  svg {
    color: ${props => props.theme.colors.accent};
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 1.5rem;
  
  li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    
    svg {
      color: ${props => props.theme.colors.accent};
    }
  }
`;

const BookButton = styled.button`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  
  &:hover {
    background-color: ${props => props.theme.colors.primary};
  }
`;

const HighlightSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 4rem 2rem;
  text-align: center;
  margin: 5rem 0;
`;

const HighlightContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const HighlightTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: white;
  margin-bottom: 1.5rem;
`;

const HighlightText = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 4rem 0;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  text-align: center;
  padding: 2rem;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  
  svg {
    color: white;
    font-size: 32px;
  }
`;

const FeatureTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const InfoSection = styled.div`
  margin: 5rem 0;
  text-align: center;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const InfoCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const InfoTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const InfoText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const RelatedSection = styled.div`
  margin-top: 4rem;
  text-align: center;
`;

const RelatedTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const RelatedLink = styled.button`
  background: ${props => props.theme.colors.accent};
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background 0.3s ease;
  
  &:hover {
    background: ${props => props.theme.colors.primary};
  }
`;

const Glamping = () => {
  const navigate = useNavigate();
  useStaySection();

  return (
    <GlampingContainer>
      <ParallaxBanner 
        welcomeText="Experience"
        mainTitle="Glamping"
        subtitle="LUXURY OUTDOOR LIVING IN THE VALE OF BELVOIR"
        image={glampingBanImage}
      />
      
      <ContentContainer>
        <SectionTitle>Escape to Nature in Style</SectionTitle>
        <IntroText>
          Immerse yourself in the tranquil beauty of the Vale of Belvoir with our unique glamping experience. 
          Our luxurious Bell Tents offer the perfect blend of comfort and adventure, with stunning views 
          and modern amenities in an unforgettable natural setting.
        </IntroText>
        
        <GlampingGrid>
          <GlampingCard>
            <GlampingImage image={glampingImage} />
            <GlampingContent>
              <GlampingTitle>Bell Tents</GlampingTitle>
              <GlampingDescription>
                Our spacious bell tents offer a perfect blend of comfort and adventure, with stunning views 
                of the Vale of Belvoir. Each tent is beautifully furnished and equipped for a memorable stay.
              </GlampingDescription>
              <GlampingDetails>
                <DetailItem>
                  <FontAwesomeIcon icon={faBed} />
                  <span>Up to 4 guests</span>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faMapMarkedAlt} />
                  <span>Vale of Belvoir</span>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faShower} />
                  <span>Shared facilities</span>
                </DetailItem>
                <DetailItem>
                  <FontAwesomeIcon icon={faCoffee} />
                  <span>Outdoor kitchen</span>
                </DetailItem>
              </GlampingDetails>
              <FeatureList>
                <li>
                  <FontAwesomeIcon icon={faWifi} />
                  <span>Free WiFi</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faParking} />
                  <span>Dedicated parking</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faUtensils} />
                  <span>BBQ facilities</span>
                </li>
              </FeatureList>
              <BookButton onClick={() => navigate('/booking')}>
                Booking Coming Soon
              </BookButton>
            </GlampingContent>
          </GlampingCard>
        </GlampingGrid>

        <HighlightSection>
          <HighlightContent>
            <HighlightTitle>A Unique Outdoor Experience</HighlightTitle>
            <HighlightText>
              Our glamping site offers the perfect balance of outdoor adventure and comfort. Wake up to birdsong, 
              enjoy stunning sunsets, and spend evenings stargazing around the campfire. It's the ideal way to 
              reconnect with nature while enjoying modern amenities.
            </HighlightText>
          </HighlightContent>
        </HighlightSection>

        <SectionTitle>The Glamping Experience</SectionTitle>
        <FeaturesGrid>
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faFire} />
            </IconWrapper>
            <FeatureTitle>Campfire Evenings</FeatureTitle>
            <FeatureDescription>
              Gather around your private fire pit for cozy evenings under the stars. Perfect for toasting 
              marshmallows and sharing stories.
            </FeatureDescription>
          </FeatureCard>
          
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faStar} />
            </IconWrapper>
            <FeatureTitle>Stunning Views</FeatureTitle>
            <FeatureDescription>
              Wake up to breathtaking views of Belvoir Castle and the Vale of Belvoir. Each tent is positioned 
              to make the most of the landscape.
            </FeatureDescription>
          </FeatureCard>
          
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faMoon} />
            </IconWrapper>
            <FeatureTitle>Dark Sky Location</FeatureTitle>
            <FeatureDescription>
              Experience incredible stargazing opportunities in our dark sky location, perfect for observing 
              the night sky away from city lights.
            </FeatureDescription>
          </FeatureCard>
        </FeaturesGrid>

        <InfoSection>
          <SectionTitle>Essential Information</SectionTitle>
          <InfoGrid>
            <InfoCard>
              <InfoTitle>Check-in & Check-out</InfoTitle>
              <InfoText>
                <strong>Check-in:</strong> From 3:00 PM
              </InfoText>
              <InfoText>
                <strong>Check-out:</strong> By 11:00 AM
              </InfoText>
              <InfoText>
                Early check-in and late check-out available upon request, subject to availability.
              </InfoText>
            </InfoCard>
            
            <InfoCard>
              <InfoTitle>What's Included</InfoTitle>
              <InfoText>
                • All bed linen and towels
              </InfoText>
              <InfoText>
                • Welcome pack with local treats
              </InfoText>
              <InfoText>
                • Firewood for your first night
              </InfoText>
              <InfoText>
                • Access to shared facilities
              </InfoText>
            </InfoCard>
          </InfoGrid>
        </InfoSection>

        <div style={{ margin: '4rem 0' }}>
          <NewsletterSignUp />
        </div>

        <RelatedSection>
          <RelatedTitle>Looking for a different camping experience?</RelatedTitle>
          <RelatedLink onClick={() => navigate('/accommodations/camping')}>
            View our traditional camping options at The Duck Campsite
          </RelatedLink>
        </RelatedSection>
      </ContentContainer>
      
      <Footer />
    </GlampingContainer>
  );
};

export default Glamping; 