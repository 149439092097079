import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import Footer from '../../Footer/Footer';
import { parseDate } from '../../../utils/eventUtils';
import LoadingSpinner from '../../common/LoadingSpinner';
import ImageSlant from '../../common/image-slant';
import castleImage from '../../../assets/castle.png';
import gardensImage from '../../../assets/gardens.png';
import retailImage from '../../../assets/retail3.webp';
import playgroundImage from '../../../assets/play.png';
import { useWhatsOnSection } from '../../../hooks/useWhatsOnSection';
import accommodationImage from '../../../assets/accommodation.jpg';
import tapasImage from '../../../assets/tapas2.jpg';
import { Helmet } from 'react-helmet-async';

// Styled components for the event detail page
const EventContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding-top: 120px;
`;

const EventHero = styled.div`
  width: 100%;
  height: 400px;
  background-image: ${props => `linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url(${props.image})`};
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 3rem;
  
  @media (max-width: 768px) {
    height: 350px;
    margin-bottom: 2rem;
  }
  
  @media (max-width: 576px) {
    height: 300px;
    margin-bottom: 1.5rem;
  }
`;

const EventHeroContent = styled.div`
  position: relative;
  z-index: 2;
  color: white;
  padding: 3rem 5rem;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    padding: 2rem 1.5rem;
  }
  
  @media (max-width: 576px) {
    padding: 1.5rem 1rem;
  }
`;

const EventTitle = styled.h1`
  font-family: ${props => props.theme.fonts.main};
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  line-height: 1.2;
  text-shadow: 0 2px 4px rgba(0,0,0,0.3);
  
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
  
  @media (max-width: 576px) {
    font-size: 2rem;
    word-wrap: break-word;
  }
`;

const EventDate = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  
  @media (max-width: 576px) {
    font-size: 1rem;
    word-wrap: break-word;
  }
  
  &:before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 24 24'%3E%3Cpath d='M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM5 8V6h14v2H5zm2 4h10v2H7v-2zm0 4h7v2H7v-2z'/%3E%3C/svg%3E");
    background-size: contain;
    margin-right: 8px;
    flex-shrink: 0;
  }
`;

const EventLocation = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  
  @media (max-width: 576px) {
    font-size: 1rem;
    word-wrap: break-word;
  }
  
  &:before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z'/%3E%3C/svg%3E");
    background-size: contain;
    margin-right: 8px;
    flex-shrink: 0;
  }
`;

const EventContent = styled.div`
  padding: 4rem 5rem;
  width: 100%;
  max-width: 1600px;
  margin: -60px auto 0;
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 4rem;
  box-sizing: border-box;
  
  @media (max-width: 1024px) {
    padding: 3rem 2rem;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: 0;
  }
  
  @media (max-width: 576px) {
    padding: 2rem 1rem;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
  box-sizing: border-box;
`;

const SideContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  box-sizing: border-box;
`;

const Strapline = styled.div`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.5rem;
  font-style: italic;
  color: ${props => props.theme.colors.accent};
  margin: 1rem 0 2rem;
  line-height: 1.4;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(157, 109, 73, 0.3);
  
  @media (max-width: 576px) {
    font-size: 1.2rem;
    word-wrap: break-word;
  }
`;

const AboutSection = styled.section`
  margin-bottom: 3rem;
  width: 100%;
  box-sizing: border-box;
`;

const EventDescription = styled.div`
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  
  @media (max-width: 576px) {
    font-size: 1rem;
  }
  
  h2, h3, h4 {
    font-family: ${props => props.theme.fonts.main};
    color: ${props => props.theme.colors.primary};
    margin: 1.5rem 0 1rem;
    max-width: 100%;
  }
  
  p {
    margin-bottom: 1.5rem;
    max-width: 100%;
  }
  
  ul, ol {
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
    max-width: 100%;
    
    @media (max-width: 576px) {
      padding-left: 1rem;
    }
  }
  
  a {
    color: ${props => props.theme.colors.accent};
    text-decoration: none;
    word-break: break-word;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1.5rem;
    
    @media (max-width: 768px) {
      display: block;
      overflow-x: auto;
      max-width: 100%;
    }
  }
  
  td, th {
    padding: 0.5rem;
    border: 1px solid #ddd;
    
    @media (max-width: 576px) {
      padding: 0.3rem;
    }
  }
  
  * {
    max-width: 100%;
    box-sizing: border-box;
  }
`;

const MainSectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 0.75rem;
  word-wrap: break-word;
  
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
  
  @media (max-width: 576px) {
    font-size: 1.6rem;
  }
  
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 2px;
    background: linear-gradient(to right, ${props => props.theme.colors.accent}, rgba(157, 109, 73, 0.3));
  }
`;

const ContentSection = styled.section`
  background: white;
  padding: 2.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  margin-bottom: 3rem;
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.imageRight ? '1fr 1.5fr' : '1.5fr 1fr'};
  gap: 3rem;
  align-items: center;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const ContentImage = styled.div`
  width: 100%;
  height: 350px;
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  order: ${props => props.imageRight ? 2 : 1};
  
  @media (max-width: 768px) {
    height: 250px;
    order: 1;
  }
`;

const ContentText = styled.div`
  order: ${props => props.imageRight ? 1 : 2};
  
  h3 {
    font-family: ${props => props.theme.fonts.main};
    font-size: 1.8rem;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 1.5rem;
  }
  
  p {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #333;
  }
  
  @media (max-width: 768px) {
    order: 2;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 0.5rem;
  word-wrap: break-word;
  
  @media (max-width: 768px) {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  
  @media (max-width: 576px) {
    font-size: 1.4rem;
  }
  
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 80px;
    height: 3px;
    background-color: ${props => props.theme.colors.accent};
  }
`;

const InfoSection = styled.div`
  background: white;
  padding: 2.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  margin-bottom: 2rem;
  width: 100%;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    padding: 1.5rem;
  }
  
  @media (max-width: 576px) {
    padding: 1rem;
  }
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-top: 1.5rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: ${props => props.theme.colors.offWhite};
  border-radius: 8px;
  transition: all 0.3s ease;
  
  @media (max-width: 768px) {
    padding: 0.75rem;
  }
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  }
`;

const InfoLabel = styled.span`
  font-weight: 600;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 0.5rem;
    color: ${props => props.theme.colors.accent};
    flex-shrink: 0;
    min-width: 16px;
  }
  
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const InfoValue = styled.span`
  font-size: 1.1rem;
  color: #333;
  word-break: break-word;
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
  
  @media (max-width: 576px) {
    font-size: 0.9rem;
  }
`;

const PriceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 1.5rem 0;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }
`;

const PriceCard = styled.div`
  background-color: ${props => props.theme.colors.offWhite};
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
  transition: all 0.3s ease;
  
  @media (max-width: 576px) {
    padding: 1rem;
  }
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  }
`;

const PriceType = styled.div`
  font-weight: 600;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  
  @media (max-width: 576px) {
    font-size: 0.9rem;
  }
`;

const Price = styled.div`
  font-size: 1.8rem;
  font-weight: 700;
  color: ${props => props.theme.colors.accent};
  margin: 0.5rem 0;
  
  @media (max-width: 576px) {
    font-size: 1.5rem;
  }
`;

const BookButton = styled.a`
  background: ${props => props.theme.colors.accent};
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-sizing: border-box;
  
  @media (max-width: 576px) {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }
  
  svg {
    margin-right: 0.75rem;
    flex-shrink: 0;
  }
  
  &:hover {
    background: ${props => props.theme.colors.primary};
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(157, 109, 73, 0.3);
  }
`;

const MemberInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: rgba(16, 29, 64, 0.05);
  border-radius: 4px;
  font-size: 0.9rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }
`;

const MemberBadge = styled.span`
  background: ${props => props.theme.colors.primary};
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  
  @media (max-width: 768px) {
    margin-right: 0;
  }
  
  svg {
    margin-right: 0.5rem;
  }
`;

const TicketTierBadge = styled.span`
  background: ${props => props.theme.colors.accent};
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: capitalize;
  
  @media (max-width: 576px) {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }
`;

const BackButton = styled.button`
  background: transparent;
  color: ${props => props.theme.colors.accent};
  border: 2px solid ${props => props.theme.colors.accent};
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  margin: 3rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  max-width: 200px;
  
  &:hover {
    background: ${props => props.theme.colors.accent};
    color: white;
  }
`;

const EventSidebar = styled.div`
  background: white;
  padding: 2.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  
  h3 {
    font-family: ${props => props.theme.fonts.main};
    font-size: 1.5rem;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 1.5rem;
    position: relative;
    padding-bottom: 0.5rem;
    
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 60px;
      height: 2px;
      background-color: ${props => props.theme.colors.accent};
    }
  }
`;

const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: flex-start;
  
  h4 {
    font-family: ${props => props.theme.fonts.main};
    font-size: 1.1rem;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 0.5rem;
  }
  
  p {
    color: #333;
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
  
  @media (max-width: 768px) {
    gap: 0.4rem;
  }
`;

const EventTag = styled.span`
  background: rgba(255, 255, 255, 0.2);
  color: white;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  font-size: 0.9rem;
  backdrop-filter: blur(4px);
  display: inline-block;
  margin-bottom: 0.3rem;
  
  @media (max-width: 768px) {
    padding: 0.3rem 0.6rem;
    font-size: 0.8rem;
  }
  
  @media (max-width: 576px) {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }
`;

const Tag = styled.span`
  background-color: ${props => props.theme.colors.accentLight};
  color: ${props => props.theme.colors.accent};
  padding: 0.5rem 1rem;
  border-radius: 50px;
  font-size: 0.9rem;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 0.3rem;
  
  @media (max-width: 768px) {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }
  
  @media (max-width: 576px) {
    padding: 0.3rem 0.6rem;
    font-size: 0.75rem;
  }
`;

const GallerySection = styled.section`
  width: 100%;
  max-width: 1600px;
  margin: 4rem auto;
  padding: 0 5rem;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    padding: 0 2rem;
    margin: 3rem auto;
  }
  
  @media (max-width: 576px) {
    padding: 0 1rem;
    margin: 2rem auto;
  }
`;

const GalleryMosaic = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 220px;
  gap: 1.5rem;
  margin-top: 2rem;
  
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-auto-rows: 180px;
  }
`;

const GalleryImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.02);
  }
  
  &:nth-child(3n+1) {
    grid-column: span 1;
    grid-row: span 1;
  }
  
  &:nth-child(3n+2) {
    grid-column: span 1;
    grid-row: span 1;
  }
  
  &:nth-child(3n) {
    grid-column: span 1;
    grid-row: span 1;
  }
  
  &:first-child {
    grid-column: span 2;
    grid-row: span 2;
  }
  
  @media (max-width: 1200px) {
    &:first-child {
      grid-column: span 2;
      grid-row: span 1;
    }
  }
  
  @media (max-width: 768px) {
    &:first-child {
      grid-column: span 1;
      grid-row: span 1;
    }
  }
`;

const BackIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ marginRight: '0.5rem' }}>
    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
  </svg>
);

const ErrorMessage = ({ message, onBack }) => (
  <div style={{ 
    padding: '2rem', 
    textAlign: 'center', 
    color: '#721c24', 
    background: '#f8d7da', 
    borderRadius: '4px',
    margin: '2rem auto',
    maxWidth: '800px'
  }}>
    <h2>Error</h2>
    <p>{message}</p>
    <BackButton onClick={onBack}>
      <BackIcon /> Go Back
    </BackButton>
  </div>
);

const formatEventDate = (startDateStr, endDateStr) => {
  if (!startDateStr) return "Date TBD";
  
  const startDate = new Date(startDateStr);
  if (isNaN(startDate.getTime())) return "Date TBD";
  
  const startFormatted = startDate.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
  
  if (!endDateStr) return startFormatted;
  
  const endDate = new Date(endDateStr);
  if (isNaN(endDate.getTime())) return startFormatted;
  
  const endFormatted = endDate.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
  
  return `${startFormatted} - ${endFormatted}`;
};

const getDefaultImageForLocation = (location) => {
  switch(location) {
    case 'CASTLE': return castleImage;
    case 'PARKLAND': return gardensImage;
    case 'RETAIL VILLAGE': return retailImage;
    default: return playgroundImage;
  }
};

const GET_EVENT_QUERY = gql`
  query GetEvent($id: ID!) {
    event(id: $id, idType: ID) {
      id
      title
      date
      featuredImage {
        node {
          sourceUrl
        }
      }
      eventDetails {
        eventLocation
        eventDescription
        eventShortDescription
        eventDate
        eventEndDate
        eventTime
        eventTicketTier
        eventTags
        eventMemberIncluded
        eventGalleryEnabled
        eventGalleryImages {
          image1 {
            node {
              sourceUrl
            }
          }
          image2 {
            node {
              sourceUrl
            }
          }
          image3 {
            node {
              sourceUrl
            }
          }
          image4 {
            node {
              sourceUrl
            }
          }
          image5 {
            node {
              sourceUrl
            }
          }
          image6 {
            node {
              sourceUrl
            }
          }
        }
        eventTicketPrices {
          adult
          child
          concession
        }
        eventBookingUrl
        eventStrapline
        eventSection1Enabled
        eventSection1Title
        eventSection1Content
        eventSection1Image {
          node {
            sourceUrl
          }
        }
        eventSection2Enabled
        eventSection2Title
        eventSection2Content
        eventSection2Image {
          node {
            sourceUrl
          }
        }
      }
    }
  }
`;

const FeatureSection = styled.section`
  width: 100%;
  max-width: 1600px;
  margin: 4rem auto;
  padding: 0 5rem;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    padding: 0 2rem;
    margin: 3rem auto;
  }
  
  @media (max-width: 576px) {
    padding: 0 1rem;
    margin: 2rem auto;
  }
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.imageRight ? '1fr 1fr' : '1fr 1fr'};
  gap: 3rem;
  max-width: 1600px;
  margin: 0 auto;
  padding: 3rem 2rem;
  align-items: center;
  
  @media (max-width: 992px) {
    gap: 2.5rem;
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 2rem 1rem;
  }
  
  @media (max-width: 576px) {
    padding: 1.5rem 0;
  }
`;

const FeatureImage = styled.div`
  width: 100%;
  height: 450px;
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  order: ${props => props.imageRight ? 2 : 1};
  position: relative;
  overflow: hidden;
  transform: ${props => props.imageRight ? 'rotate(2deg)' : 'rotate(-2deg)'};
  transition: transform 0.5s ease;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 70%, rgba(0,0,0,0.3) 100%);
    border-radius: 12px;
  }
  
  &:hover {
    transform: ${props => props.imageRight ? 'rotate(0deg)' : 'rotate(0deg)'} scale(1.02);
  }
  
  @media (max-width: 992px) {
    height: 400px;
  }
  
  @media (max-width: 768px) {
    height: 350px;
    order: 1;
    transform: rotate(0deg);
  }
`;

const FeatureContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  order: ${props => props.imageRight ? 1 : 2};
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  
  ${props => props.imageRight ? 
    'transform: translateX(3rem);' : 
    'transform: translateX(-3rem);'
  }
  
  @media (max-width: 768px) {
    order: 2;
    transform: translateX(0) translateY(-2rem);
    margin: 0 1rem;
  }
`;

const FeatureTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: ${props => props.theme.colors.primary};
  position: relative;
  padding-bottom: 0.75rem;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 80px;
    height: 3px;
    background: linear-gradient(to right, ${props => props.theme.colors.accent}, ${props => props.theme.colors.accentLight});
  }
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const FeatureText = styled.div`
  font-size: 1.1rem;
  line-height: 1.7;
  color: ${props => props.theme.colors.text};
  
  p {
    margin-bottom: 1.5rem;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  a {
    color: ${props => props.theme.colors.accent};
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s ease;
    
    &:hover {
      color: ${props => props.theme.colors.primary};
      text-decoration: underline;
    }
  }
`;

const BookNowButton = styled.a`
  display: block;
  width: calc(100% - 2rem);
  background: ${props => props.theme.colors.accent};
  color: white;
  font-family: ${props => props.theme.fonts.main};
  font-weight: 600;
  font-size: 1.3rem;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  text-decoration: none;
  margin: 1.5rem auto 0;
  text-align: center;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  
  &:hover {
    background: ${props => props.theme.colors.accentDark || props.theme.colors.accent};
    transform: translateY(-3px);
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
  }
  
  &:active {
    transform: translateY(-1px);
  }
  
  @media (max-width: 576px) {
    font-size: 1.2rem;
    padding: 0.7rem 1.2rem;
  }
`;

// Define data for the three global slant sections
const getDineSlantData = () => {
  return {
    image: tapasImage,
    title: 'Fine Dining at Belvoir Castle',
    description: 'Experience exquisite cuisine in the stunning surroundings of Belvoir Castle. Our award-winning chefs create memorable dining experiences using the finest local ingredients.',
    buttonText: 'View Dining Options',
    buttonLink: '/castle-dining'
  };
};

const getShopSlantData = () => {
  return {
    image: retailImage,
    title: 'Shop at Belvoir Castle',
    description: 'Discover our unique range of gifts, souvenirs, and local produce at the Belvoir Castle shops. From luxury homeware to artisan food and drink, there\'s something for everyone.',
    buttonText: 'Explore Our Shops',
    buttonLink: '/retail-village'
  };
};

const getStaySlantData = () => {
  return {
    image: accommodationImage,
    title: 'Luxury Accommodation',
    description: 'Extend your visit with a luxurious stay at Belvoir Castle. Our elegant rooms and suites offer the perfect retreat after a day of exploration.',
    buttonText: 'Book Your Stay',
    buttonLink: '/stay-with-us'
  };
};

const EventDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  
  // Use the What's On section hook
  useWhatsOnSection();
  
  console.log("Event ID from URL:", id);
  
  const { loading, error, data } = useQuery(GET_EVENT_QUERY, {
    variables: { id },
    skip: !id,
    onError: (error) => console.error("GraphQL Error:", error)
  });
  
  if (!id) {
    return <ErrorMessage message="No event ID provided" onBack={() => navigate('/events')} />;
  }
  
  if (loading) return <LoadingSpinner />;
  
  if (error) {
    console.error("Error loading event:", error);
    return <ErrorMessage message={`Error: ${error.message}`} onBack={() => navigate('/events')} />;
  }
  
  if (!data || !data.event) {
    console.error("Event not found:", id);
    return <ErrorMessage message="Event not found" onBack={() => navigate('/events')} />;
  }
  
  const eventData = data.event;
  const details = eventData.eventDetails || {};
  
  // Get hero image
  const heroImage = eventData.featuredImage?.node?.sourceUrl || getDefaultImageForLocation(details.eventLocation);
  
  // Format date
  const formattedDate = formatEventDate(details.eventDate, details.eventEndDate);
  
  const getEventTags = () => {
    if (!details.eventTags) return null;
    
    const tags = Array.isArray(details.eventTags) ? details.eventTags : [details.eventTags];
    
    return tags;
  };
  
  const getTicketTierName = (tier) => {
    const tierNames = {
      'super_early_bird': 'Super Early Bird',
      'early_bird': 'Early Bird',
      'advanced': 'Advanced',
      'standard': 'Standard'
    };
    
    return tierNames[tier] || tier;
  };
  
  // Add icons for the component
  const CalendarIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
    </svg>
  );

  const ClockIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
    </svg>
  );

  const LocationIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
    </svg>
  );

  const TicketIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6V4.5ZM1.5 4a.5.5 0 0 0-.5.5v1.05a2.5 2.5 0 0 1 0 4.9v1.05a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-1.05a2.5 2.5 0 0 1 0-4.9V4.5a.5.5 0 0 0-.5-.5h-13Z"/>
    </svg>
  );

  const MemberIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Zm9.886-3.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"/>
    </svg>
  );

  const BookingIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 0 13V2.5zM1.5 2a.5.5 0 0 0-.5.5v10.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V2.5a.5.5 0 0 0-.5-.5h-11z"/>
      <path d="M3.5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z"/>
    </svg>
  );

  const TagIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path d="M3 2v4.586l7 7L14.586 9l-7-7H3zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2z"/>
      <path d="M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
    </svg>
  );

  return (
    <>
      <Helmet>
        <title>{`${eventData.title} | Events at Belvoir Castle`}</title>
        <meta name="description" content={eventData.eventDetails?.eventShortDescription || 'Join us for this special event at Belvoir Castle'} />
        <meta name="keywords" content={`Belvoir Castle events, ${eventData.title}, castle events, ${eventData.eventDetails?.eventTags?.join(', ')}`} />
        <link rel="canonical" href={`https://www.belvoircastle.com/events/${eventData.id}`} />
        <meta property="og:title" content={`${eventData.title} | Events at Belvoir Castle`} />
        <meta property="og:description" content={eventData.eventDetails?.eventShortDescription} />
        <meta property="og:image" content={eventData.featuredImage?.node?.sourceUrl} />
      </Helmet>
      <EventContainer>
        <EventHero image={heroImage}>
          <EventHeroContent>
            <EventTitle>{eventData.title}</EventTitle>
            <EventDate>{formattedDate}{details.eventTime ? ` at ${details.eventTime}` : ''}</EventDate>
            <EventLocation>{details.eventLocation}</EventLocation>
            {details.eventTicketTier && (
              <TicketTierBadge tier={details.eventTicketTier}>
                {getTicketTierName(details.eventTicketTier)}
              </TicketTierBadge>
            )}
          </EventHeroContent>
        </EventHero>

        <EventContent>
          <MainContent>
            {/* About This Event - without white block */}
            <AboutSection>
              <MainSectionTitle>About This Event</MainSectionTitle>
              {details.eventStrapline && (
                <Strapline>{details.eventStrapline}</Strapline>
              )}
              <EventDescription dangerouslySetInnerHTML={{ __html: details.eventDescription }} />
            </AboutSection>
          </MainContent>

          <SideContent>
            {/* Combined Event Information Section */}
            <InfoSection>
              <SectionTitle>Event Information</SectionTitle>
              
              {/* Event Details */}
              <InfoGrid>
                <InfoItem>
                  <InfoLabel><LocationIcon /> Location</InfoLabel>
                  <InfoValue>{details.eventLocation}</InfoValue>
                </InfoItem>
                <InfoItem>
                  <InfoLabel><CalendarIcon /> Date</InfoLabel>
                  <InfoValue>{formattedDate}</InfoValue>
                </InfoItem>
                {details.eventTime && (
                  <InfoItem>
                    <InfoLabel><ClockIcon /> Time</InfoLabel>
                    <InfoValue>{details.eventTime}</InfoValue>
                  </InfoItem>
                )}
                {details.eventTicketTier && (
                  <InfoItem>
                    <InfoLabel><TicketIcon /> Ticket Tier</InfoLabel>
                    <InfoValue>{getTicketTierName(details.eventTicketTier)}</InfoValue>
                  </InfoItem>
                )}
                {getEventTags() && getEventTags().length > 0 && (
                  <InfoItem>
                    <InfoLabel><TagIcon /> Tags</InfoLabel>
                    <InfoValue>
                      <TagsContainer style={{ margin: 0, padding: 0 }}>
                        {getEventTags().map(tag => (
                          <Tag key={tag}>{tag}</Tag>
                        ))}
                      </TagsContainer>
                    </InfoValue>
                  </InfoItem>
                )}
              </InfoGrid>
              
              {/* Ticket Prices */}
              {details.eventTicketPrices && (
                <>
                  <SectionTitle style={{ marginTop: '2rem' }}>Ticket Prices</SectionTitle>
                  <PriceGrid>
                    {details.eventTicketPrices.adult && (
                      <PriceCard>
                        <PriceType>Adult</PriceType>
                        <Price>£{details.eventTicketPrices.adult}</Price>
                      </PriceCard>
                    )}
                    {details.eventTicketPrices.child && (
                      <PriceCard>
                        <PriceType>Child</PriceType>
                        <Price>£{details.eventTicketPrices.child}</Price>
                      </PriceCard>
                    )}
                    {details.eventTicketPrices.concession && (
                      <PriceCard>
                        <PriceType>Concession</PriceType>
                        <Price>£{details.eventTicketPrices.concession}</Price>
                      </PriceCard>
                    )}
                  </PriceGrid>
                </>
              )}
              
              {/* Member Benefit */}
              {details.eventMemberIncluded && (
                <MemberInfo>
                  <MemberBadge><MemberIcon /> Member Benefit</MemberBadge>
                  Included with membership
                </MemberInfo>
              )}
              
              {/* Book Now Button */}
              {details.eventBookingUrl && (
                <BookNowButton 
                  href={details.eventBookingUrl} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  Book Now
                </BookNowButton>
              )}
            </InfoSection>
          </SideContent>
        </EventContent>

        {/* Gallery Section */}
        {details.eventGalleryEnabled && details.eventGalleryImages && (
          <GallerySection>
            <SectionTitle>Event Gallery</SectionTitle>
            <GalleryMosaic>
              {Object.entries(details.eventGalleryImages)
                .filter(([key, value]) => key.startsWith('image') && value?.node?.sourceUrl)
                .map(([key, value]) => (
                  <GalleryImage 
                    key={key} 
                    src={value.node.sourceUrl}
                    alt={`Gallery image ${key}`}
                  />
                ))}
            </GalleryMosaic>
          </GallerySection>
        )}

        {/* Feature Sections - Moved below gallery */}
        {details.eventSection1Enabled && (
          <FeatureSection>
            <FeatureGrid>
              <FeatureImage 
                src={details.eventSection1Image?.node?.sourceUrl || getDefaultImageForLocation(details.location)} 
              />
              <FeatureContent>
                <FeatureTitle>{details.eventSection1Title}</FeatureTitle>
                <FeatureText dangerouslySetInnerHTML={{ __html: details.eventSection1Content }} />
              </FeatureContent>
            </FeatureGrid>
          </FeatureSection>
        )}

        {details.eventSection2Enabled && (
          <FeatureSection>
            <FeatureGrid imageRight>
              <FeatureContent imageRight>
                <FeatureTitle>{details.eventSection2Title}</FeatureTitle>
                <FeatureText dangerouslySetInnerHTML={{ __html: details.eventSection2Content }} />
              </FeatureContent>
              <FeatureImage 
                src={details.eventSection2Image?.node?.sourceUrl || getDefaultImageForLocation(details.location)} 
                imageRight
              />
            </FeatureGrid>
          </FeatureSection>
        )}

        {/* Global Image Slant Sections - Always show all three */}
        <ImageSlant 
          image={getDineSlantData().image}
          title={getDineSlantData().title}
          description={getDineSlantData().description}
          buttonText={getDineSlantData().buttonText}
          buttonAction={() => navigate(getDineSlantData().buttonLink)}
          imagePosition="left"
        />

        <ImageSlant 
          image={getShopSlantData().image}
          title={getShopSlantData().title}
          description={getShopSlantData().description}
          buttonText={getShopSlantData().buttonText}
          buttonAction={() => navigate(getShopSlantData().buttonLink)}
          imagePosition="right"
        />

        <ImageSlant 
          image={getStaySlantData().image}
          title={getStaySlantData().title}
          description={getStaySlantData().description}
          buttonText={getStaySlantData().buttonText}
          buttonAction={() => navigate(getStaySlantData().buttonLink)}
          imagePosition="left"
        />

        <BackButton onClick={() => navigate(-1)}>
          <BackIcon /> Back to Events
        </BackButton>
        <Footer />
      </EventContainer>
    </>
  );
};

export default EventDetail;