import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCouch, 
  faHome, 
  faGift, 
  faClock,
  faStore,
  faInstagram
} from '@fortawesome/free-solid-svg-icons';
import { faInstagram as fabInstagram } from '@fortawesome/free-brands-svg-icons';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';

// Import placeholder image - replace with actual shop image
import retailImage from '../../../assets/retail1.webp';
import retailBanner from '../../../assets/banner/retailBan3.webp';
// Import custom hook to force retail section
import { useRetailSection } from '../../../hooks/useRetailSection';

const BumblesContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const CurrentTimeDisplay = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 3rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
`;

const ShopCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto 4rem;
`;

const CardImage = styled.div`
  height: 350px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const CardContent = styled.div`
  padding: 2rem;
`;

const CardTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0;
`;

const StatusIndicator = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.isOpen ? '#4CAF50' : '#F44336'};
  margin-right: 8px;
`;

const StatusText = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: ${props => props.isOpen ? '#4CAF50' : '#F44336'};
  display: flex;
  align-items: center;
`;

const TimeInfo = styled.div`
  margin-bottom: 1.5rem;
`;

const DayLabel = styled.div`
  color: ${props => props.theme.colors.secondary};
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
`;

const Hours = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: 1.6rem;
  font-weight: 500;
`;

const Note = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: 0.9rem;
  font-style: italic;
  margin-top: 0.5rem;
`;

const ShopDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const ExternalLinkButton = styled.a`
  display: inline-flex;
  align-items: center;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  text-decoration: none;
  border: 2px solid transparent;
  border-radius: 25px;
  padding: 1rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  
  svg {
    margin-left: 0.5rem;
  }
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const InstagramButton = styled(ExternalLinkButton)`
  background-color: ${props => props.theme.colors.accent};
  border: 2px solid transparent;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 4rem 0;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  text-align: center;
  padding: 2rem;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  
  svg {
    color: white;
    font-size: 32px;
  }
`;

const FeatureTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const StatusBadge = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  background-color: ${props => props.isOpen ? 'rgba(76, 175, 80, 0.85)' : 'rgba(244, 67, 54, 0.85)'};
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  backdrop-filter: blur(4px);
`;

const Bumbles = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  
  // Use custom hook to ensure we stay in retail section
  useRetailSection();
  
  // Update current time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    
    return () => clearInterval(timer);
  }, []);
  
  // Format current time
  const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const currentDay = currentTime.toLocaleDateString('en-US', { weekday: 'long' });
  
  // Shop opening hours
  const openingHours = {
    days: "Tuesday - Sunday",
    times: "10:00 - 16:00",
    note: "Closed on Mondays except Bank Holidays"
  };
  
  // Function to check if the shop is currently open
  const isShopOpen = () => {
    // Check if it's Monday (0 is Sunday, 1 is Monday in JavaScript)
    const today = currentTime.getDay();
    if (today === 1) return false; // Closed on Mondays
    
    // Parse opening hours
    const timeRange = openingHours.times.split('-').map(t => t.trim());
    if (timeRange.length !== 2) return false;
    
    // Convert times to 24-hour format for comparison
    const [openHour, openMinute] = timeRange[0].split(':').map(Number);
    const [closeHour, closeMinute] = timeRange[1].split(':').map(Number);
    
    const openTime = new Date();
    openTime.setHours(openHour, openMinute || 0, 0);
    
    const closeTime = new Date();
    closeTime.setHours(closeHour, closeMinute || 0, 0);
    
    // Check if current time is between open and close times
    return currentTime >= openTime && currentTime <= closeTime;
  };
  
  return (
    <BumblesContainer>
      <ParallaxBanner 
        title="Bumbles Interiors"
        subtitle="Unique home accessories and interior design"
        image={retailBanner}
      />
      
      <ContentContainer>
        <SectionTitle>Bumbles Interiors</SectionTitle>
        <IntroText>
          Discover a carefully curated collection of unique home accessories, gifts, and interior design pieces at Bumbles Interiors in the Belvoir Castle Retail Village. 
          From stylish homeware to beautiful decorative items, Bumbles offers something special for every home.
        </IntroText>
        
        <CurrentTimeDisplay>
          Current Time: {formattedTime} on {currentDay}
        </CurrentTimeDisplay>
        
        <ShopCard>
          <CardImage image={retailImage} />
          <CardContent>
            <CardTitle>Bumbles Interiors at Belvoir Castle</CardTitle>
            <StatusContainer>
              <StatusText isOpen={isShopOpen()}>
                <StatusIndicator isOpen={isShopOpen()} />
                {isShopOpen() ? 'Open Now' : 'Closed'}
              </StatusText>
            </StatusContainer>
            <TimeInfo>
              <DayLabel>{openingHours.days}</DayLabel>
              <Hours>{openingHours.times}</Hours>
              <Note>{openingHours.note}</Note>
            </TimeInfo>
            <ShopDescription>
              Bumbles Interiors is a charming boutique offering a delightful selection of home accessories, gifts, and interior design pieces. 
              The shop showcases a blend of contemporary and classic styles, with an emphasis on quality and unique design. 
              Whether you're looking to refresh your home décor or find the perfect gift, Bumbles Interiors offers a treasure trove of beautiful items to discover.
            </ShopDescription>
            <InstagramButton href="https://www.instagram.com/bumblesinteriors/" target="_blank" rel="noopener noreferrer">
              Follow on Instagram <FontAwesomeIcon icon={fabInstagram} />
            </InstagramButton>
          </CardContent>
        </ShopCard>
        
        <SectionTitle>Featured Collections</SectionTitle>
        <IntroText>
          Bumbles Interiors offers a diverse range of products to enhance your home, from decorative accessories to practical homeware and thoughtful gifts.
        </IntroText>
        
        <FeaturesGrid>
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faHome} />
            </IconWrapper>
            <FeatureTitle>Home Accessories</FeatureTitle>
            <FeatureDescription>
              Discover beautiful decorative items including vases, candles, picture frames, and ornaments to add character and style to your home.
            </FeatureDescription>
          </FeatureCard>
          
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faCouch} />
            </IconWrapper>
            <FeatureTitle>Interior Design</FeatureTitle>
            <FeatureDescription>
              Explore our collection of cushions, throws, and textiles that bring comfort and elegance to your living spaces with distinctive designs.
            </FeatureDescription>
          </FeatureCard>
          
          <FeatureCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faGift} />
            </IconWrapper>
            <FeatureTitle>Unique Gifts</FeatureTitle>
            <FeatureDescription>
              Find the perfect present for any occasion from our selection of thoughtfully chosen gifts that are sure to delight and surprise.
            </FeatureDescription>
          </FeatureCard>
        </FeaturesGrid>
        
        <div style={{ textAlign: 'center', margin: '3rem 0' }}>
          <InstagramButton href="https://www.instagram.com/bumblesinteriors/" target="_blank" rel="noopener noreferrer">
            See Our Latest Products on Instagram <FontAwesomeIcon icon={fabInstagram} />
          </InstagramButton>
        </div>
      </ContentContainer>
      
      <Footer />
    </BumblesContainer>
  );
};

export default Bumbles; 