import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCrown, 
  faChessRook, 
  faFire, 
  faHorse, 
  faLandmark, 
  faUserShield,
  faScroll,
  faPalette,
  faGem,
  faChurch,
  faUniversity,
  faBook
} from '@fortawesome/free-solid-svg-icons';

// Import images from existing assets
import castleImage from '../../../assets/castle.png';
import gardensImage from '../../../assets/gardens.png';
import regentsGallery from '../../../assets/regents-gallery.jpg';
import elizabethSaloon from '../../../assets/elizabeth-saloon.jpg';
import ballroom from '../../../assets/ballroom.jpg';
import stateDining from '../../../assets/state-dining.jpg';
import historyBanImage from '../../../assets/banner/historyBan.webp';
// Animation keyframes
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const scaleIn = keyframes`
  from { transform: scale(0.8); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
`;

const slideInLeft = keyframes`
  from { transform: translateX(-50px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
`;

const slideInRight = keyframes`
  from { transform: translateX(50px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const HistoryContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
  animation: ${fadeIn} 1s ease-out;
`;

const TimelineContainer = styled.div`
  position: relative;
  margin: 5rem 0;
  padding-bottom: 3rem;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 4px;
    background-color: ${props => props.theme.colors.accent};
    transform: translateX(-50%);
    z-index: 0;
    
    @media (max-width: 768px) {
      left: 0;
    }
  }
`;

const TimelineItem = styled.div`
  position: relative;
  margin-bottom: 5rem;
  display: flex;
  justify-content: ${props => props.position === 'left' ? 'flex-start' : 'flex-end'};
  
  @media (max-width: 768px) {
    justify-content: flex-start;
    padding-left: 30px;
    margin-bottom: 3rem;
  }
`;

const TimelineContent = styled.div`
  width: 45%;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 30px;
    width: 20px;
    height: 20px;
    background-color: ${props => props.theme.colors.accent};
    transform: rotate(45deg);
    z-index: 1;
    
    ${props => props.position === 'left' ? 
      'right: -10px;' : 
      'left: -10px;'
    }
    
    @media (max-width: 768px) {
      left: -10px;
      top: 40px;
    }
  }
  
  @media (max-width: 768px) {
    width: 100%;
    padding-top: 3rem;
  }
`;

const TimelineDate = styled.div`
  position: absolute;
  width: 150px;
  padding: 0.5rem;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  top: 30px;
  transform: translateY(-50%);
  
  ${props => props.position === 'left' ? 
    'right: -170px;' :
    'left: -170px;'
  }
  
  @media (max-width: 768px) {
    left: auto;
    right: 20px;
    top: 15px;
    width: auto;
    min-width: 110px;
    font-size: 0.9rem;
    z-index: 3;
    transform: none;
  }
`;

const TimelineIcon = styled.div`
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  z-index: 3;
  top: 30px;
  transform: translateY(-50%);
  
  ${props => props.position === 'left' ? 
    'right: -30px;' : 
    'left: -30px;'
  }
  
  @media (max-width: 768px) {
    left: -25px;
    top: 15px;
    width: 50px;
    height: 50px;
    font-size: 20px;
    transform: none;
  }
`;

const TimelineTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  
  @media (max-width: 768px) {
    margin-top: 0.5rem;
    font-size: 1.5rem;
  }
`;

const TimelineText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const HighlightSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 5rem 2rem;
  margin: 5rem 0;
  text-align: center;
  animation: ${props => props.inView ? fadeIn : 'none'} 1s ease-out forwards;
`;

const HighlightContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const HighlightTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: white;
  margin-bottom: 2rem;
`;

const HighlightText = styled.p`
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const GallerySection = styled.div`
  margin: 5rem 0;
  animation: ${props => props.inView ? fadeIn : 'none'} 1s ease-out forwards;
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const GalleryCard = styled.div`
  position: relative;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    
    .overlay {
      opacity: 1;
    }
  }
  
  @media (max-width: 768px) {
    height: 300px;
  }
`;

const GalleryImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const GalleryOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  opacity: 0;
  transition: opacity 0.3s ease;
  color: white;
  text-align: center;
  overflow-y: auto;
  box-sizing: border-box;
  
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
  }
`;

const GalleryTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.5rem;
  margin-bottom: 0.6rem;
  width: 100%;
  word-wrap: break-word;
`;

const GalleryDescription = styled.p`
  font-size: 0.95rem;
  line-height: 1.4;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
`;

const DuchessSection = styled.div`
  margin: 5rem 0;
  animation: ${props => props.inView ? fadeIn : 'none'} 1s ease-out forwards;
`;

const DuchessGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const DuchessCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const DuchessContent = styled.div`
  padding: 2rem;
`;

const DuchessTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
`;

const DuchessYears = styled.p`
  font-size: 1rem;
  color: ${props => props.theme.colors.accent};
  font-weight: 600;
  margin-bottom: 1rem;
`;

const DuchessDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  animation: ${pulse} 2s infinite ease-in-out;
  
  svg {
    color: white;
    font-size: 32px;
  }
`;

const FactsSection = styled.div`
  margin: 5rem 0;
  animation: ${props => props.inView ? scaleIn : 'none'} 1s ease-out forwards;
`;

const FactsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FactCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const FactNumber = styled.div`
  font-size: 3rem;
  font-weight: 700;
  color: ${props => props.theme.colors.accent};
  margin-bottom: 1rem;
`;

const FactText = styled.p`
  font-size: 1.1rem;
  line-height: 1.4;
`;

// Timeline Item Component - Make this a proper React component
const TimelineItemComponent = React.memo(({ item, index }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
    rootMargin: '-50px 0px',
  });

  return (
    <div 
      ref={ref} 
      style={{ 
        opacity: inView ? 1 : 0,
        transform: inView 
          ? 'translateX(0)' 
          : `translateX(${item.position === 'left' ? '-50px' : '50px'})`,
        transition: 'opacity 0.8s ease-out, transform 0.8s ease-out'
      }}
    >
      <TimelineItem position={item.position}>
        <TimelineContent position={item.position}>
          <TimelineDate 
            position={item.position} 
            date={item.date}
          >
            {item.date}
          </TimelineDate>
          <TimelineIcon position={item.position}>
            <FontAwesomeIcon icon={item.icon} />
          </TimelineIcon>
          <TimelineTitle>{item.title}</TimelineTitle>
          <TimelineText>{item.text}</TimelineText>
        </TimelineContent>
      </TimelineItem>
    </div>
  );
});

// Add a display name for better debugging
TimelineItemComponent.displayName = 'TimelineItemComponent';

const History = () => {
  const { ref: highlightRef, inView: highlightInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  const { ref: galleryRef, inView: galleryInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  const { ref: duchessRef, inView: duchessInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  const { ref: factsRef, inView: factsInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  const timelineItems = [
    {
      date: "1067",
      title: "Norman Origins",
      text: "Robert de Todeni, William the Conqueror's Standard Bearer, built the first castle at Belvoir, strategically positioned on a hill overlooking the Vale of Belvoir.",
      icon: faChessRook,
      position: "left"
    },
    {
      date: "1174-1177",
      title: "Royal Ownership",
      text: "The castle briefly came into royal ownership when Henry II seized it during the rebellion of Robert de Ros.",
      icon: faCrown,
      position: "right"
    },
    {
      date: "1461",
      title: "Wars of the Roses",
      text: "Belvoir Castle was attacked during the Wars of the Roses, suffering significant damage as the conflict between the houses of York and Lancaster raged across England.",
      icon: faUserShield,
      position: "left"
    },
    {
      date: "1508",
      title: "Manners Family Acquisition",
      text: "The castle came into the hands of the Manners family through marriage when Eleanor, the daughter of Lord Ros, married Sir Robert Manners.",
      icon: faScroll,
      position: "right"
    },
    {
      date: "1649",
      title: "Civil War Destruction",
      text: "During the English Civil War, Belvoir Castle was destroyed by Parliamentarians after being used as a Royalist stronghold.",
      icon: faFire,
      position: "left"
    },
    {
      date: "1654-1668",
      title: "First Reconstruction",
      text: "The 8th Earl of Rutland began rebuilding the castle in a more classical style, moving away from its medieval fortress origins.",
      icon: faLandmark,
      position: "right"
    },
    {
      date: "1799",
      title: "Second Devastating Fire",
      text: "A catastrophic fire destroyed much of the castle, including priceless artworks and historical artifacts.",
      icon: faFire,
      position: "left"
    },
    {
      date: "1801-1832",
      title: "Gothic Revival Rebuild",
      text: "The 5th Duke of Rutland commissioned architect James Wyatt to rebuild the castle in the fashionable Gothic Revival style we see today.",
      icon: faPalette,
      position: "right"
    },
    {
      date: "1841",
      title: "Royal Visit",
      text: "Queen Victoria and Prince Albert visited Belvoir Castle, cementing its status as one of England's most prestigious stately homes.",
      icon: faCrown,
      position: "left"
    },
    {
      date: "1914-1918",
      title: "World War I",
      text: "During World War I, Belvoir Castle served as a military hospital, treating wounded soldiers from the front lines.",
      icon: faUniversity,
      position: "right"
    },
    {
      date: "1939-1945",
      title: "World War II",
      text: "The castle was requisitioned by the British Army during World War II, serving as a training ground and storage facility.",
      icon: faUserShield,
      position: "left"
    },
    {
      date: "Present Day",
      title: "Modern Era",
      text: "Today, Belvoir Castle remains the family home of the Duke and Duchess of Rutland, while also welcoming visitors from around the world to experience its rich history and stunning architecture.",
      icon: faBook,
      position: "right"
    }
  ];
  
  return (
    <HistoryContainer>
      <ParallaxBanner 
        title="The History of Belvoir Castle"
        subtitle="A Journey Through Nine Centuries"
        image={historyBanImage}
      />
      
      <ContentContainer>
        <SectionTitle>A Legacy of Nobility and Resilience</SectionTitle>
        <IntroText>
          For over 900 years, Belvoir Castle has stood proudly on its hilltop, witnessing the ebb and flow 
          of English history. From Norman conquest to modern day, the castle has been rebuilt and reimagined 
          several times, each incarnation reflecting the tastes and tribulations of its era.
        </IntroText>
        
        <TimelineContainer>
          {timelineItems.map((item, index) => (
            <TimelineItemComponent 
              key={index} 
              item={item} 
              index={index} 
            />
          ))}
        </TimelineContainer>
        
        <HighlightSection ref={highlightRef} inView={highlightInView}>
          <HighlightContent>
            <IconWrapper>
              <FontAwesomeIcon icon={faCrown} />
            </IconWrapper>
            <HighlightTitle>The Dukes of Rutland</HighlightTitle>
            <HighlightText>
              Since 1703, when John Manners was created the 1st Duke of Rutland, the castle has been the 
              ancestral seat of the Dukes of Rutland. Each generation has left its mark on the castle, 
              contributing to the magnificent structure we see today.
            </HighlightText>
          </HighlightContent>
        </HighlightSection>
        
        <GallerySection ref={galleryRef} inView={galleryInView}>
          <SectionTitle>Historic Spaces</SectionTitle>
          <IntroText>
            Explore some of the magnificent rooms within Belvoir Castle, each with its own unique history and character.
          </IntroText>
          
          <GalleryGrid>
            <GalleryCard>
              <GalleryImage image={regentsGallery} />
              <GalleryOverlay className="overlay">
                <GalleryTitle>The Regents Gallery</GalleryTitle>
                <GalleryDescription>
                  Spanning 131 feet in length, the Regents Gallery is a fine example of Regency period design, 
                  featuring exquisite artwork and furnishings collected over centuries.
                </GalleryDescription>
              </GalleryOverlay>
            </GalleryCard>
            
            <GalleryCard>
              <GalleryImage image={elizabethSaloon} />
              <GalleryOverlay className="overlay">
                <GalleryTitle>The Elizabeth Saloon</GalleryTitle>
                <GalleryDescription>
                  Named after the 5th Duchess of Rutland, this French Rococo-style room features a marble statue 
                  of the Duchess herself, created by renowned sculptor Matthew Wyatt.
                </GalleryDescription>
              </GalleryOverlay>
            </GalleryCard>
            
            <GalleryCard>
              <GalleryImage image={ballroom} />
              <GalleryOverlay className="overlay">
                <GalleryTitle>The Ballroom</GalleryTitle>
                <GalleryDescription>
                  With its magnificent ceiling modelled on Lincoln Cathedral, the Ballroom has hosted countless 
                  grand events and celebrations throughout the centuries.
                </GalleryDescription>
              </GalleryOverlay>
            </GalleryCard>
            
            <GalleryCard>
              <GalleryImage image={stateDining} />
              <GalleryOverlay className="overlay">
                <GalleryTitle>The State Dining Room</GalleryTitle>
                <GalleryDescription>
                  The ceiling of the State Dining Room holds 91 entirely unique flowers, based on the Church of 
                  Santa Maria Maggiore in Rome, showcasing the exquisite craftsmanship of the era.
                </GalleryDescription>
              </GalleryOverlay>
            </GalleryCard>
          </GalleryGrid>
        </GallerySection>
        
        <DuchessSection ref={duchessRef} inView={duchessInView}>
          <SectionTitle>Notable Duchesses of Rutland</SectionTitle>
          <IntroText>
            The Duchesses of Rutland have played pivotal roles in shaping Belvoir Castle's history, 
            from architectural vision to artistic patronage.
          </IntroText>
          
          <DuchessGrid>
            <DuchessCard>
              <DuchessContent>
                <DuchessTitle>Elizabeth Howard, 5th Duchess</DuchessTitle>
                <DuchessYears>1780-1825</DuchessYears>
                <DuchessDescription>
                  Elizabeth Howard was instrumental in the Gothic Revival redesign of Belvoir Castle 
                  following the devastating fire of 1799. Working closely with architect James Wyatt, 
                  she helped create the magnificent castle we see today. The Elizabeth Saloon is named 
                  in her honor.
                </DuchessDescription>
              </DuchessContent>
            </DuchessCard>
            
            <DuchessCard>
              <DuchessContent>
                <DuchessTitle>Violet Manners, 8th Duchess</DuchessTitle>
                <DuchessYears>1876-1937</DuchessYears>
                <DuchessDescription>
                  A celebrated artist and society hostess, Violet transformed Belvoir into a hub of 
                  Edwardian social life. Her artistic talents are evident in many paintings that still 
                  hang in the castle today. She was known for her "Souls" gatherings, which brought 
                  together the intellectual and artistic elite of the era.
                </DuchessDescription>
              </DuchessContent>
            </DuchessCard>
            
            <DuchessCard>
              <DuchessContent>
                <DuchessTitle>Emma Manners, 11th Duchess</DuchessTitle>
                <DuchessYears>Present Day</DuchessYears>
                <DuchessDescription>
                  The current Duchess has dedicated herself to preserving and enhancing Belvoir Castle 
                  for future generations. Her entrepreneurial vision has introduced innovative ways to 
                  share the castle's heritage with visitors, while ensuring its sustainability as a 
                  family home and historic landmark.
                </DuchessDescription>
              </DuchessContent>
            </DuchessCard>
          </DuchessGrid>
        </DuchessSection>
        
        <FactsSection ref={factsRef} inView={factsInView}>
          <SectionTitle>Fascinating Facts</SectionTitle>
          <IntroText>
            Discover some intriguing details about Belvoir Castle's rich history.
          </IntroText>
          
          <FactsGrid>
            <FactCard>
              <FactNumber>3</FactNumber>
              <FactText>
                Belvoir Castle has been completely rebuilt at least three times throughout its history, 
                each time rising from destruction more magnificent than before.
              </FactText>
            </FactCard>
            
            <FactCard>
              <FactNumber>16,000</FactNumber>
              <FactText>
                The castle's library contains over 16,000 volumes, including rare manuscripts and 
                first editions dating back several centuries.
              </FactText>
            </FactCard>
            
            <FactCard>
              <FactNumber>35</FactNumber>
              <FactText>
                The castle has featured in at least 35 films and television productions, including 
                "The Crown," where it doubles as Windsor Castle.
              </FactText>
            </FactCard>
            
            <FactCard>
              <FactNumber>11</FactNumber>
              <FactText>
                Eleven generations of the Manners family have held the title of Duke of Rutland, 
                each contributing to the castle's evolution and legacy.
              </FactText>
            </FactCard>
          </FactsGrid>
        </FactsSection>
      </ContentContainer>
      
      <Footer />
    </HistoryContainer>
  );
};

export default History;
