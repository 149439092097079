import React from 'react';
import styled from 'styled-components';
import Footer from '../../Footer/Footer';

const TermsContainer = styled.div`
  min-height: 100vh;
  padding-top: 120px;
  background-color: ${props => props.theme.colors.offWhite};
`;

const ContentContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 4rem 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
    margin: 2rem 1rem;
  }
`;

const Title = styled.h1`
  font-family: ${props => props.theme.fonts.main};
  color: ${props => props.theme.colors.primary};
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: 2rem;

  h2 {
    color: ${props => props.theme.colors.primary};
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  h3 {
    color: ${props => props.theme.colors.primary};
    font-size: 1.4rem;
    margin: 1.5rem 0 1rem;
  }

  p {
    line-height: 1.6;
    margin-bottom: 1rem;
    color: ${props => props.theme.colors.text};
  }

  ul {
    margin-left: 2rem;
    margin-bottom: 1rem;

    li {
      line-height: 1.6;
      margin-bottom: 0.5rem;
      color: ${props => props.theme.colors.text};
    }
  }
`;

const Terms = () => {
  return (
    <TermsContainer>
      <ContentContainer>
        <Title>Terms and Conditions</Title>
        
        <Section>
          <h2>1. General Terms</h2>
          <p>
            Welcome to Belvoir Castle's website. By accessing and using this website, you agree to be bound by these Terms and Conditions.
          </p>
        </Section>

        <Section>
          <h2>2. Admissions Tickets and Entrance</h2>
          <p>
            When you purchase a ticket on the Belvoir Castle website or ticket office, you agree to the following terms and conditions:
          </p>
          <ul>
            <li>Once purchased, tickets are non-refundable. We are able to exchange your tickets to another day if you are unable to attend due to unforeseen circumstances.</li>
            <li>Please ensure when booking a time slot for the castle, you arrive between your allocated time. You may be turned away if you do not show up during your time slot.</li>
            <li>You must keep your order number safe and must be able to provide it at the ticket office to gain entry.</li>
            <li>Some tickets may be higher in price depending on if we have events running on the day.</li>
            <li>Standard Admissions Tickets can be purchased on the day at the ticket office. However, Belvoir Castle has the right to refuse walk-in Castle and Garden Entry tickets sales if we have reached our capacity limitations. It is best to pre-book tickets via our website to avoid disappointment.</li>
            <li>Due to the nature of the Castle being a family home, opening hours, events and offers are subject to change and at times it may be necessary to restrict access to the facilities. We will do our best to keep you up to date via our socials, website and email.</li>
            <li>All prices are correct at the time of publishing. However, they are subject to change without prior notice.</li>
            <li>Events will take place in all weather, unless it poses a health and safety threat. Our team will assess the weather conditions leading up to the event and we will decide if it needs to be cancelled – in which case, a reschedule or a full refund would be provided.</li>
            <li>In the event of cancellation or closure, Belvoir castle accepts no responsibility for associated travel or accommodation costs.</li>
            <li>Belvoir Castle reserves the right to refuse entry or to remove a visitor from the Castle and gardens.</li>
            <li>Photography is allowed for non-commercial private use only.</li>
            <li>Stock photography may be used for promotional and advertising purposes. If you have any queries please contact us on marketing@belvoircastle.com</li>
            <li>Parking charges are in place. Belvoir Castle does not handle parking disputes, please contact info@initialparking.co.uk if you would like to raise concerns.</li>
          </ul>
        </Section>

        <Section>
          <h2>3. Afternoon Tea Gift Vouchers</h2>
          <p>
            The following terms and conditions apply to afternoon tea gift vouchers:
          </p>
          <ul>
            <li>Validity Period: The afternoon tea gift vouchers are valid for a period of 12 months from the date of purchase.</li>
            <li>Non-Transferrable: The vouchers are non-transferrable and can only be redeemed by the individual named on the voucher.</li>
            <li>No Cash Exchange: The vouchers hold no cash value and cannot be exchanged for cash or any other form of currency.</li>
            <li>Extension Policy: Requests for voucher extensions may be considered on a case-by-case basis and are subject to approval. An extension fee may apply.</li>
            <li>Exclusion Dates: The vouchers are not valid for redemption during peak times, including but not limited to Mother's Day Weekend, Good Friday, Easter Saturday, Easter Sunday, and during the Festive Period.</li>
            <li>Reservation Requirements: Reservations are recommended and subject to availability. Please book in advance to secure your preferred date and time.</li>
            <li>Allergies and Dietary Restrictions: Please inform the venue of any allergies or dietary restrictions at the time of booking to ensure suitable arrangements can be made.</li>
          </ul>
        </Section>

        <Section>
          <h2>4. Website Usage</h2>
          <p>
            The materials on Belvoir Castle's website are provided on an 'as is' basis. We make no warranties, expressed or implied, and hereby disclaim and negate all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
          </p>
        </Section>

        <Section>
          <h2>5. Limitation of Liability</h2>
          <p>
            In no event shall Belvoir Castle or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Belvoir Castle's website.
          </p>
        </Section>

        <Section>
          <h2>6. Governing Law</h2>
          <p>
            These terms and conditions are governed by and construed in accordance with the laws of England and Wales and you irrevocably submit to the exclusive jurisdiction of the courts in that location.
          </p>
        </Section>

        <Section>
          <h2>7. Contact Us</h2>
          <p>
            If you have any questions about these Terms and Conditions, please contact us at:
          </p>
          <p>
            Email: info@belvoircastle.com<br />
            Address: Belvoir Castle, Grantham, Leicestershire, NG32 1PE
          </p>
        </Section>
      </ContentContainer>
      <Footer />
    </TermsContainer>
  );
};

export default Terms; 