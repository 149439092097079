import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const WhatsOnSection = styled.section`
  padding: 2rem 5rem;
  margin-bottom: 2rem;
  background: white;
  
  @media (max-width: 768px) {
    padding: 1.5rem 2rem;
    margin-bottom: 1.5rem;
  }
`;

const WhatsOnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
  
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const EventBadges = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
`;

const IconBadge = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
  background: #f0f0f0;
  color: #666;
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  
  svg {
    width: 16px;
    height: 16px;
  }
`;

const FlagshipBadge = styled(IconBadge)`
  background: ${props => props.theme.colors.accent};
  color: white;
`;

const LocationBadge = styled.span`
  background: ${props => props.theme.colors.accent};
  color: white;
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
`;

const EventCard = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const EventImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  
  @media (max-width: 600px) {
    height: 300px;
  }
`;

const EventTitle = styled.h3`
  color: ${props => props.theme.colors.accent};
  font-family: ${props => props.theme.fonts.main};
  margin: 1rem 0 0.5rem; 
  font-size: 1.5rem;
  
  @media (max-width: 768px) {
    font-size: 1.3rem;
    margin: 0.75rem 0 0.5rem;
  }
`;

const EventDate = styled.p`
  color: #666;
  margin: 0 0 0.5rem;
  font-weight: bold;
`;

const EventDescription = styled.p`
  color: #666;
  margin: 0 0 1rem;
  flex: 1;
  
  @media (max-width: 768px) {
    font-size: 0.95rem;
    line-height: 1.5;
  }
`;

const ReadMore = styled.button`
  color: ${props => props.theme.colors.accent};
  text-decoration: none;
  font-weight: 500;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: inherit;
  text-align: left;
  
  &:hover {
    text-decoration: underline;
  }
`;

const SectionTitle = styled.h2`
  color: ${props => props.theme.colors.accent};
  font-family: ${props => props.theme.fonts.main};
  text-align: left;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  
  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
`;

const LocationInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  margin-top: 0.75rem;
`;

const Badge = styled.span`
  padding: 0.5rem 1rem;
  background: ${props => props.theme.colors.accent};
  color: white;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

const Tag = styled.span`
  background: #f0f0f0;
  color: #666;
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: capitalize;
`;

const TicketTierBadge = styled.span`
  display: inline-block;
  background: ${props => {
    switch(props.tier) {
      case 'Super Early Bird': return '#28a745';
      case 'Early Bird': return '#17a2b8';
      case 'Advanced': return '#ffc107';
      case 'Standard': return '#6c757d';
      default: return '#6c757d';
    }
  }};
  color: white;
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 0.5rem;
`;

const EmptyMessage = styled.p`
  color: #666;
  font-size: 1.1rem;
  text-align: center;
  margin: 2rem 0;
  padding: 2rem;
  background: #f9f9f9;
  border-radius: 8px;
`;

const WhatsOn = ({ title, events, emptyMessage }) => {
  const navigate = useNavigate();
  
  const handleReadMore = (eventId) => {
    console.log("Navigating to event with ID:", eventId);
    if (eventId) {
      navigate(`/event/${eventId}`);
    } else {
      console.error("No event ID provided for navigation");
    }
  };
  
  return (
    <WhatsOnSection>
      <SectionTitle>{title}</SectionTitle>
      {events.length > 0 ? (
        <WhatsOnGrid>
          {events.map((event, index) => (
            <EventCard key={index}>
              <EventImage src={event.image} alt={event.title} />
              <ContentWrapper>
                <EventBadges>
                  <LocationBadge>{event.location}</LocationBadge>
                  {event.ticketTier && (
                    <TicketTierBadge tier={event.ticketTier}>
                      {event.ticketTier}
                    </TicketTierBadge>
                  )}
                  {event.isFlagship && (
                    <FlagshipBadge>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z"/>
                      </svg>
                      Flagship Event
                    </FlagshipBadge>
                  )}
                  {event.isEarlyBird && (
                    <IconBadge>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
                      </svg>
                      Early Bird
                    </IconBadge>
                  )}
                  {event.isDogFriendly && (
                    <IconBadge>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M21,6h-2l-1.27-1.27C17.28,4.28,16.83,4,16.34,4h-2.68c-0.49,0-0.94,0.28-1.39,0.73L11,6H9 C6.24,6,4,8.24,4,11v7c0,2.76,2.24,5,5,5h6c2.76,0,5-2.24,5-5v-7c0-2.76-2.24-5-5-5h-2l1-1h2.34l1.27,1.27C19.72,7.72,20.17,8,20.66,8H21 c0.55,0,1-0.45,1-1S21.55,6,21,6z M16,18c0,1.65-1.35,3-3,3s-3-1.35-3-3s1.35-3,3-3S16,16.35,16,18z"/>
                      </svg>
                      Dog Friendly
                    </IconBadge>
                  )}
                  {event.isAccessible && (
                    <IconBadge>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M12,2c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S10.9,2,12,2z M21,9h-6v13h-2v-6h-2v6H9V9H3V7h18V9z"/>
                      </svg>
                      Accessible
                    </IconBadge>
                  )}
                </EventBadges>
                <EventTitle>{event.title}</EventTitle>
                <EventDate>{event.date}</EventDate>
                <EventDescription>{event.description}</EventDescription>
                
                {event.eventTags && event.eventTags.length > 0 && (
                  <TagsContainer>
                    {event.eventTags.map((tag, tagIndex) => (
                      <Tag key={tagIndex}>{tag}</Tag>
                    ))}
                  </TagsContainer>
                )}
                
                <ReadMore onClick={() => handleReadMore(event.id)}>
                  {event.linkText || 'Read More'}
                </ReadMore>
              </ContentWrapper>
            </EventCard>
          ))}
        </WhatsOnGrid>
      ) : (
        <EmptyMessage>{emptyMessage || "No events currently scheduled. Check back soon!"}</EmptyMessage>
      )}
    </WhatsOnSection>
  );
};

WhatsOn.propTypes = {
  title: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  emptyMessage: PropTypes.string
};

export default WhatsOn;
