import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReCAPTCHA from 'react-google-recaptcha';
import { 
  faUsers, 
  faBus, 
  faTicket, 
  faMugHot, 
  faHandshake
} from '@fortawesome/free-solid-svg-icons';

// Import images
import castleImage from '../../../assets/castle2.png';

const GET_CONTACT_DATA = gql`
  query GetContactData {
    siteSettings(where: {title: "Global Site Settings"}) {
      nodes {
        siteSettingsFields {
          contactInfo {
            phone
            email
          }
        }
      }
    }
  }
`;

const GroupsContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 100%;
  margin-bottom: 3rem;
  text-align: left;
`;

const BenefitsSection = styled.div`
  margin-bottom: 3rem;
`;

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
`;

const BenefitCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  h4 {
    font-family: ${props => props.theme.fonts.main};
    font-size: 1.5rem;
    color: ${props => props.theme.colors.primary};
    margin: 1rem 0;
  }
  
  p {
    font-size: 1.1rem;
    line-height: 1.6;
  }
`;

const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  
  svg {
    color: white;
    font-size: 28px;
  }
`;

const FormSection = styled.div`
  margin-top: 4rem;
`;

const FormCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  padding: 2.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.colors.primary};
`;

const Input = styled.input`
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.accent};
  }
`;

const Select = styled.select`
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.accent};
  }
`;

const TextArea = styled.textarea`
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  min-height: 150px;
  grid-column: 1 / -1;
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.accent};
  }
`;

const SubmitButton = styled.button`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: 2px solid transparent;
  border-radius: 25px;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  grid-column: 1 / -1;
  justify-self: start;
  margin-top: 1rem;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const SuccessMessage = styled.div`
  background-color: #d4edda;
  color: #155724;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
  font-weight: 600;
`;

const ErrorMessage = styled.div`
  background-color: #f8d7da;
  color: #721c24;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
  font-weight: 600;
`;

const ReCaptchaWrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: flex-start;
`;

const Groups = () => {
  const { loading, error, data } = useQuery(GET_CONTACT_DATA);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    organization: '',
    groupSize: '',
    preferredDate: '',
    preferredTime: '',
    specialRequirements: '',
    hearAbout: ''
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [captchaValue, setCaptchaValue] = useState(null);
  
  // Add console log to debug the site key
  console.log('reCAPTCHA Site Key:', process.env.REACT_APP_RECAPTCHA_SITE_KEY);
  
  // Extract contact info with fallback values
  const contactInfo = data?.siteSettings?.nodes?.[0]?.siteSettingsFields?.contactInfo || {
    phone: "01476 871001",
    email: "groups@belvoircastle.com"
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!captchaValue) {
      setSubmitStatus({
        type: 'error',
        message: 'Please complete the reCAPTCHA verification.'
      });
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      const response = await fetch('http://localhost:8888/Website/wordpress/wp-json/belvoir/v1/group-enquiry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          ...formData,
          captcha: captchaValue
        })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to submit enquiry');
      }

      setFormSubmitted(true);
      
      // Reset form
      setFormData({
        name: '',
        email: '',
        phone: '',
        organization: '',
        groupSize: '',
        preferredDate: '',
        preferredTime: '',
        specialRequirements: '',
        hearAbout: ''
      });
      
      // Reset captcha after successful submission
      setCaptchaValue(null);
      
      // Scroll to top of form
      window.scrollTo({
        top: document.getElementById('groupForm').offsetTop - 100,
        behavior: 'smooth'
      });
      
    } catch (error) {
      console.error('Submission error:', error);
      setSubmitStatus({
        type: 'error',
        message: error.message || 'Something went wrong. Please try again.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <GroupsContainer>
      <ParallaxBanner 
        title="Group Visits"
        subtitle="Special arrangements for groups of 15 or more"
        image={castleImage}
      />
      
      <ContentContainer>
        <SectionTitle>Group Visits to Belvoir Castle</SectionTitle>
        <IntroText>
          If you are travelling with a group of 15 or more people, book your visit in advance, and receive 
          the following benefits to make your group visit as easy and stress-free as possible. Our dedicated 
          team will help you plan every aspect of your visit to ensure a memorable experience for your group.
        </IntroText>
        
        <BenefitsSection>
          <BenefitsGrid>
            <BenefitCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faTicket} />
              </IconWrapper>
              <h4>Discounted Admission</h4>
              <p>Special reduced rates for all members of your group when booking in advance.</p>
            </BenefitCard>
            
            <BenefitCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faBus} />
              </IconWrapper>
              <h4>Free Coach Parking</h4>
              <p>Complimentary coach parking at Belvoir Car Park for the duration of your visit.</p>
            </BenefitCard>
            
            <BenefitCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faUsers} />
              </IconWrapper>
              <h4>Free Organiser Entry</h4>
              <p>Complimentary admission for the group organiser as a thank you for arranging the visit.</p>
            </BenefitCard>
            
            <BenefitCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faMugHot} />
              </IconWrapper>
              <h4>Driver Benefits</h4>
              <p>Complimentary admission and refreshments for your coach driver.</p>
            </BenefitCard>
            
            <BenefitCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faHandshake} />
              </IconWrapper>
              <h4>Personal Welcome</h4>
              <p>Meet and greet service on arrival to help your group get oriented quickly.</p>
            </BenefitCard>
            
            <BenefitCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faTicket} />
              </IconWrapper>
              <h4>Retail Village Access</h4>
              <p>Free access and parking at Belvoir Retail Village for shopping and refreshments.</p>
            </BenefitCard>
          </BenefitsGrid>
        </BenefitsSection>
        
        <FormSection id="groupForm">
          <SectionTitle>Make a Group Booking Enquiry</SectionTitle>
          <IntroText>
            Please complete the form below to enquire about a group visit. Our dedicated team will 
            respond to your enquiry within 48 hours to discuss your requirements and help plan your visit.
          </IntroText>
          
          <FormCard>
            {formSubmitted && (
              <SuccessMessage>
                Thank you for your enquiry. Our team will be in touch with you shortly.
              </SuccessMessage>
            )}
            {submitStatus?.type === 'error' && (
              <ErrorMessage>
                {submitStatus.message}
              </ErrorMessage>
            )}
            
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="name">Contact Name *</Label>
                <Input 
                  type="text" 
                  id="name" 
                  name="name" 
                  value={formData.name} 
                  onChange={handleChange} 
                  required 
                />
              </FormGroup>
              
              <FormGroup>
                <Label htmlFor="organization">Organisation Name</Label>
                <Input 
                  type="text" 
                  id="organization" 
                  name="organization" 
                  value={formData.organization} 
                  onChange={handleChange} 
                />
              </FormGroup>
              
              <FormGroup>
                <Label htmlFor="email">Email Address *</Label>
                <Input 
                  type="email" 
                  id="email" 
                  name="email" 
                  value={formData.email} 
                  onChange={handleChange} 
                  required 
                />
              </FormGroup>
              
              <FormGroup>
                <Label htmlFor="phone">Phone Number *</Label>
                <Input 
                  type="tel" 
                  id="phone" 
                  name="phone" 
                  value={formData.phone} 
                  onChange={handleChange} 
                  required 
                />
              </FormGroup>
              
              <FormGroup>
                <Label htmlFor="groupSize">Group Size *</Label>
                <Select 
                  id="groupSize" 
                  name="groupSize" 
                  value={formData.groupSize} 
                  onChange={handleChange} 
                  required
                >
                  <option value="">Please select</option>
                  <option value="15-20">15-20 people</option>
                  <option value="21-30">21-30 people</option>
                  <option value="31-40">31-40 people</option>
                  <option value="41-50">41-50 people</option>
                  <option value="50+">More than 50 people</option>
                </Select>
              </FormGroup>
              
              <FormGroup>
                <Label htmlFor="preferredDate">Preferred Visit Date *</Label>
                <Input 
                  type="date" 
                  id="preferredDate" 
                  name="preferredDate" 
                  value={formData.preferredDate} 
                  onChange={handleChange} 
                  required 
                />
              </FormGroup>
              
              <FormGroup>
                <Label htmlFor="preferredTime">Preferred Arrival Time *</Label>
                <Select 
                  id="preferredTime" 
                  name="preferredTime" 
                  value={formData.preferredTime} 
                  onChange={handleChange} 
                  required
                >
                  <option value="">Please select</option>
                  <option value="10:00">10:00</option>
                  <option value="10:30">10:30</option>
                  <option value="11:00">11:00</option>
                  <option value="11:30">11:30</option>
                  <option value="12:00">12:00</option>
                  <option value="12:30">12:30</option>
                  <option value="13:00">13:00</option>
                  <option value="13:30">13:30</option>
                  <option value="14:00">14:00</option>
                  <option value="14:30">14:30</option>
                </Select>
              </FormGroup>
              
              <FormGroup>
                <Label htmlFor="hearAbout">How did you hear about us?</Label>
                <Select 
                  id="hearAbout" 
                  name="hearAbout" 
                  value={formData.hearAbout} 
                  onChange={handleChange}
                >
                  <option value="">Please select</option>
                  <option value="website">Website</option>
                  <option value="social">Social Media</option>
                  <option value="recommendation">Recommendation</option>
                  <option value="previous">Previous Visit</option>
                  <option value="brochure">Brochure/Leaflet</option>
                  <option value="other">Other</option>
                </Select>
              </FormGroup>
              
              <FormGroup>
                <Label htmlFor="specialRequirements">Special Requirements or Questions</Label>
                <TextArea 
                  id="specialRequirements" 
                  name="specialRequirements" 
                  value={formData.specialRequirements} 
                  onChange={handleChange} 
                  placeholder="Please let us know about any special requirements, accessibility needs, or questions you may have."
                />
              </FormGroup>
              
              <ReCaptchaWrapper>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LeP8wArAAAAAEF-wVDICNGHfRo9hDA70aLQeDnB'}
                  onChange={handleCaptchaChange}
                  theme="light"
                />
              </ReCaptchaWrapper>
              
              <SubmitButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Submit Enquiry'}
              </SubmitButton>
            </Form>
          </FormCard>
        </FormSection>
        
        <FormSection>
          <SectionTitle>Contact Our Groups Team Directly</SectionTitle>
          <IntroText>
            If you prefer to discuss your group visit requirements by phone or email, please contact our 
            dedicated Groups Team using the details below:
          </IntroText>
          
          <FormCard>
            <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
              <strong>Phone:</strong> <a href={`tel:${contactInfo.phone}`} style={{ color: theme.colors.accent }}>{contactInfo.phone}</a>
            </p>
            <p style={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
              <strong>Email:</strong> <a href={`mailto:${contactInfo.email}`} style={{ color: theme.colors.accent }}>{contactInfo.email}</a>
            </p>
            <p style={{ fontSize: '1.1rem' }}>
              Our team is available Monday to Friday, 9:00am to 5:00pm to assist with your enquiry.
            </p>
          </FormCard>
        </FormSection>
      </ContentContainer>
      
      <Footer />
    </GroupsContainer>
  );
};

export default Groups;