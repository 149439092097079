import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

// Create an HTTP link to your WordPress GraphQL endpoint
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_WORDPRESS_GRAPHQL_URL,
});

// Create the Apollo Client instance
const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export default client; 