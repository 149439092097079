import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBriefcase, 
  faUtensils, 
  faFilm, 
  faGlassCheers, 
  faCalendarAlt, 
  faUsers
} from '@fortawesome/free-solid-svg-icons';

// Import images from existing assets
import castleImage from '../../../assets/castle.png';
import gardensImage from '../../../assets/gardens.png';
import retailImage from '../../../assets/retail.png';
import playgroundImage from '../../../assets/play.png';
import regentsGallery from '../../../assets/regents-gallery.jpg';
import elizabethSaloon from '../../../assets/elizabeth-saloon.jpg';
import ballroom from '../../../assets/ballroom.jpg';
import roseGarden from '../../../assets/rose-garden.jpg';
import stateDining from '../../../assets/state-dining.jpg';
import oldKitchen from '../../../assets/old-kitchen.jpg';
import activities from '../../../assets/activities.jpg';
import accommodation from '../../../assets/accommodation.jpg';
import privateHireBanner from '../../../assets/banner/roomBan.webp';
import valeHouseImage from '../../../assets/holidays/vale-house.webp';
import parkHouseImage from '../../../assets/holidays/park-house.webp';
import parkLodgeImage from '../../../assets/holidays/park-lodge.webp';
import lodgeCottageImage from '../../../assets/holidays/lodge-cottage.webp';



const GET_PRIVATE_HIRE_DATA = gql`
  query GetPrivateHireData {
    siteSettings(where: {title: "Global Site Settings"}) {
      nodes {
        siteSettingsFields {
          contactInfo {
            phone
            email
          }
        }
      }
    }
  }
`;

const PrivateHireContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const FeatureSection = styled.div`
  display: flex;
  margin: 5rem 0;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  
  @media (max-width: 992px) {
    flex-direction: ${props => props.imagePosition === 'right' ? 'column-reverse' : 'column'};
  }
`;

const FeatureImage = styled.div`
  flex: 1;
  min-height: 500px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  
  @media (max-width: 992px) {
    min-height: 300px;
  }
`;

const FeatureContent = styled.div`
  flex: 1;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const FeatureTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const FeatureDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 0 2rem 0;
  
  li {
    position: relative;
    padding-left: 2rem;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    line-height: 1.6;
    
    &:before {
      content: "✓";
      position: absolute;
      left: 0;
      color: ${props => props.theme.colors.accent};
      font-weight: bold;
    }
  }
`;

const FeatureButton = styled.button`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: 2px solid transparent;
  border-radius: 25px;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  align-self: flex-start;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const OptionsSection = styled.div`
  margin: 5rem 0;
`;

const OptionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const OptionCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
`;

const OptionImage = styled.div`
  height: 200px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const OptionContent = styled.div`
  padding: 2rem;
`;

const OptionTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const OptionDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const TestimonialsSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 5rem 2rem;
  margin: 5rem 0;
  text-align: center;
`;

const TestimonialsContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const TestimonialsTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: white;
  margin-bottom: 3rem;
`;

const TestimonialCard = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 2.5rem;
  margin-bottom: 2rem;
`;

const TestimonialText = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  font-style: italic;
  margin-bottom: 1.5rem;
`;

const TestimonialAuthor = styled.p`
  font-size: 1.1rem;
  font-weight: 600;
`;

const VenuesSection = styled.div`
  margin: 5rem 0;
`;

const VenuesTabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  flex-wrap: wrap;
`;

const VenuesTab = styled.button`
  background: ${props => props.active ? props.theme.colors.accent : 'transparent'};
  color: ${props => props.active ? 'white' : props.theme.colors.primary};
  border: 2px solid ${props => props.active ? props.theme.colors.accent : props.theme.colors.primary};
  padding: 1rem 2rem;
  margin: 0.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 25px;
  
  &:hover {
    background: ${props => props.active ? props.theme.colors.accent : props.theme.colors.greyBackground};
  }
`;

const VenueContent = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const VenueImage = styled.div`
  flex: 1;
  min-height: 400px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  
  @media (max-width: 992px) {
    min-height: 300px;
  }
`;

const VenueInfo = styled.div`
  flex: 1;
  padding: 3rem;
`;

const VenueTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const VenueDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
`;

const VenueDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin: 2rem 0;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const VenueDetailItem = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`;

const VenueDetailTitle = styled.h5`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
`;

const VenueDetailText = styled.p`
  font-size: 1rem;
  line-height: 1.6;
`;

const ContactSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 4rem 2rem;
  text-align: center;
  margin: 5rem 0 0 0;
`;

const ContactContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const ContactTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: white;
  margin-bottom: 1.5rem;
`;

const ContactText = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const ContactButton = styled.a`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  text-decoration: none;
  border: 2px solid transparent;
  border-radius: 25px;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  
  svg {
    color: white;
    font-size: 32px;
  }
`;

const PrivateHire = () => {
  const { loading, error, data } = useQuery(GET_PRIVATE_HIRE_DATA);
  const [activeVenue, setActiveVenue] = useState('regents');
  
  // Extract contact info with fallback values
  const contactInfo = data?.siteSettings?.nodes?.[0]?.siteSettingsFields?.contactInfo || {
    phone: "01476 871001",
    email: "events@belvoircastle.com"
  };
  
  const handleVenueChange = (venue) => {
    setActiveVenue(venue);
  };
  
  const handleEnquiry = () => {
    window.location.href = `mailto:${contactInfo.email}`;
  };
  
  const renderVenueContent = () => {
    switch(activeVenue) {
      case 'regents':
        return (
          <VenueContent>
            <VenueImage image={regentsGallery} />
            <VenueInfo>
              <VenueTitle>The Regents Gallery</VenueTitle>
              <VenueDescription>
                A magnificent space perfect for grand receptions, product launches, and corporate dinners. 
                The Regents Gallery spans 131 feet in length, with a large bay in the center housed within 
                the stunning tower visible as guests approach the Castle.
              </VenueDescription>
              <VenueDetails>
                <VenueDetailItem>
                  <VenueDetailTitle>Features</VenueDetailTitle>
                  <VenueDetailText>Natural daylight</VenueDetailText>
                  <VenueDetailText>Historic artwork and décor</VenueDetailText>
                  <VenueDetailText>Stunning views of the estate</VenueDetailText>
                </VenueDetailItem>
                <VenueDetailItem>
                  <VenueDetailTitle>Ideal For</VenueDetailTitle>
                  <VenueDetailText>Gala dinners</VenueDetailText>
                  <VenueDetailText>Product launches</VenueDetailText>
                  <VenueDetailText>Corporate receptions</VenueDetailText>
                </VenueDetailItem>
                <VenueDetailItem>
                  <VenueDetailTitle>Availability</VenueDetailTitle>
                  <VenueDetailText>Available year-round</VenueDetailText>
                  <VenueDetailText>Evening events from 6:30pm during Castle open season</VenueDetailText>
                  <VenueDetailText>Full day availability during closed season</VenueDetailText>
                </VenueDetailItem>
              </VenueDetails>
              <FeatureButton onClick={handleEnquiry}>Enquire Now</FeatureButton>
            </VenueInfo>
          </VenueContent>
        );
      
      case 'elizabeth':
        return (
          <VenueContent>
            <VenueImage image={elizabethSaloon} />
            <VenueInfo>
              <VenueTitle>The Elizabeth Saloon</VenueTitle>
              <VenueDescription>
                A French Rococo-style room containing many fabulous features, the Elizabeth Saloon offers an 
                elegant setting for intimate dinners, board meetings, and exclusive presentations. Named after 
                Elizabeth, the 5th Duchess, this room exudes aristocratic charm and sophistication.
              </VenueDescription>
              <VenueDetails>
                <VenueDetailItem>
                  <VenueDetailTitle>Features</VenueDetailTitle>
                  <VenueDetailText>Ornate ceiling and décor</VenueDetailText>
                  <VenueDetailText>Historic artwork</VenueDetailText>
                  <VenueDetailText>Carara marble statue of the 5th Duchess</VenueDetailText>
                </VenueDetailItem>
                <VenueDetailItem>
                  <VenueDetailTitle>Ideal For</VenueDetailTitle>
                  <VenueDetailText>Intimate dinners</VenueDetailText>
                  <VenueDetailText>Board meetings</VenueDetailText>
                  <VenueDetailText>Exclusive presentations</VenueDetailText>
                </VenueDetailItem>
                <VenueDetailItem>
                  <VenueDetailTitle>Availability</VenueDetailTitle>
                  <VenueDetailText>Available year-round</VenueDetailText>
                  <VenueDetailText>Evening events from 6:30pm during Castle open season</VenueDetailText>
                  <VenueDetailText>Full day availability during closed season</VenueDetailText>
                </VenueDetailItem>
              </VenueDetails>
              <FeatureButton onClick={handleEnquiry}>Enquire Now</FeatureButton>
            </VenueInfo>
          </VenueContent>
        );
      
      case 'ballroom':
        return (
          <VenueContent>
            <VenueImage image={ballroom} />
            <VenueInfo>
              <VenueTitle>The Ballroom</VenueTitle>
              <VenueDescription>
                With its magnificent ceiling modelled on Lincoln Cathedral, designed by Sir John Thoroton, 
                the Ballroom provides a spectacular setting for conferences, presentations, and special events. 
                The grandeur of this space will leave a lasting impression on your guests.
              </VenueDescription>
              <VenueDetails>
                <VenueDetailItem>
                  <VenueDetailTitle>Features</VenueDetailTitle>
                  <VenueDetailText>Cathedral-inspired ceiling</VenueDetailText>
                  <VenueDetailText>Excellent acoustics</VenueDetailText>
                  <VenueDetailText>State-of-the-art AV equipment available</VenueDetailText>
                </VenueDetailItem>
                <VenueDetailItem>
                  <VenueDetailTitle>Ideal For</VenueDetailTitle>
                  <VenueDetailText>Conferences</VenueDetailText>
                  <VenueDetailText>Presentations</VenueDetailText>
                  <VenueDetailText>Award ceremonies</VenueDetailText>
                </VenueDetailItem>
                <VenueDetailItem>
                  <VenueDetailTitle>Availability</VenueDetailTitle>
                  <VenueDetailText>Available year-round</VenueDetailText>
                  <VenueDetailText>Evening events from 6:30pm during Castle open season</VenueDetailText>
                  <VenueDetailText>Full day availability during closed season</VenueDetailText>
                </VenueDetailItem>
              </VenueDetails>
              <FeatureButton onClick={handleEnquiry}>Enquire Now</FeatureButton>
            </VenueInfo>
          </VenueContent>
        );
      
      case 'garden':
        return (
          <VenueContent>
            <VenueImage image={roseGarden} />
            <VenueInfo>
              <VenueTitle>The Rose Garden</VenueTitle>
              <VenueDescription>
                A breathtakingly beautiful outdoor setting that provides the perfect backdrop for summer 
                receptions, garden parties, and team-building events. This enchanting garden, home to over 
                800 roses, bursts with vibrant colors and intoxicating fragrances, creating a memorable 
                atmosphere for your event.
              </VenueDescription>
              <VenueDetails>
                <VenueDetailItem>
                  <VenueDetailTitle>Features</VenueDetailTitle>
                  <VenueDetailText>Over 800 roses in various varieties</VenueDetailText>
                  <VenueDetailText>Stunning views of the Castle</VenueDetailText>
                  <VenueDetailText>Marquee options available</VenueDetailText>
                </VenueDetailItem>
                <VenueDetailItem>
                  <VenueDetailTitle>Ideal For</VenueDetailTitle>
                  <VenueDetailText>Summer receptions</VenueDetailText>
                  <VenueDetailText>Garden parties</VenueDetailText>
                  <VenueDetailText>Team-building events</VenueDetailText>
                </VenueDetailItem>
                <VenueDetailItem>
                  <VenueDetailTitle>Availability</VenueDetailTitle>
                  <VenueDetailText>Available May through September</VenueDetailText>
                  <VenueDetailText>Weather dependent</VenueDetailText>
                  <VenueDetailText>Indoor backup options available</VenueDetailText>
                </VenueDetailItem>
              </VenueDetails>
              <FeatureButton onClick={handleEnquiry}>Enquire Now</FeatureButton>
            </VenueInfo>
          </VenueContent>
        );
      
      default:
        return null;
    }
  };
  
  return (
    <PrivateHireContainer>
      <ParallaxBanner 
        title="Private Hire at Belvoir Castle"
        subtitle="Exclusive Venues for Extraordinary Events"
        image={privateHireBanner}
      />
      
      <ContentContainer>
        <SectionTitle>Elevate Your Event in a Historic Setting</SectionTitle>
        <IntroText>
          Belvoir Castle offers exclusive private hire options for corporate events, special celebrations, 
          and filming opportunities. With a range of magnificent spaces steeped in history, your event will 
          leave a lasting impression on your guests.
        </IntroText>
        
        <FeatureSection imagePosition="left">
          <FeatureImage image={regentsGallery} />
          <FeatureContent>
            <IconWrapper>
              <FontAwesomeIcon icon={faBriefcase} />
            </IconWrapper>
            <FeatureTitle>Corporate Events & Away Days</FeatureTitle>
            <FeatureDescription>
              Host your next corporate event in the grandeur of Belvoir Castle. From board meetings and 
              conferences to team-building days and product launches, our historic spaces provide an 
              inspiring backdrop for business success.
            </FeatureDescription>
            <FeatureList>
              <li>Exclusive use of selected Castle State Rooms</li>
              <li>Flexible spaces for groups of various sizes</li>
              <li>State-of-the-art AV equipment available</li>
              <li>Bespoke catering options from our executive chef</li>
              <li>Team-building activities including archery, clay pigeon shooting, and treasure hunts</li>
              <li>Dedicated event coordinator to ensure seamless execution</li>
            </FeatureList>
            <FeatureButton onClick={handleEnquiry}>Enquire Now</FeatureButton>
          </FeatureContent>
        </FeatureSection>
        
        <OptionsSection>
          <SectionTitle>Exclusive Hire Options</SectionTitle>
          <IntroText>
            Discover our range of private hire options, each offering a unique experience in the historic 
            surroundings of Belvoir Castle.
          </IntroText>
          
          <OptionsGrid>
            <OptionCard>
              <OptionImage image={stateDining} />
              <OptionContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faUtensils} />
                </IconWrapper>
                <OptionTitle>Private Dining</OptionTitle>
                <OptionDescription>
                  Host an unforgettable dining experience in our State Dining Room or Elizabeth Saloon. 
                  Our executive chef will create a bespoke menu featuring the finest seasonal ingredients, 
                  many sourced from the Belvoir estate.
                </OptionDescription>
              </OptionContent>
            </OptionCard>
            
            <OptionCard>
              <OptionImage image={regentsGallery} />
              <OptionContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faFilm} />
                </IconWrapper>
                <OptionTitle>Filming & Photography</OptionTitle>
                <OptionDescription>
                  Belvoir Castle has featured in numerous films and TV series, including "The Crown" and 
                  "Victoria." Our historic interiors and stunning landscapes provide the perfect backdrop 
                  for period dramas, commercials, and fashion shoots.
                </OptionDescription>
              </OptionContent>
            </OptionCard>
            
            <OptionCard>
              <OptionImage image={ballroom} />
              <OptionContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faGlassCheers} />
                </IconWrapper>
                <OptionTitle>Special Celebrations</OptionTitle>
                <OptionDescription>
                  Mark milestone birthdays, anniversaries, and other special occasions in the grandeur of 
                  Belvoir Castle. Our events team will work with you to create a bespoke celebration that 
                  reflects your personal style.
                </OptionDescription>
              </OptionContent>
            </OptionCard>
            
            <OptionCard>
              <OptionImage image={activities} />
              <OptionContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faUsers} />
                </IconWrapper>
                <OptionTitle>Team Building</OptionTitle>
                <OptionDescription>
                  Strengthen team bonds with our range of engaging activities set against the backdrop of 
                  the Castle and its grounds. From outdoor adventures to creative workshops, we offer 
                  experiences to suit every team.
                </OptionDescription>
              </OptionContent>
            </OptionCard>
            
            <OptionCard>
              <OptionImage image={roseGarden} />
              <OptionContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </IconWrapper>
                <OptionTitle>Garden Parties</OptionTitle>
                <OptionDescription>
                  Host a sophisticated garden party in our Rose Garden or formal gardens. With stunning views 
                  of the Castle and surrounding countryside, these outdoor spaces provide a magical setting 
                  for summer events.
                </OptionDescription>
              </OptionContent>
            </OptionCard>
            
            <OptionCard>
              <OptionImage image={elizabethSaloon} />
              <OptionContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faBriefcase} />
                </IconWrapper>
                <OptionTitle>Product Launches</OptionTitle>
                <OptionDescription>
                  Make a statement with your next product launch at Belvoir Castle. Our historic spaces 
                  provide a prestigious backdrop that will elevate your brand and create a memorable 
                  experience for your guests.
                </OptionDescription>
              </OptionContent>
            </OptionCard>
          </OptionsGrid>
        </OptionsSection>
        
        <TestimonialsSection>
          <TestimonialsContent>
            <TestimonialsTitle>What Our Clients Say</TestimonialsTitle>
            <TestimonialCard>
              <TestimonialText>
                "Hosting our annual board meeting at Belvoir Castle elevated the entire experience. The historic 
                surroundings inspired creative thinking, and the service was impeccable. Our team is still talking 
                about it months later."
              </TestimonialText>
              <TestimonialAuthor>— James Thompson</TestimonialAuthor>
            </TestimonialCard>
            <TestimonialCard>
              <TestimonialText>
                "The Elizabeth Saloon provided the perfect setting for our product launch. The grandeur of the space 
                complemented our luxury brand perfectly, and the events team ensured every detail was executed flawlessly."
              </TestimonialText>
              <TestimonialAuthor>— Sarah Williams</TestimonialAuthor>
            </TestimonialCard>
          </TestimonialsContent>
        </TestimonialsSection>
        
        <VenuesSection>
          <SectionTitle>Our Magnificent Spaces</SectionTitle>
          <IntroText>
            Explore our range of historic venues, each offering a unique atmosphere for your event.
          </IntroText>
          
          <VenuesTabs>
            <VenuesTab 
              active={activeVenue === 'regents'} 
              onClick={() => handleVenueChange('regents')}
            >
              Regents Gallery
            </VenuesTab>
            <VenuesTab 
              active={activeVenue === 'elizabeth'} 
              onClick={() => handleVenueChange('elizabeth')}
            >
              Elizabeth Saloon
            </VenuesTab>
            <VenuesTab 
              active={activeVenue === 'ballroom'} 
              onClick={() => handleVenueChange('ballroom')}
            >
              Ballroom
            </VenuesTab>
            <VenuesTab 
              active={activeVenue === 'garden'} 
              onClick={() => handleVenueChange('garden')}
            >
              Rose Garden
            </VenuesTab>
          </VenuesTabs>
          {renderVenueContent()}
        </VenuesSection>
        
        <FeatureSection imagePosition="right">
          <FeatureContent>
            <FeatureTitle>Bespoke Corporate Packages</FeatureTitle>
            <FeatureDescription>
              Our corporate packages can be tailored to meet your specific requirements, whether you're 
              planning a board meeting, team-building day, or client entertainment event.
            </FeatureDescription>
            <FeatureList>
              <li>Full-day and half-day delegate packages available</li>
              <li>Exclusive use of selected Castle spaces</li>
              <li>Catering options from breakfast to formal dinners</li>
              <li>Team-building activities including estate tours, clay pigeon shooting, and wine tasting</li>
              <li>Accommodation options for overnight stays</li>
              <li>Dedicated event coordinator to manage all aspects of your event</li>
            </FeatureList>
            <FeatureButton onClick={handleEnquiry}>Request a Proposal</FeatureButton>
          </FeatureContent>
          <FeatureImage image={oldKitchen} />
        </FeatureSection>
        
        <FeatureSection imagePosition="left">
          <FeatureImage image={valeHouseImage} />
          <FeatureContent>
            <FeatureTitle>Accommodation Options</FeatureTitle>
            <FeatureDescription>
              Enhance your event with overnight accommodation for your guests. We offer a range of 
              options across the Belvoir Estate, from historic bedrooms in the Castle to charming 
              cottages in the grounds.
            </FeatureDescription>
            <FeatureList>
              <li>Historic bedrooms within the Castle</li>
              <li>Additional accommodation in properties across the estate</li>
              <li>All rooms feature en-suite or private bathrooms</li>
              <li>Full English breakfast included</li>
              <li>Exclusive use options available</li>
              <li>Perfect for multi-day events and retreats</li>
            </FeatureList>
            <FeatureButton onClick={handleEnquiry}>Enquire About Accommodation</FeatureButton>
          </FeatureContent>
        </FeatureSection>
        
        <ContactSection>
          <ContactContent>
            <ContactTitle>Begin Planning Your Event</ContactTitle>
            <ContactText>
              Our experienced events team is ready to help you create an unforgettable experience at 
              Belvoir Castle. Contact us today to discuss your requirements and check availability.
            </ContactText>
            <ContactButton href={`mailto:${contactInfo.email}`}>
              Contact Our Events Team
            </ContactButton>
          </ContactContent>
        </ContactSection>
      </ContentContainer>
      
      <Footer />
    </PrivateHireContainer>
  );
};

export default PrivateHire;