const VENPOS_API_BASE = 'https://online1.venpos.net/VenposJourneyAPI';

export const venposService = {
  async getWebPageData(locationId, pageId) {
    try {
      console.log('Fetching WebPage data with parameters:', {
        locationId,
        pageId
      });

      const response = await fetch(
        `${VENPOS_API_BASE}/WebPage/Get?` +
        `locationId=${locationId}&` +
        `id=${pageId}`,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json, text/javascript, */*; q=0.01',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Origin': 'https://online1.venpos.net',
            'Referer': 'https://online1.venpos.net/VenPosJourneyUI/DateSelector',
            'sec-fetch-dest': 'empty',
            'sec-fetch-mode': 'cors',
            'sec-fetch-site': 'same-origin'
          },
          credentials: 'include'
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch WebPage data');
      }

      const data = await response.json();
      console.log('WebPage Raw Response:', JSON.stringify(data, null, 2));
      return data;
    } catch (error) {
      console.error('Error fetching WebPage data:', error);
      throw error;
    }
  },

  async getWebSaleItems(locationId, webPageId, selectedDate) {
    const response = await fetch(
      `${VENPOS_API_BASE}/WebSaleItem/Get?` +
      `locationId=${locationId}&` +
      `id=${webPageId}&` +
      `pageType=WebPage&` +
      `dateSelectorType=OnScreenCalendar&` +
      `selectedDate=${selectedDate}`
    );
    
    if (!response.ok) {
      throw new Error('Failed to fetch web sale items');
    }
    
    return response.json();
  },

  async validateBasket(locationId, basket) {
    const response = await fetch(
      `${VENPOS_API_BASE}/Basket/ValidateTimes?locationId=${locationId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(basket),
      }
    );
    
    if (!response.ok) {
      throw new Error('Failed to validate basket');
    }
    
    return response.json();
  },

  async getAvailableDates(locationId, pageId, options = {}, selectedItems = []) {
    const today = new Date();
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 12);

    const {
      pageType = 'WebPage',
      selectedDate = '2024-03-19',
      dateSelectorType = 'OnScreenCalendar',
      lng = 'en',
      webSaleItemId
    } = options;

    try {
      console.log('Calendar Request Parameters:', {
        locationId,
        pageId,
        webSaleItemId,
        selectedDate,
        endDate: endDate.toISOString(),
        pageType,
        dateSelectorType,
        selectedItems
      });

      const allDates = [];
      let currentDate = new Date(selectedDate);
      let monthCount = 1;
      
      while (currentDate <= endDate) {
        const formattedCurrentDate = currentDate.toISOString().split('T')[0];
        
        const requestUrl = new URL(`${VENPOS_API_BASE}/WebSaleItem/GetCalendar`);
        requestUrl.searchParams.append('locationId', locationId);
        requestUrl.searchParams.append('id', pageId);
        requestUrl.searchParams.append('pageType', pageType);
        requestUrl.searchParams.append('selectedDate', formattedCurrentDate);
        requestUrl.searchParams.append('dateSelectorType', dateSelectorType);
        
        console.log(
          `\nFetching dates for ${currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}...`
        );

        const response = await fetch(requestUrl.toString(), {
          method: 'POST',
          headers: {
            'Accept': 'application/json, text/javascript, */*; q=0.01',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          },
          body: JSON.stringify({
            items: selectedItems
          })
        });

        if (!response.ok) {
          console.error(
            `Error fetching ${currentDate.toLocaleString('default', { month: 'long' })}:`, 
            await response.text()
          );
          throw new Error(`Failed to fetch dates for ${currentDate.toLocaleString('default', { month: 'long' })}`);
        }

        const calendarData = await response.json();
        
        if (!calendarData.isError && calendarData.data?.datePrices) {
          const formattedDates = calendarData.data.datePrices.map(datePrice => ({
            ...datePrice,
            date: new Date(datePrice.date).toISOString().replace(/\.\d{3}Z$/, '')
          }));
          allDates.push(...formattedDates);
        }

        currentDate.setMonth(currentDate.getMonth() + 1);
        monthCount++;
      }

      console.log('\n=== CALENDAR DATA SUMMARY ===');
      console.log(`Total dates retrieved: ${allDates.length}`);
      
      const datesByMonth = allDates.reduce((acc, date) => {
        const monthYear = new Date(date.date).toLocaleString('default', { month: 'long', year: 'numeric' });
        if (!acc[monthYear]) {
          acc[monthYear] = [];
        }
        acc[monthYear].push({
          date: date.date,
          disabled: date.disabled,
          price: date.price,
          capacityPotExists: date.capacityPotExists,
          disabledReason: date.disabledReason
        });
        return acc;
      }, {});

      Object.entries(datesByMonth).forEach(([monthYear, dates]) => {
        console.log(`\n${monthYear}:`);
        console.table(dates);
        console.log(`Available dates: ${dates.filter(d => !d.disabled).length}`);
        console.log(`Disabled dates: ${dates.filter(d => d.disabled).length}`);
      });

      console.log('\n=== AVAILABILITY SUMMARY ===');
      console.log(`Total available dates: ${allDates.filter(d => !d.disabled).length}`);
      console.log(`Total disabled dates: ${allDates.filter(d => d.disabled).length}`);
      console.log('===============================\n');

      const processedData = {
        isError: false,
        data: {
          calendar: allDates.map(datePrice => ({
            date: datePrice.date,
            availability: !datePrice.disabled ? 4 : 1,
            debug: {
              price: datePrice.price,
              capacityPotExists: datePrice.capacityPotExists,
              disabledReason: datePrice.disabledReason
            }
          }))
        }
      };

      return processedData;

    } catch (error) {
      console.error('Error in getAvailableDates:', error);
      throw error;
    }
  }
}; 