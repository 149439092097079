import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFire, 
  faClock, 
  faLeaf, 
  faMapMarkerAlt,
  faTicketAlt,
  faArrowRight,
  faUtensils,
  faPercent,
  faMobileAlt,
  faCoffee
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import pizzaVideo from '../../../assets/pizza.mp4';

const PizzaContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const Section = styled.section`
  padding: 6rem 0;
  background-color: ${props => props.alternate ? props.theme.colors.greyBackground : 'white'};
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.8rem;
  color: ${props => props.theme.colors.primary};
  text-align: center;
  margin-bottom: 2rem;
  
  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 3px;
    background-color: ${props => props.theme.colors.accent};
    margin: 1rem auto 0;
  }
`;

const MenuGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const MenuItem = styled.div`
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const MenuItemTitle = styled.h3`
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  span {
    color: ${props => props.theme.colors.accent};
  }
`;

const MenuItemDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: ${props => props.theme.colors.text};
  margin-bottom: 1rem;
`;

const MenuItemTags = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const MenuItemTag = styled.span`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 4rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  text-align: center;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  
  svg {
    color: white;
    font-size: 32px;
  }
`;

const FeatureTitle = styled.h4`
  font-size: 1.4rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const VideoHero = styled.div`
  position: relative;
  height: 90vh;
  overflow: hidden;
  background: ${props => props.theme.colors.primary}; // Fallback background
  
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
    object-fit: cover;
    z-index: 1;
  }
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.5)
  );
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
`;

const HeroTitle = styled.h1`
  font-family: ${props => props.theme.fonts.main};
  font-size: 4rem;
  color: white;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  
  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const ScrollIndicator = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  cursor: pointer;
  animation: bounce 2s infinite;

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateX(-50%) translateY(0);
    }
    40% {
      transform: translateX(-50%) translateY(-10px);
    }
    60% {
      transform: translateX(-50%) translateY(-5px);
    }
  }

  svg {
    width: 40px;
    height: 40px;
    fill: none;
    stroke: white;
    stroke-width: 2;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.3));
    opacity: 0.8;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }
`;

// Add this new styled component for menu categories
const MenuCategory = styled.div`
  margin-bottom: 3rem;
`;

const CategoryTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
`;

// Add these styled components to match Bistro styling
const MenuSection = styled.div`
  margin: 5rem 0;
`;

const MenuTabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  flex-wrap: wrap;
`;

const MenuTab = styled.button`
  background: ${props => props.active ? props.theme.colors.accent : 'transparent'};
  color: ${props => props.active ? 'white' : props.theme.colors.primary};
  border: 2px solid ${props => props.active ? props.theme.colors.accent : props.theme.colors.primary};
  padding: 0.8rem 1.5rem;
  margin: 0.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50px;
  
  &:hover {
    background: ${props => props.active ? props.theme.colors.accent : props.theme.colors.greyBackground};
  }
`;

// Add these styled components
const WelcomeSection = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 0;
`;

const WelcomeText = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.text};
  margin-bottom: 2rem;
`;

// Add these styled components
const MembershipSection = styled.section`
  background: ${props => props.theme.colors.greyBackground};
  padding: 4rem 0;
  text-align: center;
`;

const MembershipCard = styled.div`
  background: white;
  max-width: 800px;
  margin: 0 auto;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const MembershipIcon = styled.div`
  width: 80px;
  height: 80px;
  background: ${props => props.theme.colors.accent};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  
  svg {
    color: white;
    font-size: 2.5rem;
  }
`;

const ComingSoonBadge = styled.span`
  display: inline-block;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  margin-left: 0.5rem;
  vertical-align: middle;
`;

const MembershipButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: 25px;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: ${props => props.theme.shadows.light};
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    background-color: ${props => {
      // Darken the accent color slightly on hover
      const color = props.theme.colors.accent;
      return `${color}dd`;
    }};
  }
  
  &:active {
    transform: translateY(0);
  }
  
  svg {
    margin-left: 0.5rem;
  }
`;

const IntroText = styled.p`
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
`;

const BenefitsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
`;

const BenefitItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const BenefitText = styled.span`
  margin-left: 0.5rem;
`;

const WoodfiredPizza = () => {
  const [activeTab, setActiveTab] = React.useState('snacks');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const menuData = {
    snacks: [
      {
        title: "Mozzarella sticks",
        price: "7.00",
        description: "",
        tags: ["v"]
      },
      {
        title: "Mac and cheese bites",
        price: "5.50",
        description: "",
        tags: ["v"]
      },
      {
        title: "Chicken Goujons",
        price: "7.50",
        description: "",
        tags: []
      },
      {
        title: "Chinese duck bon bon",
        price: "6.00",
        description: "",
        tags: []
      }
    ],
    pizzas: [
      {
        title: "Margherita",
        price: "10.95",
        description: "Pomodoro sauce and mozzarella",
        tags: ["v", "vg*"]
      },
      {
        title: "Pepperoni",
        price: "15.95",
        description: "pepperoni, salami, jalapeños",
        tags: []
      },
      {
        title: "Mighty Meat",
        price: "18.95",
        description: "chicken, beef, chorizo, peppers, onions",
        tags: []
      },
      {
        title: "Seafood",
        price: "20.95",
        description: "garlic Tiger prawns, smoked salmon, ricotta & chives",
        tags: []
      },
      {
        title: "Veggie",
        price: "15.95",
        description: "Red Leicester, mushrooms, olives, peppers, red onion",
        tags: ["v", "vg*"]
      }
    ],
    paninis: [
      {
        title: "Ham and cheese panini",
        price: "6.50",
        description: "honey-glazed ham, extra mature cheddar",
        tags: ["gf*"]
      },
      {
        title: "Tomato and cheddar panini",
        price: "5.50",
        description: "plum tomato, extra mature cheddar",
        tags: ["v", "gf*"]
      },
      {
        title: "Cajun chicken",
        price: "7.00",
        description: "Cajun spiced chicken breast, Emmental cheese",
        tags: ["gf*"]
      }
    ],
    sides: [
      {
        title: "Skin on fries",
        price: "4.95",
        description: "",
        tags: ["v"]
      },
      {
        title: "Truffle skin-on fries",
        price: "7.45",
        description: "",
        tags: ["v"]
      },
      {
        title: "Sweet potato fries",
        price: "4.95",
        description: "",
        tags: ["v"]
      }
    ]
  };

  const renderMenuItems = () => {
    switch(activeTab) {
      case 'snacks':
        return (
          <MenuGrid>
            {menuData.snacks.map((item, index) => (
              <MenuItem key={index}>
                <MenuItemTitle>
                  {item.title} <span>£{item.price}</span>
                </MenuItemTitle>
                {item.description && (
                  <MenuItemDescription>{item.description}</MenuItemDescription>
                )}
                {item.tags.length > 0 && (
                  <MenuItemTags>
                    {item.tags.map(tag => (
                      <MenuItemTag key={tag}>{tag}</MenuItemTag>
                    ))}
                  </MenuItemTags>
                )}
              </MenuItem>
            ))}
          </MenuGrid>
        );

      case 'pizzas':
        return (
          <MenuGrid>
            {menuData.pizzas.map((item, index) => (
              <MenuItem key={index}>
                <MenuItemTitle>
                  {item.title} <span>£{item.price}</span>
                </MenuItemTitle>
                {item.description && (
                  <MenuItemDescription>{item.description}</MenuItemDescription>
                )}
                {item.tags.length > 0 && (
                  <MenuItemTags>
                    {item.tags.map(tag => (
                      <MenuItemTag key={tag}>{tag}</MenuItemTag>
                    ))}
                  </MenuItemTags>
                )}
              </MenuItem>
            ))}
          </MenuGrid>
        );

      case 'paninis':
        return (
          <MenuGrid>
            {menuData.paninis.map((item, index) => (
              <MenuItem key={index}>
                <MenuItemTitle>
                  {item.title} <span>£{item.price}</span>
                </MenuItemTitle>
                {item.description && (
                  <MenuItemDescription>{item.description}</MenuItemDescription>
                )}
                {item.tags.length > 0 && (
                  <MenuItemTags>
                    {item.tags.map(tag => (
                      <MenuItemTag key={tag}>{tag}</MenuItemTag>
                    ))}
                  </MenuItemTags>
                )}
              </MenuItem>
            ))}
          </MenuGrid>
        );

      case 'sides':
        return (
          <MenuGrid>
            {menuData.sides.map((item, index) => (
              <MenuItem key={index}>
                <MenuItemTitle>
                  {item.title} <span>£{item.price}</span>
                </MenuItemTitle>
                {item.description && (
                  <MenuItemDescription>{item.description}</MenuItemDescription>
                )}
                {item.tags.length > 0 && (
                  <MenuItemTags>
                    {item.tags.map(tag => (
                      <MenuItemTag key={tag}>{tag}</MenuItemTag>
                    ))}
                  </MenuItemTags>
                )}
              </MenuItem>
            ))}
          </MenuGrid>
        );

      default:
        return null;
    }
  };

  return (
    <PizzaContainer>
      <VideoHero>
        <video 
          autoPlay 
          loop 
          muted 
          playsInline
          controls={false}
          preload="auto"
          style={{ opacity: 0.9 }}
        >
          <source src={pizzaVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <VideoOverlay>
          <HeroTitle>Belvoir Woodfired Pizza</HeroTitle>
          <HeroSubtitle>Authentic Italian Pizza in Historic Surroundings</HeroSubtitle>
        </VideoOverlay>
        <ScrollIndicator onClick={() => window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })}>
          <svg viewBox="0 0 24 24">
            <path d="M12 5L12 19M12 19L5 12M12 19L19 12" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </ScrollIndicator>
      </VideoHero>

      <Section alternate>
        <ContentContainer>
          <SectionTitle>Welcome to Belvoir Woodfired Pizza</SectionTitle>
          <WelcomeSection>
            <WelcomeText>
              Experience the authentic taste of Naples in the heart of Belvoir Castle's Engine Yard. 
              Our traditional wood-fired oven, burning at a scorching 400°C, creates the perfect 
              Neapolitan-style pizza with a beautifully charred, crispy crust. Using only the finest 
              ingredients, from San Marzano tomatoes to fresh buffalo mozzarella, each pizza is 
              lovingly crafted by our skilled pizzaiolos. Whether you're joining us for a casual 
              lunch or a relaxed dinner, we invite you to savor the time-honored tradition of 
              authentic Italian pizza-making.
            </WelcomeText>
          </WelcomeSection>
        </ContentContainer>
      </Section>

      
      <Section>
        <ContentContainer>
          <SectionTitle>Our Menu</SectionTitle>
          <MenuSection>
            <MenuTabs>
              <MenuTab 
                active={activeTab === 'snacks'} 
                onClick={() => handleTabChange('snacks')}
              >
                Snacks
              </MenuTab>
              <MenuTab 
                active={activeTab === 'pizzas'} 
                onClick={() => handleTabChange('pizzas')}
              >
                Pizzas
              </MenuTab>
              <MenuTab 
                active={activeTab === 'paninis'} 
                onClick={() => handleTabChange('paninis')}
              >
                Paninis
              </MenuTab>
              <MenuTab 
                active={activeTab === 'sides'} 
                onClick={() => handleTabChange('sides')}
              >
                Sides
              </MenuTab>
            </MenuTabs>
            
            {renderMenuItems()}

            <MenuItemDescription style={{ textAlign: 'center', marginTop: '2rem', fontStyle: 'italic' }}>
              Please note we do not cater for allergies, however we're happy to accommodate for any food intolerances and/or dietary requirements.
              <br />
              v=vegetarian, vg=vegan, gf=gluten free, gf*=gluten free available on request
            </MenuItemDescription>
          </MenuSection>
        </ContentContainer>
      </Section>

      <Section alternate>
        <ContentContainer>
          <SectionTitle>Our Woodfired Tradition</SectionTitle>
          <FeatureGrid>
            <FeatureCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faFire} />
              </IconWrapper>
              <FeatureTitle>Traditional Method</FeatureTitle>
              <FeatureDescription>
                Our pizzas are cooked in an authentic woodfired oven at 400°C, creating the perfect Neapolitan-style crust.
              </FeatureDescription>
            </FeatureCard>

            <FeatureCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faLeaf} />
              </IconWrapper>
              <FeatureTitle>Fresh Ingredients</FeatureTitle>
              <FeatureDescription>
                We use only the finest ingredients, including San Marzano tomatoes and locally sourced produce.
              </FeatureDescription>
            </FeatureCard>

            <FeatureCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faClock} />
              </IconWrapper>
              <FeatureTitle>Opening Hours</FeatureTitle>
              <FeatureDescription>
                Saturday to Sunday: 10:00 - 17:00
                <br />
                Last orders at 16:30
              </FeatureDescription>
            </FeatureCard>
          </FeatureGrid>
        </ContentContainer>
      </Section>

      <Section>
        <ContentContainer>
          <SectionTitle>Visit Us</SectionTitle>
          <FeatureGrid>
            <FeatureCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </IconWrapper>
              <FeatureTitle>Location</FeatureTitle>
              <FeatureDescription>
                Located in the heart of the Engine Yard at Belvoir Castle
              </FeatureDescription>
            </FeatureCard>

            <FeatureCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faTicketAlt} />
              </IconWrapper>
              <FeatureTitle>Reservations</FeatureTitle>
              <FeatureDescription>
                Walk-ins welcome. For groups of 6 or more, please call to reserve.
              </FeatureDescription>
            </FeatureCard>

            <FeatureCard>
              <IconWrapper>
                <FontAwesomeIcon icon={faLeaf} />
              </IconWrapper>
              <FeatureTitle>Dietary Options</FeatureTitle>
              <FeatureDescription>
                Vegetarian, vegan, and gluten-free options available.
              </FeatureDescription>
            </FeatureCard>
          </FeatureGrid>
        </ContentContainer>
      </Section>

      <MembershipSection>
        <MembershipCard>
          <MembershipIcon>
            <FontAwesomeIcon icon={faTicketAlt} />
          </MembershipIcon>
          <SectionTitle>Friends of Belvoir Benefits</SectionTitle>
          <IntroText>
            Scan your membership card to enjoy exclusive benefits:
          </IntroText>
          <BenefitsList>
            <BenefitItem>
              <FontAwesomeIcon icon={faPercent} />
              <BenefitText>10% discount on all food and beverages</BenefitText>
            </BenefitItem>
            <BenefitItem>
              <FontAwesomeIcon icon={faMobileAlt} />
              <BenefitText>
                Earn loyalty points with every visit
                <ComingSoonBadge>Summer 2025</ComingSoonBadge>
              </BenefitText>
            </BenefitItem>
            <BenefitItem>
              <FontAwesomeIcon icon={faCoffee} />
              <BenefitText>
                Digital coffee card rewards
                <ComingSoonBadge>Summer 2025</ComingSoonBadge>
              </BenefitText>
            </BenefitItem>
          </BenefitsList>
          <MembershipButton to="/memberships">
            Become a Friend of Belvoir <FontAwesomeIcon icon={faArrowRight} />
          </MembershipButton>
        </MembershipCard>
      </MembershipSection>

      <Footer />
    </PizzaContainer>
  );
};

export default WoodfiredPizza;