import React, { useState } from 'react';
import styled from 'styled-components';

const SliderContainer = styled.div`
  background-color: ${props => props.theme.colors.offWhite};
  padding: 3rem 0;
  padding-top: 7rem;
  width: 100%;
  overflow: hidden;
  
  @media (max-width: 768px) {
    padding-top: 4rem;
    padding: 2rem 0;
  }
`;

const SlidesContainer = styled.div`
  display: flex;
  gap: 2rem;
  padding: 0 2rem;
  overflow-x: auto;
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  &::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, Opera */
  }

  @media (max-width: 768px) {
    gap: 1rem;
    padding: 0 1rem;
  }
`;

const Slide = styled.div`
  min-width: 500px;
  text-align: left;

  @media (max-width: 768px) {
    min-width: 300px;
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 1rem;
  user-select: none;  /* Prevent image selection while dragging */
  img {
    width: 500px;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: ${props => props.theme.shadows.light};
    pointer-events: none;  /* This prevents the image from capturing mouse events */

    @media (max-width: 768px) {
      width: 300px;
      height: 200px;
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const SlideTitle = styled.h3`
  color: ${props => props.theme.colors.accent};
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const RoundButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.accent};
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0;

  &:hover {
    opacity: 0.9;
    transform: translateX(2px);
  }

  svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
  }

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const ImageSlider = ({ slides, className }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const slidesRef = React.useRef(null);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - slidesRef.current.offsetLeft);
    setScrollLeft(slidesRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - slidesRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Multiply by 2 for faster scrolling
    slidesRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <SliderContainer className={className}>
      <SlidesContainer
        ref={slidesRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onMouseMove={handleMouseMove}
        style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
      >
        {slides.map((slide, index) => (
          <Slide key={index}>
            <ImageContainer>
              <img src={slide.image} alt={slide.title} />
            </ImageContainer>
            <TitleContainer>
              <SlideTitle>{slide.title}</SlideTitle>
              <RoundButton onClick={slide.action} aria-label="View more">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/>
                </svg>
              </RoundButton>
            </TitleContainer>
          </Slide>
        ))}
      </SlidesContainer>
    </SliderContainer>
  );
};

export default ImageSlider;
