import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faLeaf, 
  faTree, 
  faSeedling, 
  faWater, 
  faHistory,
  faBook,
  faCalendarAlt,
  faMapMarkedAlt,
  faCompass
} from '@fortawesome/free-solid-svg-icons';

// Import images from existing assets
import gardensImage from '../../../assets/gardens.png';
import castleImage from '../../../assets/castle.png';
import playgroundImage from '../../../assets/playground.jpg';
import gardensBanImage from '../../../assets/banner/gardenBan2.webp';
import hermitsImage from '../../../assets/hermits.webp';
import capabilityImage from '../../../assets/capability.webp';
import japaneseImage from '../../../assets/japanese.webp';
import duchessImage from '../../../assets/duchessGarden.webp';
//GraphQL query to fetch blog posts related to gardens
const GET_GARDEN_BLOGS = gql`
  query GetGardenBlogs {
    posts(where: {categoryName: "Garden Diaries"}, first: 3) {
      nodes {
        id
        title
        excerpt
        date
        slug
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
`;

const GardensContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const SubTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.accent};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const HighlightSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 5rem 2rem;
  margin: 5rem 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    clip-path: polygon(0 0, 100% 100%, 0 100%);
  }
`;

const HighlightContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const HighlightTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: white;
  margin-bottom: 2rem;
`;

const HighlightText = styled.p`
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  color: white;
  font-size: 32px;
`;

const CTAButton = styled.a`
  display: inline-block;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1rem 2rem;
  border: 2px solid transparent;
  border-radius: 25px;
  text-decoration: none;
  transition: all 0.3s ease;
  margin-top: 1rem;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const GardenTabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  flex-wrap: wrap;
`;

const GardenTab = styled.button`
  background: ${props => props.active ? props.theme.colors.accent : 'transparent'};
  color: ${props => props.active ? 'white' : props.theme.colors.primary};
  border: 2px solid ${props => props.active ? props.theme.colors.accent : props.theme.colors.primary};
  padding: 1rem 2rem;
  margin: 0.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 25px;
  
  &:hover {
    background: ${props => props.active ? props.theme.colors.accent : props.theme.colors.greyBackground};
  }
`;

const GardenContent = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  padding: 3rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
  gap: 3rem;
  
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const GardenText = styled.div`
  flex: 1;
`;

const GardenTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const GardenDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
`;

const GardenImageContainer = styled.div`
  flex: 1;
  min-width: 300px;
  height: 400px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    height: 300px;
  }
`;

const GardenImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const GallerySection = styled.div`
  margin: 5rem 0;
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 250px);
  gap: 1.5rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 200px);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 200px);
  }
`;

const GalleryItem = styled.div`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  
  &:nth-child(1) {
    grid-column: span 2;
    grid-row: span 1;
    
    @media (max-width: 768px) {
      grid-column: span 1;
    }
  }
  
  &:nth-child(4) {
    grid-column: span 2;
    grid-row: span 1;
    
    @media (max-width: 768px) {
      grid-column: span 1;
    }
  }
  
  &:hover img {
    transform: scale(1.1);
  }
  
  &:hover .overlay {
    opacity: 1;
  }
`;

const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
`;

const GalleryOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

const GalleryTitle = styled.h5`
  color: white;
  font-size: 1.5rem;
  text-align: center;
  padding: 1rem;
`;

const BlogSection = styled.div`
  margin: 5rem 0;
`;

const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const BlogCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
  }
`;

const BlogImageContainer = styled.div`
  height: 200px;
  overflow: hidden;
`;

const BlogImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
  
  ${BlogCard}:hover & {
    transform: scale(1.1);
  }
`;

const BlogContent = styled.div`
  padding: 1.5rem;
`;

const BlogTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
`;

const BlogDate = styled.p`
  font-size: 0.9rem;
  color: ${props => props.theme.colors.accent};
  margin-bottom: 1rem;
`;

const BlogExcerpt = styled.div`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  
  p {
    margin: 0;
  }
`;

const BlogLink = styled.a`
  display: inline-block;
  color: ${props => props.theme.colors.accent};
  font-weight: 600;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const LoadingMessage = styled.p`
  text-align: center;
  font-size: 1.2rem;
  padding: 2rem;
`;

const ErrorMessage = styled.p`
  text-align: center;
  font-size: 1.2rem;
  padding: 2rem;
  color: red;
`;

const FeatureSection = styled.div`
  display: flex;
  margin: 5rem 0;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  
  @media (max-width: 992px) {
    flex-direction: ${props => props.imagePosition === 'right' ? 'column-reverse' : 'column'};
  }
`;

const FeatureImage = styled.div`
  flex: 1;
  min-height: 500px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  
  @media (max-width: 992px) {
    min-height: 300px;
  }
`;

const FeatureContent = styled.div`
  flex: 1;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const FeatureTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`;

const FeatureDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const MapSection = styled.div`
  margin: 5rem 0;
`;

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 12px;
  overflow: hidden;
  margin-top: 3rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    height: 400px;
  }
`;

const MapPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const MapOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const MapIcon = styled.div`
  font-size: 4rem;
  color: ${props => props.theme.colors.accent};
  margin-bottom: 1rem;
`;

const MapText = styled.p`
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary};
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1rem 2rem;
  border-radius: 8px;
  font-weight: 600;
`;

const Gardens = () => {
  const { loading, error, data } = useQuery(GET_GARDEN_BLOGS);
  const [activeGarden, setActiveGarden] = useState('formal');
  
  const { ref: galleryRef, inView: galleryInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  const { ref: blogRef, inView: blogInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  const { ref: brownRef, inView: brownInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  const { ref: mapRef, inView: mapInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  // Format date for blog posts
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };
  
  const handleGardenChange = (garden) => {
    setActiveGarden(garden);
  };
  
  const renderGardenContent = () => {
    switch(activeGarden) {
      case 'formal':
        return (
          <GardenContent>
            <GardenText>
              <GardenTitle>Formal Gardens</GardenTitle>
              <GardenDescription>
                Over the centuries, Belvoir's formal gardens have undergone several major changes, with most 
                Duchesses leaving their mark. Elizabeth (5th Duchess of Rutland) commissioned the Rev Sir John 
                Thoroton to terrace the gardens in 1815 – and later on, Violet the 8th Duchess commissioned the 
                renowned Edwardian garden designer Harold Peto (1854-1933) to create the gardens we know and love today.
              </GardenDescription>
              <GardenDescription>
                The striking statue of 'Winter' by Caius Gabriel Cibber (1630-1700) presides over the Rose Garden, 
                and in the Statue Garden there are six more statues by Cibber – 'Spring', 'Autumn' and 'Summer', 
                two statues representing the senses of smell and taste, and finally 'Juno' with her peacock insignia 
                (taken from the Manners family crest).
              </GardenDescription>
              <GardenDescription>
                The original garden plans drawn up by Harold Peto have only recently been rediscovered in the Castle 
                archives. These reveal that the Rose Garden is shaped like a boat, with the Chinese horse at the bow, 
                and the circular seat at the stern – classic hallmarks of Peto.
              </GardenDescription>
              <GardenDescription>
                The roses you'll see in the garden today were planted by Emma, the current Duchess of Rutland.
              </GardenDescription>
            </GardenText>
            <GardenImageContainer>
              <GardenImage src={gardensImage} alt="Formal Gardens at Belvoir Castle" />
            </GardenImageContainer>
          </GardenContent>
        );
      
      case 'japanese':
        return (
          <GardenContent reverse>
            <GardenText>
              <GardenTitle>Japanese Woodland</GardenTitle>
              <GardenDescription>
                You'll find the Japanese Woodland to the right of the embankment on your way up to the Castle. 
                Its name comes from the extensive use of Japanese and Chinese plants in this area of the gardens. 
                Many of the magnolias, rhododendrons and camellias were sourced from the original seed collection 
                of Charles Williams from Caerhays Castle in Cornwall and Burncoose Nurseries.
              </GardenDescription>
              <GardenDescription>
                The Williams family were avid seed collectors who undertook many expeditions to Western China 
                between 1861 and 1939 – and they cultivated the seeds in their Cornish woodland gardens. The 
                conditions in this small valley at Belvoir are perfect – sheltered from winds on all sides, and 
                with wet ground which is well suited to hardy exotics. It's almost as if a piece of Cornwall has 
                been dropped into the Leicestershire countryside.
              </GardenDescription>
              <GardenDescription>
                You'll find over 250 specimen camellias to admire in the Japanese Woodland – along with tree magnolias, 
                specimen hydrangeas, azaleas and rhododendrons, snake-bark and Japanese maples, and various bamboos.
              </GardenDescription>
            </GardenText>
            <GardenImageContainer>
              <GardenImage src={japaneseImage} alt="Japanese Woodland at Belvoir Castle" />
            </GardenImageContainer>
          </GardenContent>
        );
      
      case 'duchess':
        return (
          <GardenContent>
            <GardenText>
              <GardenTitle>Duchess Garden</GardenTitle>
              <GardenDescription>
                This section of the gardens was initially cultivated by Elizabeth (the 5th Duchess of Rutland) in 1814. 
                It was a fine example of a Picturesque Regency garden – and a peaceful place to retreat from the massive 
                restoration and rebuilding of the Castle at the time. The Root and Moss house (restored in 2014) was 
                built around 1818.
              </GardenDescription>
              <GardenDescription>
                In the Victorian era, this area was known as the Spring Gardens – not because it was used for spring 
                bedding plants, but due to the abundance of natural springs in the hills and banks. The garden had been 
                completely neglected for over 30 years since the Second World War, and was only rediscovered in 1970 by 
                Frances, the Dowager Duchess of Rutland.
              </GardenDescription>
              <GardenDescription>
                Today, the Root and Moss house sits above a series of stone steps commanding an unrivalled vista through 
                a multitude of unusual trees and shrubs to a new pond with an elegant statue. Do make time for a stroll 
                through this delightful hidden corner of the gardens during your visit to Belvoir.
              </GardenDescription>
            </GardenText>
            <GardenImageContainer>
              <GardenImage src={duchessImage} alt="Duchess Garden at Belvoir Castle" />
            </GardenImageContainer>
          </GardenContent>
        );
      
      case 'hermit':
        return (
          <GardenContent reverse>
            <GardenText>
              <GardenTitle>Hermit's Garden</GardenTitle>
              <GardenDescription>
                The Hermit's Garden covers an area of seven acres adjoining the Duchess Gardens. We have recently 
                undertaken a substantial rejuvenation programme in this area of the gardens, with significant 
                investments made in clearing and replanting – and it is still very much a work in progress.
              </GardenDescription>
              <GardenDescription>
                Why is it called the Hermit's Garden? Well, during the clearing process, we uncovered a couple of 
                Regency grottoes – and apparently it was fashionable during the late Georgian and early Victorian 
                periods to pay a hermit to live in your grotto, completing the ultimate Picturesque landscape ornament.
              </GardenDescription>
            </GardenText>
            <GardenImageContainer>
              <GardenImage src={hermitsImage} alt="Hermit's Garden at Belvoir Castle" />
            </GardenImageContainer>
          </GardenContent>
        );
      
      default:
        return null;
    }
  };
  
  return (
    <GardensContainer>
      <ParallaxBanner 
        mainTitle="Gardens & Grounds"
        subtitle="Explore Centuries of Horticultural Excellence"
        image={gardensBanImage}
      />
      
      <ContentContainer>
        <SectionTitle>The Gardens at Belvoir Castle</SectionTitle>
        <IntroText>
          Discover the magnificent gardens at Belvoir Castle, where centuries of horticultural 
          excellence and design have created a stunning landscape. From formal gardens to woodland 
          walks, each area offers a unique experience throughout the seasons.
        </IntroText>
        
        <GardenTabs>
          <GardenTab 
            active={activeGarden === 'formal'} 
            onClick={() => handleGardenChange('formal')}
          >
            Formal Gardens
          </GardenTab>
          <GardenTab 
            active={activeGarden === 'japanese'} 
            onClick={() => handleGardenChange('japanese')}
          >
            Japanese Woodland
          </GardenTab>
          <GardenTab 
            active={activeGarden === 'duchess'} 
            onClick={() => handleGardenChange('duchess')}
          >
            Duchess Garden
          </GardenTab>
          <GardenTab 
            active={activeGarden === 'hermit'} 
            onClick={() => handleGardenChange('hermit')}
          >
            Hermit's Garden
          </GardenTab>
        </GardenTabs>
        
        {renderGardenContent()}
        
        <HighlightSection>
          <HighlightContent>
            <IconWrapper>
              <FontAwesomeIcon icon={faLeaf} />
            </IconWrapper>
            <HighlightTitle>A Garden for Every Season</HighlightTitle>
            <HighlightText>
              The gardens at Belvoir Castle offer something special throughout the year. From spring bulbs and 
              blossoming trees to summer roses, autumn colors, and winter structure, there's always something 
              beautiful to discover no matter when you visit.
            </HighlightText>
            <CTAButton href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=40ca044f-534f-4b7f-83ea-b4156f0633d3&type=WebPage&locationId=978&mainWebPageId=b0f1a62a-a34a-4c4e-a88d-6fda03a19279&startId=e39e1d45-8941-4502-8c92-bb7082a9ddbf">
              Plan Your Visit
            </CTAButton>
          </HighlightContent>
        </HighlightSection>
        
        <FeatureSection ref={brownRef} imagePosition="left">
          <FeatureImage image={capabilityImage} />
          <FeatureContent>
            <FeatureTitle>Capability Brown Landscape</FeatureTitle>
            <FeatureDescription>
              In January 2013, we began an extensive 2 year restoration programme at Belvoir with the clearance 
              of over 500 acres of woodland. At the same time, we made an amazing discovery in the Castle archives – 
              a set of landscaping plans drawn up in 1780 by Capability Brown, which were thought to have been lost 
              in the fire of 1816.
            </FeatureDescription>
            <FeatureDescription>
              250 years after they were originally conceived, the current Duchess of Rutland has finally brought 
              these plans to fruition, aided by her team of dedicated employees and volunteers. You can now enjoy 
              the stunning vistas of the landscaping Capability Brown planned for Belvoir, on a series of walks 
              through the gardens and grounds.
            </FeatureDescription>
            <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
              <IconWrapper style={{ width: '50px', height: '50px', margin: '0' }}>
                <FontAwesomeIcon icon={faMapMarkedAlt} style={{ fontSize: '20px' }} />
              </IconWrapper>
              <IconWrapper style={{ width: '50px', height: '50px', margin: '0' }}>
                <FontAwesomeIcon icon={faHistory} style={{ fontSize: '20px' }} />
              </IconWrapper>
              <IconWrapper style={{ width: '50px', height: '50px', margin: '0' }}>
                <FontAwesomeIcon icon={faTree} style={{ fontSize: '20px' }} />
              </IconWrapper>
            </div>
          </FeatureContent>
        </FeatureSection>
        
        <GallerySection ref={galleryRef}>
          <SectionTitle>Garden Gallery</SectionTitle>
          <IntroText>
            Explore the beauty of Belvoir Castle's gardens through the seasons.
          </IntroText>
          
          <GalleryGrid>
            <GalleryItem>
              <GalleryImage src={gardensImage} alt="Rose Garden in Summer" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Rose Garden in Summer</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={japaneseImage} alt="Japanese Woodland in Spring" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Japanese Woodland in Spring</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={capabilityImage} alt="Autumn Colors" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Autumn Colors</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={gardensImage} alt="Formal Gardens" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Formal Gardens</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={hermitsImage} alt="Hermit's Garden" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Hermit's Garden</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={capabilityImage} alt="Capability Brown Landscape" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Capability Brown Landscape</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
          </GalleryGrid>
        </GallerySection>
        
        <MapSection ref={mapRef}>
          <SectionTitle>Garden Map</SectionTitle>
          <IntroText>
            Explore the beautiful gardens of Belvoir Castle with our interactive map. 
            Plan your visit and discover the unique features of each garden area.
          </IntroText>
          
          <MapContainer>
            <MapPlaceholder image={capabilityImage} />
            <MapOverlay>
              <MapIcon>
                <FontAwesomeIcon icon={faCompass} />
              </MapIcon>
              <MapText>Interactive Garden Map Coming Soon!</MapText>
            </MapOverlay>
          </MapContainer>
        </MapSection>
        
        <BlogSection ref={blogRef}>
          <SectionTitle>The Garden Diaries</SectionTitle>
          <IntroText>
            Keep up to date with Belvoir Castle's Head Gardener, Andy Tudberry, and discover the latest 
            developments, seasonal highlights, and gardening tips from our expert team.
          </IntroText>
          
          {loading ? (
            <LoadingMessage>Loading garden diary entries...</LoadingMessage>
          ) : error ? (
            <ErrorMessage>Error loading garden diary entries. Please try again later.</ErrorMessage>
          ) : (
            <>
              <BlogGrid>
                {data && data.posts && data.posts.nodes.length > 0 ? (
                  data.posts.nodes.map(post => (
                    <BlogCard key={post.id}>
                      <BlogImageContainer>
                        <BlogImage 
                          src={post.featuredImage?.node?.sourceUrl || gardensImage} 
                          alt={post.title} 
                        />
                      </BlogImageContainer>
                      <BlogContent>
                        <BlogDate>
                          <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: '8px' }} />
                          {formatDate(post.date)}
                        </BlogDate>
                        <BlogTitle>{post.title}</BlogTitle>
                        <BlogExcerpt dangerouslySetInnerHTML={{ __html: post.excerpt }} />
                        <BlogLink href={`/blog/${post.slug}`}>Read More</BlogLink>
                      </BlogContent>
                    </BlogCard>
                  ))
                ) : (
                  <LoadingMessage>No garden diary entries found.</LoadingMessage>
                )}
              </BlogGrid>
              
              <div style={{ textAlign: 'center', marginTop: '3rem' }}>
                <CTAButton href="/blog/category/garden-diaries" secondary>View All Garden Diaries</CTAButton>
              </div>
            </>
          )}
        </BlogSection>
        
        <HighlightSection style={{ background: theme.colors.accent }}>
          <HighlightContent>
            <IconWrapper style={{ background: theme.colors.primary }}>
              <FontAwesomeIcon icon={faBook} />
            </IconWrapper>
            <HighlightTitle>Plan Your Garden Visit</HighlightTitle>
            <HighlightText>
              Experience the beauty and tranquility of Belvoir Castle's historic gardens throughout the seasons. 
              Whether you're a garden enthusiast or simply looking for a peaceful retreat, our gardens offer 
              a perfect escape into nature's splendor.
            </HighlightText>
            <CTAButton href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=40ca044f-534f-4b7f-83ea-b4156f0633d3&type=WebPage&locationId=978&mainWebPageId=b0f1a62a-a34a-4c4e-a88d-6fda03a19279&startId=e39e1d45-8941-4502-8c92-bb7082a9ddbf" style={{ backgroundColor: theme.colors.primary }}>
              Book Tickets
            </CTAButton>
          </HighlightContent>
        </HighlightSection>
      </ContentContainer>
      
      <Footer />
    </GardensContainer>
  );
};

export default Gardens;