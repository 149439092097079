import React from 'react';
import styled from 'styled-components';
import castleVideo from '../../assets/Belvoir-Castle.mp4';
import { theme } from '../../styles/theme';

const HeroContainer = styled.div`
  position: relative;
  width: 100%;
  height: 90vh;
  overflow-x: hidden;

  @media (max-width: 768px) {
    height: auto;
    min-height: auto;
    display: flex;
    flex-direction: column;
  }
`;

const VideoBackground = styled.video`
  position: absolute;
  width: 100%;
  height: 95%;
  object-fit: cover;
  z-index: -1;

  @media (max-width: 768px) {
    position: relative;
    height: 50vh;
    pointer-events: none;
    z-index: 0;
  }
`;

const LazyVideo = ({ src }) => {
  const videoRef = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && videoRef.current) {
          videoRef.current.src = src;
          videoRef.current.load();
          observer.unobserve(entry.target);
        }
      });
    }, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [src]);

  return (
    <VideoBackground
      ref={videoRef}
      autoPlay
      muted
      loop
      playsInline
      onLoadedData={() => setIsLoading(false)}
    />
  );
};

const HeroContent = styled.div`
  position: absolute;
  top: 40%;
  left: 10%;
  transform: translateY(-50%);
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  
  @media (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    text-align: center;
    padding: 2rem 1rem;
    background-color: ${theme.colors.primary};
    text-shadow: none;
    margin: 0 0 2rem 0;
    box-sizing: border-box;
  }
`;

const WelcomeText = styled.h2`
  font-family: 'Cormorant Garamond', serif;
  font-weight: 700;
  font-size: 2.5rem;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    color: white;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

const CastleTitle = styled.h1`
  font-family: ${theme.fonts.main};
  font-size: 6.5rem;
  font-weight: 700;
  margin: 0 0 1.5rem 0;

  @media (max-width: 1024px) {
    font-size: 5rem;
  }

  @media (max-width: 768px) {
    font-size: 3.2rem;
    margin-bottom: 1rem;
    color: white;
  }

  @media (max-width: 480px) {
    font-size: 3rem;
  }
`;

const Subtitle = styled.h3`
  font-size: 1.5rem;
  letter-spacing: 2px;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 1rem;
    letter-spacing: 1.5px;
    color: white;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const InfoSection = styled.div`
  position: absolute;
  bottom: 0%;
  left: 65%;
  transform: translateX(-50%);
  background-color: ${theme.colors.primary};
  color: white;
  padding: 30px 40px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 30px;
  z-index: 1;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 65%;
  max-width: 1000px;

  @media (max-width: 1024px) {
    left: 50%;
    padding: 25px 30px;
    gap: 20px;
    width: 90%;
  }

  @media (max-width: 768px) {
    position: relative;
    transform: none;
    left: 0;
    right: 0;
    margin: 0 auto 2rem auto;
    flex-direction: column;
    gap: 0;
    padding: 1.5rem;
    width: calc(100% - 2rem);
    max-width: 90%;
    border-radius: 8px;
    background-color: white;
    color: ${theme.colors.primary};
    border: 1px solid ${theme.colors.primary};
    box-sizing: border-box;
  }
`;

const InfoColumn = styled.div`
  flex: 1;
  padding: 0 20px;
  
  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }

  @media (max-width: 768px) {
    padding: 1rem;
    
    &:not(:last-child) {
      border-right: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`;

const InfoTitle = styled.h4`
  font-family: ${theme.fonts.main};
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0 0 15px 0;
  text-transform: uppercase;
  letter-spacing: 1px;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin: 0 0 8px 0;
    color: ${theme.colors.primary};
  }
`;

const InfoText = styled.p`
  font-size: 0.9rem;
  margin: 5px 0;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    margin: 3px 0;
    color: ${theme.colors.primary};
  }
`;

const InfoNote = styled.p`
  font-size: 0.8rem;
  margin: 10px 0 0;
  line-height: 1.4;
  font-style: italic;
  color: rgba(255, 255, 255, 0.8);

  @media (max-width: 768px) {
    font-size: 0.8rem;
    margin: 5px 0 0;
    color: ${theme.colors.primary};
    opacity: 0.8;
  }
`;

const HeroButton = styled.button`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.offWhite};
  border: 2px solid ${({ theme }) => theme.colors.offWhite};
  border-radius: 25px;
  padding: 12px 24px;
  margin: 10px 15px 10px 0;
  cursor: pointer;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 10px 20px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    margin: 1.5rem 0;
    
    ${HeroButton} {
      margin: 0;
      min-width: 140px;
      border-color: white;
      color: white;
      
      &:hover {
        background-color: white;
        color: ${theme.colors.primary};
      }
    }
  }
`;

const Hero = ({ 
  videoSrc,
  welcomeText = "Welcome to",
  mainTitle,
  subtitle,
  infoColumns = [],
  buttons = []
}) => {
  return (
    <HeroContainer>
      <LazyVideo src={videoSrc} />
      <HeroContent>
        <WelcomeText>{welcomeText}</WelcomeText>
        <CastleTitle>{mainTitle}</CastleTitle>
        <Subtitle>{subtitle}</Subtitle>
        {buttons.length > 0 && (
          <ButtonContainer>
            {buttons.map((button, index) => (
              <HeroButton key={index} onClick={button.onClick}>
                {button.text}
              </HeroButton>
            ))}
          </ButtonContainer>
        )}
      </HeroContent>
      <InfoSection>
        {infoColumns.map((column, index) => (
          <InfoColumn key={index}>
            <InfoTitle>{column.title}</InfoTitle>
            {column.items.map((item, itemIndex) => (
              <InfoText key={itemIndex}>{item}</InfoText>
            ))}
            {column.note && <InfoNote>{column.note}</InfoNote>}
          </InfoColumn>
        ))}
      </InfoSection>
    </HeroContainer>
  );
};

export default Hero;
