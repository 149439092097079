import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Custom hook to ensure components are recognized as part of the retail section
 * This prevents the navigation from switching back to 'castle' when navigating to retail pages
 */
export const useRetailSection = () => {
  const location = useLocation();
  
  useEffect(() => {
    // Find the retail village link in the top navigation
    const retailLink = document.querySelector('a[href="/retail-village"]');
    
    // If the link exists and doesn't have the active class, simulate a click on it
    if (retailLink && !retailLink.classList.contains('active')) {
      // Create and dispatch a custom event that the TopNav component will listen for
      const event = new CustomEvent('forceRetailSection', { detail: { section: 'retail' } });
      document.dispatchEvent(event);
    }
    
    // Set document title based on current path
    if (location.pathname.includes('farm-shop')) {
      document.title = "Farm Shop - Belvoir Castle Retail Village";
    } else if (location.pathname.includes('duchess-collection')) {
      document.title = "Duchess Collection - Belvoir Castle Retail Village";
    } else if (location.pathname.includes('tori-murphy')) {
      document.title = "Tori Murphy - Belvoir Castle Retail Village";
    } else if (location.pathname.includes('bumbles')) {
      document.title = "Bumbles Interiors - Belvoir Castle Retail Village";
    } else if (location.pathname.includes('grange-garden-centre')) {
      document.title = "The Grange Garden Centre - Belvoir Castle Retail Village";
    } else {
      document.title = "Retail Village - Belvoir Castle";
    }
  }, [location]);
}; 