import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ParallaxContainer = styled.div`
  position: relative;
  height: 400px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 3rem;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center 30%;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7));
    z-index: 2;
    
    @media (max-width: 768px) {
      background-attachment: scroll;
    }
  }

  @media (max-width: 768px) {
    height: 500px;
    margin-bottom: 2rem;
  }
  
  @media (max-width: 576px) {
    height: 400px;
    margin-bottom: 1.5rem;
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 3;
  color: white;
  padding: 3rem 5rem;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    padding: 2rem 1.5rem;
  }
  
  @media (max-width: 576px) {
    padding: 1.5rem 1rem;
  }
`;

const WelcomeText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Title = styled.h1`
  font-family: ${props => props.theme.fonts.main};
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  line-height: 1.2;
  text-shadow: 0 2px 4px rgba(0,0,0,0.3);
  
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
  
  @media (max-width: 576px) {
    font-size: 2rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  
  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const ParallaxBanner = ({ image, imageUrl, welcomeText, mainTitle, title, subtitle }) => {
  // Support both old and new prop structures
  const displayImage = image || imageUrl;
  const displayTitle = mainTitle || title;
  
  return (
    <ParallaxContainer image={displayImage}>
      <Content>
        {welcomeText && <WelcomeText>{welcomeText}</WelcomeText>}
        <Title>{displayTitle}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Content>
    </ParallaxContainer>
  );
};

ParallaxBanner.propTypes = {
  image: PropTypes.string,
  imageUrl: PropTypes.string,
  welcomeText: PropTypes.string,
  mainTitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default ParallaxBanner;
