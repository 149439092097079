import React from 'react';
import styled from 'styled-components';

const Container = styled.section`
  background: white;
  padding: 4rem 0;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 6rem;
  padding: 0 2rem;
  flex-direction: ${props => props.imagePosition === 'right' ? 'row-reverse' : 'row'};

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

const ImageContainer = styled.div`
  flex: 1.2;
  filter: drop-shadow(0 10px 15px rgba(0, 0, 0, 0.15));
`;

const Image = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  clip-path: ${props => props.imagePosition === 'right' 
    ? 'polygon(15% 0, 100% 0, 100% 100%, 0 100%)'
    : 'polygon(0 0, 85% 0, 100% 100%, 0% 100%)'};
  transform: scale(1.02);
`;

const TextContent = styled.div`
  flex: 0.8;
`;

const Title = styled.h2`
  color: ${props => props.theme.colors.accent};
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
`;

const Description = styled.p`
  color: ${props => props.theme.colors.accent};
  font-family: ${props => props.theme.fonts.body};
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const Button = styled.button`
  background-color: ${props => props.theme.colors.accent};
  color: white;
  border: 2px solid transparent;
  padding: 0.8rem 2rem;
  border-radius: 25px;
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const ImageSlant = ({ 
  image, 
  title, 
  description, 
  buttonText, 
  buttonAction, 
  imagePosition = 'left' 
}) => {
  return (
    <Container>
      <ContentWrapper imagePosition={imagePosition}>
        <ImageContainer imagePosition={imagePosition}>
          <Image src={image} alt={title} imagePosition={imagePosition} />
        </ImageContainer>
        <TextContent>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <Button onClick={buttonAction}>{buttonText}</Button>
        </TextContent>
      </ContentWrapper>
    </Container>
  );
};

export default ImageSlant;
