import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Footer from '../../Footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faParking, 
  faCreditCard, 
  faCamera, 
  faClock,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';

const ParkingContainer = styled.div`
  min-height: 100vh;
  padding-top: 180px;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  font-family: ${props => props.theme.fonts.main};
  font-size: 3rem;
  color: ${props => props.theme.colors.primary};
  text-align: center;
  margin-bottom: 3rem;
`;

const ParkingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ParkingCard = styled.div`
  background: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: ${props => props.theme.colors.accent};
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const CardTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-left: 1rem;
`;

const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
  background: ${props => props.theme.colors.accent};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
`;

const InfoList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const InfoItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: ${props => props.theme.colors.text};

  svg {
    color: ${props => props.theme.colors.accent};
    margin-right: 1rem;
  }
`;

const PriceTag = styled.div`
  background: ${props => props.theme.colors.accent};
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  display: inline-block;
  margin: 1rem 0;
  font-weight: bold;
`;

const ImportantNote = styled.div`
  background: #fff3cd;
  color: #856404;
  padding: 1rem;
  border-radius: 4px;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  svg {
    color: #856404;
  }
`;

const MapSection = styled.div`
  margin: 4rem 0;
`;

const MapTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  text-align: center;
  margin-bottom: 2rem;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 500px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const Parking = () => {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const markersRef = useRef([]);
  const infoWindowsRef = useRef([]);
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);

  useEffect(() => {
    if (window.google) {
      setIsGoogleMapsLoaded(true);
      initializeMap();
      return;
    }

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDG4S0FFyiaKQB5bn6iFIX8XuYgW0I3uZY`;
    script.async = true;
    script.defer = true;
    
    script.onload = () => {
      setIsGoogleMapsLoaded(true);
      initializeMap();
    };

    document.head.appendChild(script);

    return () => {
      if (document.head.contains(script)) {
        document.head.removeChild(script);
      }
    };
  }, []);

  const initializeMap = () => {
    if (!window.google || !mapRef.current) return;

    try {
      const mapOptions = {
        center: { lat: 52.894722, lng: -0.786389 }, // Center between both car parks
        zoom: 15,
        styles: [
          {
            featureType: "all",
            elementType: "labels.text.fill",
            stylers: [{ color: "#000000" }]
          }
        ]
      };

      mapInstanceRef.current = new window.google.maps.Map(mapRef.current, mapOptions);

      // Main Castle Car Park (Long Stay)
      const mainCarPark = {
        position: { lat: 52.894722, lng: -0.786389 },
        title: "Main Castle Car Park (Long Stay)",
        description: "£3 all day parking, payable on arrival. ANPR monitored.",
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          scale: 8,
          fillColor: "#4A90E2",
          fillOpacity: 0.8,
          strokeColor: "#FFFFFF",
          strokeWeight: 2
        }
      };

      // Retail Village Car Park (Short Stay)
      const retailCarPark = {
        position: { lat: 52.895278, lng: -0.786944 },
        title: "Retail Village Car Park (Short Stay)",
        description: "2.5 hours free parking for patrons only. ANPR monitored.",
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          scale: 8,
          fillColor: "#50E3C2",
          fillOpacity: 0.8,
          strokeColor: "#FFFFFF",
          strokeWeight: 2
        }
      };

      [mainCarPark, retailCarPark].forEach((park, index) => {
        const marker = new window.google.maps.Marker({
          position: park.position,
          map: mapInstanceRef.current,
          icon: park.icon
        });

        const infoWindow = new window.google.maps.InfoWindow({
          content: `
            <div style="padding: 15px; min-width: 250px;">
              <h4 style="margin: 0 0 8px 0; font-family: 'Playfair Display', serif; font-size: 16px;">${park.title}</h4>
              <p style="margin: 0 0 8px 0; font-size: 14px;">${park.description}</p>
            </div>
          `
        });

        marker.addListener('click', () => {
          infoWindowsRef.current.forEach(iw => iw.close());
          infoWindow.open(mapInstanceRef.current, marker);
          mapInstanceRef.current.panTo(marker.getPosition());
          mapInstanceRef.current.setZoom(16);
        });

        markersRef.current.push(marker);
        infoWindowsRef.current.push(infoWindow);
      });
    } catch (error) {
      console.error('Error initializing map:', error);
    }
  };

  return (
    <ParkingContainer>
      <ContentWrapper>
        <Title>Parking Information</Title>
        
        <ParkingGrid>
          <ParkingCard>
            <CardHeader>
              <IconWrapper>
                <FontAwesomeIcon icon={faParking} />
              </IconWrapper>
              <CardTitle>Main Castle Car Park</CardTitle>
            </CardHeader>
            <PriceTag>£3 All Day</PriceTag>
            <InfoList>
              <InfoItem>
                <FontAwesomeIcon icon={faClock} />
                Long Stay Parking
              </InfoItem>
              <InfoItem>
                <FontAwesomeIcon icon={faCreditCard} />
                Payable on Arrival
              </InfoItem>
              <InfoItem>
                <FontAwesomeIcon icon={faCamera} />
                ANPR Monitored
              </InfoItem>
            </InfoList>
            <ImportantNote>
              <FontAwesomeIcon icon={faExclamationTriangle} />
              Please ensure payment is made on arrival. The car park is monitored using ANPR technology.
            </ImportantNote>
          </ParkingCard>

          <ParkingCard>
            <CardHeader>
              <IconWrapper>
                <FontAwesomeIcon icon={faParking} />
              </IconWrapper>
              <CardTitle>Retail Village Car Park</CardTitle>
            </CardHeader>
            <PriceTag>2.5 Hours Free</PriceTag>
            <InfoList>
              <InfoItem>
                <FontAwesomeIcon icon={faClock} />
                Short Stay Parking
              </InfoItem>
              <InfoItem>
                <FontAwesomeIcon icon={faCamera} />
                ANPR Monitored
              </InfoItem>
              <InfoItem>
                <FontAwesomeIcon icon={faExclamationTriangle} />
                For Patrons Only
              </InfoItem>
            </InfoList>
            <ImportantNote>
              <FontAwesomeIcon icon={faExclamationTriangle} />
              This car park is for retail village patrons only. Parking is monitored using ANPR technology.
            </ImportantNote>
          </ParkingCard>
        </ParkingGrid>

        <MapSection>
          <MapTitle>Car Park Locations</MapTitle>
          <MapContainer ref={mapRef} />
        </MapSection>
      </ContentWrapper>
      
      <Footer />
    </ParkingContainer>
  );
};

export default Parking; 