import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCrown, 
  faLeaf,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';

import castleImage from '../../../assets/castle2.png';
import gardensImage from '../../../assets/gardens.png';

const BookingContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 3px;
    background-color: ${props => props.theme.colors.accent};
    margin: 1rem auto 0;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const OptionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const OptionCard = styled.div`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  
  &:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  }
`;

const OptionImage = styled.div`
  height: 250px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const OptionContent = styled.div`
  padding: 2rem;
  text-align: center;
`;

const OptionTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const OptionDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 2rem;
`;

const BookButton = styled.a`
  display: inline-flex;
  align-items: center;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: 25px;
  font-weight: 600;
  border: 2px solid ${props => props.theme.colors.accent};
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
  }
  
  svg {
    margin-left: 0.5rem;
  }
`;

const AfternoonTeaBooking = () => {
  return (
    <BookingContainer>
      <ParallaxBanner 
        title="Book Afternoon Tea"
        subtitle="Choose Your Entry Type for Afternoon Tea"
        image={castleImage}
      />
      
      <ContentContainer>
        <SectionTitle>Select Your Entry Ticket</SectionTitle>
        <IntroText>
          Enjoy our exquisite Afternoon Tea service with either Castle & Gardens entry or Gardens-only entry. 
          Please select your preferred option below.
        </IntroText>
        
        <OptionsGrid>
          <OptionCard>
            <OptionImage image={castleImage} />
            <OptionContent>
              <OptionTitle>
                <FontAwesomeIcon icon={faCrown} />
                Castle & Gardens Entry
              </OptionTitle>
              <OptionDescription>
                Full access to the Castle, State Rooms, and Gardens, plus Afternoon Tea in our elegant Aviary Tearoom.
                The complete Belvoir experience.
              </OptionDescription>
              <BookButton href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=b0f1a62a-a34a-4c4e-a88d-6fda03a19279&type=WebPage&locationId=978&mainWebPageId=e39e1d45-8941-4502-8c92-bb7082a9ddbf&startId=e39e1d45-8941-4502-8c92-bb7082a9ddbf">
                Book Castle & Gardens <FontAwesomeIcon icon={faArrowRight} />
              </BookButton>
            </OptionContent>
          </OptionCard>
          
          <OptionCard>
            <OptionImage image={gardensImage} />
            <OptionContent>
              <OptionTitle>
                <FontAwesomeIcon icon={faLeaf} />
                Gardens Entry
              </OptionTitle>
              <OptionDescription>
                Access to the Gardens and Afternoon Tea in our elegant Aviary Tearoom.
                Perfect for a relaxing afternoon in beautiful surroundings.
              </OptionDescription>
              <BookButton href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=40ca044f-534f-4b7f-83ea-b4156f0633d3&type=WebPage&locationId=978&mainWebPageId=b0f1a62a-a34a-4c4e-a88d-6fda03a19279&startId=e39e1d45-8941-4502-8c92-bb7082a9ddbf">
                Book Gardens Entry <FontAwesomeIcon icon={faArrowRight} />
              </BookButton>
            </OptionContent>
          </OptionCard>
        </OptionsGrid>
      </ContentContainer>
      
      <Footer />
    </BookingContainer>
  );
};

export default AfternoonTeaBooking; 