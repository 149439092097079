import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { venposService } from '../../../services/venposService';
import Footer from '../../Footer/Footer';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const BookingContainer = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.offWhite};
  font-family: ${({ theme }) => theme.fonts.main};
`;

const BookingContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const BookingHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;
  
  h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
  }
  
  p {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const BookingForm = styled.div`
  background: white;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.light};
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: ${({ theme }) => theme.colors.accent};
    border-radius: 12px 12px 0 0;
  }
`;

const DateSection = styled.div`
  margin-bottom: 3rem;
  
  h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Calendar = styled.div`
  margin-top: 2rem;
`;

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  
  button {
    background: none;
    border: none;
    color: ${({ theme }) => theme.colors.accent};
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
    
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.5rem;
`;

const WeekDay = styled.div`
  text-align: center;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0.5rem;
`;

const Day = styled.button`
  aspect-ratio: 1;
  border: none;
  background: ${({ availability, isSelected, theme }) => {
    if (isSelected) return theme.colors.accent;
    switch (availability) {
      case 1: return '#e0e0e0'; // Not Available
      case 2: return '#ffd700'; // Limited Availability
      case 3: return '#90ee90'; // Booking Duration
      case 4: return 'white';   // Available
      default: return theme.colors.greyBackground;
    }
  }};
  color: ${({ isSelected, availability }) => 
    isSelected 
      ? 'white' 
      : availability === 1 
        ? '#999' 
        : '#000'};
  border-radius: 8px;
  cursor: ${({ availability }) => availability > 1 ? 'pointer' : 'not-allowed'};
  transition: all 0.2s ease;
  
  &:hover {
    transform: ${({ availability }) => availability > 1 ? 'scale(1.05)' : 'none'};
  }
`;

const CalendarLegend = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  
  span {
    width: 1rem;
    height: 1rem;
    border-radius: 4px;
    background: ${({ color }) => color};
  }
`;

const TotalSection = styled.div`
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 2px solid ${({ theme }) => theme.colors.greyBackground};
  text-align: right;
`;

const ContinueButton = styled.button`
  background: ${({ theme }) => theme.colors.accent};
  color: white;
  padding: 1.2rem 3rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.fonts.main};
  transition: all 0.3s ease;
  
  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  }
  
  &:disabled {
    background: #ccc;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  background: #ffe6e6;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  text-align: center;
`;

const SelectDate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [availableDates, setAvailableDates] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const selectedTickets = location.state?.selectedTickets;

  useEffect(() => {
    if (!selectedTickets) {
      navigate('/book-tickets');
      return;
    }

    const fetchAvailableDates = async () => {
      try {
        const response = await venposService.getAvailableDates('978', '1bc333c3-fcf2-49ad-8036-45be136ccd5a');
        if (response.data?.calendar) {
          const datesMap = {};
          response.data.calendar.forEach(date => {
            datesMap[new Date(date.date).toISOString().split('T')[0]] = date.availability;
          });
          setAvailableDates(datesMap);
        }
      } catch (err) {
        setError('Failed to load available dates');
      } finally {
        setLoading(false);
      }
    };

    fetchAvailableDates();
  }, [navigate, selectedTickets]);

  const getDateAvailability = (date) => {
    const dateString = date.toISOString().split('T')[0];
    return availableDates[dateString] || 1; // Default to not available
  };

  const getDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const days = new Date(year, month + 1, 0).getDate();
    const firstDay = new Date(year, month, 1).getDay();
    return { days, firstDay };
  };

  const handlePrevMonth = () => {
    setCurrentMonth(prev => new Date(prev.getFullYear(), prev.getMonth() - 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(prev => new Date(prev.getFullYear(), prev.getMonth() + 1));
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const renderCalendar = () => {
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const { days, firstDay } = getDaysInMonth(currentMonth);
    const today = new Date();

    return (
      <Calendar>
        <CalendarHeader>
          <button onClick={handlePrevMonth}>&lt;</button>
          <h3>
            {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
          </h3>
          <button onClick={handleNextMonth}>&gt;</button>
        </CalendarHeader>
        <CalendarGrid>
          {weekDays.map(day => (
            <WeekDay key={day}>{day}</WeekDay>
          ))}
          {[...Array(firstDay)].map((_, index) => (
            <Day key={`empty-${index}`} availability={0} />
          ))}
          {[...Array(days)].map((_, index) => {
            const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), index + 1);
            const availability = getDateAvailability(date);
            const isSelected = selectedDate && 
              date.getDate() === selectedDate.getDate() &&
              date.getMonth() === selectedDate.getMonth() &&
              date.getFullYear() === selectedDate.getFullYear();

            return (
              <Day
                key={index}
                availability={availability}
                isSelected={isSelected}
                onClick={() => availability > 1 && handleDateSelect(date)}
                disabled={availability === 1}
              >
                {index + 1}
              </Day>
            );
          })}
        </CalendarGrid>
        <CalendarLegend>
          <LegendItem color="#e0e0e0">
            <span></span>Not Available
          </LegendItem>
          <LegendItem color="#ffd700">
            <span></span>Limited Availability
          </LegendItem>
          <LegendItem color="#90ee90">
            <span></span>Booking Duration
          </LegendItem>
          <LegendItem color="white">
            <span></span>Available
          </LegendItem>
        </CalendarLegend>
      </Calendar>
    );
  };

  const handleContinue = () => {
    if (!selectedDate) {
      setError('Please select a date for your visit');
      return;
    }

    const basketItems = selectedTickets.map(ticket => ({
      webSaleItemId: ticket.webSaleItemId,
      quantity: ticket.quantity,
      webSaleItemTypeID: ticket.webSaleItemTypeID,
      selectedDate: selectedDate.toISOString().split('T')[0]
    }));

    navigate('/checkout', { state: { basketItems } });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <BookingContainer>
      <BookingContent>
        <BookingHeader>
          <h1>Select Visit Date</h1>
          <p>Choose your preferred date to visit</p>
        </BookingHeader>

        <BookingForm>
          <DateSection>
            <h2>Select Visit Date</h2>
            {renderCalendar()}
          </DateSection>

          {error && <ErrorMessage>{error}</ErrorMessage>}

          <TotalSection>
            <ContinueButton 
              onClick={handleContinue}
              disabled={!selectedDate}
            >
              Continue to Checkout
            </ContinueButton>
          </TotalSection>
        </BookingForm>
      </BookingContent>
      <Footer />
    </BookingContainer>
  );
};

export default SelectDate; 