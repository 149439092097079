import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Custom hook to ensure components are recognized as part of the Stay section
 * This prevents the navigation from switching back to 'castle' when navigating to Stay pages
 */
export const useStaySection = () => {
  const location = useLocation();
  
  useEffect(() => {
    // Create and dispatch a custom event that the Navbar component will listen for
    const event = new CustomEvent('forceStaySection', { detail: { section: 'stay' } });
    document.dispatchEvent(event);
    
    // Set document title based on current path
    if (location.pathname.includes('accommodations/cottages')) {
      document.title = "Holiday Cottages - Belvoir Holidays";
    } else if (location.pathname.includes('accommodations/glamping')) {
      document.title = "Glamping & Camping - Belvoir Holidays";
    } else if (location.pathname.includes('accommodations')) {
      document.title = "Accommodations - Belvoir Holidays";
    } else if (location.pathname.includes('booking')) {
      document.title = "Book Your Stay - Belvoir Holidays";
    } else {
      document.title = "Belvoir Holidays";
    }
  }, [location]);
}; 