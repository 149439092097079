import React from 'react';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';
import { Link } from 'react-router-dom';

// Import images
import castleImage from '../../../assets/castle2.png';

const GET_DIRECTIONS_DATA = gql`
  query GetDirectionsData {
    siteSettings(where: {title: "Global Site Settings"}) {
      nodes {
        siteSettingsFields {
          contactInfo {
            address
            postcode
            phone
            email
          }
        }
      }
    }
  }
`;

const DirectionsContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 500px;
  margin-bottom: 4rem;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    height: 350px;
  }
`;

const AddressContainer = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  padding: 2rem;
  border-radius: 8px;
  margin-bottom: 4rem;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const AddressTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const AddressText = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 0.5rem;
`;

const ContactInfo = styled.div`
  margin-top: 1.5rem;
  
  a {
    color: ${props => props.theme.colors.accent};
    text-decoration: none;
    font-weight: 600;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const TransportSection = styled.div`
  margin-bottom: 4rem;
`;

const TransportGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const TransportCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
`;

const TransportIcon = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 1.5rem;
`;

const TransportTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.6rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const TransportText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const ParkingSection = styled.div`
  margin-bottom: 4rem;
`;

const ParkingInfo = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const ParkingTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const ParkingText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const ParkingList = styled.ul`
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
  
  li {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 0.5rem;
  }
`;

// Replace TransportIcon with SVG icon component
const IconContainer = styled.div`
  width: 60px;
  height: 60px;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    width: 100%;
    height: 100%;
    fill: ${props => props.theme.colors.accent};
  }
`;

// SVG Icons as React components
const CarIcon = () => (
  <IconContainer>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/>
    </svg>
  </IconContainer>
);

const TrainIcon = () => (
  <IconContainer>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M96 0C43 0 0 43 0 96V352c0 48 35.2 87.7 81.1 94.9l-46 46C28.1 499.9 33.1 512 43 512H82.7c8.5 0 16.6-3.4 22.6-9.4L160 448H288l54.6 54.6c6 6 14.1 9.4 22.6 9.4H405c10 0 15-12.1 7.9-19.1l-46-46c46-7.1 81.1-46.9 81.1-94.9V96c0-53-43-96-96-96H96zM64 128c0-17.7 14.3-32 32-32h80c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM272 96h80c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H272c-17.7 0-32-14.3-32-32V128c0-17.7 14.3-32 32-32zM64 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm288-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
    </svg>
  </IconContainer>
);

const BusIcon = () => (
  <IconContainer>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path d="M288 0C422.4 0 512 35.2 512 80V96l0 32c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32l0 160c0 17.7-14.3 32-32 32v32c0 17.7-14.3 32-32 32H416c-17.7 0-32-14.3-32-32V448H192v32c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32l0-32c-17.7 0-32-14.3-32-32l0-160c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h0V96h0V80C64 35.2 153.6 0 288 0zM128 160v96c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H160c-17.7 0-32 14.3-32 32zM128 352c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32V320H128v32zM96 96c0 8.8 7.2 16 16 16H464c8.8 0 16-7.2 16-16s-7.2-16-16-16H112c-8.8 0-16 7.2-16 16zM112 400a32 32 0 1 0 0 64 32 32 0 1 0 0-64zm352 32a32 32 0 1 0 64 0 32 32 0 1 0 -64 0z"/>
    </svg>
  </IconContainer>
);

const Directions = () => {
  const { loading, error, data } = useQuery(GET_DIRECTIONS_DATA);
  
  // Extract contact info with fallback values
  const contactInfo = data?.siteSettings?.nodes?.[0]?.siteSettingsFields?.contactInfo || {
    address: "Belvoir Castle, Grantham",
    postcode: "NG32 1PE",
    phone: "01476 871001",
    email: "info@belvoircastle.com"
  };
  
  return (
    <DirectionsContainer>
      <ParallaxBanner 
        title="Directions"
        subtitle="Plan your journey to Belvoir Castle"
        image={castleImage}
      />
      
      <ContentContainer>
        <SectionTitle>Find Your Way to Belvoir Castle</SectionTitle>
        <IntroText>
          Belvoir Castle is located in the picturesque Vale of Belvoir, on the border of Leicestershire, 
          Nottinghamshire, and Lincolnshire. We're easily accessible by car and public transport.
        </IntroText>
        
        <MapContainer>
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2404.5731927691384!2d-0.7809340233905837!3d52.89533647241835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879c8c1f8d0a88d%3A0x7c2aa244af8fcc13!2sBelvoir%20Castle!5e0!3m2!1sen!2suk!4v1689251234567!5m2!1sen!2suk" 
            width="100%" 
            height="100%" 
            style={{ border: 0 }} 
            allowFullScreen="" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade"
            title="Belvoir Castle Map"
          ></iframe>
        </MapContainer>
        
        <AddressContainer>
          <AddressTitle>Our Address</AddressTitle>
          <AddressText>{contactInfo.address}</AddressText>
          <AddressText>{contactInfo.postcode}</AddressText>
          <ContactInfo>
            <AddressText>Phone: <a href={`tel:${contactInfo.phone}`}>{contactInfo.phone}</a></AddressText>
            <AddressText>Email: <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a></AddressText>
          </ContactInfo>
        </AddressContainer>
        
        <TransportSection>
          <SectionTitle>Getting Here</SectionTitle>
          <TransportGrid>
            <TransportCard>
              <CarIcon />
              <TransportTitle>By Car</TransportTitle>
              <TransportText>
                Belvoir Castle is easily accessible from the A1 and A52. From the A1, take the 
                Grantham exit and follow signs for Belvoir Castle.
              </TransportText>
              <TransportText>
                <strong>From the North:</strong> Take the A1 south, exit at Grantham and follow signs for Belvoir Castle.
              </TransportText>
              <TransportText>
                <strong>From the South:</strong> Take the A1 north, exit at Grantham and follow signs for Belvoir Castle.
              </TransportText>
            </TransportCard>
            
            <TransportCard>
              <TrainIcon />
              <TransportTitle>By Train</TransportTitle>
              <TransportText>
                The nearest train station is Grantham, which is served by LNER on the London to Edinburgh East Coast Main Line.
              </TransportText>
              <TransportText>
                From Grantham station, you can take a taxi to Belvoir Castle (approximately 15 minutes).
              </TransportText>
              <TransportText>
                <a href="https://www.nationalrail.co.uk/" target="_blank" rel="noopener noreferrer">
                  Check train times
                </a>
              </TransportText>
            </TransportCard>
            
            <TransportCard>
              <BusIcon />
              <TransportTitle>By Bus</TransportTitle>
              <TransportText>
                Limited bus services run from Grantham to villages near Belvoir Castle.
              </TransportText>
              <TransportText>
                We recommend checking the Lincolnshire County Council website for up-to-date bus information.
              </TransportText>
              <TransportText>
                <a href="https://lincsbus.info/" target="_blank" rel="noopener noreferrer">
                  Check bus times
                </a>
              </TransportText>
            </TransportCard>
          </TransportGrid>
        </TransportSection>
        
        <ParkingSection>
          <SectionTitle>Parking Information</SectionTitle>
          <ParkingInfo>
            <ParkingTitle>Car Parks</ParkingTitle>
            <ParkingText>
              Car parking restrictions apply for all visitors to Belvoir Castle. Our car parks are located close to the main entrance.
            </ParkingText>
            <ParkingText>
              <strong>Opening Times:</strong> Car parks open 30 minutes before the castle and gardens and close 30 minutes after closing time.
            </ParkingText>
            <ParkingText>
              <Link to="/about/parking" style={{ color: theme.colors.accent, textDecoration: 'none', fontWeight: 600 }}>
                Click here for more parking information
              </Link>
            </ParkingText>
            <ParkingTitle>Accessibility Parking</ParkingTitle>
            <ParkingText>
              Designated accessible parking spaces are available closer to the entrance for Blue Badge holders.
            </ParkingText>
            <ParkingTitle>Important Information</ParkingTitle>
            <ParkingList>
              <li>Please follow signs and instructions from our parking attendants.</li>
              <li>During peak times and special events, overflow parking may be in operation.</li>
              <li>For coach parking, please contact us in advance to make arrangements.</li>

            </ParkingList>
          </ParkingInfo>
        </ParkingSection>
        
        <div style={{ marginBottom: '4rem' }}>
          <SectionTitle>Local Area</SectionTitle>
          <IntroText>
            Belvoir Castle is situated in the beautiful Vale of Belvoir, with many charming villages and attractions nearby. 
            Why not extend your visit and explore the local area?
          </IntroText>
          <ParkingInfo>
            <ParkingTitle>Nearby Attractions</ParkingTitle>
            <ParkingList>
              <li><strong>Belvoir Retail Village</strong> - Located on the estate, offering shopping and dining.</li>
              <li><strong>Grantham</strong> - Historic market town with shops, restaurants, and attractions (7 miles).</li>
              <li><strong>Rutland Water</strong> - Beautiful reservoir with water sports and nature reserve (20 miles).</li>
              <li><strong>Melton Mowbray</strong> - Famous for pork pies and Stilton cheese (12 miles).</li>
            </ParkingList>
            <ParkingTitle>Accommodation</ParkingTitle>
            <ParkingText>
              There are many accommodation options in the surrounding area, from luxury hotels to cozy B&Bs. 
              For a truly special experience, consider staying in one of our on-site accommodations.
            </ParkingText>
            <ParkingText>
              <a href="/stay" style={{ color: theme.colors.accent, fontWeight: 600 }}>
                View our accommodation options
              </a>
            </ParkingText>
          </ParkingInfo>
        </div>
      </ContentContainer>
      
      <Footer />
    </DirectionsContainer>
  );
};

export default Directions; 