import React from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBookOpen, 
  faLeaf, 
  faNewspaper, 
  faCalendarAlt,
  faUserTie,
  faCamera,
  faPenFancy,
  faCoffee
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';

// Import images from existing assets

import castleImage from '../../../assets/castle2.png';
import playgroundImage from '../../../assets/playground.jpg';



const JournalContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const ComingSoonSection = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 5rem 2rem;
  margin: 5rem 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  
  &::before {
    content: '';
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    clip-path: polygon(0 0, 100% 100%, 0 100%);
  }
`;

const ComingSoonContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`;

const ComingSoonTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.2rem;
  color: white;
  margin-bottom: 2rem;
`;

const ComingSoonText = styled.p`
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 50%;
  color: white;
  font-size: 32px;
`;

const PreviewSection = styled.div`
  margin: 5rem 0;
`;

const PreviewGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const PreviewCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  
  &:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  }
`;

const PreviewContent = styled.div`
  padding: 2rem;
  text-align: center;
`;

const PreviewTitle = styled.h4`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const PreviewDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
`;

const Journal = () => {
  const { ref: previewRef, inView: previewInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  return (
    <JournalContainer>
      <ParallaxBanner 
        title="The Belvoir Journal"
        subtitle="Stories from the Castle"
        image={castleImage}
      />
      
      <ContentContainer>
        <SectionTitle>Welcome to The Belvoir Journal</SectionTitle>
        <IntroText>
          Coming soon! The Belvoir Journal will be your window into the fascinating world of Belvoir Castle. 
          From garden stories to historical insights, we'll bring you the latest news and updates from our 
          beautiful estate.
        </IntroText>
        
        <ComingSoonSection>
          <ComingSoonContent>
            <IconWrapper>
              <FontAwesomeIcon icon={faBookOpen} />
            </IconWrapper>
            <ComingSoonTitle>Coming Soon</ComingSoonTitle>
            <ComingSoonText>
              We're working hard to bring you engaging content about life at Belvoir Castle. 
              Stay tuned for stories from our Head Gardener, office updates, and more!
            </ComingSoonText>
          </ComingSoonContent>
        </ComingSoonSection>
        
        <PreviewSection ref={previewRef}>
          <SectionTitle>What to Expect</SectionTitle>
          <PreviewGrid>
            <PreviewCard>
              <PreviewContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faLeaf} />
                </IconWrapper>
                <PreviewTitle>Garden Stories</PreviewTitle>
                <PreviewDescription>
                  Insights from our Head Gardener about seasonal changes, plant care, and the 
                  beautiful landscapes of Belvoir Castle.
                </PreviewDescription>
              </PreviewContent>
            </PreviewCard>
            
            <PreviewCard>
              <PreviewContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faNewspaper} />
                </IconWrapper>
                <PreviewTitle>Castle Updates</PreviewTitle>
                <PreviewDescription>
                  Latest news and developments from around the estate, including restoration 
                  projects and special events.
                </PreviewDescription>
              </PreviewContent>
            </PreviewCard>
            
            <PreviewCard>
              <PreviewContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faUserTie} />
                </IconWrapper>
                <PreviewTitle>Office Insights</PreviewTitle>
                <PreviewDescription>
                  Behind-the-scenes stories from our office team about what goes into running 
                  a historic estate.
                </PreviewDescription>
              </PreviewContent>
            </PreviewCard>
            
            <PreviewCard>
              <PreviewContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </IconWrapper>
                <PreviewTitle>Event Highlights</PreviewTitle>
                <PreviewDescription>
                  Recaps of special events, celebrations, and memorable moments at Belvoir Castle.
                </PreviewDescription>
              </PreviewContent>
            </PreviewCard>
            
            <PreviewCard>
              <PreviewContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faCamera} />
                </IconWrapper>
                <PreviewTitle>Photo Stories</PreviewTitle>
                <PreviewDescription>
                  Visual journeys through the changing seasons and special moments at the castle.
                </PreviewDescription>
              </PreviewContent>
            </PreviewCard>
            
            <PreviewCard>
              <PreviewContent>
                <IconWrapper>
                  <FontAwesomeIcon icon={faPenFancy} />
                </IconWrapper>
                <PreviewTitle>Historical Tales</PreviewTitle>
                <PreviewDescription>
                  Fascinating stories from the castle's rich history and heritage.
                </PreviewDescription>
              </PreviewContent>
            </PreviewCard>
          </PreviewGrid>
        </PreviewSection>
      </ContentContainer>
      
      <Footer />
    </JournalContainer>
  );
};

export default Journal; 