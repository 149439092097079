import React, { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';
import Footer from '../../Footer/Footer';
import ParallaxBanner from '../../common/Parralax-banner';
import { theme } from '../../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

// Import images
import castleImage from '../../../assets/castle2.png';
import gardensImage from '../../../assets/gardens.png';
import retailImage from '../../../assets/retail.png';

const GET_OPENING_HOURS = gql`
  query GetOpeningHours {
    siteSettings {
      nodes {
        siteSettingsFields {
          openingHours {
            castle {
              days
              times
              note
            }
            gardens {
              days
              times
              note
            }
            retail {
              days
              times
              note
            }
          }
        }
      }
    }
  }
`;

const OpeningTimesContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
  text-align: center;
`;

const IntroText = styled.p`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
`;

const TimesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const VenueCard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
`;

const StatusBadge = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  background-color: ${props => props.$isOpen ? 'rgba(76, 175, 80, 0.85)' : 'rgba(244, 67, 54, 0.85)'};
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  backdrop-filter: blur(4px);
`;

const VenueImage = styled.div`
  height: 200px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  position: relative;
`;

const VenueContent = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const VenueName = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
  min-height: 60px; /* Ensure consistent height for title area */
`;

const TimeInfo = styled.div`
  margin-bottom: 1.5rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.3rem;
`;

const DayLabel = styled.div`
  color: ${props => props.theme.colors.secondary};
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
`;

const Hours = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: 1.4rem;
  font-weight: 500;
`;

const Note = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: 0.9rem;
  font-style: italic;
  margin-top: 0.3rem;
`;

const VenueDescription = styled.p`
  flex-grow: 1;
  margin-top: 0.5rem;
  line-height: 1.6;
`;

const CurrentTimeDisplay = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
`;

const CTASection = styled.div`
  margin: 5rem 0;
  display: flex;
  gap: 2rem;
  justify-content: center;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CTACard = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
`;

const CTATitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const CTADescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: ${props => props.theme.colors.primary};
`;

const CTAButton = styled.a`
  display: inline-block;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1rem 2rem;
  border: 2px solid transparent;
  border-radius: 25px;
  text-decoration: none;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
  }
`;

const OpeningTimes = () => {
  const { loading, error, data } = useQuery(GET_OPENING_HOURS, {
    fetchPolicy: 'network-only'
  });
  
  const [currentTime, setCurrentTime] = useState(new Date());
  
  // Update current time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    
    return () => clearInterval(timer);
  }, []);
  
  // Format current time
  const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const currentDay = currentTime.toLocaleDateString('en-US', { weekday: 'long' });
  
  // Extract opening hours with fallback
  const openingHours = data?.siteSettings?.nodes?.[0]?.siteSettingsFields?.openingHours || {};
  
  // Function to check if a venue is currently open
  const isVenueOpen = (venue) => {
    if (!venue || !venue.times) return false;
    
    // Parse opening hours
    const timeRange = venue.times.split('-').map(t => t.trim());
    if (timeRange.length !== 2) return false;
    
    // Convert times to 24-hour format for comparison
    const [openHour, openMinute] = timeRange[0].split(':').map(Number);
    const [closeHour, closeMinute] = timeRange[1].split(':').map(Number);
    
    const openTime = new Date();
    openTime.setHours(openHour, openMinute || 0, 0);
    
    const closeTime = new Date();
    closeTime.setHours(closeHour, closeMinute || 0, 0);
    
    // Check if current time is between open and close times
    return currentTime >= openTime && currentTime <= closeTime;
  };
  
  // Check if each venue is open
  const isCastleOpen = isVenueOpen(openingHours.castle);
  const isGardensOpen = isVenueOpen(openingHours.gardens);
  const isRetailOpen = isVenueOpen(openingHours.retail);
  
  if (loading) return <p>Loading opening times...</p>;
  if (error) {
    console.error("Error loading opening times:", error);
    return <p>Error loading opening times. Please try again later.</p>;
  }
  
  return (
    <OpeningTimesContainer>
      <ParallaxBanner 
        title="Opening Times"
        subtitle="Plan Your Visit to Belvoir Castle"
        image={castleImage}
      />
      
      <ContentContainer>
        <SectionTitle>Our Opening Hours</SectionTitle>
        <IntroText>
          Belvoir Castle welcomes visitors throughout the year. Please check our opening times below before planning your visit.
          Different areas of the estate have varying opening hours to ensure you have the best experience.
        </IntroText>
        
        <CurrentTimeDisplay>
          Current Time: {formattedTime} on {currentDay}
        </CurrentTimeDisplay>
        
        <TimesGrid>
          <VenueCard>
            <VenueImage image={castleImage}>
              <StatusBadge $isOpen={isCastleOpen}>
                <FontAwesomeIcon icon={faClock} />
                {isCastleOpen ? 'Open Now' : 'Closed'}
              </StatusBadge>
            </VenueImage>
            <VenueContent>
              <VenueName>
                Castle
              </VenueName>
              <TimeInfo>
                <DayLabel>{openingHours.castle?.days || "Test1"}</DayLabel>
                <Hours>{openingHours.castle?.times || "10:00 - 17:00"}</Hours>
                {(openingHours.castle?.note) && 
                  <Note>{openingHours.castle?.note}</Note>}
              </TimeInfo>
              <VenueDescription>
                Explore the magnificent state rooms and discover centuries of history within the walls of Belvoir Castle.
                Our knowledgeable guides are available throughout the castle to enhance your experience.
              </VenueDescription>
            </VenueContent>
          </VenueCard>
          
          <VenueCard>
            <VenueImage image={gardensImage}>
              <StatusBadge $isOpen={isGardensOpen}>
                <FontAwesomeIcon icon={faClock} />
                {isGardensOpen ? 'Open Now' : 'Closed'}
              </StatusBadge>
            </VenueImage>
            <VenueContent>
              <VenueName>
                Gardens & Playground
              </VenueName>
              <TimeInfo>
                <DayLabel>{openingHours.gardens?.days || "Mon - Sun"}</DayLabel>
                <Hours>{openingHours.gardens?.times || "10:00 - 17:00"}</Hours>
                {(openingHours.gardens?.note ) && 
                  <Note>{openingHours.gardens?.note}</Note>}
              </TimeInfo>
              <VenueDescription>
                Wander through our beautifully landscaped gardens and let the children enjoy our adventure playground.
                The gardens offer stunning views across the Vale of Belvoir in every season.
              </VenueDescription>
            </VenueContent>
          </VenueCard>
          
          <VenueCard>
            <VenueImage image={retailImage}>
              <StatusBadge $isOpen={isRetailOpen}>
                <FontAwesomeIcon icon={faClock} />
                {isRetailOpen ? 'Open Now' : 'Closed'}
              </StatusBadge>
            </VenueImage>
            <VenueContent>
              <VenueName>
                Retail Village
              </VenueName>
              <TimeInfo>
                <DayLabel>{openingHours.retail?.days || "Mon - Sun"}</DayLabel>
                <Hours>{openingHours.retail?.times || "09:30 - 17:00"}</Hours>
                {openingHours.retail?.note && <Note>{openingHours.retail.note}</Note>}
              </TimeInfo>
              <VenueDescription>
                Visit our retail village for unique gifts, local produce, and souvenirs. Our shops offer a range of
                products including homeware, food and drink, and Belvoir Castle merchandise.
              </VenueDescription>
            </VenueContent>
          </VenueCard>
        </TimesGrid>
        
        <CTASection>
          <CTACard>
            <CTATitle>Visit the Castle</CTATitle>
            <CTADescription>
              Experience the majesty of our historic castle and beautiful state rooms with a guided tour.
            </CTADescription>
            <CTAButton href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=b0f1a62a-a34a-4c4e-a88d-6fda03a19279&type=WebPage&locationId=978&mainWebPageId=e39e1d45-8941-4502-8c92-bb7082a9ddbf&startId=e39e1d45-8941-4502-8c92-bb7082a9ddbf">
              Book Castle Tickets
            </CTAButton>
          </CTACard>
          
          <CTACard>
            <CTATitle>Explore the Gardens</CTATitle>
            <CTADescription>
              Discover our beautiful gardens and let the children enjoy our adventure playground.
            </CTADescription>
            <CTAButton href="https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=40ca044f-534f-4b7f-83ea-b4156f0633d3&type=WebPage&locationId=978&mainWebPageId=b0f1a62a-a34a-4c4e-a88d-6fda03a19279&startId=e39e1d45-8941-4502-8c92-bb7082a9ddbf">
              Book Garden Tickets
            </CTAButton>
          </CTACard>
        </CTASection>
        
        <div style={{ marginTop: '4rem' }}>
          <SectionTitle>Additional Information</SectionTitle>
          <IntroText>
            Please note that last entry to all areas is typically 1 hour before closing time. The castle may occasionally
            close for private events or maintenance - please check our events calendar before planning your visit.
            Dogs are welcome in the gardens but must be kept on a lead at all times.
          </IntroText>
        </div>
      </ContentContainer>
      
      <Footer />
    </OpeningTimesContainer>
  );
};

export default OpeningTimes; 