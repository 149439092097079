import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import Footer from '../../Footer/Footer';
import styled from 'styled-components';
import ImageSlantPricing from '../../common/image-slant-pricing';
import WhatsOn from '../../common/What-on';
import OpeningTimes from '../../common/Opening-times';
import ParallaxBanner from '../../common/Parralax-banner';
import { processEvents, GET_EVENTS_QUERY } from '../../../utils/eventUtils';

import castleImage from '../../../assets/castle2.png';
import gardensImage from '../../../assets/gardens.png';
import retailImage from '../../../assets/retail.png';
import playgroundImage from '../../../assets/play.png';

const GET_TICKET_PRICING = gql`
  query GetTicketPricing {
    siteSettings(where: {title: "Global Site Settings"}) {
      nodes {
        siteSettingsFields {
          ticketPricing {
            castleGardensPricing {
              adultType
              adultOnlinePrice
              adultOfficePrice
              childType
              childOnlinePrice
              childOfficePrice
              concessionType
              concessionOnlinePrice
              concessionOfficePrice
              familyType
              familyOnlinePrice
              familyOfficePrice
            }
            gardensPricing {
              adultType
              adultOnlinePrice
              adultOfficePrice
              childType
              childOnlinePrice
              childOfficePrice
              concessionType
              concessionOnlinePrice
              concessionOfficePrice
            }
          }
        }
      }
    }
  }
`;

// Simple query to explore the schema
const GET_SITE_SETTINGS = gql`
  query GetSiteSettings {
    siteSettings {
      nodes {
        title
        siteSettingsFields {
          openingHours {
            castle {
              days
              times
              note
            }
            gardens {
              days
              times
              note
            }
            retail {
              days
              times
              note
            }
          }
        }
      }
    }
  }
`;

const DayTicketsContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const PackageSection = styled.div`
  background-color: ${props => props.theme.colors.greyBackground};
  padding: 4rem 2rem;
  margin: 4rem 0;
  text-align: center;
`;

const PackageContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const PackageTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  
  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 3px;
    background-color: ${props => props.theme.colors.accent};
    margin: 1rem auto 0;
  }
`;

const PackageDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
`;

const ExtrasGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const ExtraItem = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`;

const ExtraTitle = styled.h3`
  font-family: ${props => props.theme.fonts.main};
  font-size: 1.4rem;
  color: ${props => props.theme.colors.accent};
  margin-bottom: 1rem;
`;

const ExtraDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: ${props => props.theme.colors.secondary};
`;

const DayTickets = () => {
  const navigate = useNavigate();
  const { loading: eventsLoading, error: eventsError, data: eventsData } = useQuery(GET_EVENTS_QUERY);
  const { loading: pricingLoading, error: pricingError, data: pricingData } = useQuery(GET_TICKET_PRICING);
  const { loading: settingsLoading, error: settingsError, data: settingsData } = useQuery(GET_SITE_SETTINGS);
  
  console.log("GraphQL response in DayTickets:", eventsData);
  console.log("Pricing data:", pricingData);
  console.log("Site settings data:", settingsData);

  // Default pricing data (fallback if API fails)
  const defaultCastleAndGardensPricing = [
    {
      type: 'Adult',
      onlinePrice: '£23.40',
      officePrice: '£29.99'
    },
    {
      type: 'Child',
      onlinePrice: '£9.00',
      officePrice: '£10.00'
    },
    {
      type: 'Concession',
      onlinePrice: '£21.60',
      officePrice: '£24.00'
    },
    {
      type: 'Family',
      onlinePrice: '£70.20',
      officePrice: '£77.80'
    }
  ];

  const defaultGardensAndPlaygroundPricing = [
    {
      type: 'Adult',
      onlinePrice: '£15.00',
      officePrice: '£18.00'
    },
    {
      type: 'Child',
      onlinePrice: '£7.00',
      officePrice: '£8.00'
    },
    {
      type: 'Concession',
      onlinePrice: '£13.00',
      officePrice: '£15.00'
    }
  ];

  // Extract opening hours with fallback
  const openingHours = settingsData?.siteSettings?.nodes?.[0]?.siteSettingsFields?.openingHours || {
    column1: {
      title: "Castle",
      days: "Mon - Sun",
      times: "10:00 - 16:00",
      note: "Last entry at 15:30"
    },
    column2: {
      title: "Gardens & Playground",
      days: "Mon - Sun",
      times: "09:00 - 17:00",
      note: "Last entry at 16:30"
    },
    column3: {
      title: "Retail Village",
      days: "Mon - Sun",
      times: "9:00 - 17:00",
      note: ""
    }
  };

  // Format the data for the OpeningTimes component
  const formattedOpeningHours = {
    column1: {
      title: "Castle",
      days: openingHours.castle?.days || "Mon - Sun",
      times: openingHours.castle?.times || "10:00 - 16:00",
      note: openingHours.castle?.note || "Last entry at 15:30"
    },
    column2: {
      title: "Gardens & Playground",
      days: openingHours.gardens?.days || "Mon - Sun",
      times: openingHours.gardens?.times || "09:00 - 17:00",
      note: openingHours.gardens?.note || "Last entry at 16:30"
    },
    column3: {
      title: "Retail Village",
      days: openingHours.retail?.days || "Mon - Sun",
      times: openingHours.retail?.times || "9:00 - 17:00",
      note: openingHours.retail?.note || ""
    }
  };

  // Also update the pricing data extraction
  const pricingSettings = pricingData?.siteSettings?.nodes?.[0]?.siteSettingsFields || {};
  const ticketPricing = pricingSettings.ticketPricing || {};

  // Process castle and gardens pricing
  const cgPricing = ticketPricing.castleGardensPricing || {};
  const castleAndGardensPricing = cgPricing ? [
    {
      type: cgPricing.adultType || 'Adult',
      onlinePrice: cgPricing.adultOnlinePrice || '£23.40',
      officePrice: cgPricing.adultOfficePrice || '£29.99'
    },
    {
      type: cgPricing.childType || 'Child',
      onlinePrice: cgPricing.childOnlinePrice || '£9.00',
      officePrice: cgPricing.childOfficePrice || '£10.00'
    },
    {
      type: cgPricing.concessionType || 'Concession',
      onlinePrice: cgPricing.concessionOnlinePrice || '£21.60',
      officePrice: cgPricing.concessionOfficePrice || '£24.00'
    },
    {
      type: cgPricing.familyType || 'Family',
      onlinePrice: cgPricing.familyOnlinePrice || '£70.20',
      officePrice: cgPricing.familyOfficePrice || '£77.80'
    }
  ] : defaultCastleAndGardensPricing;

  // Process gardens pricing
  const gPricing = ticketPricing.gardensPricing || {};
  const gardensAndPlaygroundPricing = gPricing ? [
    {
      type: gPricing.adultType || 'Adult',
      onlinePrice: gPricing.adultOnlinePrice || '£9.00',
      officePrice: gPricing.adultOfficePrice || '£10.00'
    },
    {
      type: gPricing.childType || 'Child',
      onlinePrice: gPricing.childOnlinePrice || '£5.40',
      officePrice: gPricing.childOfficePrice || '£6.00'
    },
    {
      type: gPricing.concessionType || 'Concession',
      onlinePrice: gPricing.concessionOnlinePrice || '£7.20',
      officePrice: gPricing.concessionOfficePrice || '£8.00'
    }
  ] : defaultGardensAndPlaygroundPricing;

  const handleBooking = (ticketType) => {
    console.log('Booking initiated for:', ticketType);
    const urls = {
      'castle-gardens': 'https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=b0f1a62a-a34a-4c4e-a88d-6fda03a19279&type=WebPage&locationId=978&mainWebPageId=e39e1d45-8941-4502-8c92-bb7082a9ddbf&startId=e39e1d45-8941-4502-8c92-bb7082a9ddbf',
      'gardens': 'https://online1.venpos.net/VenPosJourneyUI/WebSaleItems?id=40ca044f-534f-4b7f-83ea-b4156f0633d3&type=WebPage&locationId=978&mainWebPageId=b0f1a62a-a34a-4c4e-a88d-6fda03a19279&startId=e39e1d45-8941-4502-8c92-bb7082a9ddbf'
    };
    
    // Redirect to the appropriate Venpos URL
    window.location.href = urls[ticketType];
  };

  const handleEventClick = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  const venues = [
    {
      name: "Castle",
      hours: "10:00 - 16:00"
    },
    {
      name: "Gardens & Playground",
      hours: "09:00 - 17:00"
    },
    {
      name: "Retail Village",
      hours: "9:00 - 17:00"
    }
  ];

  // Custom images for this page (using castle2.png instead of castle.png)
  const customImages = {
    castle: castleImage,
    gardens: gardensImage,
    retail: retailImage,
    playground: playgroundImage
  };

  // Get all events
  const eventsContent = eventsLoading ? [] : 
    eventsData && eventsData.events && eventsData.events.nodes ? 
    processEvents(eventsData.events.nodes, customImages) : [];
  
  // Filter for tours based on the eventType field
  const toursContent = eventsContent.filter(event => 
    event.eventType === 'tour'
  );

  if (pricingLoading) return <p>Loading pricing information...</p>;
  if (pricingError) console.error("Error loading pricing:", pricingError);

  return (
    <DayTicketsContainer>
      <ParallaxBanner 
        title="Day Tickets"
        subtitle="Experience the magic of Belvoir Castle"
        image={castleImage}
      />
      <ImageSlantPricing
        image={castleImage}
        title="Castle, Gardens & Playground"
        description="Experience the majesty of our historic castle and beautiful gardens"
        buttonText="Book Now"
        buttonAction={() => handleBooking('castle-gardens')}
        imagePosition="left"
        pricingData={castleAndGardensPricing}
      />
      <ImageSlantPricing
        image={gardensImage}
        title="Gardens & Playground"
        description="Explore our beautiful gardens and let the children enjoy our adventure playground"
        buttonText="Book Now"
        buttonAction={() => handleBooking('gardens')}
        imagePosition="right"
        pricingData={gardensAndPlaygroundPricing}
      />
      <PackageSection>
        <PackageContent>
          <PackageTitle>Build Your Perfect Day Out</PackageTitle>
          <PackageDescription>
            When booking your tickets, you'll have the opportunity to enhance your visit with our range of exciting extras. 
            Create your perfect package by adding these memorable experiences to your day.
          </PackageDescription>
          <ExtrasGrid>
            <ExtraItem>
              <ExtraTitle>Afternoon Tea</ExtraTitle>
              <ExtraDescription>
                Indulge in our exquisite Afternoon Tea service in the elegant Aviary Tearoom
              </ExtraDescription>
            </ExtraItem>
            <ExtraItem>
              <ExtraTitle>Guided Tours</ExtraTitle>
              <ExtraDescription>
                Join our expert guides for fascinating insights into the Castle's history
              </ExtraDescription>
            </ExtraItem>
            <ExtraItem>
              <ExtraTitle>Animal Park</ExtraTitle>
              <ExtraDescription>
                Meet our friendly animals at the Belvoir Castle Animal Park
              </ExtraDescription>
            </ExtraItem>
            <ExtraItem>
              <ExtraTitle>Seasonal Activities</ExtraTitle>
              <ExtraDescription>
                Discover special seasonal events and activities available during your visit
              </ExtraDescription>
            </ExtraItem>
          </ExtrasGrid>
        </PackageContent>
      </PackageSection>
      <OpeningTimes columns={formattedOpeningHours} />
      {eventsLoading ? (
        <p>Loading events...</p>
      ) : eventsError ? (
        <p>Error loading events: {eventsError.message}</p>
      ) : (
        <>
          <WhatsOn 
            title="What's On at Belvoir Castle" 
            events={eventsContent} 
            onEventClick={handleEventClick}
          />
          {toursContent.length > 0 && (
            <WhatsOn 
              title="Daily Tours at Belvoir Castle" 
              events={toursContent} 
              onEventClick={handleEventClick}
            />
          )}
        </>
      )}
      <Footer />
    </DayTicketsContainer>
  );
};

export default DayTickets; 