import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import client from './apollo/client';
import { theme } from './styles/theme';
import Navbar from './components/Navbar/Navbar';
import Home from './components/pages/Home/Home';
import RetailVillage from './components/pages/Home/Retail-village';
import DayTickets from './components/pages/DayTickets/DayTickets';
import BookTickets from './components/pages/BookTickets/BookTickets';
import SelectDate from './components/pages/SelectDate/SelectDate';
import EventDetail from './components/pages/EventDetail/EventDetail';
import CastleStateRooms from './components/pages/Home/CastleStateRooms';
import ScrollToTop from './utils/ScrollToTop';
import OpeningTimes from './components/pages/OpeningTimes/OpeningTimes';
import Directions from './components/pages/Directions/Directions';
import Accessibility from './components/pages/Accessibility/Accessibility';
import Groups from './components/pages/Groups/Groups';
import AfternoonTea from './components/pages/AfternoonTea/AfternoonTea';
import AviaryTearoom from './components/pages/AviaryTearoom/AviaryTearoom';
import Weddings from './components/pages/Weddings/Weddings';
import PrivateHire from './components/pages/PrivateHire/PrivateHire';
import History from './components/pages/History/History';
import AdventurePlayground from './components/pages/AdventurePlayground/AdventurePlayground';
import Gardens from './components/pages/Gardens/Gardens';
import GiftVouchers from './components/pages/GiftVouchers/GiftVouchers';
import Memberships from './components/pages/Memberships/Memberships';
import FarmShop from './components/pages/FarmShop/FarmShop';
import DuchessCollection from './components/pages/DuchessCollection/DuchessCollection';
import ToriMurphy from './components/pages/ToriMurphy/ToriMurphy';
import Bumbles from './components/pages/Bumbles/Bumbles';
import GrangeGardenCentre from './components/pages/GrangeGardenCentre/GrangeGardenCentre';
import Exhibitions from './components/pages/Exhibitions/Exhibitions';
import SeasonalActivities from './components/pages/SeasonalActivities/SeasonalActivities';
import WhatsOn from './components/pages/WhatsOn/WhatsOn';
import Events from './components/pages/Events/Events';
import ShopsOverview from './components/pages/Shops/ShopsOverview';
import AnimalPark from './components/pages/AnimalPark/AnimalPark';
import RetailDining from './components/pages/RetailDining/RetailDining';
import BelvoirBistro from './components/pages/BelvoirBistro/BelvoirBistro';
import WoodfiredPizza from './components/pages/WoodfiredPizza/WoodfiredPizza';
import BalloonBar from './components/pages/BalloonBar/BalloonBar';
import Stay from './components/pages/Stay/Stay';
import Glamping from './components/pages/Stay/Glamping';
import Cottages from './components/pages/Stay/Cottages';
import Camping from './components/pages/Stay/Camping';
import ValeHouse from './components/pages/Stay/ValeHouse';
import Experiences from './components/pages/Experiences/Experiences';
import GettingHere from './components/pages/Stay/GettingHere';
import MembersArea from './components/pages/MembersArea/MembersArea';
import Parking from './components/pages/Parking/Parking';
import CastleDining from './components/pages/CastleDining/CastleDining';
import FeaturedTours from './components/pages/FeaturedTours/FeaturedTours';
import Tours from './components/pages/Tours/Tours';
import Journal from './components/pages/Journal/Journal';
import Terms from './components/pages/Legal/Terms';
import Privacy from './components/pages/Legal/Privacy';
import Cookies from './components/pages/Legal/Cookies';
import RetailVillageDirections from './components/pages/RetailVillageDirections/RetailVillageDirections';
import TourBooking from './components/pages/Tours/TourBooking';
import AfternoonTeaBooking from './components/pages/AfternoonTea/AfternoonTeaBooking';
import ExhibitionBooking from './components/pages/Exhibitions/ExhibitionBooking';
import VoucherRedemption from './components/pages/GiftVouchers/VoucherRedemption';
import { HelmetProvider } from 'react-helmet-async';
import { initGA, logPageView, logEvent } from './utils/analytics';
import { initializeMetaPixel, trackPageView } from './utils/metaPixel';
import DogFriendly from './components/pages/DogFriendly/DogFriendly';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    // Initialize GA with your measurement ID
    initGA(process.env.REACT_APP_GA_MEASUREMENT_ID);// Replace with your GA4 measurement ID
  }, []);

  useEffect(() => {
    // Log page view on route change
    logPageView();
  }, [location]);

  useEffect(() => {
    // Initialize Meta Pixel
    initializeMetaPixel();
  }, []);

  useEffect(() => {
    // Track page views on route change
    trackPageView();
  }, [location]);

  return (
    <HelmetProvider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <ScrollToTop />
          <Navbar />
          <Routes>
            {/* Home */}
            <Route path="/" element={<Home />} />
            
            {/* Your Visit Section */}
            <Route path="/your-visit" element={<DayTickets />} />
            <Route path="/your-visit/day-tickets" element={<DayTickets />} />
            <Route path="/your-visit/opening-times" element={<OpeningTimes />} />
            <Route path="/your-visit/directions" element={<Directions />} />
            <Route path="/your-visit/accessibility" element={<Accessibility />} />
            <Route path="/your-visit/tours" element={<Tours />} />
            <Route path="/your-visit/groups" element={<Groups />} />
            
            {/* Castle Dining Section */}
            <Route path="/castle-dining" element={<CastleDining />} />
            <Route path="/castle-dining/aviary-tearoom" element={<AviaryTearoom />} />
            <Route path="/castle-dining/afternoon-tea" element={<AfternoonTea />} />
            <Route path="/castle-dining/afternoon-tea-booking" element={<AfternoonTeaBooking />} />
            
            {/* What's On Section */}
            <Route path="/whats-on" element={<WhatsOn />} />
            <Route path="/whats-on/events" element={<Events />} />
            <Route path="/whats-on/exhibitions" element={<Exhibitions />} />
            <Route path="/whats-on/featured-tours" element={<FeaturedTours />} />
            <Route path="/whats-on/seasonal-activities" element={<SeasonalActivities />} />
            <Route path="/whats-on/exhibition-booking" element={<ExhibitionBooking />} />
            
            {/* Experiences Section */}
            <Route path="/experiences" element={<Experiences />} />
            <Route path="/getting-here" element={<GettingHere />} />
            <Route path="/stay" element={<Stay />} />
            <Route path="/accommodations" element={<Stay />} />
            <Route path="/accommodations/rooms" element={<Stay />} />
            <Route path="/accommodations/vale-house" element={<ValeHouse />} />
            <Route path="/accommodations/cottages" element={<Cottages />} />
            <Route path="/accommodations/camping" element={<Camping />} />
            <Route path="/accommodations/glamping" element={<Glamping />} />
            <Route path="/booking" element={<Stay />} />
            <Route path="/weddings" element={<Weddings />} />
            <Route path="/private-hire" element={<PrivateHire />} />
            
            {/* About Section */}
            <Route path="/about" element={<CastleStateRooms />} />
            <Route path="/about/castle-and-state-rooms" element={<CastleStateRooms />} />
            <Route path="/about/history" element={<History />} />
            <Route path="/about/gardens" element={<Gardens />} />
            <Route path="/about/adventure-playground" element={<AdventurePlayground />} />
            <Route path="/about/gift-vouchers" element={<GiftVouchers />} />
            <Route path="/about/parking" element={<Parking />} />
            <Route path="/about/dog-friendly" element={<DogFriendly />} />
           
            {/* Retail Section */}
            <Route path="/retail-village" element={<RetailVillage />} />
            <Route path="/retail-village/shops" element={<ShopsOverview />} />
            <Route path="/retail-village/shops/farm-shop" element={<FarmShop />} />
            <Route path="/retail-village/shops/duchess-collection" element={<DuchessCollection />} />
            <Route path="/retail-village/shops/tori-murphy" element={<ToriMurphy />} />
            <Route path="/retail-village/shops/bumbles" element={<Bumbles />} />
            <Route path="/retail-village/shops/grange-garden-centre" element={<GrangeGardenCentre />} />
            <Route path="/retail-village/animal-park" element={<AnimalPark />} />
            <Route path="/retail-village/dining" element={<RetailDining />} />
            <Route path="/retail-village/dining/belvoir-bistro" element={<BelvoirBistro />} />
            <Route path="/retail-village/dining/woodfired-pizza" element={<WoodfiredPizza />} />
            <Route path="/retail-village/dining/balloon-bar" element={<BalloonBar />} />
            <Route path="/retail-village/directions" element={<RetailVillageDirections />} />
            
            {/* Other Routes */}
            {/* <Route path="/book-tickets" element={<BookTickets />} /> */}
            <Route path="/select-date" element={<SelectDate />} />
            <Route path="/event/:id" element={<EventDetail />} />
            <Route path="/memberships" element={<Memberships />} />
            <Route path="/journal" element={<Journal />} />
            
            {/* Legal Pages */}
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/cookie-policy" element={<Cookies />} />
            
            {/* Legacy Routes - keeping for backward compatibility */}
            <Route path="/day-tickets" element={<DayTickets />} />
            <Route path="/castle-and-state-rooms" element={<CastleStateRooms />} />
            <Route path="/castle-and-state-rooms/history" element={<CastleStateRooms />} />
            <Route path="/castle-and-state-rooms/architecture" element={<CastleStateRooms />} />
            <Route path="/castle-and-state-rooms/collections" element={<CastleStateRooms />} />
            
            {/* Members Area */}
            <Route path="/members-area" element={<MembersArea />} />
            
            {/* Catch-all route - redirect to home */}
            <Route path="*" element={<Home />} />
            
            {/* New route */}
            <Route path="/tour-booking" element={<TourBooking />} />
            <Route path="/voucher-redemption" element={<VoucherRedemption />} />
          </Routes>
        </ThemeProvider>
      </ApolloProvider>
    </HelmetProvider>
  );
};

export default App;
