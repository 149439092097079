import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faWineGlass,
  faClock,
  faCocktail,
  faGlassCheers,
  faMusic,
  faTicketAlt,
  faMobileAlt,
  faCoffee,
  faPercent,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer/Footer';
import balloonImage from '../../../assets/balloon.jpg';
import balloonBar from '../../../assets/ballonBar.webp';
import castleGin from '../../../assets/castleGin.webp';
import cocktail from '../../../assets/cocktail.webp';
import garnishCocktail from '../../../assets/garnishCocktail.webp';
import lager from '../../../assets/lager.webp';
import makingCocktail from '../../../assets/makingCocktail.webp';
import tapas from '../../../assets/tapas.webp';
import SEO from '../../common/SEO';

const drinksMenu = [
  {
    title: "Belvoir Spritz",
    price: "9.95",
    description: "Prosecco, Aperol, soda water, orange slice",
    category: "Signature Cocktails"
  },
  {
    title: "Castle Garden",
    price: "10.95",
    description: "Hendrick's gin, elderflower, cucumber, mint, tonic",
    category: "Signature Cocktails"
  },
  {
    title: "Duchess Martini",
    price: "11.95",
    description: "Grey Goose vodka, Belvoir fruit farms cordial, prosecco",
    category: "Signature Cocktails"
  }
];

const events = [
];

const menuData = {
  tapas: [
    {
      title: "Gordal olives",
      price: "5.00",
      description: "",
      tags: ["v", "vg"]
    },
    {
      title: "Bread and butter",
      price: "4.00",
      description: "",
      tags: ["v"]
    },
    {
      title: "Pork scratchings",
      price: "4.00",
      description: "",
      tags: []
    },
    {
      title: "Mixed nuts",
      price: "4.00",
      description: "",
      tags: ["v", "vg"]
    },
    {
      title: "BBQ pulled brisket taco",
      price: "8.00",
      description: "salsa & Croxton Manor cheddar",
      tags: []
    },
    {
      title: "Chicken wings",
      price: "7.00",
      description: "truffle mayo",
      tags: []
    },
    {
      title: "Pork belly bites",
      price: "8.00",
      description: "Shriracha",
      tags: []
    },
    {
      title: "Teriyaki salmon skewers",
      price: "7.00",
      description: "",
      tags: []
    },
    {
      title: "Marinated king prawns",
      price: "12.00",
      description: "salsa Verde",
      tags: []
    },
    {
      title: "Mini fish cakes",
      price: "7.00",
      description: "tartare sauce",
      tags: []
    },
    {
      title: "Red pepper arancini",
      price: "6.00",
      description: "aioli",
      tags: ["v"]
    },
    {
      title: "Mediterranean vegetable skewers",
      price: "7.00",
      description: "",
      tags: ["v", "vg"]
    },
    {
      title: "Beetroot & goat's curd",
      price: "7.00",
      description: "",
      tags: ["v"]
    },
    {
      title: "Charcuterie Board",
      price: "17.50",
      description: "Cheese & accompaniments",
      tags: []
    }
  ],
  signatureCocktails: [
    {
      title: "Castle Bramble",
      price: "11.50",
      description: "Belvoir Raspberry and Elderflower Gin, Crème de Mure, raspberry",
      tags: []
    },
    {
      title: "Garden Spritz",
      price: "11.00",
      description: "St. Germain, Absolut, Manzana Verde, elderflower",
      tags: []
    },
    {
      title: "Limoncello Fizz",
      price: "8.50",
      description: "Limoncello, fresh lemon, sugar syrup and Prosecco garnished with fresh thyme",
      tags: []
    }
  ],
  classicCocktails: [
    {
      title: "Cosmopolitan",
      price: "11.50",
      description: "Vodka, Cointreau, cranberry, lime, chilli",
      tags: []
    },
    {
      title: "Rum Punch",
      price: "10.00",
      description: "Rum, Kirsch, fruit juices, grenadine & Griottines cherries",
      tags: []
    },
    {
      title: "Aperol Spritz",
      price: "9.00",
      description: "Aperol, Prosecco, soda, garnished with fresh orange and rosemary",
      tags: []
    },
    {
      title: "Margarita on the rocks",
      price: "10.50",
      description: "Tequila, triple sec, lime",
      tags: []
    },
    {
      title: "Belvoir Negroni",
      price: "12.00",
      description: "Belvoir Castle Dry Gin, Martini Rosso, Campari",
      tags: []
    },
    {
      title: "Old Fashioned",
      price: "12.00",
      description: "Rye whisky, sugar, bitters, soda",
      tags: []
    },
    {
      title: "Pornstar Martini",
      price: "12.00",
      description: "vodka, Passoã, passion fruit juice, lime juice",
      tags: []
    }
  ],
  dessertCocktails: [
    {
      title: "Espresso Martini",
      price: "10.00",
      description: "Kahlua, Vodka & Espresso",
      tags: []
    },
    {
      title: "Dead Man's Bounty",
      price: "10.50",
      description: "Bailey's, Dead Man's Fingers Coconut, Crème de Cacoa, whipped cream",
      tags: []
    }
  ],
  sides: [
    {
      title: "Chips",
      price: "4.95",
      description: "",
      tags: ["v", "vg"]
    },
    {
      title: "Truffle Parmesan Chips",
      price: "7.45",
      description: "",
      tags: ["v"]
    }
  ],
  desserts: [
    {
      title: "Chocolate Trifle",
      price: "6.00",
      description: "",
      tags: ["v"]
    },
    {
      title: "Sticky Toffee Pudding",
      price: "5.50",
      description: "",
      tags: ["v"]
    }
  ]
};

const BalloonBarContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: 120px;
`;

const ContentContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const Section = styled.section`
  margin: 6rem 0;
`;

const SectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
`;

const DrinksGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const DrinkCard = styled.div`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const DrinkImage = styled.div`
  height: 200px;
  background-image: url(\${props => props.image});
  background-size: cover;
  background-position: center;
`;

const DrinkContent = styled.div`
  padding: 2rem;
`;

const DrinkTitle = styled.h3`
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
`;

const DrinkPrice = styled.span`
  font-size: 1.4rem;
  color: ${props => props.theme.colors.accent};
  font-weight: bold;
`;

const DrinkDescription = styled.p`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.secondary};
  line-height: 1.6;
  margin: 1rem 0;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const InfoCard = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);

  svg {
    font-size: 2rem;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 1rem;
  }
`;

const EventsSection = styled.div`
  background: ${props => props.theme.colors.greyBackground};
  padding: 3rem;
  border-radius: 12px;
  margin-top: 3rem;
`;

const EventsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const EventCard = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
`;

const EventTitle = styled.h3`
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
`;

const EventTime = styled.p`
  font-size: 1.4rem;
  color: ${props => props.theme.colors.secondary};
  line-height: 1.6;
  margin: 1rem 0;
`;

const ImageHero = styled.div`
  position: relative;
  height: 90vh;
  overflow: hidden;
  background: ${props => props.theme.colors.primary}; // Fallback background
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${balloonImage});
    background-size: cover;
    background-position: center;
    z-index: 1;
  }
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.5)
  );
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
`;

const HeroTitle = styled.h1`
  font-family: ${props => props.theme.fonts.main};
  font-size: 4rem;
  color: white;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  
  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const ScrollIndicator = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  cursor: pointer;
  animation: bounce 2s infinite;

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateX(-50%) translateY(0);
    }
    40% {
      transform: translateX(-50%) translateY(-10px);
    }
    60% {
      transform: translateX(-50%) translateY(-5px);
    }
  }

  svg {
    width: 40px;
    height: 40px;
    fill: none;
    stroke: white;
    stroke-width: 2;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.3));
    opacity: 0.8;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }
`;

const GallerySection = styled.section`
  padding: 6rem 0;
  background: white;
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 300px);
  gap: 1.5rem;
  padding: 0 2rem;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 250px);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 200px);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 200px);
  }
`;

const GalleryItem = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  grid-column: ${props => props.$large ? 'span 2' : 'span 1'};
  grid-row: ${props => props.$large ? 'span 2' : 'span 1'};

  &:hover {
    transform: scale(1.02);
  }
`;

const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
`;

const GalleryOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 1.5rem;
  text-align: center;
`;

const GalleryTitle = styled.h3`
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
`;

const MembershipSection = styled.section`
  background: ${props => props.theme.colors.greyBackground};
  padding: 4rem 0;
  text-align: center;
`;

const MembershipCard = styled.div`
  background: white;
  max-width: 800px;
  margin: 0 auto;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const MembershipIcon = styled.div`
  width: 80px;
  height: 80px;
  background: ${props => props.theme.colors.accent};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  
  svg {
    color: white;
    font-size: 2.5rem;
  }
`;

const ComingSoonBadge = styled.span`
  display: inline-block;
  background-color: ${props => props.theme.colors.accent};
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  margin-left: 0.5rem;
  vertical-align: middle;
`;

const MembershipButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-weight: 600;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${props => props.theme.colors.accent};
    transform: translateY(-2px);
  }
  
  svg {
    margin-left: 0.5rem;
  }
`;

const IntroText = styled.p`
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
`;

const BenefitsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
`;

const BenefitItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const BenefitText = styled.span`
  margin-left: 0.5rem;
`;

const MenuSection = styled.div`
  margin: 5rem 0;
`;

const MenuTabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  flex-wrap: wrap;
`;

const MenuTab = styled.button`
  background: ${props => props.active ? props.theme.colors.accent : 'transparent'};
  color: ${props => props.active ? 'white' : props.theme.colors.primary};
  border: 2px solid ${props => props.active ? props.theme.colors.accent : props.theme.colors.primary};
  padding: 0.8rem 1.5rem;
  margin: 0.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50px;
  
  &:hover {
    background: ${props => props.active ? props.theme.colors.accent : props.theme.colors.greyBackground};
  }
`;

const MenuGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 3rem;
  margin-top: 3rem;
`;

const MenuCategory = styled.div`
  background: white;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
`;

const CategoryTitle = styled.h3`
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  border-bottom: 2px solid ${props => props.theme.colors.accent};
  padding-bottom: 1rem;
`;

const MenuItem = styled.div`
  margin-bottom: 1.5rem;
`;

const ItemTitle = styled.h4`
  font-size: 1.2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const ItemPrice = styled.span`
  color: ${props => props.theme.colors.accent};
  font-weight: bold;
`;

const ItemDescription = styled.p`
  color: ${props => props.theme.colors.secondary};
  font-size: 1rem;
  line-height: 1.4;
`;

const MenuItemTags = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

const MenuItemTag = styled.span`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
`;

const WelcomeSection = styled.section`
  padding: 4rem 0;
  text-align: center;
  background: white;
`;

const WelcomeText = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  color: ${props => props.theme.colors.secondary};
  max-width: 800px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const BalloonBar = () => {
  const [activeTab, setActiveTab] = React.useState('tapas');

  const renderMenuItems = (category) => {
    return (
      <MenuGrid>
        <MenuCategory>
          <CategoryTitle>{category === 'tapas' ? 'Tapas' : 
                         category === 'signatureCocktails' ? 'Signature Cocktails' :
                         category === 'classicCocktails' ? 'Classic Cocktails' :
                         category === 'dessertCocktails' ? 'Dessert Cocktails' :
                         category === 'sides' ? 'Sides' : 'Desserts'}</CategoryTitle>
          {menuData[category].map((item, index) => (
            <MenuItem key={index}>
              <ItemTitle>
                {item.title}
                <ItemPrice>£{item.price}</ItemPrice>
              </ItemTitle>
              {item.description && <ItemDescription>{item.description}</ItemDescription>}
              {item.tags && item.tags.length > 0 && (
                <MenuItemTags>
                  {item.tags.map((tag, tagIndex) => (
                    <MenuItemTag key={tagIndex}>{tag}</MenuItemTag>
                  ))}
                </MenuItemTags>
              )}
            </MenuItem>
          ))}
        </MenuCategory>
      </MenuGrid>
    );
  };

  return (
    <BalloonBarContainer>
      <SEO 
        title="The Balloon Bar - Cocktails & Fine Spirits"
        description="Experience craft cocktails and fine spirits at The Balloon Bar in Belvoir Castle. Enjoy our signature drinks, tapas menu, and elegant atmosphere in our historic setting."
        keywords="Balloon Bar, Belvoir Castle bar, cocktails, craft spirits, tapas, luxury bar, historic bar"
        canonicalUrl="https://www.belvoircastle.com/retail-village/dining/balloon-bar"
        ogImage={balloonBar}
        structuredData={{
          "@context": "https://schema.org",
          "@type": "BarOrPub",
          "name": "The Balloon Bar at Belvoir Castle",
          "description": "Experience craft cocktails and fine spirits at The Balloon Bar in Belvoir Castle. Enjoy our signature drinks, tapas menu, and elegant atmosphere in our historic setting.",
          "image": balloonBar,
          "servesCuisine": "Tapas",
          "priceRange": "££",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Belvoir Castle",
            "addressLocality": "Grantham",
            "addressRegion": "Leicestershire",
            "postalCode": "NG32 1PE",
            "addressCountry": "UK"
          },
          "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            "opens": "11:00",
            "closes": "23:00"
          },
          "menu": "https://www.belvoircastle.com/retail-village/dining/balloon-bar/menu",
          "acceptsReservations": true,
          "telephone": "+441476871001"
        }}
      />
      <ImageHero>
        <VideoOverlay>
          <HeroTitle>The Balloon Bar</HeroTitle>
          <HeroSubtitle>Crafted Cocktails & Fine Spirits in Historic Surroundings</HeroSubtitle>
        </VideoOverlay>
        <ScrollIndicator onClick={() => window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })}>
          <svg viewBox="0 0 24 24">
            <path d="M12 5L12 19M12 19L5 12M12 19L19 12" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </ScrollIndicator>
      </ImageHero>

      <ContentContainer>
        <WelcomeSection>
          <SectionTitle>Welcome to The Balloon Bar</SectionTitle>
          <WelcomeText>
            Step into our historic bar where tradition meets innovation. The Balloon Bar offers an elegant setting for enjoying expertly crafted cocktails, fine wines, and delectable tapas. Whether you're here for a casual drink or a special celebration, our team is dedicated to providing an exceptional experience at the heart of Belvoir retail village.
          </WelcomeText>
        </WelcomeSection>

        <MenuSection>
          <SectionTitle>Our Menu</SectionTitle>
          <MenuTabs>
            <MenuTab 
              active={activeTab === 'tapas'} 
              onClick={() => setActiveTab('tapas')}
            >
              Tapas
            </MenuTab>
            <MenuTab 
              active={activeTab === 'signatureCocktails'} 
              onClick={() => setActiveTab('signatureCocktails')}
            >
              Signature Cocktails
            </MenuTab>
            <MenuTab 
              active={activeTab === 'classicCocktails'} 
              onClick={() => setActiveTab('classicCocktails')}
            >
              Classic Cocktails
            </MenuTab>
            <MenuTab 
              active={activeTab === 'dessertCocktails'} 
              onClick={() => setActiveTab('dessertCocktails')}
            >
              Dessert Cocktails
            </MenuTab>
            <MenuTab 
              active={activeTab === 'sides'} 
              onClick={() => setActiveTab('sides')}
            >
              Sides
            </MenuTab>
            <MenuTab 
              active={activeTab === 'desserts'} 
              onClick={() => setActiveTab('desserts')}
            >
              Desserts
            </MenuTab>
          </MenuTabs>
          {renderMenuItems(activeTab)}
        </MenuSection>

        <Section>
          <SectionTitle>Events & Entertainment</SectionTitle>
          <EventsSection>
            <EventsList>
              {events.map((event, index) => (
                <EventCard key={index}>
                  <EventTitle>{event.title}</EventTitle>
                  <EventTime>{event.day} | {event.time}</EventTime>
                </EventCard>
              ))}
            </EventsList>
          </EventsSection>
        </Section>

        <Section>
          <SectionTitle>Visit Us</SectionTitle>
          <InfoGrid>
            <InfoCard>
              <FontAwesomeIcon icon={faClock} />
              <h3>Opening Hours</h3>
              <p>Thursday - Saturday<br />17:00 - 23:00</p>
            </InfoCard>
            <InfoCard>
              <FontAwesomeIcon icon={faCocktail} />
              <h3>Happy Hour</h3>
              <p>Thursday & Friday<br />16:00 - 18:00</p>
            </InfoCard>
            <InfoCard>
              <FontAwesomeIcon icon={faGlassCheers} />
              <h3>Private Events</h3>
              <p>Available for<br />private bookings</p>
            </InfoCard>
          </InfoGrid>
        </Section>

        <GallerySection>
          <SectionTitle>Our Bar</SectionTitle>
          <GalleryGrid>
            <GalleryItem $large>
              <GalleryImage src={makingCocktail} alt="Mixologist crafting a cocktail" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Mixology in Action</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={cocktail} alt="Signature cocktails" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Signature Cocktails</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={balloonBar} alt="Bar atmosphere" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Elegant Atmosphere</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={castleGin} alt="Premium spirits selection" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Premium Spirits</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={lager} alt="Beer selection" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Fine Beers</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
            
            <GalleryItem>
              <GalleryImage src={garnishCocktail} alt="Cocktail garnishing" />
              <GalleryOverlay className="overlay">
                <GalleryTitle>Attention to Detail</GalleryTitle>
              </GalleryOverlay>
            </GalleryItem>
          </GalleryGrid>
        </GallerySection>

        <MembershipSection>
          <MembershipCard>
            <MembershipIcon>
              <FontAwesomeIcon icon={faTicketAlt} />
            </MembershipIcon>
            <SectionTitle>Friends of Belvoir Benefits</SectionTitle>
            <IntroText>
              Scan your membership card to enjoy exclusive benefits:
            </IntroText>
            <BenefitsList>
              <BenefitItem>
                <FontAwesomeIcon icon={faPercent} />
                <BenefitText>10% discount on all food and beverages</BenefitText>
              </BenefitItem>
              <BenefitItem>
                <FontAwesomeIcon icon={faMobileAlt} />
                <BenefitText>
                  Earn loyalty points with every visit
                  <ComingSoonBadge>Summer 2025</ComingSoonBadge>
                </BenefitText>
              </BenefitItem>
              <BenefitItem>
                <FontAwesomeIcon icon={faCoffee} />
                <BenefitText>
                  Digital coffee card rewards
                  <ComingSoonBadge>Summer 2025</ComingSoonBadge>
                </BenefitText>
              </BenefitItem>
            </BenefitsList>
            <MembershipButton to="/memberships">
              Become a Friend of Belvoir <FontAwesomeIcon icon={faArrowRight} />
            </MembershipButton>
          </MembershipCard>
        </MembershipSection>
      </ContentContainer>
      <Footer />
    </BalloonBarContainer>
  );
};

export default BalloonBar; 